import { IBrineConverterUnitTables } from "../../../app/models/brine";
import { ISteamResultTables } from "../../../app/models/steam";

export function calculatePressure(Temperature: number) {
    const LPTI = 0.033131068906;
    const LPT1 = 0.00020384339303;
    const LPT2 = 0.000046575849849;
    const LPT3 = -1.3419782316E-07;
    const LPT4 = 5.9984017216E-09;
    const LPT5 = -1.3810671716E-11;
    const LPT6 = 1.2287399E-13;
    const LPT7 = -1.9933618115E-16;
    const LPT8 = 3.5588649505E-20;
    const LPT9 = 1.9357509083E-22;
    const LPT10 = -1.4133975143E-25;
  
    const HPTI = 179414.81443;
    const HPT1 = -1564.16121;
    const HPT2 = 4.994376831;
    const HPT3 = -0.0064039767358;
    const HPT4 = 2.1677515125E-06;
    const HPT5 = -4.0462499406E-09;
    const HPT6 = 1.0893731703E-11;
    const HPT7 = 1.9556266539E-14;
    const HPT8 = -7.8771037911E-17;
    const HPT9 = 8.118011756E-20;
    const HPT10 = -2.8307351709E-23;
  
    if (Temperature >= 35 && Temperature <= 500) {
      return LPTI + (Temperature * LPT1) + (Temperature ** 2 * LPT2) + (Temperature ** 3 * LPT3) + (Temperature ** 4 * LPT4) + (Temperature ** 5 * LPT5) + (Temperature ** 6 * LPT6) + (Temperature ** 7 * LPT7) + (Temperature ** 8 * LPT8) + (Temperature ** 9 * LPT9) + (Temperature ** 10 * LPT10);
    } else if (Temperature > 500 && Temperature <= 700) {
      return HPTI + (Temperature * HPT1) + (Temperature ** 2 * HPT2) + (Temperature ** 3 * HPT3) + (Temperature ** 4 * HPT4) + (Temperature ** 5 * HPT5) + (Temperature ** 6 * HPT6) + (Temperature ** 7 * HPT7) + (Temperature ** 8 * HPT8) + (Temperature ** 9 * HPT9) + (Temperature ** 10 * HPT10);
    } else {
      return null;
    }
}

export function Hfp(Pressure: number) {
    const LHfPI = 126.84690612;
    const LHfP1 = 4.5091338527;
    const LHfP2 = -0.064755771726;
    const LHfP3 = 0.00065611708448;
    const LHfP4 = -4.2526853462E-06;
    const LHfP5 = 1.7807556661E-08;
    const LHfP6 = -4.8588057546E-11;
    const LHfP7 = 8.5622243071E-14;
    const LHfP8 = -9.3795933463E-17;
    const LHfP9 = 5.8013379458E-20;
    const LHfP10 = -1.5466083722E-23;
    
    if (Pressure >= 14 && Pressure <= 700) {
      return LHfPI + (Pressure * LHfP1) + (Pressure ** 2 * LHfP2) + (Pressure ** 3 * LHfP3) + (Pressure ** 4 * LHfP4) + (Pressure ** 5 * LHfP5) + (Pressure ** 6 * LHfP6) + (Pressure ** 7 * LHfP7) + (Pressure ** 8 * LHfP8) + (Pressure ** 9 * LHfP9) + (Pressure ** 10 * LHfP10);
    } else {
      return null;
    }
}

export function Hgp(Pressure: number) {
    const LHgPI = 1131.9154266;
    const LHgP1 = 1.6053860838;
    const LHgP2 = -0.023960975938;
    const LHgP3 = 0.00024548795041;
    const LHgP4 = -1.6500321792E-06;
    const LHgP5 = 7.2290130989E-09;
    const LHgP6 = -2.0630555963E-11;
    const LHgP7 = 3.7875224954E-14;
    const LHgP8 = -4.3014517297E-17;
    const LHgP9 = 2.7451588374E-20;
    const LHgP10 = -7.519762816E-24;
    
    if (Pressure >= 14 && Pressure <= 700) {
      return LHgPI + (Pressure * LHgP1) + (Pressure ** 2 * LHgP2) + (Pressure ** 3 * LHgP3) + (Pressure ** 4 * LHgP4) + (Pressure ** 5 * LHgP5) + (Pressure ** 6 * LHgP6) + (Pressure ** 7 * LHgP7) + (Pressure ** 8 * LHgP8) + (Pressure ** 9 * LHgP9) + (Pressure ** 10 * LHgP10);
    } else {
      return null;
    }
}
  
export function calculateAverageSteamResult(arr: ISteamResultTables[], key: keyof ISteamResultTables): number {

    let sum = 0;
    let count = 0;

    arr.forEach((obj) => {
        if (typeof obj[key] === "number") {
        sum += obj[key] as number;
        count++;
        }
    });

    const average = sum / count;
    return average;
}

export function calculateAverageBrineResult(arr: IBrineConverterUnitTables[], key: keyof IBrineConverterUnitTables): number {

  let sum = 0;
  let count = 0;

  arr.forEach((obj) => {
      if (typeof obj[key] === "number") {
      sum += obj[key] as number;
      count++;
      }
  });

  const average = sum / count;
  return average;
}

export function samePort(steamPortLoc: string, brinePortLoc: string) {
    return steamPortLoc === brinePortLoc;
}

export function pressDiffFlag(avgSteamPressure: number, avgBrinePressure: number) {
    if(Math.abs(avgSteamPressure - avgBrinePressure) > 2) {
        return true;
    } else {
        return false;
    }
}

export function avgTestPressure(samePort: boolean, avgBrinePressure: number, avgSteamPressure: number, pressDiffFlag: boolean): number {
    if (samePort) {
        return (avgBrinePressure + avgSteamPressure) / 2;
    } else {
        if (pressDiffFlag) {
        return 0;
        } else {
        return (avgBrinePressure + avgSteamPressure) / 2;
        }
    }
}

export function steamEnthAvgPFormula(avgTestPress: number, baroPress: number) {
    if (avgTestPress !== 0) {
        const formula = Hgp(avgTestPress + baroPress);
        if(formula !== null) {
            return formula;
        } else {
            return 0;
        }
    } else {
      return 0;
    }
}

export function brineEnthAvgPFormula(avgTestPress: number, baroPress: number) {
    if (avgTestPress !== 0) {
        const formula = Hfp(avgTestPress + baroPress);
        if(formula !== null) {
            return formula;
        } else {
            return 0;
        }
    } else {
      return 0;
    }
}

export function tmfAvgPFormula(avgTestPressure: number, steamFlow: number, brineFlow: number) {
    if (avgTestPressure === 0) {
      return 0;
    } else if (steamFlow !== 0 && brineFlow !== 0) {
      return steamFlow + brineFlow;
    } else {
      return 0;
    }
}

export function tmfAvgPNgcCorFormula(avgTestPress: number, cSteamFlow: number, BrineFlow: number) {
    if (avgTestPress === 0) {
      return 0;
    } else if (cSteamFlow !== 0 && BrineFlow !== 0) {
      return cSteamFlow + BrineFlow;
    } else {
      return 0;
    }
}

export function steamFractionAvgPFormula(avgTestPress: number, steamFlow: number, tmfAvgP: number) {
    if (avgTestPress === 0) {
      return 0;
    } else {
      return steamFlow / tmfAvgP;
    }
}

export function steamFractionAvgPNcgFormula(ncgContent: number, avgTestPress: number, cSteamFlow: number, tmfAvgPNcgCor: number) {
    if (ncgContent === 0) {
      return 0;
    } else if (avgTestPress === 0) {
      return 0;
    } else if (cSteamFlow === 0) {
      return 0;
    } else {
      return cSteamFlow / tmfAvgPNcgCor;
    }
}

export function steamFractionAvgPChoiceFormula(ncgContent: number, steamFractAvgP: number, steamFracAvgPNcg: number) {
    if (ncgContent === 0) {
      return steamFractAvgP;
    } else {
      return steamFracAvgPNcg;
    }
}

export function steamEnthSteamPFormula(samePort: boolean, pressDiffFlag: boolean, avgSteamPress: number, baroPress: number) {
    if (samePort) {
      return 0;
    } else if (pressDiffFlag) {
      return Hgp(avgSteamPress + baroPress);
    } else {
      return 0;
    }
}

export function brineEnthSteamPFormula(samePort: boolean, pressDiffFlag: boolean, avgSteamPress: number, baroPress: number) {
    if (samePort) {
      return 0;
    } else if (pressDiffFlag) {
      return Hfp(avgSteamPress + baroPress);
    } else {
      return 0;
    }
}

export function calcBrineSteamPFormula(samePort: boolean, pressDiffFlag: boolean, brineFlow: number, steamFlow: number, steamEnthSteamP: number, brineEnthBrineP: number, steamEnthBrineP: number, brineEnthSteamP: number) {
    if (samePort) {
      return 0;
    } else if (pressDiffFlag) {
      if (brineFlow === 0 || steamFlow === 0) {
        return 0;
      } else {
        return ((steamEnthSteamP * steamFlow) - (brineEnthBrineP * brineFlow) + (steamEnthBrineP * (brineFlow - steamFlow))) / (steamEnthBrineP - brineEnthSteamP);
      }
    } else {
      return 0;
    }
}

export function calcBrineSteamPNcgCorFormula(NCG_content__wt: number, Same_Port: boolean, Press_Diff_Flag: boolean, Brine_Flow: number, Steam_Flow: number, C_Steam_Flow: number, Steam_Enth___Steam_P: number, Brine_Enth___Brine_P: number, Steam_Enth___Brine_P: number, Brine_Enth___Steam_P: number) {
    if (NCG_content__wt === 0) {
      return 0;
    } else if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      if (Brine_Flow === 0 || Steam_Flow === 0) {
        return 0;
      } else if (C_Steam_Flow === 0) {
        return 0;
      } else {
        return ((Steam_Enth___Steam_P * C_Steam_Flow) - (Brine_Enth___Brine_P * Brine_Flow) + (Steam_Enth___Brine_P * (Brine_Flow - C_Steam_Flow))) / (Steam_Enth___Brine_P - Brine_Enth___Steam_P);
      }
    } else {
      return 0;
    }
}

export function tmfSteamPFormula(Steam_Flow: number, Calc_Brine___Steam_P: number) {
    if (Steam_Flow !== 0 && Calc_Brine___Steam_P !== 0) {
      return Steam_Flow + Calc_Brine___Steam_P;
    } else {
      return 0;
    }
}

export function tmfSteamPNcgCorFormula(NCG_content__wt: number, C_Steam_Flow: number, Calc_Brine___Steam_P_NCG_Corr: number) {
    if (NCG_content__wt === 0) {
      return 0;
    } else if (C_Steam_Flow !== 0 && Calc_Brine___Steam_P_NCG_Corr !== 0) {
      return C_Steam_Flow + Calc_Brine___Steam_P_NCG_Corr;
    } else {
      return 0;
    }
}

export function steamFracSteamPFormula(TMF___Steam_P: number, Steam_Flow: number) {
    if (TMF___Steam_P === 0) {
      return 0;
    } else {
      return Steam_Flow / TMF___Steam_P;
    }
}

export function steamFracSteamPNcgCorFormula(NCG_content__wt: number, TMF___Steam_P_NCG_corr: number, C_Steam_Flow: number) {
    if (NCG_content__wt === 0) {
      return 0;
    } else if (TMF___Steam_P_NCG_corr === 0) {
      return 0;
    } else {
      return C_Steam_Flow / TMF___Steam_P_NCG_corr;
    }
}

export function steamFractSteamPChoiceFormula(NCG_content__wt: number, Steam_Fraction___Steam_P: number, Steam_Fraction___Steam_P_NCG_Corr: number) {
    if (NCG_content__wt === 0) {
      return Steam_Fraction___Steam_P;
    } else {
      return Steam_Fraction___Steam_P_NCG_Corr;
    }
}

export function steamEnthBrinePFormula(Same_Port: boolean, Press_Diff_Flag: boolean, Avg_Brine_Pressure: number, Barometric_Press_psia: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return Hgp(Avg_Brine_Pressure + Barometric_Press_psia);
    } else {
      return 0;
    }
}

export function brineEnthBrinePFormula(Same_Port: boolean, Press_Diff_Flag: boolean, Avg_Brine_Pressure: number, Barometric_Press_psia: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return Hfp(Avg_Brine_Pressure + Barometric_Press_psia);
    } else {
      return 0;
    }
}

export function calcSteamBrinePFormula(Same_Port: boolean, Press_Diff_Flag: boolean, Steam_Enth___Steam_P: number, Steam_Enth___Brine_P:number, Steam_Flow: number, Brine_Enth___Brine_P: number, Brine_Flow: number, Brine_Enth___Steam_P: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return ((Steam_Enth___Steam_P * Steam_Flow) - (Brine_Enth___Brine_P * Brine_Flow) + (Brine_Enth___Steam_P * (Brine_Flow - Steam_Flow))) / (Steam_Enth___Brine_P - Brine_Enth___Steam_P);
    } else {
      return 0;
    }
}

export function calcSteamBrinePNcgCorFormula(NCG_content__wt: number, Same_Port: boolean, Press_Diff_Flag: boolean, Steam_Enth___Steam_P: number, Steam_Enth___Brine_P:number, C_Steam_Flow: number, Brine_Enth___Brine_P: number, Brine_Flow: number, Brine_Enth___Steam_P: number) {
    if (NCG_content__wt === 0) {
      return 0;
    } else if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return ((Steam_Enth___Steam_P * C_Steam_Flow) - (Brine_Enth___Brine_P * Brine_Flow) + (Brine_Enth___Steam_P * (Brine_Flow - C_Steam_Flow))) / (Steam_Enth___Brine_P - Brine_Enth___Steam_P);
    } else {
      return 0;
    }
}

export function steamFracBrinePFormula(TMF___Steam_P: number, Calc_Steam___Brine_P: number) {
    if (TMF___Steam_P === 0) {
      return 0;
    } else {
      return Calc_Steam___Brine_P / TMF___Steam_P;
    }
}

export function steamFracBrinePNcgCorFormula(TMF___Steam_P: number, Calc_Steam___Brine_P: number) {
    if (TMF___Steam_P === 0) {
      return 0;
    } else {
      return Calc_Steam___Brine_P / TMF___Steam_P;
    }
}

export function steamFracBrinePChoiceFormula(TMF___Steam_P: number, Calc_Steam___Brine_P: number) {
    if (TMF___Steam_P === 0) {
      return 0;
    } else {
      return Calc_Steam___Brine_P / TMF___Steam_P;
    }
}

export function totEnthAvgPressFOrmula(Same_Port: boolean, Press_Diff_Flag: boolean, Avg_Brine_Pressure: number, Barometric_Press_psia: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return Hgp(Avg_Brine_Pressure + Barometric_Press_psia);
    } else {
      return 0;
    }
}

export function totEnthAvgPressNcgCorFormula(Same_Port: boolean, Press_Diff_Flag: boolean, Avg_Brine_Pressure: number, Barometric_Press_psia: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return Hfp(Avg_Brine_Pressure + Barometric_Press_psia);
    } else {
      return 0;
    }
}

export function totEnthDiffPressFormula(Same_Port: boolean, Press_Diff_Flag: boolean, Steam_Enth___Steam_P: number, Steam_Flow: number, Brine_Enth___Brine_P: number, Brine_Flow: number, Brine_Enth___Steam_P: number, Steam_Enth___Brine_P: number) {
    if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return ((Steam_Enth___Steam_P * Steam_Flow) - (Brine_Enth___Brine_P * Brine_Flow) + (Brine_Enth___Steam_P * (Brine_Flow - Steam_Flow))) / (Steam_Enth___Brine_P - Brine_Enth___Steam_P);
    } else {
      return 0;
    }
}

export function totEnthDiffPressNcgCorFormula(NCG_content__wt: number, Same_Port: boolean, Press_Diff_Flag: boolean, Steam_Enth___Steam_P: number, C_Steam_Flow: number, Brine_Enth___Brine_P: number, Brine_Flow: number, Brine_Enth___Steam_P: number, Steam_Enth___Brine_P: number) {
    if (NCG_content__wt === 0) {
      return 0;
    } else if (Same_Port) {
      return 0;
    } else if (Press_Diff_Flag) {
      return ((Steam_Enth___Steam_P * C_Steam_Flow) - (Brine_Enth___Brine_P * Brine_Flow) + (Brine_Enth___Steam_P * (Brine_Flow - C_Steam_Flow))) / (Steam_Enth___Brine_P - Brine_Enth___Steam_P);
    } else {
      return 0;
    }
}

export function steamEnthProdP(Production_Sep_Pressure: number, Barometric_Press_psia: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      return Hgp(Production_Sep_Pressure + Barometric_Press_psia);
    }
}

export function brineEnthProdP(Production_Sep_Pressure: number, Barometric_Press_psia: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      return Hfp(Production_Sep_Pressure + Barometric_Press_psia);
    }
}

export function calcSteamProdP(Production_Sep_Pressure: number, Avg_Total_Flow: number, Calc_Brine___Prod_P: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      const formula = Avg_Total_Flow - Calc_Brine___Prod_P;
        if (isNaN(formula) || formula === -Infinity || formula === Infinity) {
          return 0;
        } else {
          return formula;
        }
    }
}

export function calcBrineProdPFormula(Production_Sep_Pressure: number, Avg_Total_Flow: number, Avg_Enthalpy: number, Steam_Enth___Prod_P: number, Brine_Enth___Prod_P: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      const formula = (Avg_Total_Flow * (Avg_Enthalpy - Steam_Enth___Prod_P)) / (Brine_Enth___Prod_P - Steam_Enth___Prod_P);
        if (isNaN(formula) || formula === -Infinity || formula === Infinity) {
          return 0;
        } else {
          return formula;
        }
    }
}

export function tmfProdPFormula(Production_Sep_Pressure: number, Calc_Steam___Prod_P: number, Calc_Brine___Prod_P: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      const formula = Calc_Steam___Prod_P + Calc_Brine___Prod_P;
      if (isNaN(formula) || formula === -Infinity || formula === Infinity) {
        return 0;
      } else {
        return formula;
      }
    }
}

export function steamFracProdP(Production_Sep_Pressure: number, Calc_Steam___Prod_P: number, TMF___Production_P: number) {
    if (Production_Sep_Pressure === 0) {
      return 0;
    } else {
      if (Calc_Steam___Prod_P === 0) {
        return 0;
      } else {
        const formula = Calc_Steam___Prod_P / TMF___Production_P;
        if (isNaN(formula) || formula === -Infinity || formula === Infinity) {
          return 0;
        } else {
          return formula;
        }
      }
    }
}

export function totalAvgFlowFormula(NCG_content__wt: number, Same_Port: boolean, Press_Diff_Flag: boolean, TMF___Steam_P: number, TMF___Steam_P_NCG_corr: number, TMF___Avg_P: number, TMF___Avg_P_NCG_corr: number): number {
  if (NCG_content__wt === 0) {
      if (Same_Port) {
          return Press_Diff_Flag ? TMF___Steam_P : TMF___Avg_P;
      } else {
          return Press_Diff_Flag ? TMF___Steam_P_NCG_corr : TMF___Avg_P_NCG_corr;
      }
  } else {
      return Same_Port ? TMF___Avg_P_NCG_corr : Press_Diff_Flag ? TMF___Steam_P_NCG_corr : TMF___Avg_P_NCG_corr;
  }
}

export function avgEnthalpyFormula( NCG_content__wt: number, Same_Port: boolean, Press_Diff_Flag: boolean, Total_Enth_Diff_Pressures: number, Total_Enth_Avg_Pressure: number, Total_Enth_Diff_Pressures_NCG_corr: number, Total_Enth_Avg_Pressure_NCG_corr: number): number {
  if (NCG_content__wt === 0) {
      if (Same_Port) {
          return Press_Diff_Flag ? Total_Enth_Diff_Pressures : Total_Enth_Avg_Pressure;
      } else {
          return Press_Diff_Flag
              ? Total_Enth_Diff_Pressures_NCG_corr
              : Total_Enth_Avg_Pressure_NCG_corr;
      }
  } else {
      return Same_Port
          ? Total_Enth_Avg_Pressure_NCG_corr
          : Press_Diff_Flag
          ? Total_Enth_Diff_Pressures_NCG_corr
          : Total_Enth_Avg_Pressure_NCG_corr;
  }
}




