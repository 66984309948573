import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { observer } from "mobx-react-lite";
import { IQueryChart } from "../../../../app/models/query";
import { useStore } from "../../../../app/stores/store";

const initCharts: IQueryChart[] = [
  {
    name: "",
    data: [
      {
        x: "",
        y: null,
      },
    ],
  },
];

function QueryCharts() {
  const { queryChart } = useStore().queryStore;

  const options: ApexCharts.ApexOptions = {
    xaxis: {
      type: "numeric",
      tickAmount: 8,
    },
    // yaxis: {
    //   title: {
    //     text: y_label.toLocaleUpperCase()
    //   },
    //   max:  Math.max.apply(Math, data.map((item) => item[y_label])),
    // },
    // title: {
    //   align: 'left',
    //   text: 'Well',
    // },
  };
  return <ReactApexChart options={options} series={queryChart ?? initCharts} />;
}

export default observer(QueryCharts);
