import { makeAutoObservable } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { Roles } from "../config/enum";
import { IAccountInfo, IAccountLoginValues, IAccountRegisterValues } from "../models/account";
import { store } from "./store";

export default class AccountStore {
    account: IAccountInfo | null = null;
    
    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.account;
    }

    isInRole = (roles: Roles[]) => {
        const length = this.account?.roles.filter((item) => roles.includes(item)).length;
        return !!length;
    }

    login = async (creds: IAccountLoginValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            this.setUser(user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    logout = async () => {
        store.commonStore.setToken(null);
        this.setUser(null);
        history.push('/');
    }

    register = async (creds: IAccountRegisterValues) => {
        try {
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token);
            this.setUser(user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current()
            this.setUser(user);
        } catch (error) {
            console.log(error);
        }
    }

    private setUser = (user: IAccountInfo | null) => {
        this.account = user;
        if (this.account) this.account.photo = user?.photo ?? '/assets/user.png'; // utk handle logout
    }

    setImage = (photo: string) => {
        if (this.account) this.account.photo = photo;
    }
}