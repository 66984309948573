import { Box } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { observer } from "mobx-react-lite";
import { TGeologicalChartDataResponse } from "../../../app/models/geological";
import { LINE_CHARTS_LEGEND } from "../../../app/config/enum";

type TGeologicalReportLineChartProps = {
  data: TGeologicalChartDataResponse[];
};

// const data = [
//   {
//     name: "Page A",
//     MEB: 4000,
//     Rop: 2400,
//     TVD: 2400,
//     LOSS: 8000,
//   },
//   {
//     name: "Page B",
//     MEB: 3000,
//     Rop: 1398,
//     TVD: 2210,
//     LOSS: 6500,
//   },
//   {
//     name: "Page C",
//     MEB: 2000,
//     Rop: 9800,
//     TVD: 2290,
//     LOSS: 6800,
//   },
//   {
//     name: "Page D",
//     MEB: 2780,
//     Rop: 3908,
//     TVD: 2000,
//     LOSS: 7000,
//   },
//   {
//     name: "Page E",
//     MEB: 1890,
//     Rop: 4800,
//     TVD: 2181,
//     LOSS: 2000,
//   },
//   {
//     name: "Page F",
//     MEB: 2390,
//     Rop: 3800,
//     TVD: 2500,
//     LOSS: 2800,
//   },
//   {
//     name: "Page G",
//     MEB: 3490,
//     Rop: 4300,
//     TVD: 2100,
//     LOSS: 3000,
//   },
// ];

function GeologicalReportLineChart({ data }: TGeologicalReportLineChartProps) {
  return (
    <Box sx={{ height: "320px", display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          layout="vertical"
          width={1000}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{
              fontSize: 12,
              fontStyle: "italic",
              offset: "-5",
            }}
            label={{ offset: "-5" }}
            type="number"
          />
          <YAxis dataKey="depth" type="category" />
          <Tooltip />
          {LINE_CHARTS_LEGEND.map((item) => (
            <Line strokeWidth={2} dataKey={item.value} stroke={item.color} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default observer(GeologicalReportLineChart);
