import { te } from "date-fns/locale";
import { TGeneratedStep2Grid, TGeneratedStep3Grid, TZaxis } from "./type";

export const steps = ["Correction", "Comparison", "Saturation"];

export const zAxisOptions = [
  {
    label: "Elevation",
    value: "elevation",
  },
  {
    label: "Depth",
    value: "depth",
  },
];

export const comapareOptions = [
  {
    label: "Pressure",
    value: "pressure",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
  {
    label: "dP/dz",
    value: "dP/dz",
  },
  {
    label: "dT/dz",
    value: "dT/dz",
  },
];

export const selectionsOptions = [
  {
    label: "Corrected",
    value: "corrected",
  },
  {
    label: "Meassure",
    value: "meassure",
  },
  {
    label: "Reference",
    value: "reference",
  },
];

export const editableRowData = ["checkedPressure", "checkedTemperature"];

export const capitalizeFirstLetter = (sentence: string) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const mergeAndInterpolateReferenceData = (referenceData: any, measuredAndCheckedData: any) => {
  let referenceMeasuredAndCheckedData = [...measuredAndCheckedData, ...referenceData];
  let convertedData = [...referenceMeasuredAndCheckedData].map((item, index) => ({
    sequence: index + 1,
    depth: item.depth,
    elevation: item.elevation,
    pressure: item.pressure ?? item.measuredPressure,
    temperature: item.temperature ?? item.measuredTemperature,
  }));
  let maxExistingData = [...referenceData].reduce((acc, current) => Math.max(acc, current.depth), referenceData[0].depth);
  let minExistingData = [...referenceData].reduce((acc, current) => Math.min(acc, current.depth), referenceData[0].depth);
  let mergedData = [...convertedData]
    .filter((item) => item.depth >= minExistingData && item.depth <= maxExistingData)
    .sort((a, b) => a.depth - b.depth);

  let result = [...mergedData].map((item, index) => {
    if (referenceData.some((existing: any) => item.depth === existing.depth)) {
      return item;
    } else {
      let interpolatePressure =
        mergedData[index - 1].pressure +
        ((item.depth - mergedData[index - 1].depth) * (mergedData[index + 1].pressure - mergedData[index - 1].pressure)) /
          (mergedData[index + 1].depth - mergedData[index - 1].depth);
      let interpolateTemperature =
        mergedData[index - 1].temperature +
        ((item.depth - mergedData[index - 1].depth) * (mergedData[index + 1].temperature - mergedData[index - 1].temperature)) /
          (mergedData[index + 1].depth - mergedData[index - 1].depth);
      let interpolateElevation =
        mergedData[index - 1].elevation +
        ((item.depth - mergedData[index - 1].depth) * (mergedData[index + 1].elevation - mergedData[index - 1].elevation)) /
          (mergedData[index + 1].depth - mergedData[index - 1].depth);

      return {
        ...item,
        depth: item.depth,
        pressure: interpolatePressure,
        temperature: interpolateTemperature,
        elevation: interpolateElevation,
      };
    }
  });

  return result;
};

const getSelectionData = (
  compare: string,
  selection: string,
  item: any,
  choosedMeasured: any,
  measuredAllData: any,
  referenceAllData: any,
  referenceDataIndex: number
) => {
  if (compare === "pressure") {
    if (selection === "corrected") return choosedMeasured.checkedPressure;
    if (selection === "meassure") return choosedMeasured.measuredPressure;
    if (selection === "reference") return item.pressure;
  }
  if (compare === "temperature") {
    if (selection === "corrected") return choosedMeasured.checkedTemperature;
    if (selection === "meassure") return choosedMeasured.measuredTemperature;
    if (selection === "reference") return item.temperature;
  }
  if (compare === "dP/dz") {
    if (selection === "reference") {
      return referenceDataIndex === 0
        ? item.pressure
        : referenceAllData[referenceDataIndex].pressure - referenceAllData[referenceDataIndex - 1].pressure;
    } else {
      if (choosedMeasured.sequence === 1) {
        if (selection === "corrected") return choosedMeasured.checkedPressure;
        if (selection === "meassure") return choosedMeasured.measuredPressure;
      } else {
        if (selection === "corrected")
          return measuredAllData[choosedMeasured.sequence].checkedPressure - measuredAllData[choosedMeasured.sequence - 1].checkedPressure;
        if (selection === "meassure")
          return measuredAllData[choosedMeasured.sequence].measuredPressure - measuredAllData[choosedMeasured.sequence - 1].measuredPressure;
      }
    }
  }
  if (compare === "dT/dz") {
    if (selection === "reference") {
      return referenceDataIndex === 0
        ? item.temperature
        : referenceAllData[referenceDataIndex].temperature - referenceAllData[referenceDataIndex - 1].temperature;
    } else {
      if (choosedMeasured.sequence === 1) {
        if (selection === "corrected") return choosedMeasured.checkedTemperature;
        if (selection === "meassure") return choosedMeasured.measuredTemperature;
      } else {
        if (selection === "corrected")
          return measuredAllData[choosedMeasured.sequence].checkedTemperature - measuredAllData[choosedMeasured.sequence - 1].checkedTemperature;
        if (selection === "meassure")
          return measuredAllData[choosedMeasured.sequence].measuredTemperature - measuredAllData[choosedMeasured.sequence - 1].measuredTemperature;
      }
    }
  }

  return "";
};

export const generateStep2GridData = (
  zAxis: string,
  compare: string,
  selectionA: string,
  selectionB: string,
  referenceData: any,
  measuredAndCheckedData: any
) => {
  let zAxiskey = zAxis as keyof TGeneratedStep2Grid;
  let result: TGeneratedStep2Grid[] = [];

  referenceData.map((item: any, index: number) => {
    if ([...measuredAndCheckedData].some((measuredChecked: any) => measuredChecked[zAxiskey] === item[zAxiskey])) {
      let findMeaasuredCheckedData = [...measuredAndCheckedData].find((choosedMeasured: any) => choosedMeasured[zAxiskey] === item[zAxiskey]);
      let tempSelectionA = getSelectionData(compare, selectionA, item, findMeaasuredCheckedData, measuredAndCheckedData, referenceData, index);
      let tempSelectionB = getSelectionData(compare, selectionB, item, findMeaasuredCheckedData, measuredAndCheckedData, referenceData, index);

      result.push({
        depthOrElevation: item[zAxiskey],
        selectionA: tempSelectionA,
        selectionB: tempSelectionB,
        delta: tempSelectionA - tempSelectionB,
      });
    }
  });

  return result;
};

const T4_p = (pressure: number) => {
  let beta = pressure ^ 0.25;
  let e = beta ^ (2 - 17.073846940092 * beta + 14.91510861353);
  let f = (1167.0521452767 * beta) ^ (2 + 12020.82470247 * beta - 4823.2657361591);
  let g = (-724213.16703206 * beta) ^ (2 - 3232555.0322333 * beta + 405113.40542057);
  let d = (2 * g) / ((-f - (f ^ (2 - 4 * e * g))) ^ 0.5);

  return ((650.17534844798 + d - ((650.17534844798 + d) ^ (2 - 4 * (-0.23855557567849 + 650.17534844798 * d)))) ^ 0.5) / 2;
};

const p4_T = (temperature: number) => {
  let teta = temperature - 0.23855557567849 / (temperature - 650.17534844798);
  let a = teta ^ (2 + 1167.0521452767 * teta - 724213.16703206);
  let b = (-17.073846940092 * teta) ^ (2 + 12020.82470247 * teta - 3232555.0322333);
  let c = (14.91510861353 * teta) ^ (2 - 4823.2657361591 * teta + 405113.40542057);
  return ((2 * c) / ((-b + (b ^ (2 - 4 * a * c))) ^ 0.5)) ^ 4;
};
const fromSIunit_T = (pressure: number) => pressure - 273.15;
const toSIunit_p = (pressure: number) => pressure / 10;
const generateTSat = (pressure: number) => (pressure >= 0.0611657 && pressure <= 220.6395 ? fromSIunit_T(T4_p(toSIunit_p(pressure))) : 0);
const generateDeltaTsatCorrectionMeasured = (selectionP: string, pressure: number, measuredAndCheckedData: any[], index: number) => {
  const prevPressure = measuredAndCheckedData[index - 1]?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"];
  return prevPressure ? generateTSat(pressure) - generateTSat(prevPressure) : generateTSat(pressure);
};

const B23p_T = (temperature: number) => 0.165 * temperature - 0.025;
const convertThermodynamicStateToStatus = (thermodynamicState: number) => {
  switch (thermodynamicState) {
    case 1:
      return "Liquid";
    case 2:
      return "Steam";
    case 4:
      return "Mixture";
    default:
      return "";
  }
};

/**
 * @param {number} pressure - pressure in bar
 * @param {number} temperature - temperature in Kelvin
 * @return {number} thermodynamic state
 */
const generateThermodynamicState = (pressure: number, temperature: number): number => {
  const regionPressure = pressure * 0.1;
  const regionTemperature = temperature + 273.15;
  if (regionTemperature > 1073.15 && regionPressure < 10 && regionTemperature < 2273.15 && regionPressure > 0.000611) return 5;
  if (regionTemperature <= 1073.15 && regionTemperature > 273.15 && regionPressure <= 100 && regionPressure > 0.000611) {
    if (regionTemperature > 623.15) {
      if (regionPressure > B23p_T(regionTemperature)) {
        return regionTemperature > 647.096 && Math.abs(regionPressure - p4_T(regionTemperature)) < 0.00001 ? 4 : 3;
      }
      return 2;
    }
    return Math.abs(regionPressure - p4_T(regionTemperature)) < 0.00001 ? 4 : regionPressure > p4_T(regionTemperature) ? 1 : 2;
  }
  return 0;
};

/**
 * Generate step 3 grid data based on the selection of pressure and temperature from the drop down menus.
 * @param {string} zAxis - the axis of the depth or elevation.
 * @param {string} selectionP - the selection of pressure, either "corrected", "meassure" or "reference".
 * @param {string} selectionT - the selection of temperature, either "corrected", "meassure" or "reference".
 * @param {any[]} measuredAndCheckedData - the data from the measured and checked table.
 * @param {any[]} referenceData - the data from the reference table.
 * @returns {TGeneratedStep3Grid[]} - the data for the step 3 grid.
 */
export const generateStep3GridData = (
  zAxis: "depth" | "elevation",
  selectionP: "corrected" | "meassure" | "reference",
  selectionT: "corrected" | "meassure" | "reference",
  measuredAndCheckedData: any[],
  referenceData: any[]
) => {
  const zAxiskey = zAxis as keyof TZaxis;
  const result =
    selectionP !== "reference" && selectionT !== "reference"
      ? measuredAndCheckedData.map((item, index) => ({
          sequence: index,
          depthOrElevation: item[zAxiskey],
          valueSelectionP: item[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"],
          valueSelectionT: item[selectionT === "corrected" ? "checkedTemperature" : "measuredTemperature"],
          tsat: generateTSat(item[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"]),
          deltaTsat: generateDeltaTsatCorrectionMeasured(
            selectionP,
            item[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"],
            measuredAndCheckedData,
            index
          ),
          thermodynamicState: convertThermodynamicStateToStatus(
            generateThermodynamicState(
              item[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"],
              item[selectionT === "corrected" ? "checkedTemperature" : "measuredTemperature"]
            )
          ),
        }))
      : referenceData.map((item, index) => {
          const prevItem = referenceData[index - 1];
          const foundItem = measuredAndCheckedData.find((measuredChecked) => measuredChecked[zAxiskey] === item[zAxiskey]);
          return {
            sequence: index,
            depthOrElevation: item[zAxiskey],
            valueSelectionP:
              selectionP !== "reference" ? foundItem?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"] : item.pressure,
            valueSelectionT:
              selectionT !== "reference" ? foundItem?.[selectionT === "corrected" ? "checkedTemperature" : "measuredTemperature"] : item.temperature,
            tsat: generateTSat(
              selectionP !== "reference" ? foundItem?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"] : item.pressure
            ),
            deltaTsat:
              index !== 0
                ? generateTSat(
                    selectionP !== "reference" ? foundItem?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"] : item.pressure
                  ) - generateTSat(prevItem.pressure)
                : generateTSat(
                    selectionP !== "reference" ? foundItem?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"] : item.pressure
                  ),
            thermodynamicState: convertThermodynamicStateToStatus(
              generateThermodynamicState(
                selectionP !== "reference" ? foundItem?.[selectionP === "corrected" ? "checkedPressure" : "measuredPressure"] : item.pressure,
                selectionT !== "reference" ? foundItem?.[selectionT === "corrected" ? "checkedTemperature" : "measuredTemperature"] : item.temperature
              )
            ),
          };
        });

  return result;
};
