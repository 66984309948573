import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BrineORCPageCreateGrid from "./BrineORCPageCreateGrid";
import { format } from "date-fns";
import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";

function BrineORCPageCreate() {
  const { brineORCMonitoringStore } = useStore();
  const navigate = useNavigate();
  const [dateByUser, setDateByUser] = useState<Date>(new Date());
  const hiddenFileInput = useRef<any>(null);
  const { date } = useParams();

  const onSubmit = async () => {
    let payload = {
      date: date ?? format(new Date(dateByUser), "yyyy-MM-dd"),
      table: brineORCMonitoringStore.brineORCMonitoringDetail,
    };

    brineORCMonitoringStore.addEditBrineOrcMonitoring(payload).then(() => {
      navigate("/geochemistry-brine");
    });
  };

  const downloadTempalte = () => brineORCMonitoringStore.getBrineOrcTemplate(new Date(dateByUser).toISOString());

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("date", new Date(dateByUser).toISOString());
      formData.append("file", e.target.files[0]);

      brineORCMonitoringStore.uploadBrineOrcTemplate(formData).then((res) => brineORCMonitoringStore.setManualBrineOrcData(res.monitorings));
    }
    e.target.value = "";
  };

  useEffect(() => {
    brineORCMonitoringStore.getBrineORCMonitoringDetail(date ?? dateByUser.toUTCString());
  }, [brineORCMonitoringStore, date, dateByUser]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Brine ORC Monitoring" subtitle="Add Edit & Delete Brine ORC Monitoring Data from this Page">
          <Grid container>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={date ?? dateByUser}
                  disabled={!!date}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => setDateByUser(e)}
                  renderInput={(props) => <TextField required fullWidth name="date" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={10} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <Box>
                <LoadingButton
                  loading={brineORCMonitoringStore.loadingTemplate}
                  sx={{ height: "100%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  startIcon={<DownloadOutlined />}
                  onClick={() => downloadTempalte()}
                >
                  Download Template
                </LoadingButton>
              </Box>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={brineORCMonitoringStore.loadingTemplate}
                  sx={{ height: "100%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  startIcon={<UploadOutlined />}
                >
                  Upload Template
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
                  />
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <BrineORCPageCreateGrid />
          <Box>
            <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
              <Box>
                <Button
                  disabled={brineORCMonitoringStore.brineOrcAdEditLoading}
                  variant="contained"
                  color="error"
                  onClick={() => navigate("/geochemistry-brine")}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  loading={brineORCMonitoringStore.brineOrcAdEditLoading}
                  onClick={onSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BrineORCPageCreate);
