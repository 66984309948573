import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import RkdCard from "../../../../app/component/card/RkdCard";
import RkdForm from "../../../../app/component/form/RkdForm";
import { useStore } from "../../../../app/stores/store";
import { IObjectOptions } from "../../../../app/models/object";
import { MenuProps } from "../../../geological/new/GeologicalPageCreate";
import { Orcs, TOrcGroupDetailResponse } from "../../../../app/models/orcGroup";

const validationSchema = yup.object({
  orcGroupName: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function OrcGroupPageCreate() {
  const { orcGridStore, orcGroupGridStore } = useStore();
  const navigate = useNavigate();
  const [selectedMultipleORC, setSelectedMultipleORC] = useState<string[]>([]);
  const [displaySelectedORC, setDisplaySelectedORC] = useState<string[]>([]);
  const [orcOptions, setOrcOptions] = useState<IObjectOptions[]>([]);
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    return id ? { orcGroupName: orcGroupGridStore.selectedOrc?.orcGroupName ?? "" } : { orcGroupName: "" };
  }, [id]);

  const onSubmit = async (values: ValidationSchema) => {
    let payload = {
      orcGroupName: values.orcGroupName,
      orcs: selectedMultipleORC,
    };

    id
      ? orcGroupGridStore.editOrcGroup(id, payload).then(() => {
          orcGroupGridStore.setSelectedOrc(null);
          navigate("/master/orc-group");
        })
      : orcGroupGridStore.addOrcGroup(payload).then(() => {
          navigate("/master/orc-group");
        });
  };

  const isAllSelected = orcOptions.length > 0 && selectedMultipleORC.length === orcOptions.length;

  const handleSelectORC = async (event: SelectChangeEvent<typeof selectedMultipleORC>) => {
    const { value } = event.target;
    let result: string[] = [];

    if (value[value.length - 1] === "all") {
      let tempDisplayText = orcOptions.map((item) => item.text);

      setFieldValue("orcGroupName", tempDisplayText.join("/"));
      setSelectedMultipleORC(selectedMultipleORC.length === orcOptions.length ? [] : orcOptions.map((item) => item.value));
      setDisplaySelectedORC(tempDisplayText);
      return;
    }

    orcOptions.forEach((val) => value.includes(val.value) && result.push(val.text));

    setFieldValue("orcGroupName", result.join("/"));
    setDisplaySelectedORC(result);
    setSelectedMultipleORC(typeof value === "string" ? value.split(",") : value);
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));

    if (id)
      orcGroupGridStore.getOrcGroupDetail(id).then((res: TOrcGroupDetailResponse) => {
        setDisplaySelectedORC(res.orcs.map((item: Orcs) => item.orcName));
        setSelectedMultipleORC(res.orcs.map((item: Orcs) => item.orcId));
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="ORC Group" subtitle="Add Edit & Delete ORC Group Data from this Page">
          <RkdForm initialValues={initialValues} submitAction={onSubmit} validationSchema={validationSchema}>
            <Grid container spacing="6px">
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">ORC</InputLabel>
                  <Select
                    multiple
                    value={selectedMultipleORC}
                    onChange={handleSelectORC}
                    input={<OutlinedInput label="ORC" />}
                    renderValue={() => displaySelectedORC.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={selectedMultipleORC.length > 0 && selectedMultipleORC.length < orcOptions.length}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {orcOptions.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        <Checkbox checked={selectedMultipleORC.indexOf(item.value) > -1} />
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="ORC Group Name"
                  value={values.orcGroupName}
                  fullWidth
                  name="orcGroupName"
                  required
                  error={!!errors.orcGroupName}
                  helperText={errors.orcGroupName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </RkdForm>
          <Box>
            <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
              <Box>
                <Button disabled={orcGridStore.orcAadEditLoading} variant="contained" color="error" onClick={() => navigate("/master/orc-group")}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  loading={orcGridStore.orcAadEditLoading}
                  onClick={() => handleSubmit()}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(OrcGroupPageCreate);
