import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { IFPTChartResponse } from "../../../app/models/fpt";
import { Box, CircularProgress, Grid } from "@mui/material";

function PTDecimationChart() {
  const { decimationStore } = useStore();
  const [chartSeries, setChartSeries] = useState<IFPTChartResponse>();

  useEffect(() => {
    decimationStore
      .getDecimationChart(decimationStore.selectedDecimation.wellId, new Date(decimationStore.selectedDecimation.surveyDate).toISOString())
      .then((res) => setChartSeries(res));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: "20px",
    },
    xaxis: {
      type: "numeric",
      // tickAmount: 8,
      // position: "top",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    markers: {
      size: 6,
    },
    yaxis: [
      {
        title: {
          text: "Temperature(degF)",
        },
        reversed: true,
      },
      {
        title: {
          text: "Pressure(psig)",
        },
        opposite: true,
        reversed: true,
      },
    ],
  };

  return (
    <Box sx={{ height: "100%" }}>
      {decimationStore.loadingChart ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ReactApexChart height="400px" options={options} series={chartSeries?.charts ?? []} />
      )}
    </Box>
  );
}

export default observer(PTDecimationChart);
