import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, Dialog, Grid, TextField, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import RkdForm from "../../../app/component/form/RkdForm";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as yup from "yup";
import { ILocationOptions } from "../../../app/models/location";
import { format } from "date-fns";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";
import { AttachFile, Delete } from "@mui/icons-material";
import { TFiles } from "../../../app/models/geophysics";

const validationSchema = yup.object({
  locationId: yup.string(),
  station: yup.string(),
  year: yup.string(),
  fileUrl: yup.string(),
  // wellId: yup.string(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function GeophysicMTCreate() {
  const { objectStore, geophyisicsMTGridStore } = useStore();
  const [selectedLocation, setSelectedLocation] = useState<TSelectedData | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [locationOptions, setLocationOptions] = useState<ILocationOptions[]>([]);
  const navigate = useNavigate();
  const [spesificationFiles, setSpesificationFiles] = useState<TFiles[]>([]);
  const [EDIFile, setEDIFile] = useState<any>(undefined);
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    return { fileUrl: "", locationId: "", station: "", year: new Date().getFullYear().toString(), wellId: "" };
  }, []);

  const onSubmit = (values: ValidationSchema) => {
    const formData = new FormData();

    values.year && formData.append("year", values.year);
    values.locationId && formData.append("locationId", values.locationId);
    values.station && formData.append("station", values.station);
    // values.wellId && formData.append("wellId", values.wellId);
    !!EDIFile && formData.append("file", EDIFile[0] as any);
    // if (spesificationFiles) {
    //   for (let i = 0; i < spesificationFiles.length; i++) {
    //     let file = spesificationFiles.item(i);
    //     formData.append(`files[${i}]`, file as any);
    //   }
    // }
    spesificationFiles.map((item, index) => formData.append(`files[${index}]`, item.file as any));

    geophyisicsMTGridStore.addMT(formData).then(() => navigate("/geophysic-mt-survey"));
  };

  const { handleSubmit, handleChange, values, errors, isValid, dirty, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const handleChangeSpesificationFiles = (value: File) => {
    let temp = [...spesificationFiles];

    temp.push({ file: value, fileUrl: undefined });
    setSpesificationFiles(temp);
  };

  const deleteSpecificationFiles = (index: number) => {
    const temp = [...spesificationFiles].filter((item, indexfiles) => index !== indexfiles);

    setSpesificationFiles(temp);
  };

  useEffect(() => {
    let locationOptionsTemp: ILocationOptions[] = [];

    const fetchLocation = async () => {
      await geophyisicsMTGridStore.getStationOptions("").then((res) => {
        setLocationOptions(res);
        locationOptionsTemp = res;
      });
    };

    fetchLocation();

    if (id) {
      geophyisicsMTGridStore.getGeophysicsDetailMT(id).then((res) => {
        let tempLocation = locationOptionsTemp.find((item) => item.value === res.locationId);
        let EDIFile = new File([res.file as unknown as BlobPart], res.file.fileName, { type: res.file.contentType }); //add filename here])
        let spesificationFiles: TFiles[] =
          !!res.fileUrls?.length && !!res.files?.length
            ? res.files.map((item, index) => ({
                file: new File([item as unknown as BlobPart], item.fileName, { type: item.contentType }),
                fileUrl: res.fileUrls[index],
              }))
            : [];

        // setFieldValue("wellId", res.wellId);
        setSelectedLocation({ label: tempLocation?.text ?? "", value: tempLocation?.value ?? "" });
        setFieldValue("locationId", res.locationId);
        setFieldValue("station", res.station);
        setFieldValue("year", res.year.toString());
        setFieldValue("fileUrl", res.fileUrl);
        setEDIFile([EDIFile]);
        setSpesificationFiles(spesificationFiles);
      });
    }
  }, []);

  const handleClose = () => setIsModalOpen(false);

  if (objectStore.loadingForm || geophyisicsMTGridStore.loadingDetail) return <RkdBackdrop />;

  return (
    <RkdCard title="MT Survey" subtitle={id ? "Edit" : "Create"}>
      <RkdForm
        initialValues={initialValues}
        actionAfterSubmit={() => navigate("/geophysic-mt-survey")}
        submitAction={async () => onSubmit}
        validationSchema={validationSchema}
      >
        <Grid container spacing="6px">
          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              fullWidth
              sx={{ marginBotom: 0 }}
              onChange={(e: any, val: any) => {
                setSelectedLocation(val);
                setFieldValue("locationId", val.value);
              }}
              disableClearable
              value={selectedLocation}
              getOptionLabel={(option) => option.label}
              options={locationOptions.map((item) => ({ label: item.text, value: item.value }))}
              renderInput={(params) => (
                <TextField
                  helperText={errors.locationId}
                  value={selectedLocation?.label}
                  name="locationId"
                  error={!!errors.locationId}
                  {...params}
                  label="Location"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="station"
              label="Station"
              disabled={!!id}
              error={!!errors.station}
              variant="outlined"
              sx={{ width: "30%" }}
              value={values.station}
              onChange={handleChange}
            />
            {/* <FormControl fullWidth>
              <InputLabel>Object</InputLabel>
              <Select
                error={!!errors.wellId}
                displayEmpty
                fullWidth
                name="wellId"
                disabled={!!id}
                labelId="well-name-id"
                label="Object"
                onChange={handleChange}
                value={values.wellId}
              >
                {objectWellList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Year"
                value={values.year}
                views={["year"]}
                disabled={!!id}
                inputFormat="yyyy"
                onChange={(e: any) => setFieldValue("year", format(new Date(e), "yyyy"))}
                renderInput={(props) => (
                  <TextField required fullWidth name="year" error={!!errors.year} helperText={errors.year && String(errors.year)} {...props} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid container sx={{ display: "flex", flexDirection: "column", gap: "6px", mb: "24px" }}>
            <Box>
              <span style={{ fontWeight: "bold", marginRight: "6px" }}>Spesification Survey:</span>
              <Button component="label" variant="contained">
                Add File
                <input
                  type="file"
                  accept="application/pdf, image/*"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) handleChangeSpesificationFiles(e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Box>
            {spesificationFiles.length > 0 ? (
              <Grid container sx={{ mt: "12px", width: "100%" }}>
                {spesificationFiles.map((item, index) => (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                      border: "1px solid  primary.main",
                      borderRadius: "3px",
                      padding: "12px",
                    }}
                  >
                    <AttachFile sx={{ flex: 0.1, mr: "2px", fontSize: "16px" }} />
                    <Box sx={{ flex: 1 }}>
                      {item.fileUrl ? (
                        <a rel="noreferrer" href={item.fileUrl}>
                          {item.file.name.length > 25 ? `${item.file.name.substring(0, 25)}...` : item.file.name}
                        </a>
                      ) : item.file.name.length > 25 ? (
                        `${item.file.name.substring(0, 25)}...`
                      ) : (
                        item.file.name
                      )}
                    </Box>
                    <Box
                      onClick={() => deleteSpecificationFiles(index)}
                      sx={{ flex: 0.1, cursor: "pointer", justifyContent: "flex-end", display: "flex", alignItems: "center", width: "100%" }}
                    >
                      <Delete sx={{ color: "red", fontSize: "16px" }} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : undefined}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Box>
              <span style={{ fontWeight: "bold", marginRight: "6px" }}>EDI File:</span>
              <Button component="label" variant="contained">
                Choose File
                <input
                  type="file"
                  // accept=".edi"
                  accept="application/x-edifact, application/x-x12"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) setEDIFile(e.target.files);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Box>
            <Box>
              {!!EDIFile && (
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                  <AttachFile sx={{ fontSize: "16px" }} />
                  {id && values.fileUrl ? (
                    <a rel="noreferrer" href={values.fileUrl}>
                      {EDIFile[0].name}
                    </a>
                  ) : (
                    EDIFile[0].name
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </RkdForm>
      <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
        <Box>
          <Button variant="contained" color="error" onClick={() => navigate("/geophysic-mt-survey")}>
            Cancel
          </Button>
        </Box>
        <Box>
          <LoadingButton onClick={() => handleSubmit()} type="submit" variant="contained" color="primary">
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default observer(GeophysicMTCreate);
