import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import { useEffect, useState } from "react";
import CleanOutHistoryDataGrid from "./CleanOutHistoryDataGrid";
import { MenuProps } from "../../geological/new/GeologicalPageCreate";
import { IObjectOptions } from "../../../app/models/object";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function CleanOutHistoryPageIndex() {
  const { commonStore, orcGridStore } = useStore();
  const [selectedMultipleORC, setSelectedMultipleORC] = useState<string[]>([]);
  const [orcOptions, setOrcOptions] = useState<IObjectOptions[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [displaySelectedORC, setDisplaySelectedORC] = useState<string[]>([]);

  const isAllSelected = orcOptions.length > 0 && selectedMultipleORC.length === orcOptions.length;

  const handleSelectORC = async (event: SelectChangeEvent<typeof selectedMultipleORC>) => {
    const { value } = event.target;
    let result: string[] = [];

    if (value[value.length - 1] === "all") {
      setSelectedMultipleORC(selectedMultipleORC.length === orcOptions.length ? [] : orcOptions.map((item) => item.value));
      setDisplaySelectedORC(orcOptions.map((item) => item.text));
      return;
    }

    orcOptions.forEach((val) => value.includes(val.value) && result.push(val.text));

    setDisplaySelectedORC(result);
    setSelectedMultipleORC(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    commonStore.setTitlePage("Clean Out History Page");
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Clean Out History List" subtitle="Add Edit & Delete Clean Out History from this Page">
          <Grid container>
            <Grid item xs={4} sx={{ display: "flex", gap: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={date}
                  views={["year"]}
                  inputFormat="yyyy"
                  onChange={(e: any) => setDate(e)}
                  renderInput={(props) => <TextField required fullWidth name="date" {...props} />}
                />
              </LocalizationProvider>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">ORC</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleORC}
                  onChange={handleSelectORC}
                  input={<OutlinedInput label="ORC" />}
                  renderValue={() => displaySelectedORC.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={selectedMultipleORC.length > 0 && selectedMultipleORC.length < orcGridStore.orcOptions.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {orcGridStore.orcOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleORC.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={8}>
              <Box sx={{ height: "100%", display: "flex", justifyContent: "flex-end", gap: "12px", alignItems: "center" }}>
                <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("add")}>
                  Add
                </Button>
              </Box>
            </Grid> */}
          </Grid>
          <CleanOutHistoryDataGrid date={date} selectedMultipleORC={selectedMultipleORC} />
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(CleanOutHistoryPageIndex);
