import { LoadingButton } from '@mui/lab'
import { TextField, Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RkdCard from '../../../app/component/card/RkdCard'
import { ILocation } from '../../../app/models/location'
import { useStore } from '../../../app/stores/store'

const initialValues: ILocation = {
    id: '',
    locationName: '',
    description: ''
}

function LocationForm() {

    const navigate = useNavigate();
    const { locationStore, commonStore, locationGridStore } = useStore();
    const { getLocationDetail, createLocation, editLocation } = locationStore;

    const [ location, setLocation ] = useState<ILocation>(initialValues);
    const params = useParams();
    const [id] = useState(params.id);

    useEffect(() => {
        if (id)
        getLocationDetail(id).then((res) => {
            if (res) {
            setLocation(res);
            }
        });
    }, [id, getLocationDetail]);

    useEffect(() => {
        commonStore.setTitlePage('Location',);
    });

    const onSubmit = async (values: ILocation) => {
        if (id) {
        await editLocation(id, values)
            .then(locationGridStore.getLocationList)
            .then(() => {
            navigate('/master/location');
            });
        } else {
        await createLocation(values)
            .then(locationGridStore.getLocationList)
            .then(() => {
            navigate('/master/location');
            });
        }
    };


  return (
    <RkdCard title={id ? 'Edit Location' : 'New Location'}>
        <TextField
            name="location"
            label="Location"
            variant="outlined"
            sx={{ width: '30%' }}
            value={location.locationName}
            onChange={(e) => setLocation({ ...location, locationName: e.target.value })}
        />
        <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            variant="outlined"
            sx={{ width: '30%' }}
            value={location.description}
            onChange={(e) => setLocation({ ...location, description: e.target.value })}
        />
        <Box sx={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="outlined"
                color="info"
                sx={{ mr: '8px' }} 
                onClick={() => {
                navigate('/master/location/'); 
                }}>
                Cancel
            </Button>
            <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={location.locationName === '' || location.description === ''}
                onClick={ () => onSubmit(location) }
            >
                Save
            </LoadingButton>
        </Box>
    </RkdCard>
  )
}

export default observer(LocationForm)