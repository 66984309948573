import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";

export default class WellGeometryGridStore extends DataGridStore {
  constructor() {
    super();

    makeObservable(this, {
      getWellGeometryList: action,
    });
  }

  updateGridCallback = action(() => {
    this.getWellGeometryList();
  });

  getWellGeometryList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geometry.listGrid(urlParams);
      result.data.forEach((item) => {
        item.modifiedDate = new Date(item.modifiedDate + "Z");
      });
      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };
}
