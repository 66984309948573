import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import FPTList from "./FPTList";

function FPTIndex(){
    const { commonStore } = useStore();
    useEffect(() => {
        commonStore.setTitlePage('Flow Performance Test',);
    });
    return (
        <Grid container>
        <Grid item xs={12}>
            <FPTList />
        </Grid>
        </Grid>
    );
}

export default observer(FPTIndex);