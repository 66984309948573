import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConnectionGrid from "./ConnectionGrid";
import { Add, Delete } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";

function ConnectionList() {
  const { dialogStore, snackbarStore, commonStore, connectionStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await connectionStore.deleteConnection(commonStore.selectedDataOnGrid).then(() => {
          connectionStore.getConnectionList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <RkdCard title="Connection" subtitle="Add Edit & Delete Connection from this Page">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <ConnectionGrid />
    </RkdCard>
  );
}

export default observer(ConnectionList);
