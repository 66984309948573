import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";

import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";

interface IProps{  
  handleSubmit: () => void,
  name: string,
}

function WellGeometryUploadExcel(props: IProps){
  const { handleSubmit, name } = props;
  const { modalStore } = useStore(); 

  return(
    <Box>
      <Typography variant='h2'>Confirmation</Typography>
      <Typography variant='body1' sx={{ my: '18px', wordBreak: 'break-word' }}>Are you sure you want to upload this {name} ?</Typography>
      <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'space-between', width: '180px' }}>
        <Button variant='outlined' color='info'  onClick={() => modalStore.close()}>No</Button>        
        <LoadingButton variant='contained' color='primary' onClick={handleSubmit}>Yes</LoadingButton>
      </Box>
    </Box>
  )
}
export default observer(WellGeometryUploadExcel);