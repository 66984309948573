import { LoadingButton } from "@mui/lab";
import { Box, Button, Stepper, Step, StepLabel, Typography, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { steps } from "./utils/utils";
import { useFormik } from "formik";
import { TSelectedData } from "../decimation/PTDecimationForm";
import { IObjectOptions } from "../../../app/models/object";
import AllStepsComponent from "./components/AllStepsComponent";
import Step1 from "./components/Step1";
import Step3 from "./components/Step3";
import Step2 from "./components/Step2";
import { ptModuleInitialValue, ptModuleValidationSchema } from "../../../app/models/ptModule";

function PTModuleForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { ptModuleStore, objectStore } = useStore();
  const { getPtModuleDetail, ptModuleDetail, createPtModule, getWellReference } = ptModuleStore;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const [wellOptions, setWellOptions] = useState<IObjectOptions[]>([]);

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    onSubmit: async (values: any) => {
      const { id: payloadId, ...rest } = values;
      await createPtModule(rest).then(() => {
        navigate("/pt");
      });
    },
    initialValues: useMemo(() => ptModuleDetail ?? ptModuleInitialValue, [ptModuleDetail]),
    validationSchema: ptModuleValidationSchema,
    enableReinitialize: true,
  });

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  useEffect(() => {
    if (!id) return;

    getPtModuleDetail(id).then(() => {
      objectStore.getObjectOptions("Well").then((res) => {
        setWellOptions(res);
      });
    });
  }, [getPtModuleDetail, id, objectStore]);

  useEffect(() => {
    if (!values.wellId || !values.surveyDate) return;

    getWellReference(values.wellId, values.surveyDate);
  }, [values.wellId, values.surveyDate, getWellReference]);

  const renderComponentsByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Step1
            values={values}
            setSelectedWell={setSelectedWell}
            selectedWell={selectedWell}
            setFieldValue={setFieldValue}
            wellOptions={wellOptions}
            errors={errors}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <Step2
            values={values}
            setSelectedWell={setSelectedWell}
            selectedWell={selectedWell}
            setFieldValue={setFieldValue}
            wellOptions={wellOptions}
            errors={errors}
            handleChange={handleChange}
          />
        );
      default:
        return (
          <Step3
            values={values}
            setSelectedWell={setSelectedWell}
            selectedWell={selectedWell}
            setFieldValue={setFieldValue}
            wellOptions={wellOptions}
            errors={errors}
            handleChange={handleChange}
          />
        );
    }
  };

  return (
    <RkdCard title="PT Module" subtitle={id ? "Edit" : "Create"}>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <Typography variant="h6" sx={{ textAlign: "center", mt: "12px", fontWeight: "bold" }}>
            General Information
          </Typography>
          <AllStepsComponent
            values={values}
            setSelectedWell={setSelectedWell}
            selectedWell={selectedWell}
            setFieldValue={setFieldValue}
            wellOptions={wellOptions}
            errors={errors}
            handleChange={handleChange}
          />
          <Divider sx={{ my: "24px" }} />
          {renderComponentsByStep(activeStep)}
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <Button variant="contained" color="error" onClick={() => navigate("/pt")}>
              Cancel
            </Button>
            <Box>
              <Button variant="contained" startIcon={<KeyboardArrowLeft />} disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <LoadingButton type="submit" variant="contained">
                  Submit
                </LoadingButton>
              ) : (
                <LoadingButton onClick={handleNext} variant="contained" endIcon={<KeyboardArrowRight />}>
                  Next
                </LoadingButton>
              )}
            </Box>
          </Box>
        </React.Fragment>
      </form>
    </RkdCard>
  );
}

export default observer(PTModuleForm);
