import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { TGeneralGeological } from "./Lithology";
import { useStore } from "../../../../app/stores/store";
import { IGeoDrillParamTableRequest } from "../../../../app/models/geological";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function DrillParameter({
  selectedGeologicalType,
  deleteSequence,
  setDeleteSequence,
  setDynamicConvertedData,
  dynamicConvertedData,
  data,
  onDelete,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType ?? "");

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      state: 3,
      dateTime: row.dateTime,
      depth: row.depth,
      tvd: row.tvd,
      section: row.section,
      rop: row.rop,
      ropInstan: row.ropInstan,
      wob: row.wob,
      hkld: row.hkld,
      rpm: row.rpm,
      rpmm: row.rpmm,
      totalRpm: row.totalRpm,
      mfi: row.mfi,
      spp: row.spp,
      tspm: row.tspm,
      torqorq: row.torqorq,
      ecd: row.ecd,
      pwdAnnularPressure: row.pwdAnnularPressure,
      pwdInternalPressure: row.pwdInternalPressure,
      mfo: row.mfo,
      mfoAd: row.mfoAd,
      mti: row.mti,
      mto: row.mto,
      mwi: row.mwi,
      mwo: row.mwo,
      activePit: row.activePit,
      bitHours: row.bitHours,
      pumpHours: row.pumpHours,
      adp: row.adp,
      scfm: row.scfm,
      bht: row.bht,
      kRevOb: row.kRevOb,
      kRevReam: row.kRevReam,
      kRevCirc: row.kRevCirc,
      totalKRev: row.totalKRev,
      cO2: row.cO2,
      h2SCellar: row.h2SCellar,
      h2SUnit: row.h2SUnit,
      ct: row.ct,
      totalPit: row.totalPit,
      temperature: row.temperature,
      spm1: row.spm1,
      spm2: row.spm2,
      spm3: row.spm3,
      spm4: row.spm4,
      condIn: row.condIn,
      condOut: row.condOut,
      adt: row.adt,
      bitSize: row.bitSize,
      whp: row.numbewhpr,
      csip: row.csip,
      blockPos: row.blockPos,
      gl: row.gl,
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoDrillParamTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        state: 3,
        dateTime: row.dateTime,
        depth: row.depth,
        tvd: row.tvd,
        section: row.section,
        rop: row.rop,
        ropInstan: row.ropInstan,
        wob: row.wob,
        hkld: row.hkld,
        rpm: row.rpm,
        rpmm: row.rpmm,
        totalRpm: row.totalRpm,
        mfi: row.mfi,
        spp: row.spp,
        tspm: row.tspm,
        torqorq: row.torqorq,
        ecd: row.ecd,
        pwdAnnularPressure: row.pwdAnnularPressure,
        pwdInternalPressure: row.pwdInternalPressure,
        mfo: row.mfo,
        mfoAd: row.mfoAd,
        mti: row.mti,
        mto: row.mto,
        mwi: row.mwi,
        mwo: row.mwo,
        activePit: row.activePit,
        bitHours: row.bitHours,
        pumpHours: row.pumpHours,
        adp: row.adp,
        scfm: row.scfm,
        bht: row.bht,
        kRevOb: row.kRevOb,
        kRevReam: row.kRevReam,
        kRevCirc: row.kRevCirc,
        totalKRev: row.totalKRev,
        cO2: row.cO2,
        h2SCellar: row.h2SCellar,
        h2SUnit: row.h2SUnit,
        ct: row.ct,
        totalPit: row.totalPit,
        temperature: row.temperature,
        spm1: row.spm1,
        spm2: row.spm2,
        spm3: row.spm3,
        spm4: row.spm4,
        condIn: row.condIn,
        condOut: row.condOut,
        adt: row.adt,
        bitSize: row.bitSize,
        whp: row.numbewhpr,
        csip: row.csip,
        blockPos: row.blockPos,
        gl: row.gl,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    {
      field: "dateTime",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      editable: true,
      field: "depth",
      headerName: "Depth",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "tvd",
      headerName: "TVD",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "section",
      editable: true,
      headerName: "Section",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "rop",
      headerName: "ROP",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "ropInstan",
      editable: true,
      headerName: "ROP Instan",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "wob",
      editable: true,
      headerName: "WOB",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "hkld",
      headerName: "HKLD",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "rpm",
      editable: true,
      headerName: "RPM",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "rpmm",
      headerName: "RPMM",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "totalRpm",
      headerName: "Total RPM",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mfi",
      headerName: "MFI",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "spp",
      editable: true,
      headerName: "SPP",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "tspm",
      headerName: "TSPM",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "torqorq",
      editable: true,
      headerName: "TORQORQ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "ecd",
      headerName: "ECD",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      editable: true,
      field: "pwdAnnularPressure",
      headerName: "PWD Annular Pressure",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "pwdInternalPressure",
      headerName: "PWD Internal Pressure",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mfo",
      editable: true,
      headerName: "MFO",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mfoAd",
      headerName: "MFOAD",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mti",
      editable: true,
      headerName: "MTI",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mto",
      headerName: "MTO",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      editable: true,
      field: "mwi",
      headerName: "MWI",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mwo",
      headerName: "MWO",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "activePit",
      headerName: "Active Pit",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "bitHours",
      headerName: "Bit Hours",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "pumpHours",
      editable: true,
      headerName: "Pump Hours",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "adp",
      headerName: "ADP",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "scfm",
      headerName: "SCFM",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "bht",
      headerName: "BHT",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "kRevOb",
      headerName: "KRevOB",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "kRevReam",
      headerName: "KRevReam",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "kRevCirc",
      headerName: "KRevCirc",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "totalKRev",
      headerName: "total KRev",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "cO2",
      headerName: "CO2",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "h2SCellar",
      headerName: "h2SCellar",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "h2SUnit",
      editable: true,
      headerName: "h2SUnit",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "ct",
      editable: true,
      headerName: "CT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "totalPit",
      headerName: "Total Pit",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "temperature",
      headerName: "Temperature",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "spm1",
      headerName: "SPM1",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "spm2",
      headerName: "SPM2",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "spm3",
      editable: true,
      headerName: "SPM3",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "spm4",
      editable: true,
      headerName: "SPM4",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "condIn",
      editable: true,
      headerName: "Cond In",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      editable: true,
      field: "condOut",
      headerName: "Cond Out",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "adt",
      editable: true,
      headerName: "ADT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "bitSize",
      headerName: "Bit Size",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "whp",
      headerName: "WHP",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "csip",
      headerName: "CSIP",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "blockPos",
      headerName: "Block Pos",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "gl",
      headerName: "GL",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
      autoHeight
      processRowUpdate={handleRowEdit}
      showCellVerticalBorder
      pagination
      showColumnVerticalBorder
    />
  );
}

export default observer(DrillParameter);
