import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";

function GeophysicMTDataGrid() {
  const { geophyisicsMTGridStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    geophyisicsMTGridStore.getGeophysicsMTGrid();
  }, [geophyisicsMTGridStore]);

  return (
    <RkdDataGrid
      columns={[
        { field: "locationName", headerName: "Location", flex: 1 },
        { field: "station", headerName: "Station", flex: 1 },
        // { field: "wellName", headerName: "Well Name", flex: 1 },
        {
          field: "year",
          headerName: "Year",
          flex: 1,
        },
        {
          field: "actions",
          headerName: "Action",
          type: "actions",
          renderCell: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              color="info"
              label="Edit"
              onClick={() => {
                navigate(`/geophysic-mt-survey/edit/${params.row.id}`);
              }}
            />,
          ],
        },
      ]}
      gridStore={geophyisicsMTGridStore}
    />
  );
}

export default observer(GeophysicMTDataGrid);
