import { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import ObjectStatusGrid from "./ObjectStatusGrid";
import { Add } from "@mui/icons-material";

function ObjectStatusIndex() {
  const { commonStore } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    commonStore.setTitlePage("Well Status");
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Well Status" subtitle="Add & Edit Well Status from this Page">
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
            <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
              Add
            </Button>
          </Box>
          <ObjectStatusGrid />
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(ObjectStatusIndex);
