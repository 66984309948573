import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { TGeophysicsImportTemplateGravitiesResponse } from "../../../app/models/geophysics";
import MapViewer from "../../../app/component/map/MapViewer";
import DynamicBarShowChartDialog from "../components/DynamicBarShowChartDialog";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-leaflet";

function GeophysicGravityReport() {
  const { geophysicsGravityGridStore, geophyisicsMTGridStore, geophysicsGravityDataConversionGridStore } = useStore();
  const [convertionData, setConvertionData] = useState<TGeophysicsImportTemplateGravitiesResponse[]>([]);
  const { id } = useParams();
  const [openChartDialog, setOpenChartDialog] = useState(false);

  const [chartData, setChartData] = useState<TGeophysicsImportTemplateGravitiesResponse[]>([]);

  const handleOpenChart = (selectedItem: any) => {
    setOpenChartDialog(true);
    setChartData([selectedItem]);
  };

  useEffect(() => {
    if (!id) return;

    geophysicsGravityDataConversionGridStore.getGeophysicGravityDetailGrid(id).then((res) => setConvertionData(res.data));
  }, []);

  if (geophyisicsMTGridStore.loadingStationOptions || geophysicsGravityGridStore.loadingDetail) return <RkdBackdrop />;

  return (
    <RkdCard title="Gravity Survey" subtitle="Report (click marker for viewing svd & fhd)">
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Map View
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <MapViewer style={{ height: "350px" }} zoom={12}>
          {convertionData.map((item) => (
            <MapViewer.Marker
              onClick={(e) => handleOpenChart(item)}
              autoFocus
              position={{
                lat: item.latDeg,
                lng: item.lonDeg,
              }}
            >
              <Tooltip permanent>Location ({item.sequence})</Tooltip>
            </MapViewer.Marker>
          ))}
        </MapViewer>
        {openChartDialog ? (
          <DynamicBarShowChartDialog chartData={chartData} openChartDialog={openChartDialog} setOpenChartDialog={setOpenChartDialog} />
        ) : undefined}
      </Box>
    </RkdCard>
  );
}

export default observer(GeophysicGravityReport);
