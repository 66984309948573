import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";
import { useEffect } from "react";
import OrcDataGrid from "./OrcGroupDataGrid";
import RkdCard from "../../../../app/component/card/RkdCard";
import { useStore } from "../../../../app/stores/store";

function OrcGroupDataPageIndex() {
  const { dialogStore, snackbarStore, commonStore, orcGroupGridStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await orcGroupGridStore.deleteOrcGroup(commonStore.selectedDataOnGrid).then(() => {
          orcGroupGridStore.getOrcGroupGrid();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  useEffect(() => {
    commonStore.setTitlePage("ORC Group");
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="ORC Group" subtitle="Add Edit & Delete ORC Group Data from this Page">
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
            <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("add")}>
              Add
            </Button>
            <Button
              disabled={commonStore.selectedDataOnGrid.ids.length === 0}
              startIcon={<Delete />}
              variant="contained"
              color="error"
              onClick={() => onDelete()}
            >
              Bulk Delete
            </Button>
          </Box>
          <OrcDataGrid />
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(OrcGroupDataPageIndex);
