import { GridValidRowModel, GridColDef, GridColumnGroup } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { DAILY_SHEET, INJECTIONS_TABLE } from "../../../../app/config/enum";
import { TInjectionsTable } from "../../../../app/models/dailysheet";
import { useStore } from "../../../../app/stores/store";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

type TDailyInjectionsGrid = {
  index: number;
};

const editableRowData = ["fcvPrev", "whpPrev", "whtPrev", "wellName", "durationPrev", "brineRatePrev", "avgBrineRatePrev", "totalBrineRatePrev"];

export default function DailyInjectionsGrid({ index }: TDailyInjectionsGrid) {
  const { setManualDailySheetData, dailySheetData } = useStore().dailysheetStore;
  const [temporaryData, setTemporaryData] = useState<TInjectionsTable[]>([...(dailySheetData?.injections[index].table ?? [])]);

  const generateGridColumns = () => {
    let temp: GridColDef<GridValidRowModel>[] = [];
    Object.entries(INJECTIONS_TABLE).map(([key, value], index) =>
      temp.push({
        field: key,
        editable: editableRowData.includes(key) ? false : true,
        headerAlign: "center",
        align: "center",
        headerName: value,
        flex: 1,
        width: 120,
      })
    );

    return temp;
  };

  const handleRowEdit = (newData: any) => {
    let temp = temporaryData.map((current) => {
      if (current.sequence !== newData.sequence) return current;
      return newData;
    });

    setManualDailySheetData(temp, index, DAILY_SHEET.INJECTIONS);
    setTemporaryData(temp);
    return newData;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = generateGridColumns();
  const generateDynamicColumnGroupingModel = () => {
    let resultTemp: GridColumnGroup[] = [
      {
        groupId: "FCV (%)",
        headerAlign: "center",
        children: [{ field: "fcv" }, { field: "fcvPrev" }],
      },
      {
        groupId: "WHP (bar)",
        headerAlign: "center",
        children: [{ field: "whp" }, { field: "whpPrev" }],
      },
      {
        groupId: "WHT (Deg C)",
        headerAlign: "center",
        children: [{ field: "wht" }, { field: "whtPrev" }],
      },
      {
        groupId: "Duration (hr)",
        headerAlign: "center",
        children: [{ field: "duration" }, { field: "durationPrev" }],
      },
      {
        groupId: "Brine Rate (M3/hr)",
        headerAlign: "center",
        children: [{ field: "brineRate" }, { field: "brineRatePrev" }],
      },
      {
        groupId: "Average Brine Rate (M3/hr)",
        headerAlign: "center",
        children: [{ field: "avgBrineRate" }, { field: "avgBrineRatePrev" }],
      },
      {
        groupId: "Total Brine Rate (M3/hr)",
        headerAlign: "center",
        children: [{ field: "totalBrineRate" }, { field: "totalBrineRatePrev" }],
      },
    ];
    return resultTemp;
  };

  return (
    <StyledDataGridPremium
      getRowId={(row) => row.id}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={(params: any) => {
        if (editableRowData.includes(params.field)) return "datagrid-disable";

        return "";
      }}
      columnGroupingModel={generateDynamicColumnGroupingModel()}
      columns={gridColumns}
      processRowUpdate={handleRowEdit}
      rows={[...(dailySheetData?.injections[index].table ?? [])]}
    />
  );
}
