import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { FormikValues } from "formik";
import { editableRowData } from "../utils/utils";

type TStep1Grid = {
  values: FormikValues;
  setFieldValue: any;
};

export default function Step1Grid({ values, setFieldValue }: TStep1Grid) {
  const handleRowEdit = (newData: any) => {
    let temp = [...values.step1?.correctionMeasureds].map((current) => {
      if (current.sequence !== newData.sequence) return current;

      return newData;
    });

    setFieldValue("step1.correctionMeasureds", temp);
    return newData;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "depth",
      headerName: "Depth (m)",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "elevation",
      headerName: "Elevation (m asl)",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "measuredPressure",
      align: "center",
      headerName: "Press (bar g)",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "measuredTemperature",
      headerName: "Temp (deg c)",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "checkedPressure",
      align: "center",
      headerName: "Press (bar g)",
      editable: true,
      headerAlign: "center",
      width: 200,
      type: "number",
    },
    {
      field: "checkedTemperature",
      editable: true,
      headerName: "Temp (deg c)",
      align: "center",
      headerAlign: "center",
      width: 200,
      type: "number",
    },
  ];

  return (
    <StyledDataGridPremium
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      getRowId={(row) => row.sequence}
      processRowUpdate={handleRowEdit}
      columns={gridColumns}
      columnGroupingModel={[
        {
          groupId: "Measured",
          headerAlign: "center",
          children: [{ field: "depth" }, { field: "elevation" }, { field: "measuredPressure" }, { field: "measuredTemperature" }],
        },
        {
          groupId: "Checked/Corrected",
          headerAlign: "center",
          children: [{ field: "checkedPressure" }, { field: "checkedTemperature" }],
        },
      ]}
      rows={values.step1?.correctionMeasureds ?? []}
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={(params: any) => {
        if (!editableRowData.includes(params.field)) return "datagrid-disable";

        return "";
      }}
      autoHeight
    />
  );
}
