import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ILocation, ILocationPayload } from "../models/location";

export default class LocationStore {
  loadingGrid = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  createLocation = async (loc: ILocationPayload) => {
    try {
      await agent.Location.create(loc);
    } catch (error) {
        throw error;
    }
  }

  deleteLocation = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.Location.delete(id);
    } catch (error) {
      throw error;
    }
    finally {
      runInAction(() => this.loadingForm = false);
    }
  }

  editLocation = async (id: string, location: ILocation) => {
    try {
        await agent.Location.edit(id, location);
    } catch (error) {
        throw error;
    }
  }

  getLocationDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.Location.detail(id);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingForm = false);
    }
  }

  getLocationOptions = async (search: string) => {
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.Location.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingForm = false);
    }
  }
  
}