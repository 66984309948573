import { Box } from "@mui/material";
import { ComposedChart, Tooltip, Bar, YAxis, Scatter, ResponsiveContainer, LabelList } from "recharts";
import { observer } from "mobx-react-lite";
import { TGeologicalBarDataResponse, TGeologicalScatterDataResponse } from "../../../app/models/geological";
import { useEffect, useState } from "react";
import { convertBarAndScatterData } from "../../../app/config/utils";
import { BARS_LEGEND } from "../../../app/config/enum";

// const data = [
//   {
//     name: "Bars & Charts Data",
//     mavd: 700,
//     masl: 200,
//     alteration: 360,
//     lithologyAndecitLava: 240,
//     lithologyDeciteLava: 1000,
//     mebY: 1200,
//   },
// ];

type TGeologicalReportBarScatterChartProps = {
  barsData: TGeologicalBarDataResponse[];
  scattersData: TGeologicalScatterDataResponse[];
};

function GeologicalReportBarScatterChart({ barsData, scattersData }: TGeologicalReportBarScatterChartProps) {
  const [convertedData, setConvertedData] = useState<any>();

  useEffect(() => {
    let tempData = convertBarAndScatterData(barsData, scattersData);

    setConvertedData(tempData);
  }, [barsData, scattersData]);

  return (
    <Box sx={{ height: "300px", display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={[convertedData]}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip label="Bars & Scatters" itemStyle={{ textAlign: "left" }} />
          <YAxis />
          {barsData.map((item) => (
            <Bar
              key={`${item.name}-${item.value}`}
              dataKey={item.name}
              barSize={36}
              stackId="a"
              fill={BARS_LEGEND.find((bar) => bar.value === item.name)?.color}
            />
          ))}
          {scattersData.map((item) => (
            <Scatter key={`${item.depth}-${item.value}`} dataKey={item.type} fill={item.feedZone === "Major" ? "red" : "blue"}>
              <LabelList dataKey={item.value} />
            </Scatter>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default observer(GeologicalReportBarScatterChart);
