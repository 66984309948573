import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import PTReportForm from "./PTReportForm";

function PTReportIndex() {
  const { commonStore } = useStore();

  useEffect(() => {
    commonStore.setTitlePage("PT Report");
  });

  return <PTReportForm />;
}

export default observer(PTReportIndex);
