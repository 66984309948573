import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { TBrineEcolabDetailDataResponse } from "../../../app/models/geochemistry";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { editableRowDataEcolab } from "../../../app/config/enum";

function BrineEcolabPageCreateGrid() {
  const { brineORCMonitoringStore } = useStore();
  const { date } = useParams();

  const handleRowEdit = (row: any) => {
    const temp: TBrineEcolabDetailDataResponse[] = brineORCMonitoringStore.brineEcolabDetail.map((current) => {
      if (current.sequence !== row.sequence) return current;
      row.dp = row.pressureIn - row.pressureOut;
      row.polymerization = ((row.silicaBefore - row.silicaAfter) / row.silicaBefore) * 100;

      return row;
    });

    brineORCMonitoringStore.setManualBrineEcolabData(temp);
    return row;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "",
      headerName: "Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => format(new Date(date ?? ""), "dd MMM yyyy"),
    },
    {
      field: "orcGroupName",
      headerName: "ORC GroupName ",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "flowRateM3",
      align: "center",
      headerName: "m3/h",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "silicaBefore",
      headerName: "(ppm)",
      align: "center",
      headerAlign: "center",
      editable: true,
      width: 100,
    },
    {
      field: "silicaAfter",
      align: "center",
      headerName: "(ppm)",
      editable: true,
      headerAlign: "center",
      width: 100,
    },
    {
      field: "phBefore",
      editable: true,
      headerName: "pH Before",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "phAfter",
      headerName: "pH After",
      editable: true,
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "ecIn",
      headerName: "us/cm",
      align: "center",
      editable: true,
      headerAlign: "center",
      width: 100,
    },
    {
      field: "ecOut",
      headerName: "us/cm",
      align: "center",
      editable: true,
      headerAlign: "center",
      width: 100,
    },
    {
      field: "dosage981",
      editable: true,
      align: "center",
      headerName: "(ppm)",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "dt3120",
      headerName: "(ppm)",
      align: "center",
      editable: true,
      headerAlign: "center",
      width: 100,
    },
    {
      field: "n2619",
      headerName: "(ppm)",
      editable: true,
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "pressureIn",
      headerName: "(bar)",
      headerAlign: "center",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      field: "pressureOut",
      align: "center",
      editable: true,
      headerName: "(bar)",
      headerAlign: "center",
      headerClassName: "tab-header",
      width: 100,
    },
    {
      field: "dp",
      headerName: "(bar)",
      headerAlign: "center",
      width: 100,
      align: "center",
      editable: true,
    },
    {
      field: "alumBefore",
      headerName: "(ppm)",
      editable: true,
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      editable: true,
      field: "alumAfter",
      headerName: "(ppm)",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      editable: true,
      field: "temperature",
      align: "center",
      headerName: "Temperature",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "polymerization",
      align: "center",
      headerName: "%",
      headerAlign: "center",
      width: 100,
      editable: true,
    },
    {
      field: "notes",
      align: "center",
      headerName: "Notes",
      headerAlign: "center",
      width: 100,
      editable: true,
    },
  ];

  return (
    <StyledDataGridPremium
      experimentalFeatures={{ columnGrouping: true }}
      getRowId={(row) => row.sequence}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columnGroupingModel={[
        {
          groupId: "Flow Rate",
          headerAlign: "center",
          children: [{ field: "flowRateM3" }],
        },
        {
          groupId: "Silica Before",
          headerAlign: "center",
          children: [{ field: "silicaBefore" }],
        },
        {
          groupId: "Silica After",
          headerAlign: "center",
          children: [{ field: "silicaAfter" }],
        },
        {
          groupId: "EC In",
          headerAlign: "center",
          children: [{ field: "ecIn" }],
        },
        {
          groupId: "EC Out",
          headerAlign: "center",
          children: [{ field: "ecOut" }],
        },
        {
          groupId: "Dosage 981",
          headerAlign: "center",
          children: [{ field: "dosage981" }],
        },
        {
          groupId: "3dt 120",
          headerAlign: "center",
          children: [{ field: "dt3120" }],
        },
        {
          groupId: "N2619",
          headerAlign: "center",
          children: [{ field: "n2619" }],
        },
        {
          groupId: "Pressure In",
          headerAlign: "center",
          children: [{ field: "pressureIn" }],
        },
        {
          groupId: "Pressure Out",
          headerAlign: "center",
          children: [{ field: "pressureOut" }],
        },
        {
          groupId: "DP",
          headerAlign: "center",
          children: [{ field: "dp" }],
        },
        {
          groupId: "Alum Before",
          headerAlign: "center",
          children: [{ field: "alumBefore" }],
        },
        {
          groupId: "Alum After",
          headerAlign: "center",
          children: [{ field: "alumAfter" }],
        },
        {
          groupId: "Polymerization",
          headerAlign: "center",
          children: [{ field: "polymerization" }],
        },
      ]}
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={(params: any) => {
        if (editableRowDataEcolab.includes(params.field)) return "datagrid-disable";

        return "";
      }}
      loading={brineORCMonitoringStore.loadingGrid}
      columns={gridColumns}
      processRowUpdate={handleRowEdit}
      rows={brineORCMonitoringStore.brineEcolabDetail}
    />
  );
}

export default observer(BrineEcolabPageCreateGrid);
