import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { ILocation } from "../../../app/models/location";
import { useStore } from "../../../app/stores/store";
import { useNavigate } from "react-router-dom";

function LocationGrid() {
  const navigate = useNavigate();
  const { locationGridStore } = useStore();

  const gridColumns: GridColumns<ILocation> = [
    {
      field: "locationName",
      headerName: "Location",
      width: 170,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="View"
            color="info"
            onClick={() => {
              navigate(`edit/${params.id}`);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    locationGridStore.getLocationList();
  }, [locationGridStore]);

  return <RkdDataGrid gridStore={locationGridStore} columns={gridColumns} />;
}

export default observer(LocationGrid);
