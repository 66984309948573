import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ObjectTypeSchema } from "../../features/master/objectType/schema/objectTypeSchema";

export default class ObjectTypeStore {
  loadingGrid = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  getObjectTypeDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.ObjectType.detail(id);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingForm = false);
    }
  }

  getObjectTypesOptions = async (search: string) => {
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.ObjectType.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingForm = false);
    }
  }

  createObjectType = async (objectType: ObjectTypeSchema) => {
    try {
      await agent.ObjectType.create(objectType);
    } catch (error) {
        throw error;
    }
  }

  editObjectType = async (id: string, objectType: ObjectTypeSchema) => {
    try {
        await agent.ObjectType.edit(id, objectType);
    } catch (error) {
        throw error;
    }
  }

  deleteObjectType = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.ObjectType.delete(id);
    } catch (error) {
      throw error;
    }
    finally {
      runInAction(() => this.loadingForm = false);
    }
  }
}