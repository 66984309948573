import { IBrineConverterUnitTables, IBrineLtAnalysis } from "../../../../app/models/brine";
import { IRegressionResponse } from "../../../../app/models/steam";

export function predictedPpmFormula(regression: IRegressionResponse[], areaCounts: number) {
    if(regression.length === 4) {
        return regression[0].coefficients+(areaCounts * regression[1].coefficients) + ((areaCounts ** 2) * regression[2].coefficients) + ((areaCounts ** 3) * regression[3].coefficients);
    } else if(regression.length === 3) {
        return regression[0].coefficients+(areaCounts * regression[1].coefficients) + ((areaCounts ** 2) * regression[2].coefficients);
    } else {
        return regression[0].coefficients+(areaCounts * regression[1].coefficients);
        
    }
}

export function diffFormula(predictedPpm: number, ppb: number) {
    return Number((((predictedPpm - ppb) / ppb) * 100).toFixed(2));
}

export function dilutionFactorFormula(initialVol: number, finalVol: number) {
    if (initialVol || initialVol !== 0) {
      if (finalVol || finalVol !== 0) {
        return finalVol / initialVol;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
}

export function totalDilutionFactorFormula(dilutionFactor1: number, dilutionFactor2: number, dilutionFactor3: number, dilutionFactor4: number) {
    if (dilutionFactor1 !== 0) {
      if (dilutionFactor2 !== 0) {
        if (dilutionFactor3 !== 0) {
          return dilutionFactor1 * dilutionFactor2 * dilutionFactor3 * dilutionFactor4;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    } else {
      return 1;
    }
}

export function rawSampConcentrateFormula(readingAbsorb: number, arr: IBrineLtAnalysis[], regression: IRegressionResponse[]) {
    if (readingAbsorb) {
      if (readingAbsorb > findMinValue(arr, 'areaCounts')) {
        if(regression.length === 4) {
          return regression[0].coefficients+(readingAbsorb * regression[1].coefficients) + ((readingAbsorb ** 2) * regression[2].coefficients) + ((readingAbsorb ** 3) * regression[3].coefficients);
        } else if(regression.length === 3) {
            return regression[0].coefficients+(readingAbsorb * regression[1].coefficients) + ((readingAbsorb ** 2) * regression[2].coefficients);
        } else {
            return regression[0].coefficients+(readingAbsorb * regression[1].coefficients);
            
        }
      } else {
        return -findMinValue(arr, 'ppb');
      }
    } else {
      return 11;
    }
}

export function finalSampConcentrateFormula(readingAbsorb: number, totDilutionFac: number, rawSampConcentrate: number) {
    if (readingAbsorb) {
      return totDilutionFac * rawSampConcentrate;
    } else {
      return 0;
    }
}

export function averageConcentrationFormula(finalSampCon1: number, finalSampCon2: number, firstRow: boolean) {
  if(firstRow) {
    return finalSampCon2;
  } else {
    return (finalSampCon1 + finalSampCon2) / 2;
  }
}

export function tempFFormula(temperatureC: number): number {
  const temperatureF = (temperatureC * 9/5) + 32;
  return temperatureF;
}

export function brineFlowRateFormula(ppmTracer: number, injRate: number, wtBrine: number, baselineBrine: number) {
  if (ppmTracer !== null || ppmTracer > 0) {
    const value = (injRate * 0.13216) * (wtBrine / 100) / (ppmTracer - baselineBrine) * 1000;
    return value;
  } else {
    return 0;
  }
}

export function ppbwBrineFormula(ppmw: number) { 
  return ppmw * 1000;
}

export function brineFlowRateKgsFormula(brineFlowRate: number) {
  return brineFlowRate * 0.000126 * 1000;
}

export function findMinValue(arr: IBrineLtAnalysis[], property: 'ppb' | 'areaCounts') {
  if (arr.length === 0) {
    return 0;
  }

  let min = arr[0][property];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][property] < min) {
      min = arr[i][property];
    }
  }

  return min;
}

export function findMinBrineFlowRateValue(arr: IBrineConverterUnitTables[], property: 'brineFlowRateKph') {
  if (arr.length === 0) {
    return 0;
  }

  let min = arr[0][property];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][property] < min) {
      min = arr[i][property];
    }
  }

  return min;
}

export function findMaxBrineFlowRateValue(arr: IBrineConverterUnitTables[], property: 'brineFlowRateKph') {
  if (arr.length === 0) {
    return 0;
  }

  let max = arr[0][property];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][property] > max) {
      max = arr[i][property];
    }
  }

  return max;
}

export function BrineFlowRateSpreadFormula(arr: IBrineConverterUnitTables[]) {
  const min = findMinBrineFlowRateValue(arr, 'brineFlowRateKph');
  const max = findMaxBrineFlowRateValue(arr, 'brineFlowRateKph');

  return max - min;
}

export function calculateStandardDeviationBrine(data: IBrineConverterUnitTables[]) {
  const steamFlowRates = data.map((obj) => obj.brineFlowRateKph);
  const stdDev = calculateStdDev(steamFlowRates);
  return !isNaN(stdDev) ? stdDev : 0;
}

function calculateStdDev(data: number[]): number {
  if (data.length <= 1) {
      return NaN; // Standard deviation is undefined for a single data point
  }

  const mean = calculateMean(data);
  const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
  const variance = squaredDifferences.reduce((acc, val) => acc + val, 0) / (data.length - 1);
  const stdDev = Math.sqrt(variance);

  return stdDev;
  }
  
function calculateMean(data: number[]): number {
  return data.reduce((acc, val) => acc + val, 0) / data.length;
}

export function calculateBrineRsd(stdDev: number, avgBrineFlowRate: number) {
  if (stdDev !== 0) {
    return stdDev / avgBrineFlowRate;
  } else {
    return 0;
  }
}