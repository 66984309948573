import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IWellGeometry } from "../../../app/models/wellGeometry";
import { useNavigate } from "react-router-dom";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { format } from "date-fns";

function WellGeometryGrid() {
  const { wellGeometryGridStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    wellGeometryGridStore.getWellGeometryList();
  }, [wellGeometryGridStore]);

  const gridColumns: GridColumns<IWellGeometry> = [
    {
      field: "objectName",
      headerName: "Object Name",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "geometryType",
      headerName: "Geometry Type",
      width: 150,
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 150,
      type: "dateTime",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => format(new Date(params.row.modifiedDate), 'dd MMMM yyyy')
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            color="info"
            label="View"
            onClick={() => {
              const type = params.row.geometryType === "Directional" ? "directional" : "completion";
              navigate(`${type}/edit/` + params.row.objectId);
            }}
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   color="error"
          //   onClick={() => {
          //     modalStore.open(
          //       <WellGeometryModalDelete
          //         handleDelete={async () => {
          //           if (params.row.id) {
          //             if (params.row.geometryType === "Directional") {
          //               await deleteDirectionalData(params.row.objectId).then(wellGeometryGridStore.getWellGeometryList);
          //               snackbarStore.show("success", "Success delete directional data");
          //             } else {
          //               await deleteCompletionData(params.row.objectId).then(wellGeometryGridStore.getWellGeometryList);
          //               snackbarStore.show("success", "Success delete directional data");
          //             }
          //             modalStore.close();
          //           }
          //         }}
          //       />
          //     );
          //   }}
          // />,
        ];
      },
    },
  ];

  return <RkdDataGrid gridStore={wellGeometryGridStore} columns={gridColumns} />;
}

export default observer(WellGeometryGrid);
