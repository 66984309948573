import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ICompletionHole, ICompletionID, ICompletionOD, ICompletionResponse, IDirectional, IWellGeometryResponse } from "../models/wellGeometry";
import { IDeleteData } from "../models/account";

export default class WellGeometryStore {
  loadingGrid = false;
  loadingForm = false;
  loadingdelete = false;
  wellGeometrys: IWellGeometryResponse | null = null;

  directionalData: Array<IDirectional> = [];

  completionHole: Array<ICompletionHole> = [];
  completionOD: Array<ICompletionOD> = [];
  completionID: Array<ICompletionID> = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDirectionalDataExcel = (data: Array<Array<any>>) => {
    this.directionalData = data[0];
  };

  setDirectionalData = (data: Array<IDirectional>) => {
    this.directionalData = data;
  };

  setCompletionDataExcel = (data: Array<Array<any>>) => {
    this.completionHole = data[0];
    this.completionOD = data[1];
    this.completionID = data[2];
  };

  setCompletionData = (data: ICompletionResponse | null) => {
    if (data) {
      this.completionHole = data.dataCompletionHoles;
      this.completionOD = data.dataCompletionOds;
      this.completionID = data.dataCompletionIds;
    } else {
      this.completionHole = [];
      this.completionOD = [];
      this.completionID = [];
    }
  };

  setCompletionHole = (data: Array<ICompletionHole>) => {
    runInAction(() => (this.completionHole = data));
  };

  setCompletionOD = (data: Array<ICompletionOD>) => {
    runInAction(() => (this.completionOD = data));
  };

  setCompletionID = (data: Array<ICompletionID>) => {
    runInAction(() => (this.completionID = data));
  };

  getWellGeometryDirectionalDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.Geometry.detailDirectional(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  getWellGeometryCompletionDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.Geometry.detailCompletion(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createDirectionalData = async (objectId: string, data: Array<IDirectional>) => {
    try {
      await agent.Geometry.createDirectional({ objectId, data });
    } catch (error) {
      throw error;
    }
  };

  createCompletionData = async (
    objectId: string,
    dataCompletionHoles: Array<ICompletionHole>,
    dataCompletionOds: Array<ICompletionOD>,
    dataCompletionIds: Array<ICompletionID>
  ) => {
    try {
      await agent.Geometry.createCompletion({ objectId, dataCompletionHoles, dataCompletionOds, dataCompletionIds });
    } catch (error) {
      throw error;
    }
  };

  editDirectionalData = async (objectId: string, data: Array<IDirectional>) => {
    try {
      await agent.Geometry.createDirectional({ objectId, data });
    } catch (error) {
      throw error;
    }
  };

  editCompletionData = async (
    objectId: string,
    dataCompletionHoles: Array<ICompletionHole>,
    dataCompletionOds: Array<ICompletionOD>,
    dataCompletionIds: Array<ICompletionID>
  ) => {
    try {
      dataCompletionHoles.forEach((item: ICompletionHole) => {
        item.objectCompletionId = objectId;
      });
      dataCompletionOds.forEach((item: ICompletionOD) => {
        item.objectCompletionId = objectId;
      });
      dataCompletionIds.forEach((item: ICompletionID) => {
        item.objectCompletionId = objectId;
      });
      await agent.Geometry.editCompletion({ objectId, dataCompletionHoles, dataCompletionOds, dataCompletionIds });
    } catch (error) {
      throw error;
    }
  };

  deleteDirectionalData = async (id: string) => {
    this.loadingForm = true;
    try {
      await agent.Geometry.deleteDirectional(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteCompletionData = async (id: string) => {
    this.loadingForm = true;
    try {
      await agent.Geometry.deleteCompeltion(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteWellGeometry = async (ids: IDeleteData) => {
    this.loadingdelete = true;

    try {
      await agent.Geometry.deleteWellGeometry(ids);
    } catch (error) {
      runInAction(() => (this.loadingdelete = false));
    }
  };
}
