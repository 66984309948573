import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridValidRowModel, GridColDef, GridEventListener } from "@mui/x-data-grid-premium";
import { ControlPoint, Edit } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../app/stores/store";
import { TOrcGroupDetailResponse } from "../../../app/models/orcGroup";
import { format } from "date-fns";
import { Box } from "@mui/material";

function BrineORCDataGrid() {
  const { orcGroupGridStore, commonStore, brineORCMonitoringStore } = useStore();
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = (params: any) => {
    if (params.field === "brineOrcDate") {
      navigate(`/geochemistry-brine/edit/${params.row.brineOrcDate}`);
    }
  };

  useEffect(() => {
    brineORCMonitoringStore.getBrineORCMonitoringGrid();
  }, [brineORCMonitoringStore]);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "brineOrcDate",
      headerName: "Brine ORC Date",
      flex: 1,
      renderCell: (params) => <Box>{format(new Date(params.row.brineOrcDate), "dd MMM yyyy")}</Box>,
    },
    {
      field: "modifiedDateUtc",
      headerName: "Modified Date UTC",
      flex: 1,
      renderCell: (params) => <Box>{format(new Date(params.row.modifiedDateUtc), "dd MMM yyyy - HH:mm:ss")}</Box>,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Add Brine ORC"
          onClick={() => {
            navigate(`/geochemistry-brine/edit/${params.row.brineOrcDate}`);
            orcGroupGridStore.setSelectedOrc(params.row as TOrcGroupDetailResponse);
          }}
        />,
        <GridActionsCellItem
          icon={<ControlPoint />}
          color="info"
          label="Add Ecolab"
          onClick={() => {
            navigate(`/geochemistry-brine/ecolab/add-edit/${params.row.brineOrcDate}`);
            orcGroupGridStore.setSelectedOrc(params.row as TOrcGroupDetailResponse);
          }}
        />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      autoHeight
      loading={brineORCMonitoringStore.loadingGrid}
      showCellVerticalBorder
      showColumnVerticalBorder
      onCellClick={handleCellClick}
      checkboxSelection
      disableColumnMenu
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={brineORCMonitoringStore.dataGridResult.data}
    />
  );
}

export default observer(BrineORCDataGrid);
