import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";

export default class BiweeklyGridStore extends DataGridStore {
  constructor() {
    super();

    makeObservable(this, {
      getBiweeklyGrid: action,
    });
  }

  getBiweeklyGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geochemistry.getBiweeklyGrid(urlParams);
      // let resultWithTempId = result.data.map((item: any, index: number) => ({ ...item, tempId: item.id, id: item.id + index }));

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  setGridFromDetail = async (data: any[]) => {
    this.setDataGridResult({ rowCount: 0, data: data });
  };

  clearGridAfterUploadGeological = () => {
    this.setDataGridResult({ rowCount: 0, data: [] });
  };
}
