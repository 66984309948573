import { Box } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { observer } from "mobx-react-lite";

type TStep2LineChartProps = {
  values: any;
};

function Step2LineChart({ values }: TStep2LineChartProps) {
  return (
    <Box sx={{ height: "320px", display: "flex", justifyContent: "center", mt: "24px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={values.step2?.details?.map((item: any) => ({
            name: `Depth ${item.depthOrElevation}`,
            selectionA: item.selectionA,
            selectionB: item.selectionB,
            delta: item.delta,
          }))}
          margin={{
            right: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{
              fontSize: 12,
              fontStyle: "italic",
              offset: "-5",
            }}
            dataKey="name"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line strokeWidth={2} dataKey="selectionA" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line strokeWidth={2} dataKey="selectionB" stroke="#82ca9d" />
          <Line strokeWidth={2} dataKey="delta" stroke="red" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default observer(Step2LineChart);
