import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { Box, CircularProgress } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { TBiweeklyChartResponse } from "../../../../app/models/geochemistry";

type TQuarterlyChartProps = {
  quarterlyChart: TBiweeklyChartResponse;
};

function QuarterlyChart({ quarterlyChart }: TQuarterlyChartProps) {
  const { quarterlyStore } = useStore();
  const { loadingChart } = quarterlyStore;

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: "20px",
    },
    xaxis: {
      type: "numeric",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    markers: {
      size: 6,
    },
    yaxis: [
      {
        title: {
          text:
            quarterlyChart?.lefts.map((item) => item.name).join(", ").length ?? 0 > 50
              ? quarterlyChart?.lefts
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : quarterlyChart?.lefts.map((item) => item.name).join(", "),
        },
        reversed: true,
      },
      {
        title: {
          text:
            quarterlyChart?.rights.map((item) => item.name).join(", ").length ?? 0 > 50
              ? quarterlyChart?.rights
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : quarterlyChart?.rights.map((item) => item.name).join(", "),
        },
        opposite: true,
        reversed: true,
      },
    ],
  };

  return (
    <Box sx={{ height: "100%" }}>
      {loadingChart ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ReactApexChart
          height={400}
          type="scatter"
          options={options}
          series={[...(quarterlyChart?.lefts ?? []), ...(quarterlyChart?.rights ?? [])]}
        />
      )}
    </Box>
  );
}

export default observer(QuarterlyChart);
