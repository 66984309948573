import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class PortLocationStore {
  loadingGrid = false;
  loadingForm = false;
  loadingListOptions = false;
  
  constructor() {
    makeAutoObservable(this);
  }

  getPortLocationOptions = async (search: string) => {
    this.loadingListOptions = true;
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.portLocation.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingListOptions = false);
    }
  }

}