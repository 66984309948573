import { observer } from 'mobx-react-lite';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import React from 'react';
import { useStore } from '../../../app/stores/store';
import WellGeometryUploadExcel from './WellGeometryUploadExcel';
import * as XLSX from 'xlsx';

interface IProps {
  columns: Array<Array<string>>;
  setData: (value: any) => void;
  type: string;
}

function WellGeometryDropdownExcel(props: IProps) {
  const { snackbarStore } = useStore() 
  const { columns, setData, type } = props;
  const { modalStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: '8px' }}
        onClick={handleClick}
      >
        Templates
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Button
            component="a"
            variant="text"
            color= "primary"
            href={`/templates/${type === 'directional' ? 'Template_Data_Directional.xlsx' : 'Template_Data_Completion.xlsx'}`}
            download
            target="_blank"
            rel="noreferrer"  
            >
            Download
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
              component="label"
              variant="text"
              color= "primary"
            >
              Upload Excel
              <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden 
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {                                       
                  if(e.target.files && e.target.files.length > 0)
                    modalStore.open(
                      <WellGeometryUploadExcel name={e.target.files[0].name} 
                        handleSubmit={
                          () => {                            
                            if(e.target.files && e.target.files.length > 0){
                              setAnchorEl(null);                              
                              //Store function to upload here
                              var files = e.target.files, f = files[0];
                              var reader = new FileReader();
                              
                              
                              reader.onload = function (evt) {
                                  if(evt.target){
                                    let dataExport: any = [];
                                    var data = evt.target.result;
                                    let readedData = XLSX.read(data, {type: 'binary'});
                                    const sheetNames = readedData.SheetNames;
                                    let isError;
                                    
                                    if(sheetNames && sheetNames.length > 0){
                                      
                                      sheetNames.forEach((sheetName, idxSheet) => {
                                        const ws = readedData.Sheets[sheetName];
                                        /* Convert array to json*/
                                        const dataParse: Array<any> = XLSX.utils.sheet_to_json(ws, {header:1}, );
                                        interface LooseObject {
                                          [key: string]: any
                                        }
                                        //console.log(dataParse[0][1], columns[idxSheet][1])
                                        if(dataParse[0].length === columns[idxSheet].length) {
                                          const exportData = dataParse.slice(1, dataParse.length).map((item: any, index: number)  => {
                                            let data: LooseObject = {}
                                            
                                            item.forEach((element: any, index: number) => {
                                              data[columns[idxSheet][index]] = element;
                                            });
                                            data.id = index + 1;
                                            data.sequence = index + 1;
                                            return data;
                                          });
                                          dataExport.push(exportData)
                                        }
                                        else {
                                          isError = true
                                        }
                                        
                                      })
                                    } else {
                                      isError = true
                                    }

                                    if(!isError){
                                      setData(dataExport);
                                    }
                                    else
                                      snackbarStore.show('error', 'File not valid');
                                  }
                                  
                              };
                              reader.onerror = function (evt) {
                                snackbarStore.show('error', 'File not valid');
                              }
                              reader.readAsBinaryString(f)
                              modalStore.close();
                            }
                          }
                        } 
                      />
                    )                 
                }}
                name='postScreenshotFile' 
              /> 
            </Button>
        </MenuItem>
      </Menu> 
    </>
  );
}

export default observer(WellGeometryDropdownExcel);