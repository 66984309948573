import { Home, Dvr, Dataset, Inventory, CrisisAlert, Schema, Science, Speed, ThermostatAuto, TravelExplore, AddTask } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import { Roles } from "./enum";
import { Box } from "@mui/material";

type Permission = "hidden" | "read-only" | "all";
type Permissions = {
  role: Roles;
  permission: Permission;
};

export interface Menu {
  title: string;
  icon?: JSX.Element;
  route?: string;
  permissions?: Permissions[];
  submenu?: Array<Menu>;
}

const SubIcon = () => (
  <Box sx={{ width: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Box width={"9px"} height={"9px"} borderRadius={"9px"} bgcolor={"#999999"} />
  </Box>
);

export const menu: Array<Menu> = [
  {
    title: "Home",
    icon: <Home />,
    route: "/",
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
  },
  {
    title: "Daily Log",
    icon: <AddTask />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    submenu: [
      { title: "Daily Sheet", route: "/daily-sheet" },
      { title: "Query", route: "/query" },
    ],
  },
  {
    title: "Pressure & Temperature",
    icon: <ThermostatAuto />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    submenu: [
      { title: "Decimation", route: "/pt" },
      { title: "PT Report", route: "/pt-report" },
    ],
  },
  {
    title: "Tracer Flow Test",
    icon: <Speed />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    submenu: [
      { title: "TFT Module", route: "/tracer-flow-test" },
      { title: "Completion Data", route: "/tracer-flow-test-complesion" },
    ],
  },
  {
    title: "PTS",
    icon: <Dvr />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    route: "/pts",
  },
  {
    title: "Flow Performance Test",
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    icon: <Schema />,
    route: "/fpt",
  },
  {
    title: "Geological",
    icon: <TravelExplore />,
    submenu: [
      { title: "Geological Module", route: "/geological" },
      { title: "Geological Report", route: "/geological-report" },
    ],
  },
  {
    title: "Geophysics",
    icon: <CrisisAlert />,
    submenu: [
      { title: "MT Survey", route: "/geophysic-mt-survey" },
      { title: "Gravity Survey", route: "/geophysic-gravity-survey" },
    ],
  },
  {
    title: "Geochemistry",
    icon: <Science />,
    submenu: [
      {
        title: "Biweekly",
        icon: <SubIcon />,
        submenu: [
          {
            title: "Biweekly Database",
            route: "/geochemistry-biweekly",
          },
          { title: "Biweekly Database Report", route: "/geochemistry-biweekly-report" },
        ],
      },
      {
        title: "Brine ORC",
        icon: <SubIcon />,
        submenu: [
          {
            title: "Brine ORC Monitoring",
            route: "/geochemistry-brine",
          },
          { title: "Brine ORC Report", route: "/geochemistry-brine-report" },
        ],
      },
      {
        title: "Quarterly",
        icon: <SubIcon />,
        submenu: [
          {
            title: "Quarterly",
            route: "/geochemistry-quarterly",
          },
          { title: "Quarterly Report", route: "/geochemistry-quarterly-report" },
        ],
      },
      { title: "Clean Out History", route: "/geochemistry-clean" },
    ],
  },
  {
    title: "Well Data Master",
    icon: <Dataset />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    submenu: [
      { title: "Well", route: "/master/object" },
      { title: "Well Status", route: "/master/object-status" },
      { title: "Well Geometry", route: "/master/geometry" },
    ],
  },
  {
    title: "Data Master",
    icon: <Inventory />,
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "all", role: Roles.Superadmin },
    ],
    submenu: [
      {
        title: "Object Types",
        route: "/master/object-types",
      },
      {
        title: "Vessel Group",
        route: "/master/vessel-group",
      },
      {
        title: "Location",
        route: "/master/location",
      },
      {
        title: "Brine Pump",
        route: "/master/brine-pump",
      },
      {
        title: "Connection",
        route: "/master/connection",
      },
      {
        title: "ORC",
        route: "/master/orc",
      },
      {
        title: "ORC Group",
        route: "/master/orc-group",
      },
    ],
  },
  {
    title: "User Management",
    icon: <PeopleIcon />,
    route: "/users",
    permissions: [
      { permission: "all", role: Roles.Superadmin },
      { permission: "hidden", role: Roles.Superadmin },
    ],
  },
];
