import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { TGeneralGeological } from "./Lithology";
import { useStore } from "../../../../app/stores/store";
import { IGeoPcsTableRequest } from "../../../../app/models/geological";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function PCS({
  onDelete,
  selectedGeologicalType,
  deleteSequence,
  setDeleteSequence,
  data,
  setDynamicConvertedData,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      mmd: row.mmd,
      mvd: row.mvd,
      mmsl: row.mmsl,
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoPcsTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        well: row.well,
        mmd: row.mmd,
        mvd: row.mvd,
        mmsl: row.mmsl,
        easting: row.easting,
        northing: row.northing,
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    {
      field: "wellId",
      headerName: "Well",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mmd",
      headerName: "mmd",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mvd",
      headerName: "mvd",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mmsl",
      headerName: "mmsl",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "easting",
      headerName: "Easting",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "northing",
      headerName: "Northing",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
  ];

  return (
    <StyledDataGridPremium
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
      autoHeight
      processRowUpdate={handleRowEdit}
      showCellVerticalBorder
      showColumnVerticalBorder
    />
  );
}

export default observer(PCS);
