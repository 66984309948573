import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Collapse, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import DynamicAccordion from "./component/DynamicAccordion";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { format } from "date-fns";
import { DAILY_SHEET } from "../../../app/config/enum";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";

function DailysheetPageIndex() {
  const { accountStore, snackbarStore } = useStore();
  const { setCreatedBy, getDailyByDate, getDailySheet, dailySheetData, addDailySheet, loadingGetData, loadingForm, copyPreviousDailySheet } =
    useStore().dailysheetStore;
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isOpenList, setIsOpenList] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>("");

  const dailySheetList = [
    {
      title: "Productions",
      component: (
        <Box>
          {dailySheetData?.productions.map((_, index) => (
            <DynamicAccordion identifier={DAILY_SHEET.PRODUCTIONS} index={index} />
          ))}
        </Box>
      ),
    },
    {
      title: "Injections",
      component: (
        <Box>
          {dailySheetData?.injections.map((_, index) => (
            <DynamicAccordion identifier={DAILY_SHEET.INJECTIONS} index={index} />
          ))}
        </Box>
      ),
    },
    {
      title: "Monitorings",
      component: (
        <Box>
          <DynamicAccordion identifier={DAILY_SHEET.MONITORINGS} index={0} />
        </Box>
      ),
    },
  ];

  const onSubmit = (isDraft: boolean, identifier?: string) => {
    const { monitoring, notes: initialNotes, status, ...rest } = dailySheetData!;

    let payloadSubmit = {
      ...rest,
      dailyLogDate: selectedDate,
      notes: notes,
      monitorings: monitoring.table,
    };

    let payloadCopy = {
      ...rest,
      dailyLogDate: selectedDate,
      monitorings: monitoring.table,
    };

    if (identifier) {
      copyPreviousDailySheet(payloadCopy).then(() => {
        snackbarStore.show("success", "Copy Previous Success");
        getDailySheet(format(new Date(selectedDate), "yyyy-MM-dd"));
      });
    } else {
      addDailySheet(isDraft, payloadSubmit).then(() => {
        snackbarStore.show("success", `Daily Sheet Saved ${isDraft ? "as draft" : ""}`);
        getDailySheet(format(new Date(selectedDate), "yyyy-MM-dd"));
      });
    }
  };

  useEffect(() => {
    getDailySheet(format(new Date(selectedDate), "yyyy-MM-dd"));
  }, [getDailyByDate, getDailySheet, selectedDate]);

  useEffect(() => {
    accountStore.getUser().then(() => {
      setCreatedBy(accountStore.account!.username, accountStore.account!.username);
    });
  }, [accountStore, setCreatedBy]);

  return (
    <RkdCard title="Daily Sheet" subtitle="Add Edit & Delete Daily Sheet from this Page">
      <Grid container>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(e: any) => setSelectedDate(e)}
              value={selectedDate}
              inputFormat="dd MMM yyyy"
              label={`Date ${dailySheetData?.status ? `(${dailySheetData?.status})` : ""}`}
              renderInput={(props) => <TextField label="Date" {...props} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}></Grid>
        <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={false}
              onClick={() => onSubmit(false, "copy")}
              sx={{ padding: "15px 20px" }}
            >
              Copy Previous
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingGetData ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {dailySheetList.map((item) => (
              <Box
                sx={{
                  p: "16px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: "1px solid #DEDEDE",
                  background: "#FFF",
                  mb: "12px",
                }}
              >
                <Box
                  onClick={() => {
                    let temp = [...isOpenList];
                    if (temp.includes(item.title)) {
                      temp = isOpenList.filter((isOpenItem) => isOpenItem !== item.title);
                    } else {
                      temp.push(item.title);
                    }
                    setIsOpenList(temp);
                  }}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Box>
                    <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                    <Box sx={{ fontSize: "12px" }}>See Details</Box>
                  </Box>
                  <Box>{isOpenList.includes(item.title) ? <ExpandLess /> : <ExpandMore />}</Box>
                </Box>
                <Collapse sx={{ mt: "24px" }} in={isOpenList.includes(item.title)} timeout="auto" unmountOnExit>
                  {item.component}
                </Collapse>
              </Box>
            ))}
            <TextField id="outlined-multiline-flexible" label="Notes" onChange={(e) => setNotes(e.target.value)} fullWidth multiline rows={4} />
          </>
        )}
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button
          variant="contained"
          disabled={loadingForm}
          color="primary"
          sx={{ backgroundColor: "#fab933", "&:hover": { backgroundColor: "#dea123" } }}
          onClick={() => onSubmit(true)}
        >
          Save as Draft
        </Button>
        <Button variant="contained" disabled={loadingForm} color="success" sx={{ mr: 1 }} onClick={() => onSubmit(true)}>
          Save
        </Button>
      </Grid>
    </RkdCard>
  );
}

export default observer(DailysheetPageIndex);
