import { makeObservable, runInAction, action, observable } from "mobx";
import agent from "../api/agent";
import DataGridStore from "./materialUI/dataGridStore";
import { IConnectionPayload } from "../models/connection";

export default class ConnectionStore extends DataGridStore {
  loadingForm = false;
  loadingListOptions = false

  constructor() {
      super();

      makeObservable(this, {
        loadingForm: observable,
        loadingListOptions: observable,
        getConnectionList: action,
        getConnectionOptions: action,
        getConnectionDetail: action,
        getConnectionVesselGroup: action,
        createConnection: action,
        editConnection: action,
        deleteConnection: action
      });
  }

  getConnectionList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Connection.listGrid(urlParams);
      this.setDataGridResult(result);
      
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingGrid = false);
    }
  }

  updateGridCallback = action(() => {
    this.getConnectionList();
  })

  getConnectionOptions = async (search: string) => {
    this.loadingListOptions = true
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.Connection.listOptions(params);
      
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingListOptions = false);
    }
  }

  getConnectionVesselGroup = async (id: string) => {
    try {
      const result = await agent.Connection.getVesselGroup(id);
      return result;
    } catch (error) {
      throw error;
    }
  }

  getConnectionDetail = async (id: string | undefined) => {
    this.loadingForm = true;
    try {
      if(id) {
        return await agent.Connection.detail(id);
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingForm = false);
    }
  }


  createConnection = async (connection: IConnectionPayload) => {
    try {
      return await agent.Connection.create(connection);
    } catch (error) {
      throw error;
    }
  }

  editConnection = async (id: string, connection: IConnectionPayload) => {
    try {
      return await agent.Connection.edit(id, connection);
    } catch (error) {
      throw error;
    }
  }

  deleteConnection = async (id: any) => {
    try {
      return await agent.Connection.delete(id);
    } catch (error) {
      throw error;
    }
  }

}