import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  TCleanDataGridDetailResponse,
  TCleanDetailDataResponse,
  TCleanGridDetailPayload,
  TCleanOoutHistoryGridDataResponse,
  TCleanOoutHistoryGridPayload,
  TSelectedCleanOutDetail,
} from "../models/geochemistry";
import { IDeleteData } from "../models/account";

export default class CleanOutHistory {
  loadingGrid = false;
  loadingDelete = false;
  loadingAddEdit = false;
  loadingDetail = false;
  cleanOutHistoryGrid: TCleanOoutHistoryGridDataResponse[] = [];
  cleanOutDetailGrid: TCleanDataGridDetailResponse[] = [];
  cleanOutDetail: TCleanDetailDataResponse | undefined = undefined;
  selectedCleanOutDetail: TSelectedCleanOutDetail | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  getCleanOutDetail = async (id: string) => {
    this.loadingDetail = true;

    try {
      let result = await agent.Geochemistry.getCleanOutDetail(id);

      runInAction(() => (this.cleanOutDetail = result.data));
      return result.data;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDetail = false));
    }
  };

  addCleanOutDetail = async (payload: FormData) => {
    this.loadingAddEdit = true;

    try {
      await agent.Geochemistry.addCleanOutDetail(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingAddEdit = false));
    }
  };

  editCleanOutDetail = async (payload: FormData, id: string) => {
    this.loadingAddEdit = true;

    try {
      await agent.Geochemistry.editCleanOutDetail(payload, id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingAddEdit = false));
    }
  };

  deleteCleanOutDetailGrid = async (ids: IDeleteData) => {
    this.loadingDelete = true;

    try {
      await agent.Geochemistry.deleteCleanOutDetailGrid(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  getCleanOutDetailGrid = async (payload: TCleanGridDetailPayload) => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getCleanOutDetailGrid(payload);

      runInAction(() => (this.cleanOutDetailGrid = result.data));
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getCleanOutHistoryGrid = async (payload: TCleanOoutHistoryGridPayload) => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getCleanOutHistoryGrid(payload);

      runInAction(() => (this.cleanOutHistoryGrid = result.data));
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  setSelectedCleanOutDetail = (data: TSelectedCleanOutDetail) => {
    this.selectedCleanOutDetail = data;
  };

  clearCleanOutDetail = () => {
    this.cleanOutDetail = undefined;
  };

  clearCleanOutHistoryGrid = () => {
    this.cleanOutHistoryGrid = [];
  };
}
