import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ObjectSchema } from "../../features/master/object/schema/objectSchema";
import { IObject, IObjectOptions } from "../models/object";
import { IDeleteData } from "../models/account";

export default class ObjectStore {
  loadingGrid = false;
  loadingForm = false;
  loadingListOptions = false;

  listOptionsByLocationId: IObjectOptions[] = [];
  listObject: IObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getObjectDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.Object.detail(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  getObjectOptions = async (search: string, locationId?: string) => {
    this.loadingListOptions = true;
    try {
      const params = new URLSearchParams({
        type: search,
        ...(locationId && { locationId: locationId }),
      });
      return await agent.Object.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingListOptions = false));
    }
  };

  getObjectOptionsByLocationId = async (locationId: string) => {
    this.loadingListOptions = true;

    try {
      const params = new URLSearchParams({
        locationId: locationId,
      });
      const res = await agent.Object.listOptionsByLocationId(params);

      runInAction(() => (this.listOptionsByLocationId = res));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingListOptions = false));
    }
  };

  createObject = async (object: ObjectSchema) => {
    try {
      await agent.Object.create(object);
    } catch (error) {
      throw error;
    }
  };

  editObject = async (id: string, object: ObjectSchema) => {
    try {
      await agent.Object.edit(id, object);
    } catch (error) {
      throw error;
    }
  };

  deleteObject = async (id: IDeleteData) => {
    this.loadingForm = true;
    try {
      await agent.Object.delete(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  dropdownObject = async () => {
    try {
      const data = await agent.Object.dropdown();
      this.setListObject(data);
      console.log(data);
    } catch (error) {
      throw error;
    }
  };

  setListObject = (data: IObject[]) => {
    this.listObject = data;
  };
}
