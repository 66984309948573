import { makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { FptConnectedWellForm, FptDetail, FptForm, FptTestVesselDetail, FptTestWellDetail, FptTestWellForm, FptTestWellResult, FptVesselForm, IFPTChartSeriesResponse, IFptDetail } from "../models/fpt";
import { IDeleteData } from "../models/account";
import { IObjectOptions } from "../models/object";

export default class FptStore extends DataGridStore{
    loadingForm = false;
    loadingGetOptions = false

    objectFptObtions: IObjectOptions[] = []
    fpt = new FptDetail();
    fptForm = new FptForm();
    fptChart : Array<IFPTChartSeriesResponse> = [];

    constructor() {
        super();
        makeObservable(this, {
            loadingForm: observable,
        });
    }

    setRequest = (fpt : IFptDetail) => {
        try{
            this.fpt = new FptDetail();
            this.fpt.id = fpt.id;
            this.fpt.wellId = fpt.wellId;
            this.fpt.surveyDate = fpt.surveyDate;
            this.fpt.wellName = fpt.wellName;
            this.fpt.pAtm = fpt.pAtm;
            this.fpt.remarks = fpt.remarks;
            this.fpt.connectedWells = fpt.connectedWells;
            this.fpt.testWells = fpt.testWells;
            this.fpt.vessel = fpt.vessel;
            this.fpt.testResults = fpt.testResults;
        }
        catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    setFPT = (fpt : IFptDetail) => {
        try{
            this.fpt = new FptDetail();
            this.fpt.id = fpt.id;
            this.fpt.wellId = fpt.wellId;
            this.fpt.surveyDate = fpt.surveyDate;
            this.fpt.wellName = fpt.wellName;
            this.fpt.pAtm = fpt.pAtm;
            this.fpt.remarks = fpt.remarks;
            this.fpt.connectedWells = fpt.connectedWells;
            this.fpt.testWells = fpt.testWells;
            this.fpt.vessel = fpt.vessel;
            this.fpt.testResults = fpt.testResults;
        }
        catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    setFPTChart = (fpt : Array<IFPTChartSeriesResponse>) => {
        try{
            this.fptChart = fpt;
        }
        catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    setFPTForm = (fpt : FptDetail) => {
        try{
            this.fptForm = new FptForm();
            this.fptForm.wellId = fpt.wellId;
            this.fptForm.surveyDate = fpt.surveyDate;
            this.fptForm.pAtm = fpt.pAtm;
            this.fptForm.remarks = fpt.remarks;

            let testWells:any = [];
            fpt.testWells.forEach(element => {
                const formTestWellData = new FptTestWellForm();
                formTestWellData.wellId = fpt.wellId;
                formTestWellData.enthalphy = element.enthalphy;
                formTestWellData.table = element.table;
                testWells.push(formTestWellData)
            })
            this.fptForm.testWells = testWells;

            let testVessels = new FptVesselForm();
            testVessels.vesselGroupId = fpt.vessel.vesselGroupId;
            testVessels.table = fpt.vessel.table;
            this.fptForm.vessel = testVessels;

            let connectedWells:any = [];
            fpt.connectedWells.forEach(element => {
                const formConnectedWell = new FptConnectedWellForm();
                formConnectedWell.wellId = element.wellId;
                formConnectedWell.enthalphy = element.enthalphy;
                formConnectedWell.prodPercentage = element.prodPercentage;
                formConnectedWell.dWsWhp = element.dWsWhp;
                formConnectedWell.table = element.table;
                connectedWells.push(formConnectedWell);
            });
            this.fptForm.connectedWells = connectedWells;

            this.fptForm.testResults = fpt.testResults;
        }
        catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    getFpt = async (wellId: string, date: string) => {
        this.loadingForm = true;
        try {
            const result = await agent.FPT.detail(wellId, date);
            result.surveyDate = new Date(result.surveyDate + "Z");
            return result;
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    getFptChart = async (wellId: string, date: string) => {
        this.loadingForm = true;
        try {
            const result = await agent.FPT.chart(wellId, date);
            return result;
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    addConnectedWell = (fptTestWellDetails : FptTestWellDetail, wellId : string) => {
        let well = this.fpt.connectedWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            if(well.table.length === 0){
                well.table = [];
                well.table.push(fptTestWellDetails);
            }
            else{
                let newArray : any = [];
                well.table.forEach(element => {
                    newArray.push(element);
                });
                newArray.push(fptTestWellDetails);
                well.table = newArray;
            }
        }
    }

    setConnectedWell = (fptTestWellDetails : Array<FptTestWellDetail>, wellId : string) => {
        let well = this.fpt.connectedWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            well.table = fptTestWellDetails;
        }
    }

    addTestWell = (fptTestWellDetails : FptTestWellDetail, wellId : string) => {
        let well = this.fpt.testWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            if(well.table.length === 0){
                well.table = [];
                well.table.push(fptTestWellDetails);
            }
            else{
                let newArray : any = [];
                well.table.forEach(element => {
                    newArray.push(element);
                });
                newArray.push(fptTestWellDetails);
                well.table = newArray;
            }
        }
    }

    setTestWell = (fptTestWellDetails : Array<FptTestWellDetail>, wellId : string) => {
        let well = this.fpt.testWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            well.table = fptTestWellDetails;
        }
    }

    setTestWellText = (wellId : string, enthalphy : number) => {
        let well = this.fpt.testWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            well.enthalphy = enthalphy;
        }
    }

    setConnectedWellText = (wellId : string, enthalphy : number, prodPercentage : number, dWsWhp : number) => {
        let well = this.fpt.connectedWells.find(x => x.wellId === wellId);
        if(well !== undefined){
            well.enthalphy = enthalphy;
            well.prodPercentage = prodPercentage;
            well.dWsWhp = dWsWhp;
        }
    }

    addTestVesselTable = (fptTestVesselTable : FptTestVesselDetail) => {
        if(this.fpt.vessel.table.length === 0){
            this.fpt.vessel.table = [];
            this.fpt.vessel.table.push(fptTestVesselTable);
        }
        else{
            let newArray : any = [];
            this.fpt.vessel.table.forEach(element => {
                newArray.push(element);
            });
            newArray.push(fptTestVesselTable);
            this.fpt.vessel.table = newArray;
        }
    }

    setTestVesselTable = (fptTestVesselTable : Array<FptTestVesselDetail>) => {
        this.fpt.vessel.table = fptTestVesselTable;
    }

    addTestWellTableResult = (fptWellTableResult : FptTestWellResult) => {
        if(this.fpt.testResults.length === 0){
            this.fpt.testResults = [];
            this.fpt.testResults.push(fptWellTableResult);
        }
        else{
            let newArray : any = [];
            this.fpt.testResults.forEach(element => {
                newArray.push(element);
            });
            newArray.push(fptWellTableResult);
            this.fpt.testResults = newArray;
        }
    }

    setTestWellTableResult = (fptWellTableResult : Array<FptTestWellResult>) => {
        this.fpt.testResults = fptWellTableResult;
    }

    createFPT = async (fpt: FptForm) => {
        try {
            fpt.surveyDate = new Date(fpt.surveyDate + "Z");
            await agent.FPT.create(fpt);
        } catch (error) {
            throw error;
        }
    }

    getObjectOptions = async () => {
        this.loadingGetOptions = true
        
        try {
            let result = await agent.FPT.getObjectOptions()

            runInAction(() => this.objectFptObtions = result)
        } catch (error) {
            throw error
        } finally {
            runInAction(() =>  this.loadingGetOptions =false)
        } 
    }
    

    deleteFPT = async (id: IDeleteData) => {
        this.loadingForm = true;
        try {
          await agent.FPT.delete(id);
        } catch (error) {
          throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }
}