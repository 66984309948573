import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { IRegressionRequest, ISteamSf6AnalysisTables } from "../../app/models/steam";
import { useStore } from "../../app/stores/store";
import { diffFormula, molesSF6Formula, predictedMolesFormula } from "./helper/steam/formula";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem, GridValidRowModel } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";

function getAverageTemp(data: ISteamSf6AnalysisTables[]) {
  const total = data.reduce((accumulator, item) => accumulator + item.temp, 0);
  return total / data.length;
}

function TFTProcessSteamStep1() {
  const { tftSteamStore, accountStore } = useStore();

  const prevTempAverage = useRef(tftSteamStore.tft.steam.sF6Analysis.tavg);
  const prevRegression = useRef(tftSteamStore.tft.steam.regressions);

  const [sf6AnalysisTable, setSf6AnalysisTable] = useState<ISteamSf6AnalysisTables[]>(tftSteamStore.tft.steam.sF6Analysis.table);
  const [regressionReq, setRegressionReq] = useState<IRegressionRequest>({
    xValues: [],
    yValues: [],
  });
  const [showRegression, setShowRegression] = useState(false);

  const [regressionActive, setRegressionActive] = useState("");

  const handleAddRow = () => {
    resetRegressionSection();
    setSf6AnalysisTable([
      ...sf6AnalysisTable,
      {
        sequence: sf6AnalysisTable.length + 1,
        loopSize: 0.1,
        loopPressure: 0,
        ppmvsF6: 5,
        areaCounts: 0,
        molesSF6: 0,
        predictedMoles: 0,
        diff: 0,
        temp: 0,
      },
    ]);
  };

  const deleteRow = (sequence: number) => {
    let data = sf6AnalysisTable.filter((x) => x.sequence !== sequence);
    if (!data.length) {
      resetRegressionSection();
    } else {
      tftSteamStore.getRegressions(regressionActive, regressionReq);
      setShowRegression(true);
    }
    data.forEach((x, index) => (x.sequence = index + 1));
    setSf6AnalysisTable(data);
  };

  const columnGcParameters: GridColDef[] = [
    {
      field: "operator",
      headerName: "Operator",
      type: "string",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body1">{accountStore.account?.displayName}</Typography>;
      },
    },
    {
      field: "carrierGas",
      headerName: "Carrier Gas",
      type: "string",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "carrierFlow",
      headerName: "Carrier Flow",
      type: "number",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "auxGas",
      headerName: "Aux Gas",
      type: "string",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "auxFlow",
      headerName: "Aux Flow",
      type: "number",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "ma",
      headerName: "mA",
      type: "number",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "detTemp",
      headerName: "Det Temp",
      type: "number",
      editable: true,
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "loopSize",
      headerName: "Loop Size (in mls)",
      width: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "loopPressure",
      headerName: "Loop Pressure (in psia)",
      width: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: true,
      headerClassName: "tab-header",
    },
    {
      field: "ppmvsF6",
      headerName: "PPMv SF6 in Std.",
      width: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "areaCounts",
      headerName: "Area Counts",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "molesSF6",
      headerName: "Moles SF6",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.molesSF6}</Typography>;
      },
    },
    {
      field: "predictedMoles",
      headerName: "Predicted Moles",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.predictedMoles}</Typography>;
      },
    },
    {
      field: "diff",
      headerName: "% Diff",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.diff}</Typography>;
      },
    },
    {
      field: "temp",
      headerName: "Temp C",
      width: 100,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [<GridActionsCellItem color="error" icon={<DeleteIcon />} label="Delete" onClick={() => deleteRow(params.row.sequence)} />];
      },
    },
  ];

  const columnRegressions: GridColDef[] = [
    {
      field: "coefficientName",
      headerName: "Name",
      type: "string",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      flex: 1,
    },
    {
      field: "coefficients",
      headerName: "Coefficients",
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "tab-header",
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.coefficients}</Typography>;
      },
    },
  ];

  const resetRegressionSection = () => {
    setRegressionActive("");
    setShowRegression(false);
  };

  const handleRowEdit = (newValue: GridValidRowModel) => {
    const data = {
      carrierGas: newValue.carrierGas,
      carrierFlow: newValue.carrierFlow,
      auxGas: newValue.auxGas,
      auxFlow: newValue.auxFlow,
      ma: newValue.ma,
      detTemp: newValue.detTemp,
      ti: newValue.ti,
      tf: newValue.tf,
      tavg: newValue.tavg,
      table: newValue.table,
    };

    tftSteamStore.setSf6Analysis(data);

    return newValue;
  };

  const handleRowEditSf6Table = (newValue: GridValidRowModel) => {
    let temp = { ...newValue };

    const data: ISteamSf6AnalysisTables[] = tftSteamStore.tft.steam.sF6Analysis.table.map((current) => {
      if (current.sequence !== newValue.sequence) return current;

      const newMolesSF6 = molesSF6Formula(newValue.ppmvsF6, newValue.loopSize, tftSteamStore.tft.steam.sF6Analysis.tavg, newValue.loopPressure);
      const newPredictedMoles = predictedMolesFormula(newValue.loopSize, tftSteamStore.tft.steam.regressions, newValue.areaCounts);
      const newDiff = diffFormula(
        newValue.loopSize,
        predictedMolesFormula(newValue.loopSize, tftSteamStore.tft.steam.regressions, newValue.areaCounts),
        newMolesSF6
      );

      temp.molesSF6 = newMolesSF6;
      temp.predictedMoles = newPredictedMoles;
      temp.diff = newDiff;

      return {
        sequence: newValue.sequence,
        loopSize: newValue.loopSize,
        loopPressure: newValue.loopPressure,
        ppmvsF6: newValue.ppmvsF6,
        areaCounts: newValue.areaCounts,
        molesSF6: newMolesSF6,
        predictedMoles: newPredictedMoles,
        diff: newDiff,
        temp: newValue.temp,
      };
    });
    setSf6AnalysisTable(data);
    resetRegressionSection();

    return temp;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "ti") tftSteamStore.setTi(Number(event.target.value));
    if (event.target.name === "tf") tftSteamStore.setTf(Number(event.target.value));

    tftSteamStore.setTavg((tftSteamStore.tft.steam.sF6Analysis.ti + tftSteamStore.tft.steam.sF6Analysis.tf) / 2);
  };

  const getNewRegression = useCallback(
    () => {
      if (!regressionActive) return;
      tftSteamStore.getRegressions(regressionActive, regressionReq);
      setShowRegression(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regressionActive]
  );

  useEffect(() => {
    tftSteamStore.setSf6AnalysisTable(sf6AnalysisTable);
  }, [sf6AnalysisTable, tftSteamStore]);

  useEffect(() => {
    if (tftSteamStore.tft.steam.sF6Analysis.tavg !== prevTempAverage.current) {
      const tempData = sf6AnalysisTable.map((data) => {
        return { ...data, molesSF6: molesSF6Formula(data.ppmvsF6, data.loopSize, tftSteamStore.tft.steam.sF6Analysis.tavg, data.loopPressure) };
      });

      setSf6AnalysisTable(tempData);
      prevTempAverage.current = tftSteamStore.tft.steam.sF6Analysis.tavg;
    }

    if (tftSteamStore.tft.steam.regressions !== prevRegression.current) {
      const tempData = sf6AnalysisTable.map((data) => {
        return {
          ...data,
          predictedMoles: predictedMolesFormula(data.loopSize, tftSteamStore.tft.steam.regressions, data.areaCounts),
          diff: diffFormula(data.loopSize, predictedMolesFormula(data.loopSize, tftSteamStore.tft.steam.regressions, data.areaCounts), data.molesSF6),
        };
      });
      setSf6AnalysisTable(tempData);
      prevRegression.current = tftSteamStore.tft.steam.regressions;
    }
  }, [sf6AnalysisTable, tftSteamStore.tft.steam.regressions, tftSteamStore.tft.steam.sF6Analysis.tavg]);

  useEffect(() => {
    setSf6AnalysisTable(tftSteamStore.tft.steam.sF6Analysis.table);
  }, [tftSteamStore.tft.steam.sF6Analysis.table]);

  useEffect(() => {
    let x: number[] = [];
    let y: number[] = [];
    let reg: IRegressionRequest = { xValues: [], yValues: [] };

    tftSteamStore.tft.steam.sF6Analysis.table.map((data) => {
      x.push(data.areaCounts);
      y.push(data.molesSF6);
      return data;
    });

    reg.xValues = x;
    reg.yValues = y;
    setRegressionReq(reg);
  }, [tftSteamStore.tft.steam.sF6Analysis.table]);

  useEffect(() => {
    if (regressionActive) getNewRegression();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewRegression]);

  console.log("sf6AnalysisTable", sf6AnalysisTable);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body1">GC Parameter</Typography>
      </Box>
      <StyledDataGridPremium
        columns={columnGcParameters}
        rows={[tftSteamStore.tft.steam.sF6Analysis]}
        autoHeight
        pagination
        getRowId={(row) => row.carrierGas}
        showCellVerticalBorder
        showColumnVerticalBorder
        processRowUpdate={handleRowEdit}
      />
      <Box sx={{ textAlign: "center", mt: "12px" }}>Ambient Temperature (in degrees Celsius)</Box>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Button variant="contained" color="primary" onClick={handleAddRow} sx={{ width: "10%", textAlign: "right" }}>
          Add
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            label="Ti"
            fullWidth
            name="ti"
            type="number"
            variant="outlined"
            onChange={handleChange}
            value={tftSteamStore.tft.steam.sF6Analysis.ti}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Tf"
            fullWidth
            name="tf"
            type="number"
            variant="outlined"
            onChange={handleChange}
            value={tftSteamStore.tft.steam.sF6Analysis.tf}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="T avg."
            name="tavg"
            fullWidth
            variant="outlined"
            disabled
            onChange={handleChange}
            value={tftSteamStore.tft.steam.sF6Analysis.tavg}
          />
        </Grid>
      </Grid>

      <StyledDataGridPremium
        columns={columns}
        rows={sf6AnalysisTable}
        autoHeight
        pagination
        getRowId={(row) => row.sequence}
        processRowUpdate={handleRowEditSf6Table}
        showCellVerticalBorder
        showColumnVerticalBorder
      />
      <Box sx={{ display: "flex", gap: 1, flexDirection: "row", width: "100%", mt: 1, justifyContent: "center" }}>
        <Button
          variant={regressionActive === "linear" ? "contained" : "outlined"}
          disabled={!sf6AnalysisTable.length}
          color="primary"
          onClick={() => setRegressionActive("linear")}
        >
          Linear
        </Button>
        <Button
          variant={regressionActive === "quadratic" ? "contained" : "outlined"}
          color="primary"
          disabled={!sf6AnalysisTable.length}
          onClick={() => setRegressionActive("quadratic")}
        >
          Quadratic
        </Button>
        <Button
          variant={regressionActive === "cubic" ? "contained" : "outlined"}
          disabled={!sf6AnalysisTable.length}
          color="primary"
          onClick={() => setRegressionActive("cubic")}
        >
          Cubic
        </Button>
      </Box>

      {showRegression && (
        <StyledDataGridPremium
          columns={columnRegressions}
          rows={tftSteamStore.tft.steam.regressions}
          autoHeight
          getRowId={(row) => row.coefficientName}
          showCellVerticalBorder
          showColumnVerticalBorder
          pagination
        />
      )}
    </Box>
  );
}

export default observer(TFTProcessSteamStep1);
