import { observer } from 'mobx-react-lite'
import { Box } from '@mui/system'
import { MenuItem, TextField, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react'
import RkdCard from '../../app/component/card/RkdCard'
import { IObjectOptions } from '../../app/models/object';
import { useStore } from '../../app/stores/store';
import { IPtsSelectors } from '../../app/models/pts';
import PtsChartReport from './PtsChartReport';
import { IPtsChartRequest, IPtsChartSeriesResponse, IPtsChartYAxis, PtsChart, PtsChartYAxis, PtsChartYAxisTitle, PtsObjectsRequest, PtsXTypesRequest, PtsY1TypesRequest, PtsY2TypesRequest } from '../../app/models/ptsChart';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

function PtsPageReport() {
    const [selectedDate, setselectedDate] = useState(new Date());
    const { objectStore, ptsStore } = useStore();
    const { chartPts } = ptsStore;
    const [objectIdsOption, setObjectIdsOption] = useState<Array<IObjectOptions>>([]);
    const [objectSelectorsOption, setObjectSelectorsOption] = useState<Array<IPtsSelectors>>([]);
    const [series, setSeries] = useState<Array<IPtsChartSeriesResponse>>([]);
    const [yAxis, setYAxis] = useState<Array<IPtsChartYAxis>>([]);
    const [ptsChartRequest] = useState<IPtsChartRequest>(new PtsChart());
    const [selectedObject, setSelectedObject] = useState("");
    const [selectedValueObject, setSelectedValueObject] = useState("");
    const [selectedObjectX, setSelectedObjectX] = useState("");
    const [selectedObjectY1, setSelectedObjectY1] = useState("");
    const [selectedObjectY2, setSelectedObjectY2] = useState("");
    const [objectTable, setObjectTable] = useState<Array<PtsObjectsRequest>>([]);
    const [xTable, setXTable] = useState<Array<PtsXTypesRequest>>([]);
    const [y1Table, setY1Table] = useState<Array<PtsY1TypesRequest>>([]);
    const [y2Table, setY2Table] = useState<Array<PtsY2TypesRequest>>([]);

    useEffect(() => {
        objectStore.getObjectOptions('Well').then((res) => {
            setObjectIdsOption(res);
        })
    }, [objectStore]);
    
    useEffect(() => {
        ptsStore.getSelectorsOptions('').then((res: any) => {
            setObjectSelectorsOption(res);
        })
    }, [ptsStore]);

    const columnObject: GridColDef[] = [
        { field: 'actions', width: 120, headerName: '', type: 'actions', getActions: (params) => [
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                setObjectTable(oldValues => {
                    return oldValues.filter(objectTableValue => objectTableValue.objectId !== params.row.objectId)
                });
            }} />
        ] },
        { field: 'objectId', headerName: 'Object ID', editable: false, sortable: false },
        { field: 'well', flex: 1, headerName: 'Well', editable: false, sortable: false }
    ]

    const columnXType: GridColDef[] = [
        { field: 'actions', width: 120, headerName: '', type: 'actions', getActions: (params) => [
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                setXTable(oldValues => {
                    return oldValues.filter(objectTableValue => objectTableValue.type !== params.row.type)
                });
            }} />
        ] },
        { field: 'type', width: 120, headerName: 'Type', editable: false, sortable: false }
    ]

    const columnY1Type: GridColDef[] = [
        { field: 'actions', width: 120, headerName: '', type: 'actions', getActions: (params) => [
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                setY1Table(oldValues => {
                    return oldValues.filter(objectTableValue => objectTableValue.type !== params.row.type)
                });
            }} />
        ] },
        { field: 'type', width: 120, headerName: 'Type', editable: false, sortable: false }
    ]

    const columnY2Type: GridColDef[] = [
        { field: 'actions', width: 120, headerName: '', type: 'actions', getActions: (params) => [
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {
                setY2Table(oldValues => {
                    return oldValues.filter(objectTableValue => objectTableValue.type !== params.row.type)
                });
            }} />
        ] },
        { field: 'type', width: 120, headerName: 'Type', editable: false, sortable: false }
    ]

    function handleDateChange(value:any) {
        setselectedDate(value);
    }

    function handleObjectChange(event:any) {
        setSelectedObject(event.target.value);
        const objectWell = objectIdsOption.find(obj => {
            return obj.value === event.target.value;
        });
        const textObject = objectWell !== undefined ? objectWell.text : "";
        setSelectedValueObject(textObject);
    }

    function handleObjectSelectorXChange(event:any) {
        setSelectedObjectX(event.target.value);
    }

    function handleObjectSelectorY1Change(event:any) {
        setSelectedObjectY1(event.target.value);
    }

    function handleObjectSelectorY2Change(event:any) {
        setSelectedObjectY2(event.target.value);
    }

    const handleObjectClick = () => {
        const newObject = new PtsObjectsRequest();
        newObject.objectId = selectedObject;
        newObject.well = selectedValueObject;
        if(objectTable.length === 0){
            let dataExport: any = [];
            dataExport.push(newObject);
            setObjectTable(dataExport);
        }
        else{
            const objects = objectTable.find(obj => {
                return obj.objectId === newObject.objectId;
            });
            if(objects === undefined){
                let dataExport: any = [];
                dataExport.push(newObject);
                const arrayMerge = [...objectTable, ...dataExport];
                setObjectTable(arrayMerge);
            }
        }
    };

    const handleObjectXClick = () => {
        const newObject = new PtsXTypesRequest();
        newObject.type = selectedObjectX;

        if(xTable.length === 0){
            let dataExport: any = [];
            dataExport.push(newObject);
            setXTable(dataExport);
        }
        else{
            const objects = xTable.find(obj => {
                return obj.type === newObject.type;
            });
            if(objects === undefined){
                let dataExport: any = [];
                dataExport.push(newObject);
                const arrayMerge = [...xTable, ...dataExport];
                setXTable(arrayMerge);
            }
        }
    };

    const handleObjectY1Click = () => {
        const newObject = new PtsY1TypesRequest();
        newObject.type = selectedObjectY1;
        if(y1Table.length === 0){
            let dataExport: any = [];
            dataExport.push(newObject);
            setY1Table(dataExport);
        }
        else{
            const objects = y1Table.find(obj => {
                return obj.type === newObject.type;
            });
            if(objects === undefined){
                let dataExport: any = [];
                dataExport.push(newObject);
                const arrayMerge = [...y1Table, ...dataExport];
                setY1Table(arrayMerge);
            }
        }
    };

    const handleObjectY2Click = () => {
        const newObject = new PtsY2TypesRequest();
        newObject.type = selectedObjectY2;
        if(y2Table.length === 0){
            let dataExport: any = [];
            dataExport.push(newObject);
            setY2Table(dataExport);
        }
        else{
            const objects = y2Table.find(obj => {
                return obj.type === newObject.type;
            });
            if(objects === undefined){
                let dataExport: any = [];
                dataExport.push(newObject);
                const arrayMerge = [...y2Table, ...dataExport];
                setY2Table(arrayMerge);
            }
        }
    };

    const onClick = () => {
        ptsChartRequest.objectIds = [];
        ptsChartRequest.y1Selectors = [];
        ptsChartRequest.y2Selectors = [];

        objectTable.forEach((data) => {
            ptsChartRequest.objectIds.push(data.objectId);
        })
        y1Table.forEach((data) => {
            ptsChartRequest.y1Selectors.push(data.type);
        })
        y2Table.forEach((data) => {
            ptsChartRequest.y2Selectors.push(data.type);
        })

        chartPts(ptsChartRequest).then(data => {
            let dataXExport: any = [];
            let dataYExport: any = [];
            data.y1.forEach((yData, index) => {
                dataXExport.push(yData);
                const yAxisTitle = new PtsChartYAxisTitle();
                yAxisTitle.text = "Y1";

                if(index === 0){
                    const yAxis = new PtsChartYAxis();
                    yAxis.opposite = false;
                    yAxis.title = yAxisTitle;
                    dataYExport.push(yAxis);
                }
            })
            data.y2.forEach((yData, index) => {
                dataXExport.push(yData);
                const yAxisTitle = new PtsChartYAxisTitle();
                yAxisTitle.text = "Y2";

                if(index === 0){
                    const yAxis = new PtsChartYAxis();
                    yAxis.opposite = true;
                    yAxis.title = yAxisTitle;
                    dataYExport.push(yAxis);
                }
            })
            setSeries(dataXExport);
            setYAxis(dataYExport);
        });
    };

    return (
        <RkdCard title='PTS Report' subtitle=''>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 2,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '40%' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        onChange={handleDateChange}
                        value={selectedDate}
                        label="Date"
                        renderInput={(props) => (
                            <TextField label="Date" fullWidth {...props}/>
                        )}
                        />
                    </LocalizationProvider>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <TextField
                            select
                            label="Object"
                            value={selectedObject}
                            onChange={handleObjectChange}
                            fullWidth
                            required
                            name="well"
                        >
                            {objectIdsOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.text}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button variant='contained' color='primary' sx={{ backgroundColor: '#fab933ff'}} onClick={handleObjectClick}>+</Button>
                    </Box>
                    <DataGridPremium columns={columnObject} rows={objectTable} getRowId={(row) => row.objectId} autoHeight getRowHeight={() => 'auto'} initialState={
                        {
                            columns: {
                              columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                objectId: false,
                                well: true,
                              },
                            },
                        }
                    }/>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <TextField
                            select
                            label="X"
                            value={selectedObjectX}
                            onChange={handleObjectSelectorXChange}
                            fullWidth
                            required
                            name="x"
                        >
                            {objectSelectorsOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.text}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button variant='contained' color='primary' sx={{ backgroundColor: '#fab933ff'}} onClick={handleObjectXClick}>+</Button>
                    </Box>
                    <DataGridPremium columns={columnXType} rows={xTable} getRowId={(row) => row.type} autoHeight getRowHeight={() => 'auto'} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <TextField
                            select
                            label="Y1"
                            value={selectedObjectY1}
                            onChange={handleObjectSelectorY1Change}
                            fullWidth
                            required
                            name="well"
                        >
                            {objectSelectorsOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.text}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button variant='contained' color='primary' sx={{ backgroundColor: '#fab933ff'}} onClick={handleObjectY1Click}>+</Button>
                    </Box>
                    <DataGridPremium columns={columnY1Type} rows={y1Table} getRowId={(row) => row.type} autoHeight getRowHeight={() => 'auto'} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <TextField
                            select
                            label="Y2"
                            value={selectedObjectY2}
                            onChange={handleObjectSelectorY2Change}
                            fullWidth
                            required
                            name="well"
                        >
                            {objectSelectorsOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.text}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button variant='contained' color='primary' sx={{ backgroundColor: '#fab933ff'}} onClick={handleObjectY2Click}>+</Button>
                    </Box>
                    <DataGridPremium columns={columnY2Type} rows={y2Table} getRowId={(row) => row.type} autoHeight getRowHeight={() => 'auto'} />
                </Box>
                <Button variant='contained' color='primary' sx={{ width: '10%' }} onClick={onClick}>Generate</Button>
            </Box>
            <PtsChartReport series={series} yAxis={yAxis}/>
        </RkdCard>
    )
}

export default observer(PtsPageReport)