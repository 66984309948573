import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { SyntheticEvent, useState } from 'react';
import TFTFormSteam from './TFTFormSteam';
import TFTFormBrine from './TFTFormBrine';
import { observer } from 'mobx-react-lite';

function TFTProcessTab() {

  const [value, setValue] = useState("1");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
     <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tab steam">
            <Tab label="Steam" value="1" />
            <Tab label="Brine" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TFTFormSteam />
        </TabPanel>
        <TabPanel value="2">
          <TFTFormBrine />
        </TabPanel>
      </TabContext> 
    </Box>
  );
}

export default observer(TFTProcessTab);