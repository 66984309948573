import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { Edit } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../../app/stores/store";
import { TOrcGroupDetailResponse } from "../../../../app/models/orcGroup";

function OrcGroupDataGrid() {
  const { orcGroupGridStore, commonStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    orcGroupGridStore.getOrcGroupGrid();
  }, [orcGroupGridStore]);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "orcGroupName",
      headerName: "ORC Group Name",
      flex: 1,
    },
    {
      field: "hasRelation",
      headerName: "Has Relation",
      flex: 1,
      renderCell: (params) => (params.row.hasRelation ? "Yes" : "No"),
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/master/orc-group/${params.row.id}`);
            orcGroupGridStore.setSelectedOrc(params.row as TOrcGroupDetailResponse);
          }}
        />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      checkboxSelection
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      disableColumnMenu
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={orcGroupGridStore.dataGridResult.data}
    />
  );
}

export default observer(OrcGroupDataGrid);
