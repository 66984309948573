import { MenuItem, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useStore } from "../../../../app/stores/store";
import { IGeoLithologyTableRequest, IGeologicalOption, TDynamicGeologicalGridResponse } from "../../../../app/models/geological";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

export type TGeneralGeological = {
  setDynamicConvertedData: Dispatch<SetStateAction<any[]>>;
  dynamicConvertedData: any[];
  setDeleteSequence: Dispatch<SetStateAction<number[]>>;
  deleteSequence: number[];
  selectedGeologicalType: string;
  data: TDynamicGeologicalGridResponse[];
  onDelete: (id: string, sequence: number) => void;
};

function Lithology({
  selectedGeologicalType,
  deleteSequence,
  setDeleteSequence,
  onDelete,
  data,
  setDynamicConvertedData,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const [lithologyOpt, setLithologyOpt] = useState<IGeologicalOption[]>([]);
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType ?? "");

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      lithology: row.lithology,
      from: row.from,
      to: row.to,
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoLithologyTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        from: row.from,
        to: row.to,
        lithology: row.lithology,
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const renderEditLithology: GridColDef["renderCell"] = (params) => {
    return (
      <TextField
        select
        value={params.value}
        onChange={(e) => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: e.target.value,
          });
        }}
        fullWidth
        name="lithologyOpt"
        sx={{ width: "100%" }}
      >
        {lithologyOpt.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const column: GridColDef[] = [
    {
      field: "from",
      editable: true,
      headerName: "From (mMD)",
      headerAlign: "center",
      sortable: false,
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      sortable: false,
      editable: true,
      field: "to",
      headerName: "To (mMD)",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "lithology",
      sortable: false,
      headerName: "Lithology",
      width: 120,
      type: "string",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
      renderEditCell: renderEditLithology,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];

  useEffect(() => {
    geologicalStore.getLithologyOptions().then((data) => {
      setLithologyOpt(data);
    });
  }, []);

  return (
    <StyledDataGridPremium
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      autoHeight
      onProcessRowUpdateError={(e) => console.error(e)}
      processRowUpdate={handleRowEdit}
      pagination
      showCellVerticalBorder
      showColumnVerticalBorder
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
    />
  );
}

export default observer(Lithology);
