import { Box, Button, Grid } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import { useNavigate } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";
import { useEffect } from "react";
import BiweeklyDataGrid from "./BiweeklyDataGrid";

function BiweeklyDataPageIndex() {
  const { ptsStore, dialogStore, snackbarStore, commonStore, biweeklyStore, biweeklyGridStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await biweeklyStore.deleteBiweekly(commonStore.selectedDataOnGrid).then(() => {
          biweeklyGridStore.getBiweeklyGrid();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  useEffect(() => {
    commonStore.setTitlePage("Biweekly Database");
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Biweekly Data List" subtitle="Add Edit & Delete Biweekly Data from this Page">
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
            <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("add")}>
              Add
            </Button>
            <Button
              disabled={commonStore.selectedDataOnGrid.ids.length === 0}
              startIcon={<Delete />}
              variant="contained"
              color="error"
              onClick={() => onDelete()}
            >
              Bulk Delete
            </Button>
          </Box>
          <BiweeklyDataGrid />
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BiweeklyDataPageIndex);
