import { Box } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import ObjectStatusModalDetail from "./ObjectStatusModalDetail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RkdModalForm from "../../../app/component/modal/RkdModalForm";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

function ObjectStatusGrid() {
  const navigate = useNavigate();

  const { objectStatusGridStore, commonStore } = useStore();

  const [selectedId, setSelectedId] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "objectName",
      headerName: "Well",
      width: 170,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 170,
      type: "string",
      filterable: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1,
      renderCell: (params) => new Date(params.row.modifiedDate).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            color="info"
            label="Edit"
            onClick={() => {
              navigate(`edit/${params.row.objectId}`);
            }}
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="info"
            onClick={() => {
              setSelectedId(params.row.objectId);
              setIsModalOpen(true);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    objectStatusGridStore.getObjectStatusList();
  }, [objectStatusGridStore]);

  return (
    <Box sx={{ width: "100%" }}>
      <StyledDataGridPremium
        autoHeight
        showCellVerticalBorder
        showColumnVerticalBorder
        pageSizeOptions={[10, 25, 50, 100]}
        checkboxSelection
        pagination
        disableColumnMenu
        onRowSelectionModelChange={(newSelectionModel: any) => {
          commonStore.setSelectionDataOnGrid(newSelectionModel);
        }}
        columns={gridColumns}
        rows={objectStatusGridStore.dataGridResult.data}
      />
      <RkdModalForm
        toggleModal={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        children={<ObjectStatusModalDetail id={selectedId} />}
        width="50%"
      />
    </Box>
  );
}

export default observer(ObjectStatusGrid);
