import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IBrinePump } from "../models/brinePump";

export default class BrinePumpStore {
  loadingGrid = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  createBrinePump = async (data: IBrinePump) => {
    try {
      await agent.BrinePump.create(data);
    } catch (error) {
        throw error;
    }
  }

  getBrinePumpDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.BrinePump.detail(id);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingForm = false);
    }
  }

  getBrinePumpOptions = async (search: string) => {
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.BrinePump.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => this.loadingForm = false);
    }
  }

  editBrinePump = async (id: string, BrinePump: IBrinePump) => {
    try {
        await agent.BrinePump.edit(id, BrinePump);
    } catch (error) {
        throw error;
    }
  }

  deleteBrinePump = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.BrinePump.delete(id);
    } catch (error) {
      throw error;
    }
    finally {
      runInAction(() => this.loadingForm = false);
    }
  }
}