import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";

export default class FptGridStore extends DataGridStore{
    constructor() {
        super();
  
        makeObservable(this, {
            getFPTList: action,
        });
    }

    getFPTList = async () => {
      this.loadingGrid = true;
      try {
        const urlParams = this.createDataGridParam();
        const result = await agent.FPT.listGrid(urlParams);
        result.data.forEach((item) => {
          item.surveyDate = new Date(item.surveyDate + "Z")
       }) 
        this.setDataGridResult(result);
        
      } catch (error) {
        throw error;
      } finally {
          runInAction(() => this.loadingGrid = false);
      }
    }
  
    updateGridCallback = action(() => {
      this.getFPTList();
    })
}