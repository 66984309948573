import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TFTStepper from './TFTStepper';
import TFTProcessSteamStep1 from './TFTProcessSteamStep1';
import TFTProcessSteamStep2 from './TFTProcessSteamStep2';
import TFTProcessSteamStep3 from './TFTProcessSteamStep3';
import TFTProcessSteamStep4 from './TFTProcessSteamStep4';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';


const steps = ['SF6 Analysis', 'Sample Analysis', 'Drycall PTF Corrections', 'TFT Steam Result'];

function TFTFormSteam() {

  const { tftSteamStore } = useStore();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep === steps.length - 1 ) {
      tftSteamStore.setProcessTab(2);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <>
      <TFTStepper activeStep={activeStep} steps={steps} />
      <>
        {activeStep === 0 ? (
          <TFTProcessSteamStep1 />
        ) : activeStep === 1 ? (
          <TFTProcessSteamStep2 />
        ) : activeStep === 2 ? (
          <TFTProcessSteamStep3 />
        ) : (
          <TFTProcessSteamStep4 />
        )}
        
        <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'end',  width: '40%', my: 2 }}>
          <Button
            color="inherit"
            variant='contained'
            startIcon={<KeyboardArrowLeftIcon />}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {
            activeStep !== steps.length - 1 &&
            <Button 
              onClick={handleNext}
              variant='contained'
              endIcon={<KeyboardArrowRightIcon />}
              disabled={activeStep === steps.length - 1 }
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          }
        </Box>
      </>
    </>
  );
}

export default observer(TFTFormSteam)
