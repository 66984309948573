import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { TGeneralGeological } from "./Lithology";
import { IGeoFeedZoneTableRequest } from "../../../../app/models/geological";
import { useStore } from "../../../../app/stores/store";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function FeedZone({
  selectedGeologicalType,
  onDelete,
  deleteSequence,
  data,
  setDeleteSequence,
  setDynamicConvertedData,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { id } = useParams();
  const { geologicalStore, geologicalAddGridStore } = useStore();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      feedzoneType: row.feedzoneType,
      depth: row.depth,
      feedZone: row.feedZone,
      feedZoneDepth: row.feedZoneDepth,
      mvd: row.mvd,
      masl: row.masl,
      eastern: row.eastern,
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoFeedZoneTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        feedZone: row.feedZone,
        feedZoneDepth: row.feedZoneDepth,
        mvd: row.mvd,
        masl: row.masl,
        eastern: row.eastern,
        northern: row.northern,
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    {
      field: "feedZoneType",
      headerName: "Feed Zone",
      type: "string",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "feedZoneDepth",
      headerName: "Feed Depth (mMD)",
      type: "number",
      headerAlign: "center",
      editable: true,
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mvd",
      headerName: "mVD",
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "masl",
      headerName: "mASL",
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "eastern",
      headerName: "Eastern",
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      editable: true,
      field: "northern",
      headerName: "Northern",
      width: 120,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
      autoHeight
      processRowUpdate={handleRowEdit}
      pagination
      showCellVerticalBorder
      showColumnVerticalBorder
    />
  );
}

export default observer(FeedZone);
