import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IDirectional } from '../../../app/models/wellGeometry';
import { useStore } from '../../../app/stores/store';
import { DataGrid, GridCellEditCommitParams, GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';

interface IProps {
  data: Array<IDirectional>,
}

const WellGeometryDirectionalGrid = (props: IProps) => {
  const { data } = props;
  const { wellGeometryStore } = useStore();
  const { setDirectionalData } = wellGeometryStore;
  const [rowsPerPage, setRowsPerPage] = useState(25);
  
  const handleEditGrid = (params: GridCellEditCommitParams) => {
    const edited = data.map((item: IDirectional) => {
      if(item.id === params.id)
      {
        item[params.field as keyof IDirectional] = params.value;
      }
      return item
    })
    setDirectionalData(edited)
  }


  const gridColumns: GridColumns<IDirectional> = [     
    { field: 'md', headerName: 'MD (meter)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'inc', headerName: 'Inc (meter)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'azi', headerName: 'Azi (meter)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'tvd', headerName: 'TVD (m)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'elevation', headerName: 'Elevation (m)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'dls', headerName: 'DLS (m)', width: 110, type: "number", editable: true, sortable: false },
    { field: 'northing', headerName: 'Northing (meter)', width: 150, type: "number", editable: true, sortable: false },
    { field: 'easting', headerName: 'Easting (meter)', width: 150, type: "number", editable: true, sortable: false },
    { field: 'throw', headerName: 'Throw (meter)', width: 150, type: "number", editable: true, sortable: false },
  ]

  return (
    <Box sx={{ height: 550 }}>
      <DataGrid 
        rows={data} 
        columns={gridColumns}
        onCellEditCommit={handleEditGrid}
        getRowHeight={() => 'auto'}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[25, 100, 150]}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      />
    </Box>
  );
};

export default observer(WellGeometryDirectionalGrid);