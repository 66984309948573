import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import RkdCard from "../../../app/component/card/RkdCard";
import { Box, Button } from "@mui/material";
import { format } from "date-fns";

type TOrcPassingData = {
  orcName: string;
  year: number;
  month: number;
  week: number;
};

function CleanOutHistoryDetailDataGrid() {
  const { snackbarStore, dialogStore, cleanOutHistoryStore, commonStore } = useStore();
  const navigate = useNavigate();

  const { startDate, endDate } = useParams();
  const { state } = useLocation();
  const orcData = state as TOrcPassingData;

  const onGetCleanOutDetail = () => {
    let paylaod = {
      startDate: format(new Date(startDate ?? ""), "yyyy-MM-dd"),
      endDate: format(new Date(endDate ?? ""), "yyyy-MM-dd"),
    };
    cleanOutHistoryStore.getCleanOutDetailGrid(paylaod);
  };

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await cleanOutHistoryStore.deleteCleanOutDetailGrid(commonStore.selectedDataOnGrid).then(() => {
          onGetCleanOutDetail();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  useEffect(() => {
    onGetCleanOutDetail();
    cleanOutHistoryStore.clearCleanOutDetail();
  }, []);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "cleaningDate",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => format(new Date(params.row.cleaningDate), "dd MMMM yyyy"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: () => "",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            const { year, month, week } = orcData;
            let data = {
              year: year ?? 0,
              month: month ?? 0,
              weekNumber: week ?? 0,
              startDate: startDate ?? new Date(),
              endDate: endDate ?? new Date(),
            };
            navigate(`/geochemistry-clean/edit/${params.row.id}`);

            cleanOutHistoryStore.setSelectedCleanOutDetail(data);
          }}
        />,
      ],
    },
  ];

  return (
    <RkdCard title="Clean Out History Detail" subtitle="Add Edit & Delete Clean Out History from this Page">
      <Box sx={{ textAlign: "center", fontWeight: "bold", textDecoration: "underline", fontSize: "16px" }}>
        {`${orcData.orcName} (${format(new Date(startDate ?? new Date()), "dd MMMM yyyy")} - ${format(
          new Date(endDate ?? new Date()),
          "dd MMMM yyyy"
        )})`}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          onClick={() => {
            const { year, month, week } = orcData;
            let data = {
              year: year ?? 0,
              month: month ?? 0,
              weekNumber: week ?? 0,
              startDate: new Date(startDate ?? 0) ?? new Date(),
              endDate: new Date(endDate ?? 0) ?? new Date(),
            };

            cleanOutHistoryStore.setSelectedCleanOutDetail(data);
            navigate("/geochemistry-clean/add");
          }}
        >
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>

      <StyledDataGridPremium
        sx={{
          ".datagrid-delta-false": { background: "#FEF3CC" },
          ".datagrid-delta-true": { background: "#43C55F" },
        }}
        getCellClassName={(params: any) => {
          const commonRow = ["actions", "__check__"];

          if (typeof params.value === "string" || commonRow.includes(params.field)) return "";
          if (!params.value) return "datagrid-delta-false";
          if (params.value) return "datagrid-delta-true";

          return "";
        }}
        showCellVerticalBorder
        showColumnVerticalBorder
        checkboxSelection
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          commonStore.setSelectionDataOnGrid(newSelectionModel);
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        columns={gridColumns}
        rows={cleanOutHistoryStore.cleanOutDetailGrid}
        autoHeight
      />
    </RkdCard>
  );
}

export default observer(CleanOutHistoryDetailDataGrid);
