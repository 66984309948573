import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import WellGeometryDirectionalGrid from "./WellGeometryDirectionalGrid";
import WellGeometryDirectionalChart from "./WellGeometryDirectionalChart";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/system";
import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material";

import WellGeometryDropdownExcel from "./WellGeometryDropdownExcel";
import { useNavigate, useParams } from "react-router-dom";
import { IObjectOptions } from "../../../app/models/object";
import { LoadingButton } from "@mui/lab";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const columnsDirectional = ["md", "inc", "azi", "tvd", "elevation", "dls", "northing", "easting", "throw"];

const WellGeometryDirectionalForm = () => {
  const { commonStore, wellGeometryStore, objectStore } = useStore();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedObject, setSelectedObject] = useState<TSelectedData | undefined>(undefined);
  const [id] = useState(params.id);

  const [objectType, setObjectType] = useState<Array<IObjectOptions>>([]);
  const [objectId, setObjectId] = useState("");
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOnChangeObjectId = (val: TSelectedData) => {
    setObjectId(val.value);
  };

  useEffect(() => {
    commonStore.setTitlePage("Well Geometry");
  });

  useEffect(() => {
    objectStore.getObjectOptions("").then((res) => {
      setObjectType(res);
    });
  }, [objectStore]);

  useEffect(() => {
    let objectTemp: IObjectOptions[] = [];

    const fetchObject = async () => {
      await objectStore.getObjectOptions("").then((res) => {
        setObjectType(res);
        objectTemp = res;
      });
    };

    fetchObject();

    if (id) {
      wellGeometryStore.getWellGeometryDirectionalDetail(id).then((res) => {
        let temp = objectTemp.find((item) => item.value === res.objectId);

        setSelectedObject({ label: temp?.text ?? "", value: temp?.value ?? "" });
        wellGeometryStore.setDirectionalData(res.data);
        setObjectId(res.objectId);
      });
    } else {
      wellGeometryStore.setDirectionalData([]);
      setObjectId("");
    }
  }, [id, wellGeometryStore]);

  const handleSubmit = () => {
    setLoading(true);
    wellGeometryStore
      .createDirectionalData(objectId, wellGeometryStore.directionalData)
      .then(() => {
        navigate("/master/geometry");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (wellGeometryStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title={id ? "Edit Well Geometry Directional" : `Add Well Geometry Directional`}>
      <Grid container>
        <Grid item xs={12}>
          <WellGeometryDropdownExcel columns={[columnsDirectional]} setData={wellGeometryStore.setDirectionalDataExcel} type="directional" />
        </Grid>
        <Grid item xs={12} my={3}>
          <Autocomplete
            disablePortal
            fullWidth
            sx={{ marginBotom: 0 }}
            onChange={(e: any, val: any) => {
              setSelectedObject(val);
              handleOnChangeObjectId(val);
            }}
            disableClearable
            value={selectedObject}
            getOptionLabel={(option) => option.label}
            options={objectType.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => <TextField value={selectedObject?.label} name="objectId" {...params} label="Object Name *" />}
          />
        </Grid>
        <Grid item xs={6}>
          <WellGeometryDirectionalChart data={wellGeometryStore.directionalData} x_label="throw" y_label="elevation" />
        </Grid>
        <Grid item xs={6}>
          <WellGeometryDirectionalChart data={wellGeometryStore.directionalData} x_label="easting" y_label="northing" />
        </Grid>
        <Grid item xs={12}>
          <WellGeometryDirectionalGrid data={wellGeometryStore.directionalData} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="info"
              sx={{ mr: "8px" }}
              onClick={() => {
                navigate("/master/geometry");
              }}
            >
              Cancel
            </Button>
            <LoadingButton variant="contained" color="primary" onClick={handleSubmit} loading={loading} disabled={loading || objectId === ""}>
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </RkdCard>
  );
};

export default observer(WellGeometryDirectionalForm);
