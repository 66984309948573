import { Button, Box } from "@mui/material";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { IGeologicalMebGrid } from "../../../app/models/geological";
import { useStore } from "../../../app/stores/store";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import GeologicalMebModalDelete from "./GeologicalMebModalDelete";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";

function GeologicalMebIndex() {
  const { geologicalMebGridStore, modalStore, geologicalStore } = useStore();
  const navigate = useNavigate();

  const gridColumns: GridColumns<IGeologicalMebGrid> = [
    { field: "wellName", headerName: "Well", width: 200, type: "string", filterable: true, sortable: true },
    { field: "groundLevel", headerName: "Ground Level (masl)", width: 200, type: "string", filterable: true, sortable: true },
    { field: "rightFloorHeight", headerName: "Rig Floor Height (m)", width: 170, type: "string", filterable: true, sortable: true, flex: 1 },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => {
        return [
          <GridActionsCellItem
          color="info"
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              navigate(`meb/edit/${params.row.id}`);
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              modalStore.open(
                <GeologicalMebModalDelete
                  handleDelete={() => {
                    if (params.row.id) {
                      geologicalStore.deleteMeb({ ids: [params.row.id] }).then(geologicalMebGridStore.getGeologicalMebList);
                      modalStore.close();
                    }
                  }}
                />
              );
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    geologicalMebGridStore.getGeologicalMebList();
  }, [geologicalMebGridStore]);

  return (
    <RkdCard title="Geological MeB">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("meb")}>
          Add
        </Button>
      </Box>

      <Box sx={{ width: "60%" }}>
        <RkdDataGrid gridStore={geologicalMebGridStore} columns={gridColumns} />
      </Box>
    </RkdCard>
  );
}

export default observer(GeologicalMebIndex);
