import { useEffect, useState } from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Edit, Assessment } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { useNavigate } from "react-router-dom";
import { IGeological } from "../../../app/models/geological";
import { Box } from "@mui/material";

function GeophysicsGravityDataGrid() {
  const { geophysicsGravityGridStore } = useStore();
  const { getGeophysicsGravityGrid } = useStore().geophysicsGravityGridStore;
  const navigate = useNavigate();

  useEffect(() => {
    getGeophysicsGravityGrid();
  }, [getGeophysicsGravityGrid]);

  const gridColumns: GridColumns<IGeological> = [
    { field: "locationName", headerName: "Location", flex: 1, type: "string", filterable: true, sortable: true },
    { field: "station", headerName: "Station", flex: 1, type: "string", filterable: true, sortable: true },
    {
      field: "year",
      headerName: "Year",
      filterable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/geophysic-gravity-survey/edit/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Assessment />}
          color="info"
          label="Report"
          onClick={() => {
            navigate(`/geophysic-gravity-survey/report/${params.row.id}`);
          }}
        />,
      ],
    },
  ];

  return (
    <Box>
      <RkdDataGrid columns={gridColumns} gridStore={geophysicsGravityGridStore} />
    </Box>
  );
}

export default observer(GeophysicsGravityDataGrid);
