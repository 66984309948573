import { Box, MenuItem, TextField, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RkdCard from "../../../app/component/card/RkdCard";
import { GeoMebTableRequest, IGeoChart, IGeoChartData, IGeologicalMeb, IGeologicalOption } from "../../../app/models/geological";
import { useStore } from "../../../app/stores/store";
import { DataGridPremium, gridClasses, GridColDef } from "@mui/x-data-grid-premium";
import { GridActionsCellItem } from "@mui/x-data-grid";
// import GeologicalModalUploadExcel from "../GeologicalModalUploadExcel";
import * as XLSX from "xlsx";
import GeologicalMebChart from "./GeologicalMebChart";
import GeologicalMebModalDelete from "./GeologicalMebModalDelete";
import DeleteIcon from "@mui/icons-material/Delete";

function GeologicalMebForm() {
  const { geologicalStore, modalStore, snackbarStore, objectStore } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const hiddenFileInput = useRef<any>(null);

  const [wellOption, setWellOption] = useState<IGeologicalOption[]>([]);
  const [deleteSequence, setDeleteSequence] = useState<number[]>([]);
  const [meb, setMeb] = useState<IGeologicalMeb>({
    wellId: "Well",
    groundLevel: 0,
    rightFloorHeight: 0,
    table: [],
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [mebChart, setMebChart] = useState<IGeoChart>({
    name: "",
    data: [],
  });

  const column: GridColDef[] = [
    {
      field: "elevation",
      headerName: "Elev (m asl)",
      width: 150,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "depth",
      headerName: "Depth (Meter RKB)",
      width: 200,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "tvd",
      headerName: "TVD (Meter RKB)",
      width: 200,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "rock",
      headerName: "Rock (gr)",
      width: 150,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "meB",
      headerName: "MeB (ml)",
      width: 150,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "meBIndex",
      headerName: "MeB Index (ml/ gr)",
      width: 200,
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "disabled-cell",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            color="error"
            label="Delete"
            onClick={() => {
              modalStore.open(
                <GeologicalMebModalDelete
                  handleDelete={() => {
                    if (params.row.id) {
                      //delete data on meb.table
                      setMeb({ ...meb, table: meb.table.filter((current) => current.id !== params.row.id) });
                      setDeleteSequence([...deleteSequence, params.row.id]);
                    } else {
                      setMeb({ ...meb, table: meb.table.filter((current) => current.sequence !== params.row.sequence) });
                    }
                    modalStore.close();
                  }}
                />
              );
            }}
          />,
        ];
      },
    },
  ];

  const handleSubmit = () => {
    geologicalStore.deleteMebRowTable({ ids: deleteSequence });
    geologicalStore.createMeb(meb).then(() => {
      navigate("/geological-meb");
    });
  };

  const handleLoadChart = () => {
    let chart: IGeoChartData[] = [];
    meb.table.forEach((current) => {
      chart.push({
        x: current.meBIndex,
        y: current.elevation,
      });
    });
    if (mebChart.name !== "") {
      setMebChart({ name: mebChart.name, data: chart });
    } else {
      setMebChart({ name: "MeB Index", data: chart });
    }
  };

  useEffect(() => {
    if (id) {
      objectStore.getObjectOptions("").then((data: any) => {
        setWellOption(data);
      });
      let well = "";
      let groundLevel = 0;
      let rightFloorHeight = 0;
      geologicalStore.getGeoMebDetail(id).then((data: any) => {
        well = data.wellId;
        groundLevel = data.groundLevel;
        rightFloorHeight = data.rightFloorHeight;
        geologicalStore.getGeoMebDetailTable(data.wellId).then((data: any) => {
          setMeb({ wellId: well, groundLevel: groundLevel, rightFloorHeight: rightFloorHeight, table: data.data });
        });
        setMebChart(data.chart);
      });
    } else {
      geologicalStore.getWellOption(3).then((data: IGeologicalOption[]) => {
        setWellOption(data);
      });
    }
  }, [geologicalStore, id, objectStore]);

  useEffect(() => {
    meb.table.forEach((current) => {
      current.elevation = meb.groundLevel + meb.rightFloorHeight - current.tvd;
    });
  }, [meb.groundLevel, meb.rightFloorHeight, meb.table]);

  console.log(meb.table);

  return (
    <RkdCard title={id ? `Edit MeB` : `Add MeB`}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, "& .disabled-cell": { backgroundColor: "#e2e2e2" } }}>
        <TextField
          select
          label="Well"
          value={meb.wellId}
          onChange={(e) => setMeb({ ...meb, wellId: e.target.value })}
          fullWidth
          disabled={id ? true : false}
          name="well"
          sx={{ width: "20%" }}
        >
          <MenuItem value={"Well"} disabled>
            Select Well
          </MenuItem>
          {wellOption.map((item, idx) => (
            <MenuItem key={idx} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </TextField>
        {/* <Button variant="contained" color="primary" onClick={() => geologicalStore.downloadTemplate(3, meb.wellId)}>
          Download
        </Button> */}
        <Button color="primary" onClick={handleClick}>
          Upload
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={hiddenFileInput}
            hidden
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // if (e.target.files && e.target.files.length > 0) {
              //   var filesGeo = e.target.files[0];
              //   modalStore.open(
                //   <GeologicalModalUploadExcel
                //     name={filesGeo.name}
                //     handleSubmit={() => {
                //       modalStore.close();
                //       geologicalStore.templateValidator({ type: 3, wellId: meb.wellId, file: filesGeo }).then(() => {
                //         var f = filesGeo;
                //         var reader = new FileReader();

                //         reader.onload = function (evt) {
                //           if (evt.target) {
                //             let dataExport: any = [];
                //             var data = evt.target.result;
                //             let readedData = XLSX.read(data, { type: "binary", cellDates: true });
                //             const sheetNames = readedData.SheetNames;
                //             let isError;
                //             console.log(readedData);
                //             if (sheetNames && sheetNames.length > 0) {
                //               sheetNames.forEach((sheetName, idxSheet) => {
                //                 if (sheetName === "Data") {
                //                   const ws = readedData.Sheets[sheetName];
                //                   const dataParse: Array<any> = XLSX.utils.sheet_to_json(ws, { header: 1 });
                //                   console.log(dataParse[0]);
                //                   let index = meb.table.length + 1;
                //                   for (var i = 3; i < dataParse.length; i++) {
                //                     if (dataParse[i][0] !== "") {
                //                       const newMeb = new GeoMebTableRequest();

                //                       newMeb.sequence = index;
                //                       newMeb.elevation = meb.groundLevel + meb.rightFloorHeight - dataParse[i][1];
                //                       newMeb.depth = dataParse[i][0];
                //                       newMeb.tvd = dataParse[i][1];
                //                       newMeb.rock = dataParse[i][2];
                //                       newMeb.meB = dataParse[i][3];
                //                       newMeb.meBIndex = dataParse[i][4];
                //                       dataExport.push(newMeb);

                //                       index++;
                //                     } else {
                //                       break;
                //                     }
                //                   }
                //                 }
                //               });
                //             } else {
                //               isError = true;
                //             }

                //             if (!isError) {
                //               const data = meb.table.concat(dataExport);
                //               setMeb({ ...meb, table: data });
                //             } else snackbarStore.show("error", "File not valid");
                //           }
                //         };
                //         reader.onerror = function (evt) {
                //           snackbarStore.show("error", "File not valid");
                //         };
                //         reader.readAsBinaryString(f);
                //       });
                //     }}
                //   />
                // );
              // } else {
              //   console.log("tidak ada file");
              // }

              e.target.value = "";
            }}
          />
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: 2, mt: 2, flexDirection: "row", width: "40%" }}>
        <TextField
          label="Ground Level (masl)"
          name="groundLevel"
          fullWidth
          type="number"
          variant="outlined"
          onChange={(e) => setMeb({ ...meb, groundLevel: parseInt(e.target.value) })}
          value={meb.groundLevel}
        />
        <TextField
          label="Rig Floor Height (m)"
          name="rigthFloorHeight"
          fullWidth
          type="number"
          variant="outlined"
          onChange={(e) => setMeb({ ...meb, rightFloorHeight: parseInt(e.target.value) })}
          value={meb.rightFloorHeight}
        />
      </Box>
      <DataGridPremium
        columns={column}
        rows={meb.table}
        getRowId={(row) => row.sequence}
        autoHeight
        onProcessRowUpdateError={(e) => console.error(e)}
        // processRowUpdate={handleRowEdit}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 2,
            borderColor: "#000000",
          },
          width: "80%",
          borderColor: "#000000",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#D4E7C5",
            fontWeight: "bold",
            border: 1,
            borderRight: 0,
            borderTop: 1,
            borderBottom: 1,
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderRight: 0,
            borderTop: 1,
            borderBottom: 0,
            // add more css for customization
          },
        }}
      />
      <Button variant="contained" color="info" onClick={() => handleLoadChart()} disabled={geologicalStore.loadingForm}>
        Load Chart
      </Button>
      <Box sx={{ width: "40%" }}>
        <GeologicalMebChart series={mebChart} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
        <Button variant="contained" color="primary" onClick={() => handleSubmit()} disabled={geologicalStore.loadingForm}>
          Submit
        </Button>
        <Button variant="contained" color="error" onClick={() => navigate("/geological-meb")} disabled={geologicalStore.loadingForm}>
          Cancel
        </Button>
      </Box>
    </RkdCard>
  );
}

export default observer(GeologicalMebForm);
