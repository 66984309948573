import { Box } from '@mui/system'
import { Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import RkdCard from '../../app/component/card/RkdCard'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { IPtsRequest, Pts, PtsTableRequest } from '../../app/models/pts';
import { useNavigate, useParams } from 'react-router-dom';
import { IObjectOptions } from '../../app/models/object';
import { useStore } from '../../app/stores/store';
import RkdBackdrop from '../../app/component/helper/RkdBackdrop';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import RkdForm from '../../app/component/form/RkdForm';
import RkdSelect from '../../app/component/form/RkdSelect';
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function PtsPageView() {
    const { objectStore, ptsStore } = useStore();
    const { createPts } = ptsStore;
    // const { pts } = ptsStore;
    const [ ptsTable, setPtsTable] = useState<Array<PtsTableRequest>>([]);
    const [objectIdsOption, setObjectIdsOption] = useState<Array<IObjectOptions>>([]);
    const navigate = useNavigate();
    const [pts, setPts] = useState<IPtsRequest>(new Pts());
    const [selected, setSelected] = useState("");
    const [datePts, setDatePts] = useState(new Date());
    const { objectId } = useParams<{ objectId: string }>();
    const { date } = useParams<{ date: string }>();

    useEffect(() => {
        if (objectId && date){
            ptsStore.getPts(objectId, date).then((pts) => {
                setPts(pts);
                setDatePts(new Date(pts.ptsDate));
                setSelected(pts.objectId);
                setPtsTable(pts.table);
            })
        }
    }, [date, objectId, ptsStore]);

    useEffect(() => {
        objectStore.getObjectOptions('Well').then((res) => {
            setObjectIdsOption(res);
        })
    }, [objectStore]);

    const columnPts: GridColDef[] = [
        { field: 'time', headerName: 'Time', width: 140, type: "date", align: "center", headerAlign: "center",
            valueFormatter: (params: GridValueFormatterParams) =>{ return format(new Date(params.value), "HH:mm:ss"); }
        },
        { field: 'depth', headerName: 'Depth (m)', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
        { field: 'rate', headerName: 'Rate (m/min)', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
        { field: 'press', headerName: 'Press (bara)', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
        { field: 'temp', headerName: 'Temp (C)', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
        { field: 'spin', headerName: 'Spin (rev/s)', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
        { field: 'ttemp', headerName: 'TTemp', width: 120, type: "number", align: "center", headerAlign: "center", sortable: false },
    ]

    const processRowUpdate = useCallback(
        async(newRow:any) => {
            const updatedRow = { ...newRow, isNew: false };
            setPtsTable(ptsTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            pts.table.map(obj => {
                if(obj.sequence === newRow.sequence){
                    return {...obj, time: updatedRow.time, depth: updatedRow.depth, rate: updatedRow.rate, press: updatedRow.press, temp: updatedRow.temp, spin: updatedRow.spin, tTemp: updatedRow.tTemp};
                }
                return obj;
            });
            return updatedRow;
        }, [ptsTable, pts]
    );

    function handleChangeDate(event:any) {
        setDatePts(event);
        pts.ptsDate = event;
    }

    function handleChange(event:any) {
      setSelected(event.target.value);
      pts.objectId = event.target.value;
    }

    const onSubmit = async () => {
        pts.table = ptsTable;
            pts.table.forEach(row => {
                row.time = new Date(row.time + "Z");
            });
            pts.ptsDate = new Date(datePts + "Z");
            await createPts(pts);
    }

    const validationSchema = Yup.object({
        objectId: Yup.string().required(),
        ptsDate: Yup.date().required(),
    });

  if (objectStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title='PTS' subtitle='Create'>
        <RkdForm initialValues={pts} submitAction={ onSubmit }  validationSchema={validationSchema} actionAfterSubmit={() => navigate('/pts')} >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2,}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '70%', alignItems: 'center' }}>
                    <RkdSelect name="objectId" label="Well" list={objectIdsOption} onChange={handleChange} value={selected} readOnly={true} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="Date" value={datePts} onChange={handleChangeDate} readOnly={true} renderInput={(props:any) => (
                            <TextField label="Survey Date" {...props} />
                        )} />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ height: 550 }}>
                    <DataGridPremium 
                        disableColumnMenu
                        columns={columnPts} 
                        rows={ptsTable} 
                        getRowId={(row) => row.sequence}
                        onProcessRowUpdateError={(e) => console.error(e)} 
                        processRowUpdate={processRowUpdate} 
                        initialState={{
                        sorting: {
                            sortModel: [{ field: 'sequence', sort: 'asc' }],
                        },
                        }}  
                    /> 
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
                    <Button variant='contained' color="error" onClick={() => navigate('/pts')}>Cancel</Button>
                </Box>
            </Box>
        </RkdForm>
    </RkdCard>
  )
}

export default observer(PtsPageView)