import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IQueryChart, IQueryGetSelector, IQueryRequest, TChartSelectorObject } from "../models/query";

export default class QueryStore {
  selectors: IQueryGetSelector = {
    objectSelectors: [],
    fieldSelectors: [],
  };

  objectList: TChartSelectorObject[] = [];
  fieldList: TChartSelectorObject[] = [];
  loadingGetSelector = false;
  loadingChart = false;

  queryChart: IQueryChart[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getFieldList = async () => {
    this.loadingGetSelector = true;
    try {
      let result = await agent.Query.fieldList();

      runInAction(() => (this.fieldList = result));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetSelector = false));
    }
  };

  getObjectList = async () => {
    this.loadingGetSelector = true;
    try {
      let result = await agent.Query.objectList();

      runInAction(() => (this.objectList = result));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetSelector = false));
    }
  };

  getSelector = async () => {
    this.loadingGetSelector = true;
    try {
      const data = await agent.Query.list();
      this.setSelector(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingGetSelector = false));
    }
  };

  setSelector = (data: IQueryGetSelector) => {
    this.selectors = data;
  };

  getQueryChart = async (data: IQueryRequest) => {
    this.loadingChart = true;

    try {
      let response = await agent.Query.getChartData(data);

      runInAction(() => (this.queryChart = response));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };
}
