import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";


export default class BrinePumpGridStore extends DataGridStore {

  constructor() {
      super();

      makeObservable(this, {
          getBrinePumpList: action,
      });
  }

  getBrinePumpList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.BrinePump.listGrid(urlParams);
      this.setDataGridResult(result);
      
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingGrid = false);
    }
  }

  updateGridCallback = action(() => {
    this.getBrinePumpList();
  })

}