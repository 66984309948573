import { LoadingButton } from "@mui/lab";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";


interface IModalProps{  
  handleDelete: () => void
}

function GeologicalMebModalDelete(props: IModalProps){
  const { handleDelete } = props
  const { modalStore } = useStore();  
  return(
    <Box>
      <Typography variant='h2'>Confirmation</Typography>
      <Typography variant='body1' sx={{ my: '18px' }}>Are you sure want to delete this data?</Typography>
      <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'space-between', width: '180px' }}>
        <Button variant='contained' color='info'  onClick={() => modalStore.close()}>No</Button>        
        <LoadingButton variant='contained' color='error' onClick={handleDelete}>Yes</LoadingButton>
      </Box>
    </Box>
  )
}

export default observer(GeologicalMebModalDelete);