import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../../app/stores/store";

function Trajectory() {
  const { geologicalAddGridStore } = useStore();

  const column: GridColDef[] = [
    {
      field: "azi",
      headerName: "Azi",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "easting",
      headerName: "Easting",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "elevation",
      headerName: "Elevation",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "inch",
      headerName: "Inch",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "md",
      headerName: "MD",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "northing",
      headerName: "Northing",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "throw",
      headerName: "Throw",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "tvd",
      headerName: "TVD",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
  ];

  console.log("geologicalAddGridStore.loadingGrid", geologicalAddGridStore.loadingGrid);

  return (
    <StyledDataGridPremium
      columns={column}
      rows={geologicalAddGridStore.dataGridResult.data}
      loading={!geologicalAddGridStore.dataGridResult.data.length}
      autoHeight
      pagination
      showCellVerticalBorder
      showColumnVerticalBorder
    />
  );
}

export default observer(Trajectory);
