import { useEffect, useState } from "react";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { convertDataGridCleanOutHistory } from "../../../app/config/utils";

type TCleanOutHistoryDataGrid = {
  date: Date;
  selectedMultipleORC: string[];
};

function CleanOutHistoryDataGrid({ date, selectedMultipleORC }: TCleanOutHistoryDataGrid) {
  const { cleanOutHistoryStore } = useStore();
  const [gridColumnHelper, setGridColumnHelper] = useState<any[]>([]);
  const [gridGroupingHelper, setGridGroupingHelper] = useState<any>([]);
  const [gridGroupingNewData, setGridGroupingNewData] = useState<any>([]);
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = (params: any) => {
    if (params.field !== "orc") {
      navigate(`/geochemistry-clean/view-detail/${params.row[`${params.field}startDate`]}/${params.row[`${params.field}endDate`]}`, {
        state: {
          orcName: params.row.orc,
          year: params.row.year,
          week: params.row[`${params.field}weekNumber`],
          month: params.row[`${params.field}month`],
        },
      });
    }
  };

  useEffect(() => {
    if (!selectedMultipleORC.length) {
      cleanOutHistoryStore.clearCleanOutHistoryGrid();

      return;
    }

    let payload = {
      ids: selectedMultipleORC,
      year: date.getFullYear(),
    };

    cleanOutHistoryStore.getCleanOutHistoryGrid(payload).then((res) => {
      const { tempColumnHelper, tempGroupingHelper, tempGroupingNewData } = convertDataGridCleanOutHistory(res.data);

      setGridColumnHelper(tempColumnHelper);
      setGridGroupingHelper(tempGroupingHelper);
      setGridGroupingNewData(tempGroupingNewData);
    });
  }, [cleanOutHistoryStore, date, selectedMultipleORC]);

  return (
    <StyledDataGridPremium
      getRowId={(row) => row.id}
      onCellClick={handleCellClick}
      sx={{
        ".datagrid-delta-false": { background: "#FEF3CC" },
        ".datagrid-delta-true": { background: "#43C55F" },
      }}
      getCellClassName={(params: any) => {
        const defaultColorType = ["string", "object"];

        if (defaultColorType.includes(typeof params.value)) return "";
        if (!params.value) return "datagrid-delta-false";
        if (params.value) return "datagrid-delta-true";

        return "";
      }}
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      initialState={{
        pinnedColumns: {
          left: ["orc"],
        },
      }}
      disableColumnSelector
      columns={[
        ...gridColumnHelper,
        {
          field: "orc",
          headerName: "ORC",
          sortable: false,
          align: "center",
          headerAlign: "center",
          disableColumnMenu: true,
          width: 100,
          pinnable: true,
        },
      ]}
      columnGroupingModel={gridGroupingHelper}
      rows={gridGroupingNewData}
      autoHeight
    />
  );
}

export default observer(CleanOutHistoryDataGrid);
