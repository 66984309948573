import Chart from 'react-apexcharts'
import { observer } from 'mobx-react-lite';
import { IDirectional } from '../../../app/models/wellGeometry';



 interface IProps {
  data: Array<IDirectional>
  x_label: keyof IDirectional,
  y_label: keyof IDirectional,
 }

 
function WellGeometryDirectionalChart(props: IProps) {
  const { data, x_label, y_label } = props;
  const options: ApexCharts.ApexOptions  = {
    xaxis: {
      type: 'numeric',
      title: {
        text: x_label.toLocaleUpperCase()
      },
      tickAmount: 8,
    },
    yaxis: {
      title: {
        text: y_label.toLocaleUpperCase()
      },
      max:  Math.max.apply(Math, data.map((item) => item[y_label])),
    },
    title: {
      align: 'left',
      text: 'Well',
    },
  };
  const series: ApexAxisChartSeries = [
    {
      name: y_label,
      data: data.map((d: IDirectional) => {
        return {
          x: d[x_label],
          y: d[y_label]
        }
      } )
    }
  ];

  return <Chart options={options} series={series} type="line" height={750} />;
}

export default observer(WellGeometryDirectionalChart);