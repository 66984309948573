import { Add, Delete } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

function WellGeometryDropdownMenu() {
  const { wellGeometryGridStore, wellGeometryStore, dialogStore, snackbarStore, commonStore } = useStore();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await wellGeometryStore.deleteWellGeometry(commonStore.selectedDataOnGrid).then(() => {
          wellGeometryGridStore.getWellGeometryList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={handleClick}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button component="label" variant="text" color="primary" onClick={() => navigate("/master/geometry/directional")}>
            Well Geometry Directional
          </Button>
        </MenuItem>
        <MenuItem>
          <Button component="label" variant="text" color="primary" onClick={() => navigate("/master/geometry/completion")}>
            Well Geometry Completion
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}

export default observer(WellGeometryDropdownMenu);
