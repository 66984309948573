import { action, makeObservable, observable, runInAction } from "mobx";
import { IDeleteData } from "../models/account";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { TQuarterlyChartDataResponse, TQuarterlyChartPayload, TQuarterlyPayload, TQuarterlyTablePayload, TYAxesResponse } from "../models/quarterly";

export default class QuarterlyStore extends DataGridStore {
  quarterlyLoading = false;
  loadingChart = false;
  quarterlyPageCreateGrid: TQuarterlyTablePayload[] = [];
  quarterlyChartRightYAxes: TYAxesResponse[] = [];
  quarterlyChartLeftYAxes: TYAxesResponse[] = [];
  quarterlyCharts: TQuarterlyChartDataResponse[] = [];

  constructor() {
    super();

    makeObservable(this, {
      quarterlyLoading: observable,
      deleteQuarterly: action,
      downloadTemplateQuarterly: action,
      uploadTemplateQuarterly: action,
      quarterlyPageCreateGrid: observable,
      getQuarterlyAxes: action,
      quarterlyChartRightYAxes: observable,
      quarterlyChartLeftYAxes: observable,
      loadingChart: observable,
      quarterlyCharts: observable,
      getQuarterlyChart: action,
    });
  }

  addQuarterly = async (payload: TQuarterlyPayload) => {
    this.loadingGrid = true;

    try {
      await agent.Geochemistry.addQuarterly(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  editQuarterly = async (payload: TQuarterlyTablePayload) => {
    this.loadingGrid = true;

    try {
      await agent.Geochemistry.editQuarterly(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getQuarterlyDetail = async (wellId: string) => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getQuarterlyDetail(wellId);

      runInAction(() => (this.quarterlyPageCreateGrid = result.data));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getQuarterlyChart = async (payload: TQuarterlyChartPayload) => {
    this.loadingChart = true;

    try {
      const result = await agent.Geochemistry.getQuarterlCharts(payload);

      runInAction(() => (this.quarterlyCharts = result.data));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };

  getQuarterlyGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geochemistry.getQuarterlyGrid(urlParams);

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  uploadTemplateQuarterly = async (payload: FormData) => {
    this.quarterlyLoading = true;

    try {
      let res = await agent.Geochemistry.uploadTemplateQuarterly(payload);

      runInAction(() => (this.quarterlyPageCreateGrid = res.data));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.quarterlyLoading = false));
    }
  };

  downloadTemplateQuarterly = async (wellId: string) => {
    this.quarterlyLoading = true;

    try {
      await agent.Geochemistry.downloadTemplateQuarterly(wellId);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.quarterlyLoading = false));
    }
  };

  deleteQuarterlyDetail = async (ids: IDeleteData) => {
    this.quarterlyLoading = true;

    try {
      await agent.Geochemistry.deleteQuarterlyDetail(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.quarterlyLoading = false));
    }
  };

  deleteQuarterly = async (ids: IDeleteData) => {
    this.quarterlyLoading = true;

    try {
      await agent.Geochemistry.deleteQuarterly(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.quarterlyLoading = false));
    }
  };

  getQuarterlyAxes = async (identifier: string) => {
    this.quarterlyLoading = true;

    try {
      const res = await agent.Geochemistry.getQuarterlyAxes(identifier);

      identifier === "right" ? (this.quarterlyChartRightYAxes = res) : (this.quarterlyChartLeftYAxes = res);
    } catch (error) {
    } finally {
      runInAction(() => (this.quarterlyLoading = false));
    }
  };

  setQuarterlyPageCreateGrid = (data: TQuarterlyTablePayload[]) => {
    this.quarterlyPageCreateGrid = data;
  };
}
