import { Box } from "@mui/system";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { DecimationSegmentTable, DecimationSelect } from "../../../../app/models/decimation";
import { useStore } from "../../../../app/stores/store";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

interface IProps {
  decimationSegmentSelect: Array<DecimationSelect>;
  value: number;
}

function PTDecimationTableViewPerSegment(props: IProps) {
  const [selectedSegmen, setSelectedSegmen] = useState("");
  const [valuePrev, setValuePrev] = useState(0);
  const [decimationSegmentTable, setDecimationSegmentTable] = useState<Array<DecimationSegmentTable>>([]);
  const { decimationStore } = useStore();

  const gridColumns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 300, type: "string" },
    { field: "depth", headerName: "Depth", flex: 1, headerAlign: "center", align: "center", type: "number" },
    { field: "pressure", headerName: "Pressure", headerAlign: "center", align: "center", flex: 1, type: "number" },
    { field: "temperature", headerName: "Temperature", headerAlign: "center", align: "center", flex: 1, type: "number" },
  ];

  useEffect(() => {
    if (props.value > 0) {
      setSelectedSegmen(props.value + "");
      setDecimationSegmentTable(decimationStore.decimation.table.filter((x) => x.segment === props.value));
    }
  }, [props.value, decimationStore]);

  function handleChange(event: any) {
    let checkSegment = decimationStore.decimation.selectedSegments.find((x) => x === event.target.value);
    if (checkSegment === null || checkSegment === undefined) {
      let selectedSegmens: any = [];
      if (decimationStore.decimation.selectedSegments.length > 0) {
        var index = decimationStore.decimation.selectedSegments.indexOf(valuePrev);
        if (index !== -1) {
          decimationStore.decimation.selectedSegments.splice(index, 1);
        }
        selectedSegmens = decimationStore.decimation.selectedSegments;
      }
      decimationStore.setDecimationSegmentSelected([]);
      setSelectedSegmen(event.target.value);
      selectedSegmens.push(event.target.value);
      decimationStore.setDecimationSegmentSelected(selectedSegmens);
      setDecimationSegmentTable(decimationStore.decimation.table.filter((x) => x.segment === event.target.value));
    } else {
      alert("Segmen is choosen");
    }
  }

  function handleOpen(event: any) {
    setValuePrev(parseInt(selectedSegmen));
  }

  return (
    <Box>
      <FormControl fullWidth>
        <Grid container>
          <Grid xs={4}>
            <InputLabel id="demo-simple-select-label">Segmen</InputLabel>
            <Select
              name="wesumSelectedSegmentllId"
              labelId="Segmen"
              fullWidth
              label="Segmen"
              onChange={handleChange}
              onOpen={handleOpen}
              value={selectedSegmen}
              readOnly={true}
            >
              {props.decimationSegmentSelect.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </FormControl>
      <StyledDataGridPremium
        sx={{ mt: "12px", width: "100%" }}
        columns={gridColumns}
        rows={decimationSegmentTable}
        getRowId={(row) => row.sequence}
        autoHeight
        pagination
        showColumnVerticalBorder
        showCellVerticalBorder
      />
    </Box>
  );
}

export default PTDecimationTableViewPerSegment;
