import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IObjectStatusDetail } from "../../../app/models/objectStatus";
import { useStore } from "../../../app/stores/store";
import ObjectStatusDetailTable from "./ObjectStatusDetailTable";


interface IModalProps{  
  id: string
}

function ObjectStatusModalDetail(props: IModalProps){
  const { id } = props
  const { objectStatusStore } = useStore();  
    const [ detail, setDetail ] = useState<IObjectStatusDetail[]>([]);

  useEffect(() => {
    objectStatusStore.getObjectStatusDetail(id).then((res) => {
        setDetail(res);
      });
  }, [objectStatusStore.getObjectStatusDetail, id, objectStatusStore]);
  return(
    <Box width='100%' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant='h2'>Well Status History</Typography>
      <ObjectStatusDetailTable rows={detail} />
    </Box>
  )
}

export default observer(ObjectStatusModalDetail);