import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import RkdCard from "../../app/component/card/RkdCard";
import { useStore } from "../../app/stores/store";
import { ITftCompletion, ITftCompletionChart, ITftCompletionData, ITftCompletionRequest } from "../../app/models/tft";
import { IObjectOptions } from "../../app/models/object";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { DataGridPremium, gridClasses, GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import TFTComplesionChart from "./TFTComplesionChart";
import { TSelectedData } from "../pressureandtemp/decimation/PTDecimationForm";

const initialChart: ITftCompletionChart = {
  data: {
    name: "",
    data: [],
  },
};

function TFTPageComplesionIndex() {
  const { objectStore, tftSteamStore } = useStore();
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const [complesion, setComplesion] = useState<ITftCompletionRequest>({
    id: "",
    startDate: new Date(),
    endDate: new Date(),
  });
  const [objectType, setObjectType] = useState<Array<IObjectOptions>>([]);

  const [data, setData] = useState<ITftCompletion>({
    data: [],
    steamRateChart: initialChart,
    brineRateChart: initialChart,
    enthalpyChart: initialChart,
    totalFlowRateChart: initialChart,
  });

  const columnComplesion: GridColDef<ITftCompletionData>[] = [
    { field: "tftDate", headerName: "Date", width: 120, type: "date", align: "center", headerAlign: "center" },
    { field: "whp", headerName: "WHP (psig)", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "averageTotalFlow", headerName: "Total Flow (kph)", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "avgSteamPressure", headerName: "Steam Pressure", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "steamFlowSteamPChoice", headerName: "Steam Flow @ Steam P Choice", width: 120, type: "number", align: "center", headerAlign: "center" },
    {
      field: "steamFractionSteamPChoice",
      headerName: "Steam Fraction Steam P Choice",
      width: 120,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "avgBrinePressure", headerName: "Brine Pressure", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "brineFlowRateAverage", headerName: "Brine Flow", width: 120, type: "number", align: "center", headerAlign: "center" },
    {
      field: "steamFractionBrinePChoice",
      headerName: "Steam Fraction @ Brine P Choice",
      width: 120,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "avgTestPressure", headerName: "Test Pressure", width: 120, type: "number", align: "center", headerAlign: "center" },
    {
      field: "steamFractionAvgPChoice",
      headerName: "Steam Fraction @ Avg P Choice",
      width: 120,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "productionSepPressure", headerName: "Production Sep Pressure", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "calcSteamProdP", headerName: "Calc Steam Production P", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "calcBrineProdP", headerName: "Calc Brine Production P", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "averageTestEnthalpy", headerName: "Test Enthalpy", width: 120, type: "number", align: "center", headerAlign: "center" },
    { field: "controlValve", headerName: "Control Valve", width: 120, type: "number", align: "center", headerAlign: "center" },
  ];

  useEffect(() => {
    objectStore.getObjectOptions("").then((res) => {
      setObjectType(res);
    });
  }, [objectStore]);

  const handleComplesion = () => {
    tftSteamStore.completionTft(complesion).then((res) => {
      setData(res);
    });
  };

  return (
    <RkdCard title="Completion Data" subtitle="Add Edit & Delete Completion Data from this Page">
      <Grid container sx={{ display: "flex" }}>
        <Grid item xs={6} sx={{ display: "flex", gap: "12px" }}>
          <Autocomplete
            disablePortal
            fullWidth
            onChange={(e: any, val: any) => {
              setSelectedWell(val);
              setComplesion({ ...complesion, id: val.value });
            }}
            disableClearable
            value={selectedWell}
            getOptionLabel={(option) => option.label}
            options={objectType.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => <TextField {...params} label="Well" />}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(e) => setComplesion({ ...complesion, startDate: e })}
              value={complesion?.startDate}
              label="Start Date"
              renderInput={(props) => <TextField label="Start Date" fullWidth {...props} sx={{ mb: 1 }} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(e) => setComplesion({ ...complesion, endDate: e })}
              value={complesion?.endDate}
              label="End Date"
              renderInput={(props) => <TextField label="End Date" fullWidth {...props} sx={{ mb: 1 }} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <LoadingButton
            sx={{ height: "90%" }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={complesion.id === "" || complesion.startDate === null || complesion.endDate === null}
            loading={tftSteamStore.loadingGrid}
            onClick={() => handleComplesion()}
          >
            Search
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sx={{ marginY: "24px" }}>
          <DataGridPremium
            columns={columnComplesion}
            rows={data.data}
            getRowId={(row) => Number(row.tftDate)}
            autoHeight
            showCellVerticalBorder
            showColumnVerticalBorder
            getRowHeight={() => "auto"}
            initialState={{
              sorting: {
                sortModel: [{ field: "tftDate", sort: "asc" }],
              },
            }}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 2,
              },
            }}
          />
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
            <TFTComplesionChart data={data.steamRateChart} title={"Steam Rate"} />
            <TFTComplesionChart data={data.enthalpyChart} title={"Enthalpy"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
            <TFTComplesionChart data={data.brineRateChart} title={"Brine Rate"} />
            <TFTComplesionChart data={data.totalFlowRateChart} title={"Total Flow Rate"} />
          </Box>
        </Box>
      </Grid>
    </RkdCard>
  );
}

export default observer(TFTPageComplesionIndex);
