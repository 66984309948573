import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import DailysheetCharts from "./DailysheetCharts";
import { IDailyChart } from "../../../../app/models/dailysheet";

type TBarDialogProps = {
  openChartDialog: boolean;
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>;
  series: IDailyChart[] | undefined;
};

function DynamicBarShowChartDialog({ series, setOpenChartDialog, openChartDialog }: TBarDialogProps) {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => setOpenChartDialog(false);

  return (
    <Dialog fullWidth maxWidth="md" open={openChartDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Generated Chart</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <DailysheetCharts series={series} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DynamicBarShowChartDialog);
