import { IObjectTypes } from "../../../app/models/objectTypes";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";

function ObjectTypeGrid() {
  const { objectTypeGridStore } = useStore();
  const { getObjectTypeList } = objectTypeGridStore;
  const navigate = useNavigate();

  useEffect(() => {
    getObjectTypeList();
  }, [getObjectTypeList]);

  const gridColumns: GridColumns<IObjectTypes> = [
    {
      field: "objectTypeName",
      headerName: "Object Types Name",
      width: 170,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      type: "string",
      filterable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            color="info"
            label="View"
            onClick={() => {
              navigate(`edit/${params.id}`);
            }}
          />,
        ];
      },
    },
  ];

  return <RkdDataGrid gridStore={objectTypeGridStore} columns={gridColumns} />;
}

export default observer(ObjectTypeGrid);
