import React from "react";
import RkdCard from "../../../app/component/card/RkdCard";
import VesselGroupGrid from "./VesselGroupGrid";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

function VesselGroupList() {
  const { dialogStore, snackbarStore, commonStore, vesselGroupStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await vesselGroupStore.deleteVesselGroup(commonStore.selectedDataOnGrid).then(() => {
          vesselGroupStore.getVesselGroupList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <RkdCard title="Vessel Group" subtitle="Add Edit & Vessel Group Type from this Page">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <VesselGroupGrid />
    </RkdCard>
  );
}

export default observer(VesselGroupList);
