import { useMemo } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import * as yup from "yup";
import { useFormik } from "formik";
import RkdForm from "../../../app/component/form/RkdForm";

const validationSchema = yup.object({
  orcName: yup.string().required(),
  orcDescription: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function OrcPageCreate() {
  const { orcGridStore } = useStore();
  const navigate = useNavigate();

  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    return orcGridStore.selectedOrc ? orcGridStore.selectedOrc : { orcName: "", orcDescription: "" };
  }, [orcGridStore.selectedOrc]);

  const onSubmit = async (values: ValidationSchema) => {
    id
      ? orcGridStore.editOrc(id, values).then(() => {
          orcGridStore.setSelectedOrc(null);
          navigate("/master/orc");
        })
      : orcGridStore.addOrc(values).then(() => {
          navigate("/master/orc");
        });
  };

  const { handleSubmit, handleChange, values, errors } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="ORC" subtitle="Add Edit & Delete ORC Data from this Page">
          <RkdForm initialValues={initialValues} submitAction={onSubmit} validationSchema={validationSchema}>
            <TextField
              label="ORC Name"
              value={values.orcName}
              fullWidth
              name="orcName"
              required
              error={!!errors.orcName}
              helperText={errors.orcName}
              onChange={handleChange}
            />
            <TextField
              name="orcDescription"
              label="Description"
              multiline
              rows={4}
              error={!!errors.orcDescription}
              helperText={errors.orcDescription}
              variant="outlined"
              onChange={handleChange}
              value={values.orcDescription}
            />
          </RkdForm>
          <Box>
            <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
              <Box>
                <Button disabled={orcGridStore.orcAadEditLoading} variant="contained" color="error" onClick={() => navigate("/master/orc")}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  loading={orcGridStore.orcAadEditLoading}
                  onClick={() => handleSubmit()}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(OrcPageCreate);
