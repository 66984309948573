import { action, makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { IDeleteData } from "../models/account";
import { store } from "./store";

export default class GeophysicsGravityGridStore extends DataGridStore {
  loadingDelete = false;
  loadingDetail = false;
  loadingStationOptions = false;
  selectedGeophysicGravityId = "";

  constructor() {
    super();

    makeObservable(this, {
      loadingDelete: observable,
      loadingDetail: observable,
      getGeophysicsDetailGravity: action,
    });
  }

  getGeophysicsDetailGravity = async (id: string) => {
    this.loadingDetail = true;

    try {
      const result = await agent.Geophysic.getGeophysicGravityDetail(id);

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDetail = false));
    }
  };

  getGeophysicsGravityGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geophysic.getGeophysicGravityGrid(urlParams);
      result.data.forEach((item, index) => ({
        ...item,
        id: index + 1,
      }));

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  addGravity = (payload: any) => {
    try {
      agent.Geophysic.addGeophysicGravity(payload);
    } catch (error) {
      throw error;
    }
  };

  downloadTemplateGravity = async (locationID: string, year: string) => {
    try {
      await agent.Geophysic.downloadTemplateGravity(locationID, year);
    } catch (error) {
      store.snackbarStore.show("error", "File Not Found");
      throw error;
    }
  };

  uploadTemplateGravity = async (payload: any) => {
    try {
      let res = await agent.Geophysic.uploadTemplateGravity(payload);

      return res;
    } catch (error) {
      store.snackbarStore.show("error", "File Not Found");
      throw error;
    }
  };

  deleteGeophysicsGravity = async (ids: IDeleteData) => {
    this.loadingDelete = true;
    try {
      await agent.Geophysic.deleteGeophysicGravity(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  getGravitySurveyChart = async (id: string) => {
    this.loadingDetail = true;

    try {
      const result = await agent.Geophysic.getGeophysicGravityChart(id);
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDetail = false));
    }
  };

  setGeophysicGravityId = (id: string) => {
    this.selectedGeophysicGravityId = id;
  };

  updateGridCallback = action(() => {
    this.getGeophysicsGravityGrid();
  });
}
