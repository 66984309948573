import React, { useEffect } from "react";
import VesselGroupList from "./VesselGroupList";
import { Grid } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

function VesselGroupPage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage("Vessel Group");
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <VesselGroupList />
      </Grid>
    </Grid>
  );
}

export default observer(VesselGroupPage);
