import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as yup from "yup";
import { Close, OpenInBrowser, UploadOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import { BootstrapDialog } from "../../geophysics/mtSurvey/GeophysicMTCreate";
import { IObjectOptions } from "../../../app/models/object";

const validationSchema = yup.object({
  orcId: yup.string().required(),
  cleaningDate: yup.date(),
  mechanism: yup.string().required(),
  imageUrlBefore: yup.mixed().required(),
  imageUrlAfter: yup.mixed(),
  imageUrlSample: yup.mixed(),
  imageUrlXrd: yup.mixed(),
});

const uploadInputHelper = ["imageUrlBefore", "imageUrlAfter", "imageUrlSample", "imageUrlXrd"];

type ValidationSchema = yup.InferType<typeof validationSchema>;

function CleanOutHistoryPageCreate() {
  const { biweeklyStore, cleanOutHistoryStore, orcGridStore } = useStore();
  const { cleanOutDetail, loadingDetail, addCleanOutDetail, getCleanOutDetail, editCleanOutDetail } = cleanOutHistoryStore;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [orcOptions, setOrcOptions] = useState<IObjectOptions[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    if (id && cleanOutDetail) {
      let temp: {
        [key: string]: any;
      } = {};

      uploadInputHelper.map((item) => {
        if (!!eval("cleanOutDetail." + item)) {
          let imageDataHelper = new File([cleanOutDetail[item] as unknown as BlobPart], eval("cleanOutDetail." + item + ".fileName"), {
            type: cleanOutDetail.imageUrlBefore.contentType,
          });
          temp[item] = imageDataHelper;
        }
      });

      return {
        ...cleanOutDetail,
        imageUrlBefore: temp.imageUrlBefore,
        imageUrlAfter: temp.imageUrlAfter,
        imageUrlSample: temp.imageUrlSample,
        imageUrlXrd: temp.imageUrlXrd,
      };
    } else {
      return {
        orcId: "",
        cleaningDate: new Date(),
        mechanism: "",
        imageUrlBefore: undefined,
        imageUrlAfter: undefined,
        imageUrlSample: undefined,
        imageUrlXrd: undefined,
      };
    }
  }, [cleanOutDetail]);

  const onSubmit = (values: ValidationSchema) => {
    const formData = new FormData();

    values.cleaningDate && formData.append("cleaningDate", values.cleaningDate.toLocaleString());
    values.orcId && formData.append("orcId", values.orcId);
    values.mechanism && formData.append("mechanism", values.mechanism);
    !!values.imageUrlBefore && formData.append("imageUrlBefore", values.imageUrlBefore as any);
    !!values.imageUrlAfter && formData.append("imageUrlAfter", values.imageUrlAfter as any);
    !!values.imageUrlSample && formData.append("imageUrlSample", values.imageUrlSample as any);
    !!values.imageUrlXrd && formData.append("imageUrlXrd", values.imageUrlXrd as any);

    id
      ? editCleanOutDetail(formData, id).then(() => navigate("/geochemistry-clean"))
      : addCleanOutDetail(formData).then(() => navigate("/geochemistry-clean"));
  };

  const handleClose = () => setIsModalOpen(false);

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));

    if (!id) return;
    getCleanOutDetail(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Clean Out History" subtitle={id ? "Edit" : "Add"}>
          {loadingDetail || orcGridStore.orcGetOptionsLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container spacing="6px">
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Cleaning Date"
                      value={values.cleaningDate}
                      disabled={biweeklyStore.loading}
                      inputFormat="dd MMM yyyy"
                      onChange={(e: any) => setFieldValue("cleaningDate", e)}
                      renderInput={(props) => <TextField required fullWidth name="cleaningDate" {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <TextField select label="ORC" value={values.orcId} onChange={handleChange} fullWidth name="orcId">
                    {orcOptions.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: "right" }}>
                  <Box sx={{ height: "100%", alignItems: "center" }}>
                    <Button
                      sx={{ height: "68%", whiteSpace: "nowrap" }}
                      onClick={() => setIsModalOpen(true)}
                      component="label"
                      variant="outlined"
                      startIcon={<OpenInBrowser />}
                    >
                      Open Upload Modal
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: "24px" }}>
                  <TextField
                    label="Cleaning Mechanism"
                    name="mechanism"
                    minRows={3}
                    fullWidth
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.mechanism}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: "24px" }}>
                  <span style={{ fontWeight: "bold" }}>Before: </span>
                  {!!values.imageUrlBefore ? values.imageUrlBefore.name : "No File Selected"}
                  {!!errors.imageUrlBefore && <FormHelperText sx={{ color: "red" }}>Required</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                  <span style={{ fontWeight: "bold" }}>After: </span>
                  {!!values.imageUrlAfter ? values.imageUrlAfter.name : "No File Selected"}
                </Grid>
                <Grid item xs={12}>
                  <span style={{ fontWeight: "bold" }}>Sample: </span>
                  {!!values.imageUrlSample ? values.imageUrlSample.name : "No File Selected"}
                </Grid>
                <Grid item xs={12}>
                  <span style={{ fontWeight: "bold" }}>XRD: </span>
                  {!!values.imageUrlXrd ? values.imageUrlXrd.name : "No File Selected"}
                </Grid>
              </Grid>
              <Box>
                <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
                  <Box>
                    <Button disabled={biweeklyStore.loadingAdd} variant="contained" color="error" onClick={() => navigate("/geochemistry-clean")}>
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton
                      loading={biweeklyStore.loadingAdd}
                      onClick={() => handleSubmit()}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </RkdCard>
      </Grid>
      <BootstrapDialog fullWidth maxWidth="xs" onClose={handleClose} aria-labelledby="customized-dialog-title" open={isModalOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload Modal
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth component="label" variant="outlined" startIcon={<UploadOutlined />}>
                Before
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .pdf, .gif"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) setFieldValue("imageUrlBefore", e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth component="label" variant="outlined" startIcon={<UploadOutlined />}>
                After
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .pdf, .gif"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) setFieldValue("imageUrlAfter", e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth component="label" variant="outlined" startIcon={<UploadOutlined />}>
                Sample
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .pdf, .gif"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) setFieldValue("imageUrlSample", e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth component="label" variant="outlined" startIcon={<UploadOutlined />}>
                XRD
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .pdf, .gif"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) setFieldValue("imageUrlXrd", e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
}

export default observer(CleanOutHistoryPageCreate);
