import React, { useEffect, useState } from 'react';
import RkdCard from '../../app/component/card/RkdCard';
import { ITftAvg, ITftReport } from '../../app/models/tft';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Button } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IObjectOptions } from '../../app/models/object';
import { IPortLocationOption } from '../../app/models/portLocation';
import { observer } from 'mobx-react-lite';
import { DataGridPremium, gridClasses, GridColDef } from '@mui/x-data-grid-premium';
import { calculateAverageBrineResult, calculateAverageSteamResult } from './helper/tftFormula';

const initialData: ITftReport = {
  tftDate: new Date(),
  objectId: "",
  tftSdtTmpl: "",
  wht: 0,
  whp: 0,
  controlValve: 0,
  pumpStroke: 0,
  mfc: "",
  baselinePTSA: 0,
  wtPTSASolin: 0,
  steamTracerName: "",
  steamTracerMW: 0,
  baselineSF6: 0,
  volSF6: 0,
  baroPress: 0,
  ncgContent: 0,
  productionSepPressure: 0,
  reportAnalytical: true,
  baselineLiquidtrace: 0,
  liquidtraceSoln: 0,
  steamPortLoccationId: "",
  brinePortLocationId: "",
  mfcInOut: 0,
  brineTracerName: "",
  brineBaseline: "",
  wt: 0,
  steamBaseline: "",
  tmfHcalculationBlock: 0,
  samePort: true,
  pressDiffFlag: true,
  avgSteamPressure: 0,
  avgBrinePressure: 0,
  averageTestPressure: 0,
  steamEnthAvgP: 0,
  brineEnthAvgP: 0,
  steamFlowSteamPChoice: 0,
  tmfAvgP: 0,
  tmfAvgPNCGcorr: 0,
  steamFractionAvgP: 0,
  steamFractionAvgPNCGCorr: 0,
  steamFractionAvgPChoice: 0,
  steamEnthSteamP: 0,
  brineEnthSteamP: 0,
  calcBrineSteamP: 0,
  calcBrineSteamPNCGCorr: 0,
  tmfSteamP: 0,
  tmfSteamPNCGcorr: 0,
  steamFractionSteamP: 0,
  steamFractionSteamPNCGCorr: 0,
  steamFractionSteamPChoice: 0,
  steamEnthBrineP: 0,
  brineEnthBrineP: 0,
  calcSteamBrineP: 0,
  calcSteamBrinePNCGcorr: 0,
  steamFractionBrineP: 0,
  steamFractionBrinePNCGCorr: 0,
  steamFractionBrinePChoice: 0,
  totalEnthAvgPressure: 0,
  totalEnthAvgPressureNCGcorr: 0,
  totalEnthDiffPressures: 0,
  totalEnthDiffPressuresNCGcorr: 0,
  steamEnthProdP: 0,
  brineEnthProdP: 0,
  calcSteamProdP: 0,
  calcBrineProdP: 0,
  tmfProductionP: 0,
  steamFractionProdP: 0,
  steam: {
    steamFlowRateSpread: 0,
    standardDeviation: 0,
    rsd: 0,
    table: [
      {
        sequence: 0,
        labNumber: 1,
        timeStart: new Date(),
        timeEnd: new Date(),
        separatorTemp: 0,
        pressPsig: 0,
        saturationPressure: 0,
        injRateSLPM: 0,
        ppmTracer: 0,
        steamFlowRate: 0
      }
    ]
  },
  brine: {
    whtC: 0,
    whpBarg: 0,
    controlValve: 0,
    brineFlowRateSpread: 0,
    standardDeviation: 0,
    rsd: 0,
    table: [
      {
        sequence: 0,
        labNumber: 0,
        intervalStart: new Date(),
        intervalEnd: new Date(),
        tempC: 0,
        pressBarg: 0,
        tempF: 0,
        pressPsig: 0,
        saturationPressure: 0,
        liquidtraceInjRate: 0,
        ppmwTracerInBrine: 0,
        brineFlowRateKph: 0,
        ppbwTracerInBrine: 0,
        brineFlowRateKgs: 0
      }
    ]
  },
  tftSummary: {
    whp: 0,
    controlValve: 0,
    averageTestEnthalpy: 0,
    averageTotalFlow: 0,
    enthalpyTemperature: 0,
    enthalpyTemperatureMessage: 0,
    steamFractionSteamPsig: 0,
    steamFractionBrinePsig: 0,
    productionSeparatorSteam: 0,
    productionSeparatorBrine: 0,
    steamFraction: 0
  }
}

const initialTftAverages: ITftAvg = {
  steam: {
      avgSeparatorTemp: 0,
      avgPressPsig: 0,
      avgSaturationPressure: 0,
      avgSteamFlowRate: 0
  },
  brine: {
      avgSeparatorTemp: 0,
      avgPressPsig: 0,
      avgSaturationPressure: 0,
      avgBrineFlowRate: 0
  }
}

function TFTPageReport() {

  const { tftSteamStore, objectStore, portLocationStore } = useStore();
  const params = useParams();
  const navigate = useNavigate();
  const [id] = useState(params.id);
  const [data, setData] = useState<ITftReport>(initialData);
  const [tftAvg, setTftAvg] = useState<ITftAvg>(initialTftAverages);

  const [ objectType, setObjectType] = useState<Array<IObjectOptions>>([]);
  const [ portLocation, setPortLocation ] = useState<Array<IPortLocationOption>>([]);

  const columnResultSteam: GridColDef[] = [
    { field: 'labNumber', headerName: 'Lab Number', width: 100, type: "number", align: "center", headerAlign: "center" },
    { field: 'timeStart', headerName: 'Time Start', width: 200, type: "string", align: "center", headerAlign: "center",
        renderCell: (params) => {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label=""
                        disabled
                        value={params.row.timeStart}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask='__:__'
                        onChange={(newValue) => console.log(newValue)}
                        renderInput={(props) => (
                            <TextField label="Date" sx={{ my: 1}} {...props} />
                        )}
                    />
                </LocalizationProvider>
            )
        }
    },
    { field: 'timeEnd', headerName: 'Time End', width: 200, type: "string", align: "center", headerAlign: "center",
        renderCell: (params) => {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label=""
                        disabled
                        value={params.row.timeEnd}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask='__:__'
                        onChange={(newValue) => console.log(newValue)}
                        renderInput={(props) => (
                            <TextField label="Date" sx={{ my: 1}} {...props} />
                        )}
                    />
                </LocalizationProvider>
            )
        }
    },
    { field: 'separatorTemp', headerName: 'Separator Temp, F', width: 200, type: "number", groupable: false, align: "center", headerAlign: "center",
        valueGetter: (params: any) => {
            if (params.value == null) {
            return null;
            }
    
            return params.value;
        },
    },
    { field: 'pressPsig', headerName: 'Press, psig', width: 200, type: "number", align: "center", headerAlign: "center",},
    { field: 'saturationPressure', headerName: 'Saturation Pressure, psig', width: 200, type: "number", align: "center", headerAlign: "center",  
        renderCell: (params) => {
            if (params.row.saturationPressure == null) {
            return <Typography variant='body1'>Out Of Range</Typography>
            } else {
                return <Typography variant='body1'>{params.row.saturationPressure}</Typography>
            }
        }
    },
    { field: 'injRateSLPM', headerName: '5.00% SF6 Inj Rate, SLPM', width: 200, type: "number", align: "center", headerAlign: "center", },
    { field: 'ppmTracer', headerName: 'PPM, Tracer in steam', width: 200, type: "number", align: "center", headerAlign: "center", },
    { field: 'steamFlowRate', headerName: 'Steam Flow Rate, KPH', width: 200, type: "number", align: "center", headerAlign: "center", },
  ];

  const columnResultBrine: GridColDef[] = [
    { field: 'labNumber', headerName: 'Lab Number', width: 100, type: "number", align: "center", headerAlign: "center" },
    { field: 'intervalStart', headerName: 'Interval Start', width: 200, type: "string", align: "center", headerAlign: "center",
        renderCell: (params) => {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label=""
                        disabled
                        value={params.row.intervalStart}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask='__:__'
                        onChange={(newValue) => console.log(newValue)}
                        renderInput={(props) => (
                            <TextField label="Date" sx={{ my: 1}} {...props} />
                        )}
                    />
                </LocalizationProvider>
            )
        }
    },
    { field: 'intervalEnd', headerName: 'Interval End', width: 200, type: "string", align: "center", headerAlign: "center",
        renderCell: (params) => {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label=""
                        disabled
                        value={params.row.intervalEnd}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask='__:__'
                        onChange={(newValue) => console.log(newValue)}
                        renderInput={(props) => (
                            <TextField label="Date" sx={{ my: 1}} {...props} />
                        )}
                    />
                </LocalizationProvider>
            )
        }
    },
    { field: 'tempC', headerName: 'Separator Temp, C', width: 200, type: "number", groupable: false, align: "center", headerAlign: "center",
        valueGetter: (params: any) => {
            if (params.value == null) {
            return null;
            }
    
            return params.value;
        },
    },
    { field: 'pressBarg', headerName: 'Press, barg', width: 200, type: "number", align: "center", headerAlign: "center",},
    { field: 'tempF', headerName: 'Separator Temp, F', width: 200, type: "number", groupable: false, align: "center", headerAlign: "center",
        valueGetter: (params: any) => {
            if (params.value == null) {
            return null;
            }
    
            return params.value;
        },
    },
    { field: 'pressPsig', headerName: 'Press, psig', width: 200, type: "number", align: "center", headerAlign: "center",},
    { field: 'liquidtraceInjRate', headerName: 'Liquidtrace Inj Rate', width: 200, type: "number", align: "center", headerAlign: "center" },
    { field: 'ppmwTracerInBrine', headerName: 'Ppmw Tracer in Brine', width: 200, type: "number", align: "center", headerAlign: "center"},
    { field: 'ppbwTracerInBrine', headerName: 'Ppbw Tracer in Brine', width: 200, type: "number", align: "center", headerAlign: "center" },
    { field: 'brineFlowRateKgs', headerName: 'Brine Flow Rate Kgs', width: 200, type: "number", align: "center", headerAlign: "center" },
  ];

  useEffect(() => {
    objectStore.getObjectOptions('').then((res) => {
      setObjectType(res);
    });
  }, [objectStore]);

  useEffect(() => {
    portLocationStore.getPortLocationOptions('').then((res) => {
      setPortLocation(res);
    });
  }, [portLocationStore]);

  useEffect(() => {
    if (id)
    tftSteamStore.reportTft(id).then((res) => {
        if (res) {
          setData(res);
          const avg: ITftAvg = {
            steam: {
              avgSeparatorTemp: calculateAverageSteamResult(data.steam.table, 'separatorTemp'),
              avgPressPsig: calculateAverageSteamResult(data.steam.table, 'pressPsig'),
              avgSaturationPressure: calculateAverageSteamResult(data.steam.table, 'saturationPressure'),
              avgSteamFlowRate: calculateAverageSteamResult(data.steam.table, 'steamFlowRate')
            },
            brine: {
                avgSeparatorTemp: calculateAverageBrineResult(data.brine.table, 'tempC'),
                avgPressPsig: calculateAverageBrineResult(data.brine.table, 'pressPsig'),
                avgSaturationPressure: calculateAverageBrineResult(data.brine.table, 'saturationPressure'),
                avgBrineFlowRate: calculateAverageBrineResult(data.brine.table, 'brineFlowRateKph')
            }
          }

          setTftAvg(avg);
        }
      });
  }, [data.brine.table, data.steam.table, id, tftSteamStore]);

  return (
    <RkdCard title='Tracer Flow Test' subtitle='Report'>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Accordion sx={{ mb: 1, mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#fab933",
            }}
          >
            <Typography sx={{ color: 'white' }}>TFT Header 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{ mt: 2, pl: 2 }}>
              <Grid container item xs={6} spacing={2}>
                <TextField
                  select
                  label="Well"
                  value={data.objectId}
                  fullWidth
                  required
                  name="well"
                  sx={{ mb: 1 }}
                >
                  {
                    objectType.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>{item.text}</MenuItem>
                    ))
                  }
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    onChange={(e) => tftSteamStore.setTftDate(e)}
                    value={data.tftDate}
                    label="Date"
                    renderInput={(props) => (
                      <TextField label="Date" fullWidth {...props} sx={{ mb: 1 }}/>
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  name="wht"
                  label="WHT (F)"
                  variant="outlined"
                  fullWidth
                  value={data.wht}
                  onChange={(e) => tftSteamStore.setWht(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="whp"
                  label="WHP (psig)"
                  variant="outlined"
                  fullWidth
                  value={data.whp}
                  onChange={(e) => tftSteamStore.setwhp(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="controlValve"
                  label="Control Valve (%)"
                  variant="outlined"
                  fullWidth
                  value={data.controlValve}
                  onChange={(e) => tftSteamStore.setControlValve(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="pumpStroke"
                  label="Pump Stroke (%)"
                  variant="outlined"
                  fullWidth
                  value={data.pumpStroke}
                  onChange={(e) => tftSteamStore.setPumpStroke(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  select
                  label="Steam Port Location"
                  value={data.steamPortLoccationId ?  data.steamPortLoccationId : 'null'}
                  onChange={(e) => tftSteamStore.setSteamPortLoc(e.target.value)}
                  fullWidth
                  required
                  name="steamPortLoc"
                  sx={{ mb: 1 }}
                >
                  {
                    portLocation.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>{item.text}</MenuItem>
                    ))
                  }
                  <MenuItem key={999} value={'null'}>{'Empty'}</MenuItem>
                </TextField>
                <TextField
                  select
                  label="Brine Port Location"
                  value={data.brinePortLocationId ? data.brinePortLocationId : 'null'}
                  onChange={(e) => tftSteamStore.setBrinePortLocation(e.target.value)}
                  fullWidth
                  required
                  name="brinePortLoc"
                  sx={{ mb: 1 }}
                >
                  {
                    portLocation.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>{item.text}</MenuItem>
                    ))
                  }
                  <MenuItem key={999} value={'null'}>{'Empty'}</MenuItem>
                </TextField>
                <TextField
                  name="mfc"
                  label="MFC In/Out"
                  variant="outlined"
                  fullWidth
                  value={data.mfc}
                  onChange={(e) => tftSteamStore.setMfc(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineTracerName"
                  label="Brine Tracer Name"
                  variant="outlined"
                  fullWidth
                  value={data.brineTracerName}
                  onChange={(e) => tftSteamStore.setBrineTracerName(e.target.value)}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid container item xs={6} spacing={2}>
                <TextField
                  name="brineBaseline"
                  label={`Brine Baseline ${data.brineTracerName}`}
                  variant="outlined"
                  fullWidth
                  value={data.baselineLiquidtrace}
                  onChange={(e) => tftSteamStore.setBaselineLiquidtrace(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="wt"
                  label="Wt %"
                  variant="outlined"
                  fullWidth
                  value={data.wtPTSASolin}
                  onChange={(e) => tftSteamStore.setWTPTSASolin(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamTracerName"
                  label="Steam Tracer Name"
                  variant="outlined"
                  fullWidth
                  value={data.steamTracerName}
                  onChange={(e) => tftSteamStore.setSteamTracerName(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamTracerMW"
                  label="Steam Tracer MW"
                  variant="outlined"
                  fullWidth
                  value={data.steamTracerMW}
                  onChange={(e) => tftSteamStore.setSteamTracerMW(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamBaseline"
                  label={`Steam Baseline ${data.steamTracerName}`}
                  variant="outlined"
                  fullWidth
                  value={data.baselineSF6}
                  onChange={(e) => tftSteamStore.setBaselineSF6(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="volSF6"
                  label="Vol % SF6"
                  variant="outlined"
                  fullWidth
                  value={data.volSF6}
                  onChange={(e) => tftSteamStore.setVolSF6(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="baroPress"
                  label="Baro. Press (psia)"
                  variant="outlined"
                  fullWidth
                  value={data.baroPress}
                  onChange={(e) => tftSteamStore.setBaroPress(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="ncgContent"
                  label="NCG Content (wt %)"
                  variant="outlined"
                  fullWidth
                  value={data.ncgContent}
                  onChange={(e) => tftSteamStore.setNcgContent(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="productionSepPressure"
                  label="Production Separator Pressure"
                  variant="outlined"
                  fullWidth
                  value={data.productionSepPressure}
                  onChange={(e) => tftSteamStore.setProductionSepPressure(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Report Analytical</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={data.reportAnalytical ? 'yes' : 'no'}
                    onChange={(e) => tftSteamStore.setReportAnalytical(e.target.value)}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <TextField
                  name="tmf"
                  label="TMF/H calculation Block"
                  variant="outlined"
                  fullWidth
                  value={data.tmfHcalculationBlock}
                  onChange={(e) => tftSteamStore.setTmfCalculation(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Accordion sx={{ mb: 2, border: 'none', shadow: 'none', }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#fab933",
              border: 'none',
              shadow: 'none',
            }}
          >
            <Typography sx={{ color: 'white' }}>TFT Header 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{ mt: 2, pl: 2 }} justifyContent="center" alignItems="center">
              <Grid container item xs={4} spacing={2}>
                <TextField
                  name="samePort"
                  label="Same Port"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.samePort ? 'Yes' : 'No'}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="pressDiffFlag"
                  label="Press Diff Flag"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.pressDiffFlag ? 'Yes' : 'No'}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="avgSteamPressure"
                  label="Avg Steam Pressure"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.avgSteamPressure}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="avgBrinePressure"
                  label="Avg Brine Pressure"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.avgBrinePressure}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="avgTestPressure"
                  label="Avg Test Pressure"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.averageTestPressure}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamEnthAvgP"
                  label="Steam Enth @ Avg P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamEnthAvgP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineEnthAvgP"
                  label="Brine Enth @ Avg P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.brineEnthAvgP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFlowSteamPChoice"
                  label="Steam Flow @ Steam P Choice"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFlowSteamPChoice}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="tmfAvgP"
                  label="TMF @ Avg P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.tmfAvgP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="tmfAvgPNcgCorr"
                  label="TMF @ Avg P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.tmfAvgPNCGcorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionAvgP"
                  label="Steam Fraction @ Avg P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionAvgP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionAvgPNcgCorr"
                  label="Steam Fraction @ Avg P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionAvgPNCGCorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionAvgPChoice"
                  label="Steam Fraction @ Avg P Choice"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionAvgPChoice}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid container item xs={4} spacing={2}>
                <TextField
                  name="steamEnthSteamP"
                  label="Steam Enth @ Steam P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamEnthSteamP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineEnthSteamP"
                  label="Brine Enth @ Steam P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.brineEnthSteamP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcBrineSteamP"
                  label="Calc Brine @ Steam P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcBrineSteamP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcBrineSteamPNcgCorr"
                  label="Calc Brine @ Steam P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcBrineSteamPNCGCorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="tmfSteamP"
                  label="TMF @ Steam P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.tmfSteamP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="tmfSteamPNcgCorr"
                  label="TMF @ Steam P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.tmfSteamPNCGcorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionSteamP"
                  label="Steam Fraction @ Steam P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionSteamP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionAvgPNcgCorr"
                  label="Steam Fraction @ Steam P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionAvgPNCGCorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionAvgPChoice"
                  label="Steam Fraction @ Steam P Choice"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionAvgPChoice}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamEnthBrineP"
                  label="Steam Enth @ Brine P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamEnthBrineP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineEnthBrineP"
                  label="Brine Enth @ Brine P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.brineEnthBrineP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcSteamBrineP"
                  label="calc Steam @ Brine P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcSteamBrineP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcSteamBrinePNcgCorr"
                  label="Calc Steam @ Brine P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcSteamBrinePNCGcorr}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid container item xs={4} spacing={2}>
                <TextField
                  name="steamFractionBrineP"
                  label="Steam Fraction @ Brine P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionBrineP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionBrinePNcgCorr"
                  label="Steam Fraction @ Brine P NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionBrinePNCGCorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionBrinePChoice"
                  label="Steam Fraction @ Brine P Choice"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionBrinePChoice}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="totalEnthAvgPress"
                  label="Total Enth @ Avg Pressure"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.totalEnthAvgPressure}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="totalEnthAvgPressNcgCorr"
                  label="Total Enth @ Avg Pressure NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.totalEnthAvgPressureNCGcorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="totalEnthDiffPress"
                  label="Total Enth @ Diff Pressures"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.totalEnthDiffPressures}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="totalEnthDiffPressNcgCorr"
                  label="Total Enth @ Diff Pressures NCG Corr"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.totalEnthDiffPressuresNCGcorr}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamEnthProdP"
                  label="Steam Enth @ Prod P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamEnthProdP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineEnthProdP"
                  label="Brine Enth @ Prod P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.brineEnthProdP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcSteamProdP"
                  label="Calc Steam @ Prod P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcSteamProdP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="calcBrineProdP"
                  label="Calc Brine @ Prod P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.calcBrineProdP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="tmfProductionP"
                  label="TMF @ Production P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.tmfProductionP}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamFractionProdP"
                  label="Steam Fraction @ Prod P"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={data.steamFractionProdP}
                  sx={{ mb: 1 }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                '& .disabled-cell': {
                    backgroundColor: '#e2e2e2',
                  },
            }}
        >
          <DataGridPremium
              columns={columnResultSteam}
              rows={data.steam.table} 
              getRowId={(row) => row.sequence} 
              autoHeight 
              getRowHeight={() => 'auto'}
              onProcessRowUpdateError={(e) => console.error(e)} 
              initialState={{
              sorting: {
                  sortModel: [{ field: 'sequence', sort: 'asc' }],
              },
              }}  
              sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 0,
                  },
              }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ width: '35%' }}>
                  <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none" }}>
                      <Table sx={{ minWidth: 250, border: 'none' }} size="small" aria-label="a dense table">
                          <TableBody>
                          <TableRow
                              key={1}
                              sx={{ "& td, th": { border: 0 } }}
                              >
                                  <TableCell component="th" scope="row" align="left">Steam Port Location  :</TableCell>
                                  <TableCell align="left">{data.steamPortLoccationId}</TableCell>
                              </TableRow>
                          </TableBody>
                      </Table>
                  </TableContainer>
                  <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none", border: 0 }}>
                  <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
                      <TableHead>
                      <TableRow>
                          <TableCell align="left">Average</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow
                          key={1}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">Separator Temp</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{tftAvg.steam.avgSeparatorTemp}</TableCell>
                          </TableRow>
                          <TableRow
                          key={2}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">Separator Press, Psig</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{tftAvg.steam.avgPressPsig}</TableCell>
                          </TableRow>
                          <TableRow
                          key={3}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">Saturation Pressure Psig</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{tftAvg.steam.avgSaturationPressure}</TableCell>
                          </TableRow>
                          <TableRow
                          key={4}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">Steam Flow Rate, KPH</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{tftAvg.steam.avgSteamFlowRate}</TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
                  </TableContainer>
              </Box>
              <Box sx={{ width: '65%', display: 'flex', justifyContent: 'end' }}>
              <TableContainer component={Paper} sx={{ width: 500, boxShadow: "none" }}>
                  <Table sx={{ width: 500, border: 'none' }} size="small" aria-label="a dense table">
                      <TableBody>
                      <TableRow
                          key={1}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row" align="right">Corrected Steam Flow Rate % NCG by Wt</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{tftSteamStore.correctionSteamFlowRate}</TableCell>
                          </TableRow>
                          <TableRow
                          key={2}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row" align="right">Steam Flow Rate Spread (KPH)</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{data.steam.steamFlowRateSpread}</TableCell>
                          </TableRow>
                          <TableRow
                          key={3}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row" align="right">Standar Deviation</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{data.steam.standardDeviation}</TableCell>
                          </TableRow>
                          <TableRow
                          key={4}
                          sx={{ "& td, th": { border: 0 } }}
                          >
                              <TableCell component="th" scope="row" align="right">RSD %</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="center">{data.steam.rsd}</TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </TableContainer>
              </Box>
          </Box> 
      </Box>
      <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            mt: 8,
            gap: 2,
            '& .disabled-cell': {
                backgroundColor: '#e2e2e2',
                },
        }}
      >   

        <DataGridPremium
            columns={columnResultBrine}
            rows={data.brine.table} 
            getRowId={(row) => row.sequence} 
            autoHeight 
            getRowHeight={() => 'auto'}
            initialState={{
            sorting: {
                sortModel: [{ field: 'sequence', sort: 'asc' }],
            },
            }}  
            sx={{
                [`& .${gridClasses.cell}`]: {
                    py: 0,
                },
            }}
            /> 
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '32%', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column' }}>
                <TextField
                    name="whtC"
                    label="WHT C"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={data.brine.whtC}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TextField
                    name="whp"
                    label="WHP, barg"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={data.brine.whpBarg}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TextField
                    name="controlValve"
                    label="Control Valve (%)"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={data.brine.controlValve}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none" }}>
                    <Table sx={{ minWidth: 250, border: 'none' }} size="small" aria-label="a dense table">
                        <TableBody>
                        <TableRow
                            key={1}
                            sx={{ "& td, th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align="left">Brine Port Location  :</TableCell>
                                <TableCell align="left">{data.brinePortLocationId}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none", border: 0 }}>
                <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Average</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        key={1}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Separator Temp</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftAvg.brine.avgSeparatorTemp}</TableCell>
                        </TableRow>
                        <TableRow
                        key={2}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Separator Press, Psig</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftAvg.brine.avgPressPsig}</TableCell>
                        </TableRow>
                        <TableRow
                        key={3}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Saturation Pressure Psig</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftAvg.brine.avgSaturationPressure}</TableCell>
                        </TableRow>
                        <TableRow
                        key={4}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Brine Flow Rate, KPH</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftAvg.brine.avgBrineFlowRate}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
            <Box sx={{ width: '65%', display: 'flex', justifyContent: 'start', alignItems: 'end', flexDirection: 'column' }}>
            <TableContainer component={Paper} sx={{ width: 500, boxShadow: "none" }}>
                <Table sx={{ width: 500, border: 'none' }} size="small" aria-label="a dense table">
                    <TableBody>
                    <TableRow
                        key={1}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Corrected Brine Flow Rate</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.correctionBrineFlowRate}</TableCell>
                        </TableRow>
                        <TableRow
                        key={2}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Steam Flow Rate Spread (KPH)</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{data.brine.brineFlowRateSpread}</TableCell>
                        </TableRow>
                        <TableRow
                        key={3}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Standar Deviation</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{data.brine.standardDeviation}</TableCell>
                        </TableRow>
                        <TableRow
                        key={4}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">RSD %</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{data.brine.rsd}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box> 
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: '8px', backgroundColor: '#e30d20',  "&:hover": { backgroundColor: '#c20818' } }} 
        onClick={() => {
          navigate('/tracer-flow-test/'); 
        }}>
        Cancel
      </Button>
    </RkdCard>
  );
}

export default observer(TFTPageReport);