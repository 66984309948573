import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { TGeophysicsImportTemplateGravitiesResponse } from "../../../app/models/geophysics";

type TBarDialogProps = {
  openChartDialog: boolean;
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>;
  chartData: TGeophysicsImportTemplateGravitiesResponse[];
};

function DynamicBarShowChartDialog({ setOpenChartDialog, chartData, openChartDialog }: TBarDialogProps) {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  console.log("chartData", chartData);

  const handleClose = () => setOpenChartDialog(false);

  return (
    <Dialog fullWidth maxWidth="md" open={openChartDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Generated Chart ({!!chartData ? chartData[0].sequence : undefined})</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Box sx={{ height: "360px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis />
                <Legend />
                <ReferenceLine y={Math.max(...chartData.map((item) => item.svd))} label="SVD" stroke="red" />
                <ReferenceLine y={Math.max(...chartData.map((item) => item.fhd))} label="FHD" stroke="green" />
                <Line type="monotone" dataKey="svd" stroke="#8884d8" />
                <Line type="monotone" dataKey="fhd" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DynamicBarShowChartDialog);
