import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import VesselGroupForm from "./VesselGroupForm";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { IVesselGroup, IVesselGroupPayload } from "../../../app/models/vesselGroup";
import { VesselGroupSchemaZod } from "./schema/vesselSchemaZod";

const initialValues = {
  vesselId: "",
  vesselGroupName: "",
  groups: [],
  locationId: "",
  isBrinePump: false,
  brinePumpId: null,
};

function VesselGroupFormPage() {
  const { objectStore, vesselGroupStore, commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage("Vessel Group");
  });
  const { getVesselGroupDetail } = vesselGroupStore;
  const navigate = useNavigate();
  const params = useParams();
  const [id] = useState(params.id);
  const [data, setData] = useState<IVesselGroup>(initialValues);

  useEffect(() => {
    if (id)
      getVesselGroupDetail(id).then((res) => {
        if (res) {
          setData(res);
          objectStore.getObjectOptionsByLocationId(res.locationId);
        }
      });
  }, [id, getVesselGroupDetail, objectStore]);

  const onSubmit = async (values: VesselGroupSchemaZod) => {
    const data: IVesselGroupPayload = {
      vesselId: values.vesselId,
      vesselGroupName: values.vesselGroupName,
      locationId: values.locationId,
      groupIds: values.groups.map((item) => item.id),
      isBrinePump: values.isBrinePump,
      brinePumpId: values.brinePumpId,
    };

    const dataEdit: IVesselGroupPayload = {
      id: id,
      vesselId: values.vesselId,
      locationId: values.locationId,
      vesselGroupName: values.vesselGroupName,
      groupIds: values.groups.map((item) => item.id),
      isBrinePump: values.isBrinePump,
      brinePumpId: values.brinePumpId,
    };
    if (id) {
      await vesselGroupStore.editVesselGroup(id, dataEdit).then(() => {
        navigate("/master/vessel-group");
      });
    } else {
      await vesselGroupStore.createVesselGroup(data).then(() => {
        navigate("/master/vessel-group");
      });
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <VesselGroupForm onSubmit={onSubmit} initialValues={data} id={id} />
      </Grid>
    </Grid>
  );
}

export default observer(VesselGroupFormPage);
