import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { IFptGrid } from "../../app/models/fpt";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RkdDataGrid from "../../app/component/dataGrid/RkdDataGrid";

function FPTGrid() {
  const { ftpGridStore } = useStore();
  const { getFPTList } = ftpGridStore;
  const navigate = useNavigate();

  useEffect(() => {
    getFPTList();
  }, [getFPTList]);

  const gridColumns: GridColumns<IFptGrid> = [
    { field: "wellName", headerName: "Well Name", flex: 1, type: "string" },
    { field: "surveyDate", headerName: "Date", flex: 1, type: "date" },
    { field: "connectedWells", headerName: "Connected Wells", flex: 1, type: "string" },
    { field: "remarks", headerName: "Remarks", flex: 1, type: "string" },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="info"
          icon={<VisibilityIcon />}
          label="View"
          // disabled = {params.row.status !== 'COMPLETED'}
          onClick={() => {
            navigate(`report/${params.row.wellId}/${params.row.surveyDate.toISOString().split("T")[0]}`);
          }}
        />,
        <GridActionsCellItem
          color="info"
          icon={<EditIcon />}
          label="Edit"
          // disabled = {params.row.status === 'COMPLETED'}
          onClick={() => {
            navigate(`edit/${params.row.wellId}/${params.row.surveyDate.toISOString().split("T")[0]}`);
          }}
        />,
      ],
    },
  ];

  return (
    <RkdDataGrid
      columns={gridColumns}
      gridStore={ftpGridStore}
      // rows={ftpGridStore.dataGridResult.data}
      // rowCount={ftpGridStore.dataGridResult.rowCount}
      // loading={ftpGridStore.loadingGrid}
      // paginationMode="server"
      // pageSize={ftpGridStore.pageSize}
      // page={ftpGridStore.currentPage}
      // onPageChange={(newPage) => ftpGridStore.setPage(newPage)}
      // onPageSizeChange={(newPageSize) => ftpGridStore.setPageSize(newPageSize)}
      // sortingMode="server"
      // onSortModelChange={ftpGridStore.setSortModel}
      // filterMode="server"
      // onFilterModelChange={ftpGridStore.setFilterModel}
      // rowsPerPageOptions={[10, 25, 50, 100]}
      // checkboxSelection
      // onSelectionModelChange={(newSelectionModel) => {
      // commonStore.setSelectionDataOnGrid(newSelectionModel)
      // }}
      //   isRowSelectable={(params: GridRowParams) => params.row.status !== 'COMPLETED'}
      // selectionModel={commonStore.selectedDataOnGrid.ids}
    />
  );
}

export default observer(FPTGrid);
