import { LoadingButton } from "@mui/lab";
import { TextField, MenuItem, Button, Box, Autocomplete, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RkdCard from "../../../app/component/card/RkdCard";
import { ObjectStatus } from "../../../app/config/enum";
import { IObjectOptions } from "../../../app/models/object";
import { IObjectStatus } from "../../../app/models/objectStatus";
import { useStore } from "../../../app/stores/store";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const initialValues: IObjectStatus = {
  objectId: "",
  status: "",
};

function ObjectStatusForm() {
  const navigate = useNavigate();
  const [selectedObject, setSelectedObject] = useState<TSelectedData | undefined>(undefined);
  const { objectStore, objectStatusStore } = useStore();

  const [object, setObject] = useState<IObjectOptions[]>([]);
  const [objectStatus, setObjectStatus] = useState<IObjectStatus>(initialValues);

  const params = useParams();
  const [id] = useState(params.id);

  useEffect(() => {
    let objectTemp: IObjectOptions[] = [];

    const fetchObject = async () => {
      await objectStore.getObjectOptions("").then((res) => {
        setObject(res);
        objectTemp = res;
      });
    };

    fetchObject();

    if (id)
      objectStatusStore.getObjectStatusDetail(id).then((res) => {
        let temp = objectTemp.find((item) => item.value === res[res.length - 1].objectId);
        console.log("temp", temp);

        setSelectedObject({ label: temp?.text ?? "", value: temp?.value ?? "" });
        setObjectStatus({ ...res, objectId: res[res.length - 1].objectId, status: res[0].status });
      });

    // objectStore.getObjectOptions("").then((res) => {
    //   setObject(res);
    // });
  }, [id, objectStatusStore, objectStatusStore.getObjectStatusDetail, objectStore]);

  console.log("selectedObject", selectedObject);

  return (
    <RkdCard title={id ? "Edit Well Status" : `New Well Status`}>
      <Grid container sx={{ spacing: "12px" }}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            fullWidth
            onChange={(e: any, val: any) => {
              setSelectedObject(val);
              setObjectStatus({ ...objectStatus, objectId: val.value });
            }}
            disableClearable
            value={selectedObject}
            getOptionLabel={(option) => option.label}
            options={object.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => <TextField value={selectedObject?.label} name="Object" {...params} label="Object *" />}
          />
        </Grid>
        <Grid item xs={6} sx={{ alignItems: "right" }}>
          <Box>
            <TextField
              select
              fullWidth
              label="Status"
              value={objectStatus.status}
              onChange={(e) => setObjectStatus({ ...objectStatus, status: e.target.value })}
              required
              name="Status"
            >
              {Object.values(ObjectStatus).map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "right", marginTop: "24px" }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ mr: "8px" }}
            onClick={() => {
              navigate("/master/object-status/");
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={objectStatus.objectId === "" || objectStatus.status === ""}
            onClick={() => {
              objectStatusStore.createObjectStatus(objectStatus).then(() => navigate("/master/object-status"));
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </RkdCard>
  );
}

export default observer(ObjectStatusForm);
