import { observer } from "mobx-react-lite";
import { FptTestWell, FptTestWellDetail } from "../../app/models/fpt";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";

interface IProps {
    fptTestWell: FptTestWell;
}

function FPTTestWellGrid(props: IProps){
    const { ftpStore } = useStore();
    const [ testWellTable, setTestWellTable] = useState(props.fptTestWell.table);
    const [enthalphy, setEnthalphy] = useState(0);

    useEffect(() => {
        setTestWellTable(props.fptTestWell.table);
        if(props.fptTestWell.enthalphy != null){
            setEnthalphy(props.fptTestWell.enthalphy)
        }
        else{
            setEnthalphy(0)
        }
    }, [ftpStore, props.fptTestWell.enthalphy, props.fptTestWell.table]);

    const gridColumns: GridColDef[] = [
        { field: 'fcv', headerName: 'FCV (%)', width: 180, type: "number", editable: true },
        { field: 'whp', headerName: 'WHP (psg)', width: 180, type: "number", editable: true },
    ]

    const handleAddRow = () => {
        let detail = new FptTestWellDetail();
        detail.sequence = testWellTable.length + 1;
        detail.fcv = 0;
        detail.whp = 0;
        ftpStore.addTestWell(detail, props.fptTestWell.wellId);
        setTestWellTable((nextTestWell:any) => [...nextTestWell, detail]);
    }

    const processRowUpdate = useCallback(
        async(newRow:any) => {
            const updatedRow = { ...newRow, isNew: false };
            setTestWellTable(testWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            ftpStore.setTestWell(testWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)), props.fptTestWell.wellId);
            return updatedRow;
        }, [ftpStore, props.fptTestWell.wellId, testWellTable]
    );

    function handleChangeEnthalphy(event:any) {
        setEnthalphy(event.target.value);
        ftpStore.setTestWellText(props.fptTestWell.wellId, event.target.value);
    }

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
            <b>{props.fptTestWell.wellName}</b>
            <Button variant='contained' color='primary' onClick={handleAddRow} sx={{ width: '10%' }}>Add</Button>
            <DataGridPremium 
                columns={gridColumns} 
                rows={testWellTable} 
                getRowId={(row) => row.sequence} 
                autoHeight 
                getRowHeight={() => 100}
                onProcessRowUpdateError={(e) => console.error(e)} 
                processRowUpdate={processRowUpdate} 
                // initialState={{
                // sorting: {
                //     sortModel: [{ field: 'sequence', sort: 'asc' }],
                // },
                // }}  
            /> 
            <TextField name="enthalphy" type="text" label="Enthalpy BTU/lb" onChange={handleChangeEnthalphy} value={enthalphy}  />
        </Box>
        
    );
}

export default observer(FPTTestWellGrid);