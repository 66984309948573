import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { IBrineConverterUnitTables } from '../../app/models/brine';
import { useStore } from '../../app/stores/store'
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridColDef, gridClasses, GridValueFormatterParams} from '@mui/x-data-grid-premium';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { brineFlowRateFormula, brineFlowRateKgsFormula, BrineFlowRateSpreadFormula, calculateBrineRsd, calculateStandardDeviationBrine, tempFFormula } from './helper/brine/formula';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { calculateAverageBrineResult, calculatePressure } from './helper/tftFormula';
import { saturationPressure } from './helper/steam/formula';
import { format } from 'date-fns';

function TFTProcessBrineStep3() {

    const { tftSteamStore } = useStore();

    const [ converterUnit, setConverterUnit ] = useState<IBrineConverterUnitTables[]>(tftSteamStore.tft.brine.converterUnit.table);

    const renderEditTimeStartInputCell: GridColDef['renderCell'] = (params) => {
        let timeDefault = params.value;
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label=""
                value={params.row.intervalStart}
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                mask='__:__'
                onChange={(newValue, date) => {
                    if(!isNaN(newValue)){
                        if(newValue === null){
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: timeDefault
                            });
                        }
                        else{
                            let newTime = new Date();
                            let splitTime = date?.split(':');
                            if(splitTime !== undefined){
                                newTime.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
                            }
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: newTime
                            });
                        }
                    }
                    else{
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: timeDefault
                        });
                    }
                }}
                renderInput={(props) => (
                    <TextField label="Date" sx={{ my: 1}} {...props} />
                )}
            />
        </LocalizationProvider>;
    };

    const renderEditTimeEndInputCell: GridColDef['renderCell'] = (params) => {
        let timeDefault = params.value;
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label=""
                value={params.row.intervalStart}
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                mask='__:__'
                onChange={(newValue, date) => {
                    if(!isNaN(newValue)){
                        if(newValue === null){
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: timeDefault
                            });
                        }
                        else{
                            let newTime = new Date();
                            let splitTime = date?.split(':');
                            if(splitTime !== undefined){
                                newTime.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
                            }
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: newTime
                            });
                        }
                    }
                    else{
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: timeDefault
                        });
                    }
                }}
                renderInput={(props) => (
                    <TextField label="Date" sx={{ my: 1}} {...props} />
                )}
            />
        </LocalizationProvider>;
    };

    const handleRowEditResult = useCallback((newValue: IBrineConverterUnitTables) => {  
    
    const data: IBrineConverterUnitTables[] = tftSteamStore.tft.brine.converterUnit.table.map(current => {
        if (current.sequence !== newValue.sequence) return current

        let ppbw = 0;

        if(tftSteamStore.tft.brine.sampleAnalysis.table[newValue.sequence - 1].result) {
            ppbw = tftSteamStore.tft.brine.sampleAnalysis.table[newValue.sequence - 1].result
        }

        const newTempF = tempFFormula(newValue.tempC);
        const newSatPress = saturationPressure(calculatePressure(newTempF), tftSteamStore.tft.baroPress);
        const newBrineFlowRateKph = brineFlowRateFormula(newValue.ppmwTracerInBrine, newValue.liquidtraceInjRate, tftSteamStore.tft.wtPTSASolin, tftSteamStore.tft.baselinePTSA);
        const newBrineFlowRateKgs = brineFlowRateKgsFormula(newBrineFlowRateKph);

        return ({
            sequence: newValue.sequence,
            labNumber: newValue.labNumber,
            intervalStart: newValue.intervalStart,
            intervalEnd: newValue.intervalEnd,
            tempC: newValue.tempC,
            pressBarg: newValue.pressBarg,
            tempF: newTempF,
            pressPsig: newValue.pressBarg,
            saturationPressure: newSatPress,
            liquidtraceInjRate: newValue.liquidtraceInjRate,
            ppmwTracerInBrine: newValue.ppmwTracerInBrine,
            brineFlowRateKph: newBrineFlowRateKph,
            ppbwTracerInBrine: ppbw,
            brineFlowRateKgs: newBrineFlowRateKgs
        })
    }) 
    setConverterUnit(data);
    
    return newValue  
    }, [tftSteamStore]);

    const columnConverterUnit: GridColDef[] = [
        { field: 'intervalStart', headerName: 'Interval Start', width: 200, type: "number", editable: true, align: "center", headerAlign: "center", headerClassName: 'tab-header',
            valueFormatter: (params: GridValueFormatterParams) =>{ return format(new Date(params.value), "HH:mm") }, 
            renderEditCell: renderEditTimeStartInputCell
        },
        { field: 'intervalEnd', headerName: 'Interval End', width: 200, type: "number", editable: true, align: "center", headerAlign: "center", headerClassName: 'tab-header',
            valueFormatter: (params: GridValueFormatterParams) =>{ return format(new Date(params.value), "HH:mm") },     
            renderEditCell: renderEditTimeEndInputCell
        },
        { field: 'tempC', headerName: 'Separator Temp, C', width: 200, type: "number", editable: true, groupable: false, align: "center", headerAlign: "center", headerClassName: 'tab-header',
            valueGetter: (params: any) => {
                if (params.value == null) {
                return null;
                }
        
                return params.value;
            },
        },
        { field: 'pressBarg', headerName: 'Press, barg', width: 200, type: "number", editable: true, align: "center", headerAlign: "center", headerClassName: 'tab-header',},
        { field: 'tempF', headerName: 'Separator Temp, F', width: 200, type: "number", groupable: false, align: "center", headerAlign: "center", headerClassName: 'tab-header',
            valueGetter: (params: any) => {
                if (params.value == null) {
                return null;
                }
        
                return params.value;
            },
        },
        { field: 'pressPsig', headerName: 'Press, psig', width: 200, type: "number", editable: true, align: "center", headerAlign: "center", headerClassName: 'tab-header',},
        { field: 'liquidtraceInjRate', headerName: 'Liquidtrace Inj Rate', width: 200, type: "number", align: "center", headerAlign: "center", editable: true, headerClassName: 'tab-header', },
        { field: 'ppmwTracerInBrine', headerName: 'Ppmw Tracer in Brine', width: 200, type: "number", align: "center", headerAlign: "center", editable: true, headerClassName: 'tab-header', },
        { field: 'ppbwTracerInBrine', headerName: 'Ppbw Tracer in Brine', width: 200, type: "number", align: "center", headerAlign: "center", cellClassName: 'disabled-cell', headerClassName: 'tab-header', },
        { field: 'brineFlowRateKgs', headerName: 'Brine Flow Rate Kgs', width: 200, type: "number", align: "center", headerAlign: "center", cellClassName: 'disabled-cell', headerClassName: 'tab-header', },
    ];

    useEffect(() => {
        tftSteamStore.setAvgSeparatorTempBrine(calculateAverageBrineResult(tftSteamStore.tft.brine.converterUnit.table, 'tempC'));
        tftSteamStore.setAvgPressPsigBrine(calculateAverageBrineResult(tftSteamStore.tft.brine.converterUnit.table, 'pressPsig'));
        tftSteamStore.setAvgSaturationPressureBrine(calculateAverageBrineResult(tftSteamStore.tft.brine.converterUnit.table, 'saturationPressure'));
        tftSteamStore.setAvgBrineFlowRate(calculateAverageBrineResult(tftSteamStore.tft.brine.converterUnit.table, 'brineFlowRateKph'));
        tftSteamStore.setBrineFlowRateSpread(BrineFlowRateSpreadFormula(tftSteamStore.tft.brine.converterUnit.table));
        tftSteamStore.setBrineStandardDeviation(calculateStandardDeviationBrine(tftSteamStore.tft.brine.converterUnit.table));
        tftSteamStore.setBrineRsd(calculateBrineRsd(calculateStandardDeviationBrine(tftSteamStore.tft.brine.converterUnit.table), tftSteamStore.tftAverages.brine.avgBrineFlowRate))
    }, [converterUnit, tftSteamStore, tftSteamStore.tft.baroPress]);

    useEffect(() => {
        tftSteamStore.setConverterUnitBrine(converterUnit);
    }, [converterUnit, tftSteamStore]);

  return (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2,
            '& .disabled-cell': {
                backgroundColor: '#e2e2e2',
                },
            '& .tab-header': {
                backgroundColor: '#D4E7C5',
                fontWeight: 'bold',
                },
        }}
    >   
        <DataGridPremium
            columns={columnConverterUnit}
            rows={converterUnit} 
            getRowId={(row) => row.sequence} 
            onProcessRowUpdateError={(e) => console.error(e)} 
            processRowUpdate={handleRowEditResult} 
            showCellVerticalBorder
            showColumnVerticalBorder
            initialState={{
            sorting: {
                sortModel: [{ field: 'sequence', sort: 'asc' }],
            },
            }}  
            sx={{
                [`& .${gridClasses.cell}`]: {
                    py: 2,
                    borderColor: '#000000'
                },
                borderColor: '#000000',
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#D4E7C5',
                    fontWeight: 'bold',
                    border: 1,
                    borderRight: 0,
                    borderTop: 1,
                    borderBottom: 0
                  },
                  "& .MuiDataGrid-cell": {
                  border: 1,
                  borderRight: 0,
                  borderTop: 1,
                  borderBottom: 0
                  // add more css for customization
                  },
            }}
            /> 
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '32%', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column' }}>
                <TextField
                    name="whtC"
                    label="WHT C"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={tftSteamStore.tft.wht}
                    onChange={(e) => tftSteamStore.setWhtC(Number(e.target.value))}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TextField
                    name="whp"
                    label="WHP, barg"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={tftSteamStore.tft.whp}
                    onChange={(e) => tftSteamStore.setWhpBarg(Number(e.target.value))}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TextField
                    name="controlValve"
                    label="Control Valve (%)"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={tftSteamStore.tft.controlValve}
                    onChange={(e) => tftSteamStore.setControlValveBrine(Number(e.target.value))}
                    sx={{ mb: 1, width: '100%' }}
                />
                <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none" }}>
                    <Table sx={{ minWidth: 250, border: 'none' }} size="small" aria-label="a dense table">
                        <TableBody>
                        <TableRow
                            key={1}
                            sx={{ "& td, th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align="left">Brine Port Location  :</TableCell>
                                <TableCell align="left">{tftSteamStore.tft.brinePortLocationId}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none", border: 0 }}>
                <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Average</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        key={1}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Separator Temp</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tftAverages.brine.avgSeparatorTemp}</TableCell>
                        </TableRow>
                        <TableRow
                        key={2}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Separator Press, Psig</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tftAverages.brine.avgSeparatorTemp}</TableCell>
                        </TableRow>
                        <TableRow
                        key={3}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Saturation Pressure Psig</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tftAverages.brine.avgSaturationPressure}</TableCell>
                        </TableRow>
                        <TableRow
                        key={4}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Brine Flow Rate, KPH</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tftAverages.brine.avgBrineFlowRate}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
            <Box sx={{ width: '65%', display: 'flex', justifyContent: 'start', alignItems: 'end', flexDirection: 'column' }}>
            <TableContainer component={Paper} sx={{ width: 500, boxShadow: "none" }}>
                <Table sx={{ width: 500, border: 'none' }} size="small" aria-label="a dense table">
                    <TableBody>
                    <TableRow
                        key={1}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Corrected Brine Flow Rate</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.correctionBrineFlowRate}</TableCell>
                        </TableRow>
                        <TableRow
                        key={2}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Steam Flow Rate Spread (KPH)</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tft.brine.converterUnit.brineFlowRateSpread}</TableCell>
                        </TableRow>
                        <TableRow
                        key={3}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">Standar Deviation</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tft.brine.converterUnit.standardDeviation}</TableCell>
                        </TableRow>
                        <TableRow
                        key={4}
                        sx={{ "& td, th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="right">RSD %</TableCell>
                            <TableCell align="left">:</TableCell>
                            <TableCell align="center">{tftSteamStore.tft.brine.converterUnit.rsd}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box> 
    </Box>
  )
}

export default observer(TFTProcessBrineStep3)