import { LoadingButton } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RkdCard from '../../../app/component/card/RkdCard'
import { IBrinePump } from '../../../app/models/brinePump'
import { useStore } from '../../../app/stores/store'

const initialValues: IBrinePump = {
    brinePumpName: '',
    description: ''
}

function BrinePumpForm() {

    const navigate = useNavigate();

    const { brinePumpStore, commonStore, brinePumpGridStore } = useStore();

    const [ brinePump, setBrinePump ] = useState<IBrinePump>(initialValues);

    const params = useParams();
    const [id] = useState(params.id);

    const onSubmit = async (values: IBrinePump) => {
        if (id) {
        await brinePumpStore.editBrinePump(id, values)
            .then(brinePumpGridStore.getBrinePumpList)
            .then(() => {
            navigate('/master/brine-pump');
            });
        } else {
        await brinePumpStore.createBrinePump(values)
            .then(brinePumpGridStore.getBrinePumpList)
            .then(() => {
            navigate('/master/brine-pump');
            });
        }
    };

    useEffect(() => {
        if (id)
        brinePumpStore.getBrinePumpDetail(id).then((res) => {
            if (res) {
            setBrinePump(res);
            }
        });
    }, [id, brinePumpStore, brinePumpStore.getBrinePumpDetail]);

    useEffect(() => {
        commonStore.setTitlePage('Brine Pump',);
    });
    
  return (
    <RkdCard title={id?'Edit Brine Pump':'New Brine Pump'}>
        <TextField
            label="Brine Pump Name"
            value={brinePump.brinePumpName}
            onChange={(e) => setBrinePump({ ...brinePump, brinePumpName: e.target.value })}
            required
            name="brinePumpName"
            sx={{ mb: 1, width: '30%' }}
        >
        </TextField>
        <TextField
            name="description"
            label="Description"
            required
            multiline
            rows={4}
            variant="outlined"
            sx={{ width: '30%' }}
            value={brinePump.description}
            onChange={(e) => setBrinePump({ ...brinePump, description: e.target.value })}
        />
        <Box gap={1} sx={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="outlined"
                color="info"
                sx={{ mr: '8px' }} 
                onClick={() => {
                navigate('/master/brine-pump/'); 
                }}>
                Cancel
            </Button>
            <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={brinePump.brinePumpName === '' || brinePump.description === ''}
                onClick={ () => onSubmit(brinePump) }
            >
                Save
            </LoadingButton>
        </Box>
    </RkdCard>
  )
}

export default observer(BrinePumpForm)