import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { IGroup, IVesselGroupOptions } from "../../../app/models/vesselGroup";
import RkdCard from "../../../app/component/card/RkdCard";
import { Box } from "@mui/system";
import { Autocomplete, Button, CircularProgress, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { VesselGroupSchemaZod, vesselGroupSchemaFormik } from "./schema/vesselSchemaZod";
import { LoadingButton } from "@mui/lab";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { IBrinePumpOptions } from "../../../app/models/brinePump";
import { ILocationOptions } from "../../../app/models/location";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

interface IProps {
  onSubmit: (values: VesselGroupSchemaZod) => Promise<unknown>;
  initialValues: VesselGroupSchemaZod;
  id?: string;
}

function VesselGroupForm(props: IProps) {
  const { onSubmit, initialValues, id } = props;
  const { objectStore, vesselGroupStore, brinePumpStore, locationStore } = useStore();
  const { getObjectOptionsByLocationId, getObjectOptions, loadingListOptions, listOptionsByLocationId } = objectStore;
  const navigate = useNavigate();

  const {
    handleSubmit: handleFormikSubmit,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
    touched,
    handleReset,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: vesselGroupSchemaFormik,
    enableReinitialize: true,
  });

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    setFieldValue("groups", groups);
    handleFormikSubmit(e);
  };
  const isUpdate = useMemo(() => Boolean(id), [id]);
  const [selectedObjectId, setSelectedObjectId] = useState<string>("");
  const [vesselIdsOption, setVesselIdsOption] = useState<Array<IVesselGroupOptions>>([]);
  const [locationOptions, setLocationOptions] = useState<ILocationOptions[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<TSelectedData | undefined>(undefined);
  const [brinePumpsOption, setBrinePumpsOption] = useState<Array<IBrinePumpOptions>>([]);
  const [groups, setGroups] = useState<Array<IGroup>>([]);
  const [validationObject, setValidationObject] = useState<boolean>(false);

  const gridColumns: GridColumns<IGroup> = [
    { field: "groupName", headerName: "Object", type: "string", flex: 1, headerAlign: "center", align: "center" },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              const temp = groups.filter((item) => item.id !== params.row.id);
              setGroups(temp);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    setGroups(initialValues.groups);
  }, [initialValues.groups]);

  useEffect(() => {
    setFieldValue("groups", groups);
  }, [groups, setFieldValue]);

  useEffect(() => {
    if (!values.locationId) return;

    getObjectOptionsByLocationId(values.locationId);
    getObjectOptions("Vessel", values.locationId).then((res) => setVesselIdsOption(res));
  }, [getObjectOptions, getObjectOptionsByLocationId, values.locationId]);

  useEffect(() => {
    brinePumpStore.getBrinePumpOptions("").then((res) => setBrinePumpsOption(res));
  }, [brinePumpStore]);

  useEffect(() => {
    let vesselGroupName = "";
    values.groups.forEach((item, idx) => {
      if (idx === 0) {
        vesselGroupName = vesselGroupName + item.groupName;
      } else {
        vesselGroupName = `${vesselGroupName}/${item.groupName}`;
      }
    });
    setFieldValue("vesselGroupName", vesselGroupName);
  }, [values.groups, setFieldValue]);

  const handleOnChangeObjectId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedObjectId(e.target.value);
  };

  const handleAddObject = () => {
    const found = listOptionsByLocationId.find((option) => option.value === selectedObjectId);
    const duplicate = groups.find((item) => item.id === selectedObjectId);
    if (found && !duplicate) {
      setGroups([
        ...groups,
        {
          id: found.value,
          groupName: found.text,
        },
      ]);
    } else {
      setValidationObject(true);
    }
  };

  useEffect(() => {
    locationStore.getLocationOptions("").then((res) => setLocationOptions(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (vesselGroupStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title="Vessel Group" subtitle={isUpdate ? "Edit" : "Add"}>
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiFormControl-root": { mb: 2 },
        }}
      >
        <Grid container spacing="12px">
          <Grid item xs={2}>
            <Autocomplete
              disablePortal
              fullWidth
              sx={{ marginBotom: 0 }}
              onChange={(e: any, val: any) => {
                setSelectedLocation(val);
                setFieldValue("locationId", val.value);
              }}
              disableClearable
              value={selectedLocation}
              getOptionLabel={(option) => option.label}
              options={locationOptions.map((item) => ({ label: item.text, value: item.value }))}
              renderInput={(params) => (
                <TextField
                  helperText={errors.locationId}
                  value={selectedLocation?.label}
                  name="locationId"
                  error={!!errors.locationId}
                  {...params}
                  label="Location"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Vessel"
              value={values.vesselId}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              name="vesselId"
              InputProps={{
                startAdornment: (loadingListOptions || vesselGroupStore.loadingForm) && (
                  <InputAdornment position="end">
                    <CircularProgress size={15} />
                  </InputAdornment>
                ),
              }}
            >
              {vesselIdsOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TextField
                select
                label="Well"
                value={selectedObjectId}
                onChange={handleOnChangeObjectId}
                onBlur={handleBlur}
                fullWidth
                name="objectIds"
                error={validationObject}
                helperText={validationObject ? "Please select other well" : ""}
                InputProps={{
                  startAdornment: loadingListOptions && (
                    <InputAdornment position="end">
                      <CircularProgress size={15} />
                    </InputAdornment>
                  ),
                }}
              >
                {listOptionsByLocationId.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
              <Button sx={{ width: "53px", height: "53px", mb: 2, ml: 1 }} variant="contained" color="primary" onClick={handleAddObject}>
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="vesselGroupName"
              label="Vessel Group Name"
              variant="outlined"
              fullWidth
              required
              disabled={values.vesselGroupName === ""}
              value={values.vesselGroupName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.vesselGroupName && !!errors.vesselGroupName}
              helperText={touched.vesselGroupName && errors.vesselGroupName}
            />
          </Grid>
        </Grid>

        <DataGrid rows={groups} columns={gridColumns} autoHeight sx={{ mb: 3 }} />

        <Box sx={{ display: "flex", gap: "24px" }}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Brine Pump</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={values.isBrinePump === true ? "yes" : "no"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("isBrinePump", (e.target as HTMLInputElement).value === "no" ? false : true);
                setFieldValue("brinePumpId", (e.target as HTMLInputElement).value === "no" ? null : values.brinePumpId);
              }}
            >
              <FormControlLabel value={"no"} control={<Radio />} label="No" />
              <FormControlLabel value={"yes"} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
          {values.isBrinePump && (
            <TextField
              select
              label="Brine Pump"
              value={values.brinePumpId}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              name="brinePumpId"
              sx={{ flex: 1 }}
            >
              {brinePumpsOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ mr: "8px" }}
            onClick={(e) => {
              handleReset(e);
              navigate("/master/vessel-group");
            }}
            onBlur={handleBlur}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" type="submit" disabled={!isValid} loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default observer(VesselGroupForm);
