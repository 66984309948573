import { useEffect } from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { useNavigate } from "react-router-dom";
import { IGeological } from "../../../app/models/geological";

function GeologicalDataGrid() {
  const { geologicalGridStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    geologicalGridStore.getGeologicalList();
  }, [geologicalGridStore]);

  const gridColumns: GridColumns<IGeological> = [
    { field: "wellName", headerName: "Well", flex: 1, type: "string", filterable: true, sortable: true },
    { field: "type", headerName: "Type", flex: 1, type: "string", filterable: true, sortable: true },
    {
      field: "modifiedDateUtc",
      headerName: "Modified Date",
      filterable: true,
      sortable: true,
      flex: 1,
      renderCell: (params) => format(new Date(params.row.modifiedDateUtc), "dd MMMM yyyy, hh:mm:ss"),
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/geological/edit/${params.row.id}`);
          }}
        />,
      ],
    },
  ];

  return <RkdDataGrid columns={gridColumns} gridStore={geologicalGridStore} />;
}

export default observer(GeologicalDataGrid);
