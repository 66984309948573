import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IObjectStatus } from "../models/objectStatus";

export default class ObjectStatusStore {
  loadingGrid = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  createObjectStatus = async (objectStatus: IObjectStatus) => {
    try {
      await agent.ObjectStatus.create(objectStatus);
    } catch (error) {
        throw error;
    }
  }

  getObjectStatusDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.ObjectStatus.detail(id);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingForm = false);
    }
  }
}