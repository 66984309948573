import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";

export const vesselGroupSchemaZod = z.object({
  vesselId: z.string({ required_error: "Vessel required" }),
  locationId: z.string({ required_error: "Location required" }),
  vesselGroupName: z.string({ required_error: "Vessel Group required" }),
  groups: z.array(
    z.object({
      id: z.string(),
      groupName: z.string(),
    })
  ),
  isBrinePump: z.boolean(),
  brinePumpId: z.string().nullable(),
});

export type VesselGroupSchemaZod = z.infer<typeof vesselGroupSchemaZod>;
export const vesselGroupSchemaFormik = toFormikValidationSchema(vesselGroupSchemaZod);
