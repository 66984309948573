import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { TGeneralGeological } from "./Lithology";
import { useStore } from "../../../../app/stores/store";
import { IGeoTempTableRequest } from "../../../../app/models/geological";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function MEB({
  selectedGeologicalType,
  deleteSequence,
  onDelete,
  data,
  setDeleteSequence,
  setDynamicConvertedData,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      depth: Number(row.depth),
      tvd: Number(row.tvd),
      rock: Number(row.rock),
      meb: Number(row.meb),
      mebIndex: Number(row.mebIndex),
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoTempTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        depth: Number(row.depth),
        tvd: Number(row.tvd),
        rock: Number(row.rock),
        meb: Number(row.meb),
        mebIndex: Number(row.mebIndex),
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    {
      field: "depth",
      headerName: "Depth (Meter RKB)",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      headerClassName: "tab-header",
    },
    {
      field: "tvd",
      headerName: "TVD (Meter RKB)",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "rock",
      headerName: "Rock (gr)",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "meb",
      headerName: "MeB (ml)",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mebIndex",
      headerName: "MeB Index (ml/gr)",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      autoHeight
      processRowUpdate={handleRowEdit}
      showCellVerticalBorder
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      showColumnVerticalBorder
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
    />
  );
}

export default observer(MEB);
