import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";

export default class PtsGridStore extends DataGridStore{
    constructor() {
        super();
  
        makeObservable(this, {
            getPtsList: action,
        });
    }
    
    getPtsList = async () => {
      this.loadingGrid = true;
      try {
          const urlParams = this.createDataGridParam();
          const result = await agent.PTS.listGrid(urlParams);
          result.data.forEach(row => {
              row.ptsDate = new Date(row.ptsDate + "Z");
          });
          this.setDataGridResult(result);
      } catch (error) {
          throw error;
      } finally {
          runInAction(() => this.loadingGrid = false);
      }
    }

    updateGridCallback = action(() => {
        this.getPtsList();
    })
}