import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Collapse, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DownloadOutlined, ExpandLess, ExpandMore, UploadOutlined } from "@mui/icons-material";
import DynamicBiweeklyDataGrid from "./components/DynamicBiweeklyDataGrid";
import { BIWEEKLY_DATA } from "../../../app/config/enum";
import { format } from "date-fns";

function BiweeklyDataPageCreate() {
  const { biweeklyStore } = useStore();
  const [date, setDate] = useState<Date>(new Date());
  const [isOpenList, setIsOpenList] = useState<string[]>([]);
  const navigate = useNavigate();
  const hiddenFileInput = useRef<any>(null);
  const { dateDetail } = useParams();

  // Biweekly page report
  // charts: [{
  //   CI: {data: [{x: , y: }], label: 'Well1', display: true, position: 'left'},
  //   silica: {data: [{x: , y: }], label: 'Well1', display: true, position: 'left'},
  //   phBrine: {data: [{x: , y: }], label: 'Well1', display: true, position: 'right'},
  //   phSteam: {data: [{x: , y: }], label: 'Well1',display: true, position: 'right'},
  //   ECBrine: {data: [{x: , y: }], label: 'Well1',display: true, position: 'right'}
  //   NCG: {data: [{x: , y: }], label: 'Well1',display: true, position: 'right'}
  // }]

  const biweeklyDataGridList = [
    {
      title: "Separator",
      proposal: <DynamicBiweeklyDataGrid date={date} identifier={BIWEEKLY_DATA.Separators} isSeparator />,
    },
    {
      title: "Injection Well",
      proposal: <DynamicBiweeklyDataGrid date={date} identifier={BIWEEKLY_DATA.Injections} isSeparator={false} />,
    },
    {
      title: "Production Well",
      proposal: <DynamicBiweeklyDataGrid date={date} identifier={BIWEEKLY_DATA.Productions} isSeparator={false} />,
    },
  ];

  const onSubmit = async () => {
    if (biweeklyStore.biweeklyData) {
      biweeklyStore.addBiweekly({ ...biweeklyStore.biweeklyData, date }).then(() => navigate("/geochemistry-biweekly"));
    } else {
      navigate("/geochemistry-biweekly");
    }
  };

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("date", new Date(date).toISOString());
      formData.append("file", e.target.files[0]);

      biweeklyStore.uploadBiweeklyTemplate(formData).then((res) => {
        let payloadTemp = { ...res.data, date: date };
        biweeklyStore.setBiWeeklyData(payloadTemp);
      });
    }
    e.target.value = "";
  };

  const downloadTempalte = () => biweeklyStore.getBiweeklyTemplate(new Date(date).toISOString());

  useEffect(() => {
    let dateTemp = dateDetail ?? date;
    biweeklyStore.getBiweekly(format(new Date(dateTemp), "yyyy-MM-dd"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Biweekly Data" subtitle="Add Edit & Delete Biweekly Data from this Page">
          <Grid container spacing="6px">
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={dateDetail ?? date}
                  disabled={!!dateDetail || biweeklyStore.loading}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => setDate(e)}
                  renderInput={(props) => <TextField required fullWidth name="endDate" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={10} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={biweeklyStore.loadingDownloadTemplate}
                  sx={{ height: "68%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  disabled={!!dateDetail || biweeklyStore.loading}
                  startIcon={<DownloadOutlined />}
                  onClick={() => downloadTempalte()}
                >
                  Download Template
                </LoadingButton>
              </Box>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={biweeklyStore.loadingUploadTemplate}
                  sx={{ height: "68%", whiteSpace: "nowrap" }}
                  disabled={!!dateDetail || biweeklyStore.loading}
                  component="label"
                  variant="contained"
                  startIcon={<UploadOutlined />}
                >
                  Upload Template
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
                  />
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          {biweeklyStore.loading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box sx={{ mt: "24px" }}>
                {biweeklyDataGridList.map((item) => (
                  <Box
                    sx={{
                      p: "16px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid #DEDEDE",
                      background: "#FFF",
                      mb: "12px",
                    }}
                  >
                    <Box
                      onClick={() => {
                        let temp = [...isOpenList];
                        if (temp.includes(item.title)) {
                          temp = isOpenList.filter((isOpenItem) => isOpenItem !== item.title);
                        } else {
                          temp.push(item.title);
                        }
                        setIsOpenList(temp);
                      }}
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                      <Box>
                        <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                        <Box sx={{ fontSize: "12px" }}>Data Grid Detail</Box>
                      </Box>
                      <Box>{isOpenList.includes(item.title) ? <ExpandLess /> : <ExpandMore />}</Box>
                    </Box>
                    <Collapse sx={{ mt: "24px" }} in={isOpenList.includes(item.title)} timeout="auto" unmountOnExit>
                      {item.proposal}
                    </Collapse>
                  </Box>
                ))}
              </Box>

              <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
                <Box>
                  <Button disabled={biweeklyStore.loadingAdd} variant="contained" color="error" onClick={() => navigate("/geochemistry-biweekly")}>
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <LoadingButton loading={biweeklyStore.loadingAdd} onClick={() => onSubmit()} type="submit" variant="contained" color="primary">
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          )}
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BiweeklyDataPageCreate);
