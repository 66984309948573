import { useEffect } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BrineEcolabPageCreateGrid from "./BrineEcolabPageCreateGrid";

function BrineEcolabPageCreate() {
  const { brineORCMonitoringStore } = useStore();
  const navigate = useNavigate();
  const { date } = useParams();

  const onSubmit = async () => {
    let payload = {
      date: date ?? "",
      table: brineORCMonitoringStore.brineEcolabDetail,
    };

    brineORCMonitoringStore.addEditBrineEcolab(payload).then(() => {
      navigate("/geochemistry-brine");
    });
  };

  useEffect(() => {
    // if (!date) return;

    brineORCMonitoringStore.getBrineEcolab(date ?? "");
  }, [brineORCMonitoringStore, date]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Brine ORC Ecolab" subtitle="Add Edit & Delete Brine ORC Ecolab Data from this Page">
          <Grid container>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={date}
                  disabled
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => undefined}
                  renderInput={(props) => <TextField required fullWidth name="date" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <BrineEcolabPageCreateGrid />
          <Box>
            <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
              <Box>
                <Button
                  disabled={brineORCMonitoringStore.brineOrcAdEditLoading}
                  variant="contained"
                  color="error"
                  onClick={() => navigate("/geochemistry-brine")}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  loading={brineORCMonitoringStore.brineOrcAdEditLoading}
                  onClick={onSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BrineEcolabPageCreate);
