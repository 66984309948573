import { TStepProps } from "../utils/type";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Step2Grid from "./Step2Grid";
import { comapareOptions, selectionsOptions, zAxisOptions } from "../utils/utils";
import Step2LineChart from "./Step2LineChart";

export default function Step2({ values, setSelectedWell, selectedWell, setFieldValue, wellOptions, errors, handleChange }: TStepProps) {
  const generateWarnings = () => {
    if (!values.step1?.whp) {
      setFieldValue("step2.dataReported", "WHP during test not reported In Orig.Data");
    } else {
      if (values.step2?.limit?.limitP < values.step1?.whp) setFieldValue("step2.warning.dataReported", "WHP Lower In Orig.Data");
      if (values.step2?.limit?.limitP > values.step1?.whp) setFieldValue("step2.warning.dataReported", "WHP Higher In Orig.Data");
      if (values.step2?.limit?.limitP === values.step1?.whp) setFieldValue("step2.warning.dataReported", "OK");
    }

    if (values.step2?.limit?.limitWhpVariable === values.step1?.whp) {
      setFieldValue("step2.warning.whpVariability", "OK");
    } else {
      setFieldValue("step2.warning.whpVariability", "Not OK");
    }
  };

  return (
    <Grid container spacing="12px">
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Z-Axis</InputLabel>
          <Select value={values.step2?.zAxis} name="step2.zAxis" label="Z-Axis" onChange={handleChange}>
            {zAxisOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Compare</InputLabel>
          <Select value={values.step2?.compareField} name="step2.compareField" label="Compare" onChange={handleChange}>
            {comapareOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Selection A</InputLabel>
          <Select value={values.step2?.selectionA} name="step2.selectionA" label="Selection A" onChange={handleChange}>
            {selectionsOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Selection B</InputLabel>
          <Select value={values.step2?.selectionB} name="step2.selectionB" label="Selection B" onChange={handleChange}>
            {selectionsOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="WHP"
          sx={{ width: "180px" }}
          required
          type="number"
          name="step1.whp"
          value={values.step1?.whp}
          error={!!errors.step1?.whp}
          helperText={errors.step1?.whp}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Step2Grid
          values={values}
          setFieldValue={setFieldValue}
          selectionALabel={values.step2?.selectionA}
          selectionBLabel={values.step2?.selectionB}
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: "24px" }}>
        <Typography variant="h6" sx={{ my: "12px", textAlign: "center", fontWeight: "bold" }}>
          Graph
        </Typography>
        <Step2LineChart values={values} />
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ border: "1.5px solid #EDEFF1", p: "12px", borderRadius: "4px", height: "370px" }}>
          <Box sx={{ mb: "12px", fontSize: "16px", fontWeight: "bold" }}>Limits for Warning (in DB_Units)</Box>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <TextField
                label="dP/dz"
                type="number"
                fullWidth
                name="step2.limit.limitDpDz"
                value={values.step2?.limit?.limitDpDz}
                error={!!errors.step2?.limit?.limitDpDz}
                helperText={errors.step2?.limit?.limitDpDz}
                onChange={handleChange}
              />
              <TextField
                label="dT/dz"
                type="number"
                fullWidth
                name="step2.limit.limitDtDz"
                value={values.step2?.limit?.limitDtDz}
                error={!!errors.step2?.limit?.limitDtDz}
                helperText={errors.step2?.limit?.limitDtDz}
                onChange={handleChange}
              />
              <TextField
                label="P"
                type="number"
                fullWidth
                name="step2.limit.limitP"
                value={values.step2?.limit?.limitP}
                error={!!errors.step2?.limit?.limitP}
                helperText={errors.step2?.limit?.limitP}
                onChange={handleChange}
              />
              <TextField
                label="T"
                type="number"
                fullWidth
                name="step2.limit.limitT"
                value={values.step2?.limit?.limitT}
                error={!!errors.step2?.limit?.limitT}
                helperText={errors.step2?.limit?.limitT}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <TextField
                label="WHP Consis"
                type="number"
                fullWidth
                name="step2.limit.limitWhpConsist"
                value={values.step2?.limit?.limitWhpConsist}
                error={!!errors.step2?.limit?.limitWhpConsist}
                helperText={errors.step2?.limit?.limitWhpConsist}
                onChange={handleChange}
              />
              <TextField
                label="WHP Indicator"
                type="number"
                fullWidth
                name="step2.limit.limitWhpIndicator"
                value={values.step2?.limit?.limitWhpIndicator}
                error={!!errors.step2?.limit?.limitWhpIndicator}
                helperText={errors.step2?.limit?.limitWhpIndicator}
                onChange={handleChange}
              />
              <TextField
                label="WHP Variable"
                type="number"
                fullWidth
                name="step2.limit.limitWhpVariable"
                value={values.step2?.limit?.limitWhpVariable}
                error={!!errors.step2?.limit?.limitWhpVariable}
                helperText={errors.step2?.limit?.limitWhpVariable}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box sx={{ mt: "24px", display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={generateWarnings}>
              Process
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ border: "1.5px solid #EDEFF1", p: "12px", borderRadius: "4px", height: "370px" }}>
          <Box sx={{ mb: "12px", fontSize: "16px", fontWeight: "bold" }}>Warnings: </Box>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", flex: 1 }}>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>Data Concistency</Box>
                <Box sx={{ color: values.step2?.warning?.dataConcistency === "OK" ? "green" : "red" }}>{values.step2?.warning?.dataConcistency}</Box>
              </Box>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>Data Reported</Box>
                <Box sx={{ color: values.step2?.warning?.dataReported === "OK" ? "green" : "red" }}>{values.step2?.warning?.dataReported}</Box>
              </Box>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>Report Concistency</Box>
                <Box sx={{ color: values.step2?.warning?.reportConcistency === "OK" ? "green" : "red" }}>
                  {values.step2?.warning?.reportConcistency}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", flex: 1 }}>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>WHP Variability</Box>
                <Box sx={{ color: values.step2?.whpVariability === "OK" ? "green" : "red" }}>{values.step2?.warning?.whpVariability}</Box>
              </Box>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>Measure at Depth = 0</Box>
                <Box sx={{ color: values.step2?.warning?.measureAtDepth0 === "OK" ? "green" : "red" }}>{values.step2?.warning?.measureAtDepth0}</Box>
              </Box>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>WHP Concistency</Box>
                <Box sx={{ color: values.step2?.warning?.whpConcistency === "OK" ? "green" : "red" }}>{values.step2?.warning?.whpConcistency}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
