import * as yup from "yup";

export const objectSchema = yup.object({
  objectTypeId: yup.string().required("Object type required"),
  objectName: yup.string().required("Object name required"),
  wellSite: yup.string().required("Well Site required"),
  rigName: yup.string().required("Rig name required"),
  drillingSpudDate: yup.date().required("Date required"),
  drillingCompletedDate: yup.date().required("Date required"),
  cellarEvalation: yup.string().required("Cellar Evalation required"),
  rkbHeight: yup.number().required("RKB height required"),
  totalDepth: yup.number().required("MD required"),
  isActive: yup.boolean().default(true),
  latitude: yup.number().when("objectTypeId", {
    is: "1",
    then: yup.number().required("Latitude required"),
  }),
  longitude: yup.number().when("objectTypeId", {
    is: "1",
    then: yup.number().required("Longitude required"),
  }),
  inclinasi: yup.number().when("objectTypeId", {
    is: "1",
    then: yup.number().required("Inclinasi required"),
  }),
  azimuth: yup.number().when("objectTypeId", {
    is: "1",
    then: yup.number().required("Azimuth required"),
  }),
  srNo: yup.string().required("SR No. required"),
  locationId: yup.string().required("Location required"),
  remarks: yup.string().required("Remarks required"),
});

export type ObjectSchema = yup.InferType<typeof objectSchema>;
