import { TStepProps } from "../utils/type";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Step3Grid from "./Step3Grid";
import { selectionsOptions, zAxisOptions } from "../utils/utils";
import Step3LineChart from "./Step3LineChart";

export default function Step3({ values, setFieldValue, errors, handleChange }: TStepProps) {
  const { step3 } = values;
  const { zAxis, selectionP, selectionT, phaseDistributor, liqGradientComp, gradientCheck } = step3 ?? {};
  const { gasLevel, twoPhaseBottom, waterLevel, remarks } = phaseDistributor ?? {};

  return (
    <Grid container spacing="12px">
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Z-Axis</InputLabel>
          <Select value={zAxis} name="step3.zAxis" label="Z-Axis" onChange={handleChange}>
            {zAxisOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Selection P</InputLabel>
          <Select value={selectionP} name="step3.selectionP" label="Selection P" onChange={handleChange}>
            {selectionsOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel>Selection T</InputLabel>
          <Select value={selectionT} name="step3.selectionT" label="Selection T" onChange={handleChange}>
            {selectionsOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="Gradient Check"
          fullWidth
          type="number"
          name="step3.gradientCheck"
          value={gradientCheck}
          error={!!errors.step3?.gradientCheck}
          helperText={errors.step3?.gradientCheck}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="Liq Gradient Comp"
          fullWidth
          type="number"
          name="step3.liqGradientComp"
          value={liqGradientComp}
          error={!!errors.step3?.liqGradientComp}
          helperText={errors.step3?.liqGradientComp}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Step3Grid values={values} setFieldValue={setFieldValue} selectionPLabel={selectionP} selectionTLabel={selectionT} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textAlign: "center", my: "12px", fontWeight: "bold" }}>
          Graph
        </Typography>
        <Step3LineChart values={values} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ border: "1.5px solid #EDEFF1", p: "12px", borderRadius: "4px" }}>
          <Box sx={{ mb: "12px", fontSize: "16px", fontWeight: "bold" }}>Phase Distribution</Box>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <TextField
              label="Gas Level"
              type="number"
              fullWidth
              name="step3.phaseDistributor.gasLevel"
              value={gasLevel}
              error={!!errors.step3?.phaseDistributor?.gasLevel}
              helperText={errors.step3?.phaseDistributor?.gasLevel}
              onChange={handleChange}
            />
            <TextField
              label="2-Phase Bottom"
              type="number"
              fullWidth
              name="step3.phaseDistributor.twoPhaseBottom"
              value={twoPhaseBottom}
              error={!!errors.step3?.phaseDistributor?.twoPhaseBottom}
              helperText={errors.step3?.phaseDistributor?.twoPhaseBottom}
              onChange={handleChange}
            />
            <TextField
              label="Water Level"
              type="number"
              fullWidth
              name="step3.phaseDistributor.waterLevel"
              value={waterLevel}
              error={!!errors.step3?.phaseDistributor?.waterLevel}
              helperText={errors.step3?.phaseDistributor?.waterLevel}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ mt: "12px" }}>
            <TextField
              label="Remarks"
              fullWidth
              multiline
              rows={3}
              name="step3.phaseDistributor.remarks"
              value={remarks}
              error={!!errors.step3?.phaseDistributor?.remarks}
              helperText={errors.step3?.phaseDistributor?.remarks}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
