import { toFormikValidationSchema } from "zod-formik-adapter"
import { z } from "zod"

export const connectionSchemaZod = z.object({
  connectionName: z.string({ required_error: 'Connection Name required' }),
  groups: z.array(
    z.object({
      id: z.string(),
      groupName: z.string(),
      objects: z.array(
        z.object({
          id: z.string(),
          groupName: z.string()
        })
      )
    })
  )
})

export type ConnectionSchemaZod = z.infer<typeof connectionSchemaZod>
export const connectionSchemaFormik = toFormikValidationSchema(connectionSchemaZod);