import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { IConnection } from "../../../app/models/connection";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";

function VesselGroupGrid() {
  const navigate = useNavigate();
  const { vesselGroupStore } = useStore();

  useEffect(() => {
    vesselGroupStore.getVesselGroupList();
  }, [vesselGroupStore]);

  const gridColumns: GridColumns<IConnection> = [
    {
      field: "vesselGroupName",
      headerName: "Vessel Group Name",
      width: 170,
      type: "string",
      filterable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "brinePumpName",
      headerName: "Brine Pump",
      width: 170,
      type: "string",
      filterable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            color="info"
            label="View"
            onClick={() => {
              navigate(`edit/${params.id}`);
            }}
          />,
        ];
      },
    },
  ];

  return <RkdDataGrid columns={gridColumns} gridStore={vesselGroupStore} />;
}

export default observer(VesselGroupGrid);
