import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { MenuProps } from "../../geological/new/GeologicalPageCreate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import BiweeklyChart from "./components/BiweeklyChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function BiweeklyDataReport() {
  const { commonStore, biweeklyStore } = useStore();
  const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
  const [selectedMultipleLeftYAxis, setSelectedMultipleLeftYAxis] = useState<string[]>([]);
  const [selectedMultipleRightYAxis, setSelectedMultipleRightYAxis] = useState<string[]>([]);
  const [displayLeftYAxis, setDisplayLeftYAxis] = useState<string[]>([]);
  const [displayRightYAxis, setDisplayRightYAxis] = useState<string[]>([]);
  const [displaySelectedSelector, setDisplaySelectedSelector] = useState<string[]>([]);
  const [selectedMultipleSelector, setSelectedMultipleSelector] = useState<string[]>([]);
  const [value, setValue] = useState("1");

  const onChangeDate = (newDate: Date, identifier: string) => {
    let temp = [...date];

    if (identifier === "startDate") temp[0] = newDate;
    if (identifier === "endDate") temp[1] = newDate;

    setDate(temp);
  };

  const isAllLeftYAxisSelected =
    biweeklyStore.biweeklyLeftYAxis.length > 0 && selectedMultipleLeftYAxis.length === biweeklyStore.biweeklyLeftYAxis.length;
  const isAllRightYAxisSelected =
    biweeklyStore.biweeklyRightYAxis.length > 0 && selectedMultipleRightYAxis.length === biweeklyStore.biweeklyRightYAxis.length;
  const isAllSelectorSelected =
    biweeklyStore.biweeklySelector.length > 0 && selectedMultipleSelector.length === biweeklyStore.biweeklySelector.length;

  const handleSelectSelector = async (event: SelectChangeEvent<typeof selectedMultipleLeftYAxis>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    if (identifier === "left") {
      if (value[value.length - 1] === "all") {
        setSelectedMultipleLeftYAxis(
          selectedMultipleLeftYAxis.length === biweeklyStore.biweeklyLeftYAxis.length ? [] : biweeklyStore.biweeklyLeftYAxis.map((item) => item.value)
        );
        setDisplayLeftYAxis(biweeklyStore.biweeklyLeftYAxis.map((item) => item.text));
        return;
      }

      biweeklyStore.biweeklyLeftYAxis.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplayLeftYAxis(result);
      setSelectedMultipleLeftYAxis(typeof value === "string" ? value.split(",") : value);
    } else if (identifier === "right") {
      if (value[value.length - 1] === "all") {
        setSelectedMultipleRightYAxis(
          selectedMultipleRightYAxis.length === biweeklyStore.biweeklyRightYAxis.length
            ? []
            : biweeklyStore.biweeklyRightYAxis.map((item) => item.value)
        );
        setDisplayRightYAxis(biweeklyStore.biweeklyRightYAxis.map((item) => item.text));
        return;
      }

      biweeklyStore.biweeklyRightYAxis.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplayRightYAxis(result);
      setSelectedMultipleRightYAxis(typeof value === "string" ? value.split(",") : value);
    } else {
      if (value[value.length - 1] === "all") {
        setSelectedMultipleSelector(
          selectedMultipleSelector.length === biweeklyStore.biweeklySelector.length ? [] : biweeklyStore.biweeklySelector.map((item) => item.objectId)
        );
        setDisplaySelectedSelector(biweeklyStore.biweeklySelector.map((item) => item.objectName));
        return;
      }

      biweeklyStore.biweeklySelector.forEach((val) => value.includes(val.objectId) && result.push(val.objectName));
      setDisplaySelectedSelector(result);
      setSelectedMultipleSelector(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  useEffect(() => {
    commonStore.setTitlePage("Biweekly Database Report");
    biweeklyStore.getBiweeklyChartSelector();
    biweeklyStore.getBiweeklyLeftYAxis();
    biweeklyStore.getBiweeklyRightYAxis();
  }, []);

  useEffect(() => {
    if (!selectedMultipleLeftYAxis.length) return;
    let tempStartDate = format(new Date(date[0]), "yyyy-MM-dd");
    let tempEndDate = format(new Date(date[1]), "yyyy-MM-dd");

    let payload = {
      startDate: tempStartDate,
      endDate: tempEndDate,
      leftYAxis: selectedMultipleLeftYAxis.map((item, index) => ({
        value: item,
        text: displayLeftYAxis[index],
      })),
      rightYAxis: selectedMultipleRightYAxis.map((item, index) => ({
        value: item,
        text: displayRightYAxis[index],
      })),
      objects: selectedMultipleSelector.map((item, index) => ({ objectId: item, objectName: displayLeftYAxis[index] })),
    };

    biweeklyStore.getBiweeklyChart(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedMultipleRightYAxis, selectedMultipleLeftYAxis]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Biweekly Database Report" subtitle="Biweekly Database Report View">
          <Grid container spacing="6px">
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={date[0]}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => onChangeDate(e, "startDate")}
                  renderInput={(props) => <TextField required fullWidth name="startDate" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={date[1]}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => onChangeDate(e, "endDate")}
                  renderInput={(props) => <TextField required fullWidth name="endDate" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Well & Separator</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleSelector}
                  onChange={(e) => handleSelectSelector(e, "well")}
                  input={<OutlinedInput label="Well & Separator" />}
                  renderValue={() => displaySelectedSelector.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelectorSelected}
                      indeterminate={selectedMultipleSelector.length > 0 && selectedMultipleSelector.length < biweeklyStore.biweeklySelector.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {biweeklyStore.biweeklySelector.map((item, index) => (
                    <MenuItem key={index} value={item.objectId}>
                      <Checkbox checked={selectedMultipleSelector.indexOf(item.objectId) > -1} />
                      <ListItemText primary={item.objectName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2.5}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Left YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleLeftYAxis}
                  onChange={(e) => handleSelectSelector(e, "left")}
                  input={<OutlinedInput label="Left YAxes" />}
                  renderValue={() => displayLeftYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllLeftYAxisSelected}
                      indeterminate={
                        selectedMultipleLeftYAxis.length > 0 && selectedMultipleLeftYAxis.length < biweeklyStore.biweeklyLeftYAxis.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {biweeklyStore.biweeklyLeftYAxis.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleLeftYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Right YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleRightYAxis}
                  onChange={(e) => handleSelectSelector(e, "right")}
                  input={<OutlinedInput label="Right YAxes" />}
                  renderValue={() => displayRightYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllRightYAxisSelected}
                      indeterminate={
                        selectedMultipleRightYAxis.length > 0 && selectedMultipleRightYAxis.length < biweeklyStore.biweeklyRightYAxis.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {biweeklyStore.biweeklyRightYAxis.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleRightYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            {!!selectedMultipleSelector.length ? (
              <Box>
                <Divider sx={{ mt: "6px", mb: "24px" }} />
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  Chart Reports
                </Typography>
                <Box sx={{ width: "100%", typography: "body1", mb: "24px", mt: "12px" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {displaySelectedSelector?.map((item: string, index: number) => (
                          <Tab label={item} value={(index + 1).toString()} />
                        ))}
                      </TabList>
                    </Box>
                    {biweeklyStore?.biweeklyCharts.map((item: any, index: number) => (
                      <TabPanel sx={{ color: "black" }} value={(index + 1).toString()}>
                        <BiweeklyChart biweeklyChart={item} />
                      </TabPanel>
                    ))}
                  </TabContext>
                </Box>
              </Box>
            ) : undefined}
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BiweeklyDataReport);
