import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import TFTProcessBrineStep1 from './TFTProcessBrineStep1';
import TFTProcessBrineStep2 from './TFTProcessBrineStep2';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TFTStepper from './TFTStepper';
import TFTProcessBrineStep3 from './TFTProcessBrineStep3';

const steps = ['Lt Analysis', 'Sample Analysis', 'TFT Brine Result'];
function TFTFormBrine() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <>
      <TFTStepper activeStep={activeStep} steps={steps} />
      <>
        {activeStep === 0 ? (
          <TFTProcessBrineStep1 />
        ) : activeStep === 1 ? (
          <TFTProcessBrineStep2 />
        ) : (
          <TFTProcessBrineStep3 />
        )}
        
        <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'space-between', width: '180px', my: 2 }}>
          <Button
            color="inherit"
            variant='contained'
            startIcon={<KeyboardArrowLeftIcon />}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {
            activeStep !== steps.length - 1 &&
            <Button 
              onClick={handleNext}
              variant='contained'
              endIcon={<KeyboardArrowRightIcon />}
              disabled={activeStep === steps.length - 1 }
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          }
          
        </Box>
      </>
    </>
  );
}

export default TFTFormBrine;