import { action, makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import { IPtsRequest, Pts, TPTSData } from "../models/pts";
import agent from "../api/agent";
import { IPtsChartRequest } from "../models/ptsChart";
import { format } from "date-fns";
import { IDeleteData } from "../models/account";

export default class PtsStore extends DataGridStore {
  loadingDelete = false;
  loadingForm = false;
  loadingListOptions = false;
  loadingDownloadFile = false;
  loadingCreateUpdate = false;

  selectedPTS: TPTSData | null = null;

  pts = new Pts();

  constructor() {
    super();

    makeObservable(this, {
      loadingCreateUpdate: observable,
      loadingDownloadFile: observable,
      loadingDelete: observable,
      loadingForm: observable,
      loadingListOptions: observable,
      selectedPTS: observable,
      setSelectedPTS: action,
      createPts: action,
      getSelectorsOptions: action,
      chartPts: action,
      getDetailPTS: action,
      addPTS: action,
      clearSelectedPTS: action,
    });
  }

  chartPts = async (pts: IPtsChartRequest) => {
    try {
      return await agent.PTS.chart(pts);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingListOptions = false));
    }
  };

  getSelectorsOptions = async (search: string) => {
    this.loadingListOptions = true;

    try {
      const params = new URLSearchParams({
        search,
      });
      return await agent.PTS.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingListOptions = false));
    }
  };

  getPts = async (objectId: string, date: string) => {
    this.loadingForm = true;
    try {
      const result = await agent.PTS.detail(objectId, date);
      result.ptsDate = new Date(result.ptsDate + "Z");
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  getDetailPTS = async (ptsId: string) => {
    this.loadingForm = true;

    try {
      const result = await agent.PTS.getDetailPTS(ptsId);

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deletePts = async (id: string) => {
    this.loadingDelete = true;
    try {
      await agent.PTS.delete(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  createPts = async (pts: IPtsRequest) => {
    this.loadingCreateUpdate = true;

    try {
      await agent.PTS.create(pts);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingCreateUpdate = false));
    }
  };

  addPTS = async (body: any) => {
    this.loadingCreateUpdate = true;

    try {
      await agent.PTS.addPTS(body);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingCreateUpdate = false));
    }
  };

  editPTS = async (body: any, ptsId: string) => {
    try {
      await agent.PTS.editPTS(body, ptsId);
    } catch (error) {
      throw error;
    }
  };

  getPtsList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.PTS.listGrid(urlParams);
      result.data.forEach((row) => {
        row.ptsDate = new Date(row.ptsDate + "Z");
      });
      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getPTSFile = async (id: string, fileUrl: string) => {
    this.loadingDownloadFile = true;

    try {
      await agent.PTS.getPTSFile(id, fileUrl);
      // return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadFile = false));
    }
  };

  deletePTS = async (ids: IDeleteData) => {
    this.loadingDelete = true;
    try {
      await agent.PTS.deletePTS(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  setSelectedPTS = (data: TPTSData | null) => {
    this.selectedPTS = data;
  };

  clearSelectedPTS = () => {
    this.selectedPTS = null;
  };
}
