import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import DialogStore from "./helper/dialogStore";
import ExampleStore from "./exampleStore";
import ModalStore from "./helper/modalStore";
import SnackbarStore from "./helper/snackbarStore";
import UserStore from "./userStore";
import ObjectTypeStore from "./objectTypeStore";
import ObjectStore from "./objectStore";
import WellGeometryStore from "./wellGeometryStore";
import ObjectTypeGridStore from "./objectTypeGridStore";
import ObjectGridStore from "./objectGridStore";
import ConnectionStore from "./connectionStore";
import WellGeometryGridStore from "./wellGeometryGridStore";
import VesselGroupStore from "./vesselGroupStore";
import TftSteamStore from "./tftSteamStore";
import PortLocationStore from "./portLocationStore";
import TftGridStore from "./tftGridStore";
import PtsStore from "./ptsStore";
import ObjectStatusStore from "./objectStatusStore";
import ObjectStatusGridStore from "./objectStatusGridStore";
import LocationGridStore from "./locationGridStore";
import LocationStore from "./locationStore";
import BrinePumpStore from "./brinePumpStore";
import BrinePumpGridStore from "./brinePumpGridStore";
import DecimationStore from "./decimationStore";
import DecimationGridStore from "./decimationGridStore";
import FptStore from "./fptStore";
import FptGridStore from "./fptGridStore";
import GeologicalGridStore from "./geologicalGridStore";
import GeologicalStore from "./geologicalStore";
import PtsGridStore from "./ptsGridStore";
import GeologicalMebGridStore from "./geologicalMebGridStore";
import GeophysicsGravityGridStore from "./geophysicsGravityGridStore";
import GeophysicsMTGridStore from "./geophysicsMTGridStore";
import GeologicalAddGridStore from "./geologicalAddGridStore";
import GeophysicsGravityDataConversionGridStore from "./geophysicsGravityDataConversionGridStore";
import BiweeklyStore from "./biweeklyStore";
import BiweeklyGridStore from "./biweeklyGridStore";
import OrcGridStore from "./orc";
import CleanOutHistory from "./cleanOutHistory";
import OrcGroupGridStore from "./orcGroup";
import BrineORCMonitoringStore from "./brineORCMonitoringStore";
import QuarterlyStore from "./quarterlyStore";
import DailysheetStore from "./dailysheetStore";
import QueryStore from "./queryStore";
import PtModuleStore from "./ptModuleStore";

interface Store {
  exampleStore: ExampleStore;
  commonStore: CommonStore;
  accountStore: AccountStore;
  userStore: UserStore;
  snackbarStore: SnackbarStore;
  modalStore: ModalStore;
  dialogStore: DialogStore;
  objectTypeStore: ObjectTypeStore;
  objectTypeGridStore: ObjectTypeGridStore;
  objectStore: ObjectStore;
  objectGridStore: ObjectGridStore;
  wellGeometryStore: WellGeometryStore;
  wellGeometryGridStore: WellGeometryGridStore;
  connectionStore: ConnectionStore;
  tftSteamStore: TftSteamStore;
  vesselGroupStore: VesselGroupStore;
  portLocationStore: PortLocationStore;
  tftGridStore: TftGridStore;
  ptsStore: PtsStore;
  ptsGridStore: PtsGridStore;
  objectStatusStore: ObjectStatusStore;
  objectStatusGridStore: ObjectStatusGridStore;
  locationGridStore: LocationGridStore;
  locationStore: LocationStore;
  brinePumpStore: BrinePumpStore;
  brinePumpGridStore: BrinePumpGridStore;
  decimationStore: DecimationStore;
  decimationGridStore: DecimationGridStore;
  ftpStore: FptStore;
  ftpGridStore: FptGridStore;
  geologicalGridStore: GeologicalGridStore;
  geologicalStore: GeologicalStore;
  geologicalMebGridStore: GeologicalMebGridStore;
  geophyisicsMTGridStore: GeophysicsMTGridStore;
  geophysicsGravityGridStore: GeophysicsGravityGridStore;
  geologicalAddGridStore: GeologicalAddGridStore;
  geophysicsGravityDataConversionGridStore: GeophysicsGravityDataConversionGridStore;
  biweeklyStore: BiweeklyStore;
  biweeklyGridStore: BiweeklyGridStore;
  orcGridStore: OrcGridStore;
  orcGroupGridStore: OrcGroupGridStore;
  cleanOutHistoryStore: CleanOutHistory;
  brineORCMonitoringStore: BrineORCMonitoringStore;
  quarterlyStore: QuarterlyStore;
  dailysheetStore: DailysheetStore;
  queryStore: QueryStore;
  ptModuleStore: PtModuleStore;
}

export const store: Store = {
  exampleStore: new ExampleStore(),
  commonStore: new CommonStore(),
  accountStore: new AccountStore(),
  userStore: new UserStore(),
  snackbarStore: new SnackbarStore(),
  modalStore: new ModalStore(),
  dialogStore: new DialogStore(),
  objectTypeStore: new ObjectTypeStore(),
  objectTypeGridStore: new ObjectTypeGridStore(),
  objectStore: new ObjectStore(),
  objectGridStore: new ObjectGridStore(),
  wellGeometryStore: new WellGeometryStore(),
  wellGeometryGridStore: new WellGeometryGridStore(),
  connectionStore: new ConnectionStore(),
  vesselGroupStore: new VesselGroupStore(),
  tftSteamStore: new TftSteamStore(),
  portLocationStore: new PortLocationStore(),
  tftGridStore: new TftGridStore(),
  ptsStore: new PtsStore(),
  ptsGridStore: new PtsGridStore(),
  objectStatusStore: new ObjectStatusStore(),
  objectStatusGridStore: new ObjectStatusGridStore(),
  locationGridStore: new LocationGridStore(),
  locationStore: new LocationStore(),
  brinePumpStore: new BrinePumpStore(),
  brinePumpGridStore: new BrinePumpGridStore(),
  decimationStore: new DecimationStore(),
  decimationGridStore: new DecimationGridStore(),
  ftpStore: new FptStore(),
  ftpGridStore: new FptGridStore(),
  geologicalGridStore: new GeologicalGridStore(),
  geologicalStore: new GeologicalStore(),
  geologicalMebGridStore: new GeologicalMebGridStore(),
  geophyisicsMTGridStore: new GeophysicsMTGridStore(),
  geophysicsGravityGridStore: new GeophysicsGravityGridStore(),
  geologicalAddGridStore: new GeologicalAddGridStore(),
  geophysicsGravityDataConversionGridStore: new GeophysicsGravityDataConversionGridStore(),
  biweeklyStore: new BiweeklyStore(),
  biweeklyGridStore: new BiweeklyGridStore(),
  orcGridStore: new OrcGridStore(),
  orcGroupGridStore: new OrcGroupGridStore(),
  cleanOutHistoryStore: new CleanOutHistory(),
  brineORCMonitoringStore: new BrineORCMonitoringStore(),
  quarterlyStore: new QuarterlyStore(),
  dailysheetStore: new DailysheetStore(),
  queryStore: new QueryStore(),
  ptModuleStore: new PtModuleStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
