import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import ObjectTypeList from "./ObjectTypeList";

function ObjectTypePage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Object Types',);
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <ObjectTypeList />
      </Grid>
    </Grid>
  );
}

export default observer(ObjectTypePage);