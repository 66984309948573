import RkdCard from "../../../app/component/card/RkdCard";
import { Box } from "@mui/system";
import { Autocomplete, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormikHelpers, useFormik } from "formik";
import { ObjectSchema, objectSchema } from "./schema/objectSchema";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { LoadingButton } from "@mui/lab";
import { IObjectTypesOptions } from "../../../app/models/objectTypes";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { ILocationOptions } from "../../../app/models/location";
import { OBJECT_STATUS } from "../../../app/config/enum";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

interface IProps {
  onSubmit: (values: ObjectSchema, helpers: FormikHelpers<ObjectSchema>) => Promise<unknown>;
  initialValues: ObjectSchema;
  id?: string;
  setSelectedLocation: Dispatch<SetStateAction<TSelectedData | undefined>>;
  selectedLocation: TSelectedData | undefined;
  location: ILocationOptions[];
}

function ObjectForm(props: IProps) {
  const { initialValues, onSubmit, id, selectedLocation, setSelectedLocation, location } = props;
  const { objectStore, objectTypeStore } = useStore();
  const navigate = useNavigate();
  const [objectType, setObjectType] = useState<Array<IObjectTypesOptions>>([]);

  const {
    handleSubmit: handleFormikSubmit,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
    touched,
    handleReset,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: objectSchema,
    enableReinitialize: true,
  });

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    handleFormikSubmit(e);
  };

  const isUpdate = useMemo(() => Boolean(id), [id]);

  useEffect(() => {
    objectTypeStore.getObjectTypesOptions("").then((res) => {
      setObjectType(res);
    });
  }, [objectTypeStore]);

  // useEffect(() => {
  //   connectionStore.getConnectionOptions('').then((res) => {
  //     setConnection(res);
  //   });
  // },[connectionStore]);

  // useEffect(() => {
  //   if(values.connectionId){
  //     connectionStore.getConnectionDetail(values.connectionId).then((res) => {
  //       if(res){
  //         setGroups(res.groups);
  //       }
  //     })
  //   }
  // },[connectionStore, values.connectionId])

  if (objectStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title={`${isUpdate ? "Edit" : "New"} Object`}>
      <Grid component={"form"} onSubmit={handleSubmit} container spacing={5}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiFormControl-root": { mb: 2 },
            }}
          >
            <TextField
              select
              label="Object Type"
              value={values.objectTypeId ?? ""}
              onChange={(e) => setFieldValue("objectTypeId", e.target.value)}
              onBlur={handleBlur}
              fullWidth
              required
              name="objectTypeId"
              error={touched.objectTypeId && !!errors.objectTypeId}
              helperText={touched && errors.objectTypeId}
            >
              {objectType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="latitude"
              label="Easting"
              variant="outlined"
              fullWidth
              required={touched.latitude && !!errors.latitude}
              value={values.latitude ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.latitude && !!errors.latitude}
              helperText={touched.latitude && errors.latitude}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              name="longitude"
              label="Northing"
              variant="outlined"
              fullWidth
              required={touched.longitude && !!errors.longitude}
              value={values.longitude ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.longitude && !!errors.longitude}
              helperText={touched.longitude && errors.longitude}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              name="inclinasi"
              label="Inclination"
              variant="outlined"
              fullWidth
              required={touched.inclinasi && !!errors.inclinasi}
              value={values.inclinasi ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.inclinasi && !!errors.inclinasi}
              helperText={touched.inclinasi && errors.inclinasi}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              name="azimuth"
              label="Azimuth"
              variant="outlined"
              fullWidth
              required={touched.azimuth && !!errors.azimuth}
              value={values.azimuth ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.azimuth && !!errors.azimuth}
              helperText={touched.azimuth && errors.azimuth}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              name="objectName"
              label="Object Name"
              variant="outlined"
              fullWidth
              required
              value={values.objectName ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.objectName && !!errors.objectName}
              helperText={touched.objectName && errors.objectName}
            />

            <TextField
              name="wellSite"
              label="Well Site"
              variant="outlined"
              fullWidth
              required
              value={values.wellSite ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.wellSite && !!errors.wellSite}
              helperText={touched.wellSite && errors.wellSite}
            />

            <TextField
              name="rigName"
              label="Rig Name"
              variant="outlined"
              fullWidth
              required
              value={values.rigName ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.wellSite && !!errors.wellSite}
              helperText={touched.wellSite && errors.wellSite}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e) => setFieldValue("drillingSpudDate", e)}
                value={values.drillingSpudDate ?? ""}
                label="Spud Date"
                renderInput={(props) => <TextField label="Spud Date" {...props} />}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e) => setFieldValue("drillingCompletedDate", e)}
                value={values.drillingCompletedDate ?? ""}
                label="Completion Date"
                renderInput={(props) => <TextField label="Completion Date" {...props} />}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiFormControl-root": { mb: 2 },
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth
                name="isActive"
                labelId="isActive"
                label="Status"
                onChange={(e) => setFieldValue("isActive", e.target.value === OBJECT_STATUS.ACTIVE ? true : false)}
                value={values.isActive === true ? OBJECT_STATUS.ACTIVE : OBJECT_STATUS.NON_ACTIVE}
              >
                {Object.entries(OBJECT_STATUS).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="cellarEvalation"
              label="Cellar Elevation"
              variant="outlined"
              fullWidth
              required
              value={values.cellarEvalation ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.cellarEvalation && !!errors.cellarEvalation}
              helperText={touched.cellarEvalation && errors.cellarEvalation}
            />

            <TextField
              type="number"
              name="rkbHeight"
              label="RKB Height"
              variant="outlined"
              fullWidth
              required
              value={values.rkbHeight ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.rkbHeight && !!errors.rkbHeight}
              helperText={touched.rkbHeight && errors.rkbHeight}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              type="number"
              name="totalDepth"
              label="Total Depth"
              variant="outlined"
              fullWidth
              required
              value={values.totalDepth ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.totalDepth && !!errors.totalDepth}
              helperText={touched.totalDepth && errors.totalDepth}
              InputProps={{
                endAdornment: <InputAdornment position="start">meter</InputAdornment>,
              }}
            />

            <TextField
              name="srNo"
              label="SR No."
              variant="outlined"
              fullWidth
              required
              value={values.srNo ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.srNo && !!errors.srNo}
              helperText={touched.srNo && errors.srNo}
            />
            <Autocomplete
              disablePortal
              fullWidth
              onChange={(e: any, val: any) => {
                setSelectedLocation(val);
                setFieldValue("locationId", val.value);
              }}
              disableClearable
              value={selectedLocation}
              getOptionLabel={(option) => option.label}
              options={location.map((item) => ({ label: item.text, value: item.value }))}
              renderInput={(params) => (
                <TextField
                  error={touched.locationId && !!errors.locationId}
                  helperText={touched && errors.locationId}
                  value={selectedLocation?.label}
                  name="locationId"
                  {...params}
                  label="Location *"
                />
              )}
            />
            <TextField
              name="remarks"
              label="Remarks"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={"5"}
              value={values.remarks ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.remarks && !!errors.remarks}
              helperText={touched.remarks && errors.remarks}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="info"
              sx={{ mr: "8px" }}
              onClick={(e) => {
                handleReset(e);
                navigate("/master/object");
              }}
              onBlur={handleBlur}
            >
              Cancel
            </Button>
            <LoadingButton variant="contained" color="primary" type="submit" disabled={!isValid} loading={isSubmitting}>
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </RkdCard>
  );
}

export default observer(ObjectForm);
