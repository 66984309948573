import { Button, Box } from "@mui/material";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RkdCard from "../../app/component/card/RkdCard";
import { useStore } from "../../app/stores/store";
import { ITftGrid } from "../../app/models/tft";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import RkdDataGrid from "../../app/component/dataGrid/RkdDataGrid";
import { Add, Delete } from "@mui/icons-material";
import { observer } from "mobx-react-lite";

function TFTPageIndex() {
  const navigate = useNavigate();
  const { snackbarStore, dialogStore, tftGridStore, tftSteamStore, commonStore } = useStore();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await tftSteamStore.deleteTft(commonStore.selectedDataOnGrid).then(() => {
          tftGridStore.getTftList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  useEffect(() => {
    commonStore.setTitlePage("Tracer Flow Test");
  });

  const gridColumns: GridColumns<ITftGrid> = [
    {
      field: "objectName",
      headerName: "Well",
      width: 170,
      type: "string",
      filterable: false,
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    { field: "tftDate", headerName: "Date", width: 170, type: "date", filterable: false, sortable: true, align: "center", headerAlign: "center" },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      type: "string",
      filterable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === "COMPLETED") {
          return <Chip label={params.row.status} color="success" />;
        } else {
          return <Chip label={params.row.status} color="warning" />;
        }
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={params.row.status === "COMPLETED" ? <VisibilityIcon /> : <VisibilityOffIcon />}
            label="View"
            color="info"
            disabled={params.row.status !== "COMPLETED"}
            onClick={() => {
              navigate(`report/${params.id}`);
            }}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            color="info"
            onClick={() => {
              navigate(`edit/${params.row.tftDate.toISOString().split("T")[0]}/${params.row.objectId}`);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    tftGridStore.getTftList();
  }, [tftGridStore]);

  return (
    <RkdCard title="Tracer Flow Test" subtitle="Add Edit & Delete Tracer Flow Test from this Page">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("/tracer-flow-test/process")}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <RkdDataGrid columns={gridColumns} gridStore={tftGridStore} />
    </RkdCard>
  );
}

export default observer(TFTPageIndex);
