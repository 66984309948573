import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TStepProps } from "../utils/type";

export default function AllStepsComponent({ setSelectedWell, selectedWell, setFieldValue, wellOptions, errors, handleChange, values }: TStepProps) {
  return (
    <Grid container spacing="12px" sx={{ mb: "24px", mt: "12px" }}>
      <Grid item xs={2.4}>
        <FormControl fullWidth>
          <InputLabel sx={{ color: "#B8B8B9" }}>Well</InputLabel>
          <Select disabled value={values.wellId} name="wellId" label="Well" onChange={handleChange}>
            {wellOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="Done By"
          required
          fullWidth
          name="doneBy"
          value={values.doneBy}
          error={!!errors.doneBy}
          helperText={errors.doneBy}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="Survey NR"
          fullWidth
          disabled
          required
          name="surveyNo"
          value={values.surveyNo}
          error={!!errors.surveyNo}
          helperText={errors.surveyNo}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TextField
          label="Checked By"
          fullWidth
          required
          name="checkedBy"
          value={values.checkedBy}
          error={!!errors.checkedBy}
          helperText={errors.checkedBy}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2.4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            onChange={() => undefined}
            disabled
            value={values.surveyDate}
            renderInput={(props) => <TextField fullWidth label="Date *" {...props} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Remarks"
          fullWidth
          multiline
          rows={3}
          name="remarks"
          value={values.remarks}
          error={!!errors.remarks}
          helperText={errors.remarks}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
