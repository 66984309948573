import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import WellGeometryGrid from "./WellGeometryGrid";
import WellGeometryDropdownMenu from "./WellGeometryDropdownMenu";

function WellGeometryList() {
  return (
    <RkdCard title="Well Geometry" subtitle="Add Edit & Delete Well Geometry from this Page">
      <WellGeometryDropdownMenu />
      <WellGeometryGrid />
    </RkdCard>
  );
}

export default observer(WellGeometryList);
