import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  IGeoDeleteChild,
  IGeoTemplateValidator,
  TDeleteGeologicalDetail,
  TDownloadTemplatePayload,
  TDynamicGeologicalEditPayload,
  TGeneratePCSDataPayload,
  TGeologicalCancel,
  TGeologicalChartPayload,
  TGeologicalChartResponse,
  TGeologicalDataResponse,
  TGeologicalSubmitPayload,
  TUploadGeologicalTemplateResponse,
} from "../models/geological";
import { store } from "./store";

export default class GeologicalStore {
  loadingGrid = false;
  loadingForm = false;
  loadingDownloadTemplate = false;
  dynamicDataGridLoading = false;
  loadingGetChart = false;
  dynamicDataGrid: TUploadGeologicalTemplateResponse | undefined = undefined;
  geologicalReportData: TGeologicalChartResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  cancelGeologicalAdd = async (payload: TGeologicalCancel) => {
    try {
      await agent.Geological.cancelGeologicalAdd(payload);
    } catch (error) {
      throw error;
    }
  };

  deleteGeologicalDetail = async (payload: TDeleteGeologicalDetail[]) => {
    try {
      await agent.Geological.deleteGeologicalDetail(payload);
    } catch (error) {
      throw error;
    }
  };

  getGeologicalChart = async (payload: TGeologicalChartPayload) => {
    this.loadingGetChart = true;

    try {
      let res = await agent.Geological.getGeologicalChart(payload);

      runInAction(() => (this.geologicalReportData = res));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetChart = false));
    }
  };

  generatePCSData = async (payload: TGeneratePCSDataPayload) => {
    this.dynamicDataGridLoading = true;

    try {
      return await agent.Geological.generatePCSData(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.dynamicDataGridLoading = false));
    }
  };

  downloadTemplate = async (payload: TDownloadTemplatePayload) => {
    this.loadingDownloadTemplate = true;

    try {
      await agent.Geological.downloadTemplate(payload);
    } catch (error) {
      store.snackbarStore.show("error", "File Not Found");
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadTemplate = false));
    }
  };

  templateValidator = async (data: IGeoTemplateValidator) => {
    try {
      await agent.Geological.templateValidator(data);
    } catch (error) {
      store.snackbarStore.show("error", "Wrong Template");
      throw error;
    }
  };

  getWellOption = async (type: number) => {
    try {
      return await agent.Geological.listWellOption(type);
    } catch (error) {
      throw error;
    }
  };

  getLithologyOptions = async () => {
    try {
      return await agent.Geological.optionsLithology();
    } catch (error) {
      throw error;
    }
  };

  getAlterationOptions = async () => {
    try {
      return await agent.Geological.optionsAlteration();
    } catch (error) {
      throw error;
    }
  };

  editGeological = async (payload: TDynamicGeologicalEditPayload, type: string) => {
    try {
      return await agent.Geological.editGeological(payload, type);
    } catch (error) {
      throw error;
    }
  };

  submitGeologicalAdd = async (payload: TGeologicalSubmitPayload) => {
    try {
      return await agent.Geological.submitGeologicalAdd(payload);
    } catch (error) {
      throw error;
    }
  };

  addGeological = async (type: string, payload: TGeologicalDataResponse) => {
    this.loadingForm = true;
    try {
      return await agent.Geological.addGeological(type, payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  uploadGeologicalTemplate = async (payload: FormData) => {
    this.dynamicDataGridLoading = true;
    try {
      let res = await agent.Geological.uploadGeologicalTemplate(payload);
      runInAction(() => (this.dynamicDataGrid = res));

      return res;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.dynamicDataGridLoading = false));
    }
  };

  getFormationOptions = async () => {
    try {
      return await agent.Geological.optionsFormation();
    } catch (error) {
      throw error;
    }
  };

  getGeologicalDetail = async (id: string) => {
    this.loadingGrid = true;
    try {
      return await agent.Geological.detailGeological(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getGeologicalDetailTable = async (type: string, id: string) => {
    this.loadingGrid = true;
    try {
      return await agent.Geological.detailGeologicalTable(type, id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getGeoMebDetail = async (id: string) => {
    this.loadingGrid = true;
    try {
      return await agent.Geological.detailGeologicalMeb(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getGeoMebDetailTable = async (id: string) => {
    this.loadingGrid = true;
    try {
      return await agent.Geological.detailGeologicalMebTable(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  createLithology = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createLithology(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createAlteration = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createAlteration(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createDrillParam = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createDrillParam(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createPcs = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createPcs(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createFeedZone = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createFeedZone(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createTemp = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createTemperature(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createLoss = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createLoss(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  createMeb = async (data: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.createMeb(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteGeo = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.deleteGeological(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteMeb = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.deleteGeologicalMeb(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteMebRowTable = async (id: any) => {
    this.loadingForm = true;
    try {
      await agent.Geological.deleteGeologicalMebTable(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  deleteGeologicalRowTable = async (type: number, geologicalId: string, sequence: number[]) => {
    this.loadingGrid = true;
    try {
      const data: IGeoDeleteChild = {
        type,
        geologicalId,
        sequence,
      };
      await agent.Geological.deleteGeologicalTable(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  clearDynamicDataGrid = () => {
    this.dynamicDataGrid = undefined;
  };
}
