import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";


export default class GeologicalMebGridStore extends DataGridStore {

  constructor() {
      super();

      makeObservable(this, {
          getGeologicalMebList: action,
      });
  }

  getGeologicalMebList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geological.listGridMeb(urlParams);
      this.setDataGridResult(result);
      
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingGrid = false);
    }
  }

  updateGridCallback = action(() => {
    this.getGeologicalMebList();
  })

}