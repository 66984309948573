import { Box, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import BrinePumpGrid from "./BrinePumpGrid";
import { Add, Delete } from "@mui/icons-material";

function BrinePumpIndex() {
  const { dialogStore, snackbarStore, commonStore, brinePumpStore, brinePumpGridStore } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    commonStore.setTitlePage("Brine Pump");
  });

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await brinePumpStore.deleteBrinePump(commonStore.selectedDataOnGrid).then(() => {
          brinePumpGridStore.getBrinePumpList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <RkdCard title="Brine Pump" subtitle="Add Edit & Delete Brine Pump from this Page">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <BrinePumpGrid />
    </RkdCard>
  );
}

export default observer(BrinePumpIndex);
