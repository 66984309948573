import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IBrineConverterUnitTables, IBrineLtAnalysis, IBrineSampleAnalysisTables } from "../models/brine";
import { IRegressionRequest, IRegressionResponse, ISteamDrycallPTFCorrections, ISteamResultTables, ISteamSampleAnalysisTables, ISteamSf6Analysis, ISteamSf6AnalysisTables } from "../models/steam";
import { ITft, ITftAvg, ITftCompletionRequest } from "../models/tft";
import { IDeleteData } from "../models/account";

const initialDataTft: ITft = {
    tftDate: new Date(),
    objectId: "",
    tftSdtTmpl: "Test",
    wht: 0,
    whp: 0,
    controlValve: 0,
    pumpStroke: 0,
    mfc: "",
    baselinePTSA: 0,
    wtPTSASolin: 0,
    steamTracerName: "",
    steamTracerMW: 0,
    baselineSF6: 0,
    volSF6: 0,
    baroPress: 0,
    ncgContent: 0,
    productionSepPressure: 0,
    reportAnalytical: true,
    baselineLiquidtrace: 0,
    liquidtraceSoln: 0,
    steamPortLoccationId: "",
    brinePortLocationId: "",
    mfcInOut: 0,
    brineTracerName: "",
    brineBaseline: "",
    wt: 0,
    steamBaseline: "",
    tmfHcalculationBlock: 0,
    samePort: true,
    pressDiffFlag: true,
    avgSteamPressure: 0,
    avgBrinePressure: 0,
    averageTestPressure: 0,
    steamEnthAvgP: 0,
    brineEnthAvgP: 0,
    steamFlowSteamPChoice: 0,
    tmfAvgP: 0,
    tmfAvgPNCGcorr: 0,
    steamFractionAvgP: 0,
    steamFractionAvgPNCGCorr: 0,
    steamFractionAvgPChoice: 0,
    steamEnthSteamP: 0,
    brineEnthSteamP: 0,
    calcBrineSteamP: 0,
    calcBrineSteamPNCGCorr: 0,
    tmfSteamP: 0,
    tmfSteamPNCGcorr: 0,
    steamFractionSteamP: 0,
    steamFractionSteamPNCGCorr: 0,
    steamFractionSteamPChoice: 0,
    steamEnthBrineP: 0,
    brineEnthBrineP: 0,
    calcSteamBrineP: 0,
    calcSteamBrinePNCGcorr: 0,
    steamFractionBrineP: 0,
    steamFractionBrinePNCGCorr: 0,
    steamFractionBrinePChoice: 0,
    totalEnthAvgPressure: 0,
    totalEnthAvgPressureNCGcorr: 0,
    totalEnthDiffPressures: 0,
    totalEnthDiffPressuresNCGcorr: 0,
    steamEnthProdP: 0,
    brineEnthProdP: 0,
    calcSteamProdP: 0,
    calcBrineProdP: 0,
    tmfProductionP: 0,
    steamFractionProdP: 0,
    steam: {
        sF6Analysis : {
            carrierGas: 'Carrier',
            carrierFlow: 0,
            auxGas: 'Aux Gas 1',
            auxFlow: 0,
            ma: 0,
            detTemp: 0,
            ti: 0,
            tf: 0,
            tavg: 0,
            table: []
        },
        ampleAnalysis : {
            compoundName: "",
            milkedBombs: true,
            molecularWeight: 0,
            solubilityFactor: 0,
            inletSystemDeadVolume: 0,
            table: []
        },
        drycallPTFCorrections : [],
        tftSteamResult: {
            steamFlowRateSpread: 0,
            standardDeviation: 0,
            rsd: 0,
            table: []
        },
        regressions: [{
            coefficientName: 'INTERCEPT',
            coefficients: 0
        },
        {
            coefficientName: 'X1',
            coefficients: 0
        },
        {
            coefficientName: 'X2',
            coefficients: 0
        },
        {
            coefficientName: 'X3',
            coefficients: 0
        }]
    },
    brine: {
        regressions: [{
            coefficientName: 'INTERCEPT',
            coefficients: 0
        },
        {
            coefficientName: 'X1',
            coefficients: 0
        },
        {
            coefficientName: 'X2',
            coefficients: 0
        },
        {
            coefficientName: 'X3',
            coefficients: 0
        }],
        ltAnalyses: [],
        sampleAnalysis: {
            analysisNumber: '',
            location: '',
            analyte: '',
            analyst: '',
            remarks: '',
            solutionConcentration: 0,
            table: []
        },
        converterUnit: {
            whtC: 0,
            whpBarg: 0,
            controlValve: 0,
            brineFlowRateSpread: 0,
            standardDeviation: 0,
            rsd: 0,	  
            table: []
        }
    }
}
export default class TftSteamStore {

    loadingGrid = false;
    loadingGridRegression = false;
    loadingForm = false;

    tftProcessTab = 1;

    tft: ITft = initialDataTft;

    correctionSteamFlowRate: number = 0;
    correctionBrineFlowRate: number = 0;
    avgTotalFlow: number = 0;
    avgEnthalpy: number = 0;


    tftAverages: ITftAvg = {
        steam: {
            avgSeparatorTemp: 0,
            avgPressPsig: 0,
            avgSaturationPressure: 0,
            avgSteamFlowRate: 0
        },
        brine: {
            avgSeparatorTemp: 0,
            avgPressPsig: 0,
            avgSaturationPressure: 0,
            avgBrineFlowRate: 0
        }
    }

    constructor() {
        makeAutoObservable(this);
    }

    setProcessTab = (data: number) => {
        this.tftProcessTab = data;
    }

    resetTft = () => {
        this.tft = initialDataTft;
    }

    draftTft = async (tft: ITft) => {
        this.loadingForm = true;
        try {
          await agent.TFT.draft(tft);
        } catch (error) {
            throw error;
        } finally {
            this.loadingForm = false;
        }
    }

    detailTft =async (date: string, objectId: string) => {
        try {
         const data = await agent.TFT.detail(date, objectId);
         data.steam.tftSteamResult.table.forEach(item => {
            item.labNumber = item.sequence;
            item.timeStart  = new Date(item.timeStart + "Z");
            item.timeEnd  = new Date(item.timeEnd + "Z");
         })
         data.steam.drycallPTFCorrections.forEach(item => {
            item.time  = new Date(item.time + "Z");
        })
        data.brine.converterUnit.table.forEach(item => {
            item.intervalStart  = new Date(item.intervalEnd + "Z");
            item.intervalEnd  = new Date(item.intervalEnd + "Z");
        })
         this.setTft(data);
        } catch (error) {
            throw error;
        }
    }

    getMfcFlowRate = async (steamId: string, sequence: number) => {
        try {
            return await agent.TFT.mfcFlowRate(steamId, sequence);
        } catch (error) {
            throw error;
        }
    }

    createTft = async (tft: ITft) => {
        this.loadingForm = true;
        try {
          await agent.TFT.create(tft);
        } catch (error) {
            throw error;
        } finally {
            this.loadingForm = false;
        }
    }

    deleteTft = async (id: IDeleteData) => {
        this.loadingForm = true;
        try {
          await agent.TFT.delete(id);
        } catch (error) {
          throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    reportTft = async (id: string) => {
        try {
            const result = await agent.TFT.report(id);
            return result;
            
        } catch (error) {
            throw error;
        }
    }

    completionTft = async (data: ITftCompletionRequest) => {
        this.loadingGrid = true;
        try {
            const result = await agent.TFT.completion(data);
            return result;
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingGrid = false);
        }
    }

    setTft = (tft: ITft) => {
        this.tft = tft;
    }

    setAvgSeparatorTempSteam = (avgSeparatorTemp: number) => {
        this.tftAverages.steam.avgSeparatorTemp = avgSeparatorTemp;
    }

    setAvgPressPsigSteam = (avgPressPsig: number) => {
        this.tftAverages.steam.avgPressPsig = avgPressPsig;
    }

    setAvgSaturationPressureSteam = (avgSaturationPressure: number) => {
        this.tftAverages.steam.avgSaturationPressure = avgSaturationPressure;
        this.setAvgSteamPressure(avgSaturationPressure);
    }

    setAvgSteamFlowRateSteam = (avgSteamFlowRate: number) => {
        this.tftAverages.steam.avgSteamFlowRate = avgSteamFlowRate;
    }

    setAvgSeparatorTempBrine = (avgSeparatorTemp: number) => {
        this.tftAverages.brine.avgSeparatorTemp = avgSeparatorTemp;
    }

    setAvgPressPsigBrine = (avgPressPsig: number) => {
        this.tftAverages.brine.avgPressPsig = avgPressPsig;
    }

    setAvgSaturationPressureBrine = (avgSaturationPressure: number) => {
        this.tftAverages.brine.avgSaturationPressure = avgSaturationPressure;
        this.setAvgBrinePressure(avgSaturationPressure);
    }

    setAvgBrineFlowRate = (avgBrineFlowRate: number) => {
        this.tftAverages.brine.avgBrineFlowRate = avgBrineFlowRate;
    }

    setCSteamFlow = (steamFlowRate: number) => {
        this.correctionSteamFlowRate = steamFlowRate;
    }

    // SF6 Analysis

    setSf6Analysis = (sf6Analysis: ISteamSf6Analysis) => {
        this.tft.steam.sF6Analysis = sf6Analysis;
    }

    setSf6AnalysisTable = (data: ISteamSf6AnalysisTables[]) => {
        if(this.tft.steam.sF6Analysis){
            this.tft.steam.sF6Analysis.table = data;
        }
    }

    setTi = (ti: number) => {
        this.tft.steam.sF6Analysis.ti = ti;
    }

    setTf = (tf: number) => {
        this.tft.steam.sF6Analysis.tf = tf;
    }

    setTavg = (tAvg: number) => {
        this.tft.steam.sF6Analysis.tavg = tAvg;
    }

    getRegressions = async (type: string, data: IRegressionRequest) => {
        this.loadingGridRegression = true;
        try {
            const regression = await agent.TFT.regression(type, data);
            this.setRegression(regression);
        } catch (error) {
            console.log(error)
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    setBrineRegression = (regression: IRegressionResponse[]) => {
        this.tft.brine.regressions = regression;
    }

    getBrineRegressions = async (type: string, data: IRegressionRequest) => {
        this.loadingGridRegression = true;
        try {
            const regression = await agent.TFT.regression(type, data);
            this.setBrineRegression(regression);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    setRegression = (regression: IRegressionResponse[]) => {
        this.tft.steam.regressions = regression;
    }

    // Sample Analysis
    setCompoundName = (compoundName: string) => {
        this.tft.steam.ampleAnalysis.compoundName = compoundName;
    }

    setMilkedBombs = (milkedBombs: boolean) => {
        this.tft.steam.ampleAnalysis.milkedBombs = milkedBombs;
    }

    setMolecularWeight = (molecularWeight: number) => {
        this.tft.steam.ampleAnalysis.molecularWeight = molecularWeight;
    }

    setSolubilityFactor = (solubilityFactor: number) => {
        this.tft.steam.ampleAnalysis.solubilityFactor = solubilityFactor;
    }

    setInletSystemDeadVolume = (inletSystemDeadVolume: number) => {
        this.tft.steam.ampleAnalysis.inletSystemDeadVolume = inletSystemDeadVolume;
    }

    setSampleAnalysisTable = (data: ISteamSampleAnalysisTables[]) => {
        this.tft.steam.ampleAnalysis.table = data;
    }

    //drycallPTFCorrection

    setDrycall = (data: ISteamDrycallPTFCorrections[]) => {

        // data.forEach((x, index) => {
        //     x.time = new Date(x.time + 'Z');
        // })
        this.tft.steam.drycallPTFCorrections = data;
    }

    //steamResult

    setSteamFlowRateSpread = (data: number) => {
        this.tft.steam.tftSteamResult.steamFlowRateSpread = data;
    }

    setSteamStandardDeviation = (data: number) => {
        this.tft.steam.tftSteamResult.standardDeviation = data;
    }

    setSteamRsd = (data: number) => {
        this.tft.steam.tftSteamResult.rsd = data;
    }

    setSteamResultTable = (data: ISteamResultTables[]) => {
        this.tft.steam.tftSteamResult.table = data;
    }

    addSteamResultTable = () => {
        this.tft.steam.tftSteamResult.table.push({
            sequence: this.tft.steam.tftSteamResult.table.length + 1,
            labNumber: this.tft.steam.tftSteamResult.table.length + 1,
            timeStart: new Date(),
            timeEnd: new Date(),
            separatorTemp: 0,
            pressPsig: 0,
            saturationPressure: 0,
            injRateSLPM: 0,
            ppmTracer: 0,
            steamFlowRate: 0
        });
    }

    clearSteamResultTable = () => {
        this.tft.steam.tftSteamResult.table = [];
    }

    clearBrineResultTable = () => {
        this.tft.brine.converterUnit.table = [];
    }

    addBrineResultTable= () => {
        this.tft.brine.converterUnit.table.push({
            sequence: this.tft.brine.converterUnit.table.length + 1,
            labNumber: this.tft.brine.converterUnit.table.length + 1,
            intervalStart: new Date(),
            intervalEnd: new Date(),
            tempC: 0,
            pressBarg: 0,
            tempF: 0,
            pressPsig: 0,
            saturationPressure: 0,
            liquidtraceInjRate: 0,
            ppmwTracerInBrine: 0,
            brineFlowRateKph: 0,
            ppbwTracerInBrine: 0,
            brineFlowRateKgs: 0
        })
    }

    //tft

    setTftDate = (tftDate: Date | null) => {
        if(tftDate) {
            this.tft.tftDate = tftDate;
        }
    }

    setObjectId = (objectId: string) => {
        this.tft.objectId = objectId;
    }

    setTftSdtTmpl = (tftSdtTmpl: string) => {
        this.tft.tftSdtTmpl = tftSdtTmpl;
    }

    setWht = (wht: number) => {
        this.tft.wht = wht;
    }

    setWell = (well: string) => {
        this.tft.objectId = well;
    }

    setSteamBaseline = (steamBaseline: string) => {
        this.tft.steamBaseline = steamBaseline;
    }

    setBrineBaseline = (brineBaseline: string) => {
        this.tft.brineBaseline = brineBaseline;
    }

    setwhp = (whp: number) => {
        this.tft.whp = whp;
    }

    setControlValve = (controlValve: number) => {
        this.tft.controlValve = controlValve;
    }

    setPumpStroke = (pumpStroke: number) => {
        this.tft.pumpStroke = pumpStroke;
    }

    setSteamPortLoc = (steamPortLoc: string) => {
        this.tft.steamPortLoccationId = steamPortLoc;
    }

    setMfc = (mfc: string) => {
        this.tft.mfc = mfc;
    }

    setBaselinePTSA = (baselinePTSA: number) => {
        this.tft.baselinePTSA = baselinePTSA;
    }

    setWTPTSASolin = (wtPTSASolin: number) => {
        this.tft.wtPTSASolin = wtPTSASolin;
    }

    setSteamTracerName = (steamTracerName: string) => {
        this.tft.steamTracerName = steamTracerName;
    }

    setSteamTracerMW = (steamTracerMW: number) => {
        this.tft.steamTracerMW = steamTracerMW;
    }

    setBaselineSF6 = (baselineSF6: number) => {
        this.tft.baselineSF6 = baselineSF6;
    }

    setVolSF6 = (volSF6: number) => {
        this.tft.volSF6 = volSF6;
    }

    setBaroPress = (baroPress: number) => {
        this.tft.baroPress = baroPress;
    }

    setNcgContent = (ncgContent: number) => {
        this.tft.ncgContent = ncgContent;
    }

    setProductionSepPressure = (productionSepPressure: number) => {
        this.tft.productionSepPressure = productionSepPressure;
    }

    setReportAnalytical = (reportAnalytical: string) => {
        
        if(reportAnalytical === 'yes'){
            this.tft.reportAnalytical = true;
        } else {
            this.tft.reportAnalytical = false;
        }
        
    }

    setBrinePortLocation = (brinePortLocation: string) => {
        this.tft.brinePortLocationId = brinePortLocation;
    }

    setBaselineLiquidtrace = (baselineLiquidtrace: number) => {
        this.tft.baselineLiquidtrace = baselineLiquidtrace;
    }

    setLiquidtraceSoln = (liquidtraceSoln: number) => {
        this.tft.liquidtraceSoln = liquidtraceSoln;
    }

    setBrineTracerName = (brineTracerName: string) => {
        this.tft.brineTracerName = brineTracerName;
    }

    setTmfCalculation = (tmfCalculation: number) => {
        this.tft.tmfHcalculationBlock = tmfCalculation;
    }

    //tft header

    setSamePort = (samePort: boolean) => {
        this.tft.samePort = samePort;
    }

    setDiffFlag = (pressDiffFlag: boolean) => {
        this.tft.pressDiffFlag = pressDiffFlag;
    }

    setAvgSteamPressure = (avgSteamPressure: number) => {
        this.tft.avgSteamPressure = avgSteamPressure;
    }

    setAvgBrinePressure = (avgBrinePressure: number) => {
        this.tft.avgBrinePressure = avgBrinePressure;
    }

    setAvgTestPressure = (avgTestPressure: number) => {
        this.tft.averageTestPressure = avgTestPressure;
    }

    setSteamEnthAvgP = (steamEnthAvgP: number) => {
        this.tft.steamEnthAvgP = steamEnthAvgP;
    }

    setBrineEnthAvgP = (brineEnthAvgP: number) => {
        this.tft.brineEnthAvgP = brineEnthAvgP;
    }

    setSteamFlowSteamPChoice = (steamFlowSteamPChoice: number) => {
        if(steamFlowSteamPChoice !== 0) {
            this.tft.steamFlowSteamPChoice = steamFlowSteamPChoice;
        } else {
            this.tft.steamFlowSteamPChoice = this.tftAverages.steam.avgSteamFlowRate;
        }
    }

    setTmfAvgP = (TmfAvgP: number) => {
        this.tft.tmfAvgP = TmfAvgP;
    }
    
    setTmfAvgPNcgCor = (tmfAvgPNcgCor: number) => {
        this.tft.tmfAvgPNCGcorr = tmfAvgPNcgCor;
    }

    setSteamFractionAvgP = (steamFractionAvgP: number) => {
        this.tft.steamFractionAvgP = steamFractionAvgP;
    }

    setSteamFractionAvgPNcgCor = (steamFractionAvgPNcgCor: number) => {
        this.tft.steamFractionAvgPNCGCorr = steamFractionAvgPNcgCor;
    }

    setSteamFractionAvgPChoice = (steamFractionAvgPChoice: number) => {
        this.tft.steamFractionAvgPChoice = steamFractionAvgPChoice;
    }

    setSteamEnthSteamP = (steamEnthSteamP: number | null) => {
        if(steamEnthSteamP) {
            this.tft.steamEnthSteamP = steamEnthSteamP;
        } else {
            this.tft.steamEnthSteamP = 0;
        }
        
    }

    setBrineEnthSteamP = (brineEnthSteamP: number | null ) => {
        if(brineEnthSteamP){
            this.tft.brineEnthSteamP = brineEnthSteamP;
        } else {
            this.tft.brineEnthSteamP = 0;
        }
        
    }

    setCalcBrineSteamP = (calcBrineSteamP: number) => {
        this.tft.calcBrineSteamP = calcBrineSteamP;
    }

    setCalcBrineSteamPNcgCor = (calcBrineSteamPNcgCor: number) => {
        this.tft.calcBrineSteamPNCGCorr = calcBrineSteamPNcgCor;
    }

    setTmfSteamP = (tmfSteamP: number) => {
        this.tft.tmfSteamP = tmfSteamP;
    }

    setTmfSteamPNcgCor = (tmfSteamPNcgCor: number) => {
        this.tft.tmfSteamPNCGcorr = tmfSteamPNcgCor;
    }

    setSteamFractionSteamP = (steamFractionSteamP: number) => {
        this.tft.steamFractionSteamP = steamFractionSteamP;
    }

    setSteamFractionSteamPNcgCor = (steamFractionSteamPNcgCor: number) => {
        this.tft.steamFractionSteamPNCGCorr = steamFractionSteamPNcgCor;
    }

    setSteamFractionSteamPChoice = (steamFractionSteamPChoice: number) => {
        this.tft.steamFractionSteamPChoice = steamFractionSteamPChoice;
    }

    setSteamEnthBrineP = (steamEnthBrineP: number | null) => {
        if(steamEnthBrineP){
            this.tft.steamEnthBrineP = steamEnthBrineP;
        } else {
            this.tft.steamEnthBrineP = 0;
        }    
    }

    setBrineEnthBrineP = (brineEnthBrineP: number | null) => {
        if(brineEnthBrineP){
            this.tft.brineEnthBrineP = brineEnthBrineP;
        } else {
            this.tft.brineEnthBrineP = 0;
        }        
    }

    setCalcSteamBrineP = (calcSteamBrineP: number) => {
        this.tft.calcSteamBrineP = calcSteamBrineP;
    }

    setCalcSteamBrinePNcgCor = (calcSteamBrinePNcgCor: number) => {
        this.tft.calcSteamBrinePNCGcorr = calcSteamBrinePNcgCor;
    }

    setSteamFractionBrineP = (steamFractionBrineP: number) => {
        this.tft.steamFractionBrineP = steamFractionBrineP;
    }

    setSteamFractionBrinePNcgCor = (steamFractionBrinePNcgCor: number) => {
        this.tft.steamFractionBrinePNCGCorr = steamFractionBrinePNcgCor;
    }

    setSteamFractionBrinePChoice = (steamFractionBrinePChoice: number) => {
        this.tft.steamFractionBrinePChoice = steamFractionBrinePChoice;
    }

    setTotalEnthAvgPress = (totalEnthAvgPress: number | null) => {
        if(totalEnthAvgPress){
            this.tft.totalEnthAvgPressure = totalEnthAvgPress;
        } else {
            this.tft.totalEnthAvgPressure = 0;
        }   
    }

    setTotalEnthAvgPressNcgCor = (totalEnthAvgPressNcgCor: number | null) => {
        if(totalEnthAvgPressNcgCor){
            this.tft.totalEnthAvgPressureNCGcorr = totalEnthAvgPressNcgCor;
        } else {
            this.tft.totalEnthAvgPressureNCGcorr = 0;
        } 
    }

    setTotalEnthDiffPress = (totalEnthDiffPress: number) => {
        this.tft.totalEnthDiffPressures = totalEnthDiffPress;
    }

    setTotalEnthDiffPressNcgCor = (totalEnthDiffPressNcgCor: number) => {
        this.tft.totalEnthDiffPressuresNCGcorr = totalEnthDiffPressNcgCor;
    }

    setSteamEnthProdP = (steamEnthProdP: number | null) => {
        if(steamEnthProdP){
            this.tft.steamEnthProdP = steamEnthProdP;
        } else {
            this.tft.steamEnthProdP = 0;
        }
    }

    setBrineEnthProdP = (brineEnthProdP: number | null) => {
        if(brineEnthProdP){
            this.tft.brineEnthProdP = brineEnthProdP;
        } else {
            this.tft.brineEnthProdP = 0;
        }
    }

    setAvgTotalFlow= (avgTotalFlow: number) => {
        this.avgTotalFlow = avgTotalFlow;
    }

    setAvgEnthalpy = (avgEnthalpy: number) => {
        this.avgEnthalpy = avgEnthalpy;
    }

    setCalcSteamProdP = (calcSteamProdP: number) => {
        this.tft.calcSteamProdP = calcSteamProdP;
    }

    setCalcBrineProdP = (calcBrineProdP: number) => {
        this.tft.calcBrineProdP = calcBrineProdP;
    }

    setTmfProdP = (tmfProdP: number) => {
        this.tft.tmfProductionP = tmfProdP;
    }

    setSteamFractionProdP = (steamFractionProdP: number) => {
        this.tft.steamFractionProdP = steamFractionProdP;
    }

    //Brine

    setLtAnalysis = (ltAnalysis: IBrineLtAnalysis[]) => {
        this.tft.brine.ltAnalyses = ltAnalysis;
    }

    setSampleAnalysisNumber = (data: string) => {
        this.tft.brine.sampleAnalysis.analysisNumber = data;
    }

    setSampleAnalysisLocation = (data: string) => {
        this.tft.brine.sampleAnalysis.location = data;
    }

    setSampleAnalysisAnalyte = (data: string) => {
        this.tft.brine.sampleAnalysis.analyte = data;
    }

    setSampleAnalysisAnalyst= (data: string) => {
        this.tft.brine.sampleAnalysis.analyst = data;
    }

    setSampleAnalysisRemarks = (data: string) => {
        this.tft.brine.sampleAnalysis.remarks = data;
    }

    setSampleAnalysisSolutionConcentration = (data: number) => {
        this.tft.brine.sampleAnalysis.solutionConcentration = data;
    }

    setSampleAnalysisTableBrine = (data: IBrineSampleAnalysisTables[]) => {
        this.tft.brine.sampleAnalysis.table = data;
    }

    setConverterUnitBrine = (data: IBrineConverterUnitTables[]) => {
        this.tft.brine.converterUnit.table = data;
    }

    setWhtC = (wht : number) => {
        this.tft.brine.converterUnit.whtC = wht;
    }

    setWhpBarg = (whp: number) => {
        this.tft.brine.converterUnit.whpBarg = whp;
    }

    setControlValveBrine = (cv: number) => {
        this.tft.brine.converterUnit.controlValve = cv;
    }

    setBrineFlowRateSpread = (data: number) => {
        this.tft.brine.converterUnit.brineFlowRateSpread = data;
    }

    setBrineStandardDeviation = (data: number) => {
        this.tft.brine.converterUnit.standardDeviation = data;
    }

    setBrineRsd = (data: number) => {
        this.tft.brine.converterUnit.rsd = data;
    }
    

}