import { useEffect } from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { Box } from "@mui/material";
import { format } from "date-fns";

function QuarterlyDataGrid() {
  const { commonStore, quarterlyStore } = useStore();
  const { getQuarterlyGrid, dataGridResult } = quarterlyStore;
  const navigate = useNavigate();

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "wellName",
      headerName: "Well Name",
      flex: 1,
    },
    {
      field: "modifiedDateUtc",
      headerName: "Modified Date",
      flex: 1,
      renderCell: (params) => <Box>{format(new Date(params.row.modifiedDateUtc), "yyyy-MM-dd - HH:mm:ss")}</Box>,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/geochemistry-quarterly/edit/${params.row.wellId}`);
          }}
        />,
      ],
    },
  ];

  useEffect(() => {
    getQuarterlyGrid();
  }, [getQuarterlyGrid]);

  return (
    <StyledDataGridPremium
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      checkboxSelection
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={dataGridResult.data}
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnMenu
    />
  );
}

export default observer(QuarterlyDataGrid);
