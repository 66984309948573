import { Step, StepLabel, Stepper } from "@mui/material";
import { Box } from "@mui/system";

interface IProps {
  activeStep: number;
  steps: string[];
}

function PTDecimationStepper(props: IProps) {
  const { activeStep, steps } = props;
  return (
    <Box sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default PTDecimationStepper;
