export interface IFptForm {
    wellId: string,
    surveyDate: Date,
    pAtm: number,
    remarks: string,
    testWells: Array<FptTestWellForm>,
    vessel: FptVesselForm,
    connectedWells : Array<FptConnectedWellForm>,
    testResults : Array<FptTestWellResult>
}

export interface IFptTestWell {
    wellId: string,
    wellName: string,
    enthalphy: number,
    table: Array<FptTestWellDetail>,
}

export interface IFptTestWellForm {
    wellId: string,
    enthalphy: number,
    table: Array<FptTestWellDetail>,
}

export interface IFptVesselForm {
    vesselGroupId: string,
    table: Array<FptTestVesselDetail>,
}

export interface IFptConnectedWellForm {
    wellId: string,
    enthalphy: number,
    dWsWhp: number,
    prodPercentage: number,
    table: Array<FptTestWellDetail>
}

export class FptConnectedWellForm implements IFptConnectedWellForm {
    wellId;
    enthalphy;
    dWsWhp;
    prodPercentage;
    table: Array<FptTestWellDetail>;

    constructor() {
        this.wellId = "";
        this.enthalphy = 0;
        this.dWsWhp = 0;
        this.prodPercentage = 0;
        this.table = [];
    }

    clone(fptConnectedWellForm: FptConnectedWellForm) {
        this.wellId = fptConnectedWellForm.wellId;
        this.enthalphy = fptConnectedWellForm.enthalphy;
        this.dWsWhp = fptConnectedWellForm.dWsWhp;
        this.prodPercentage = fptConnectedWellForm.prodPercentage;
        this.table = fptConnectedWellForm.table;
    }
}

export interface IFptGrid {
    id: string,
    wellId: string,
    surveyDate: Date,
    wellName: string,
    pAtm: number,
    remarks: string,
    connectedWells: Array<string>,
}

export interface IFptDetail {
    id: string,
    wellId: string,
    surveyDate: Date,
    wellName: string,
    pAtm: number,
    enthalphy: number,
    remarks: string,
    connectedWells: Array<FptConnectedWellDetail>,
    testWells: Array<FptTestWell>,
    vessel: VesselDetail,
    testResults: Array<FptTestWellResult>,
}

export interface IFptTestWellDetail {
    sequence: number,
    fcv: number,
    whp: number,
}

export interface IFptTestVesselDetail {
    sequence: number,
    psep: number,
    wkph: number,
}

export interface IFptConnectedWellDetail {
    wellId: string,
    wellName: string,
    enthalphy: number,
    dWsWhp: number,
    prodPercentage: number,
    table: Array<FptTestWellDetail>
}

export interface IVesselDetail {
    vesselGroupId: string,
    vesselId: string,
    vesselName: string,
    table: Array<FptTestVesselDetail>
}

export interface IFptTestWellResult {
    sequence: number,
    fcv: number,
    whp: number,
    psep: number,
    wkph: number,
    remarks: string,
}

export interface IFPTChartResponse {
    charts: Array<IFPTChartSeriesResponse>
} 

export interface IFPTChartSeriesResponse {
    name: string,
    data: Array<IFPTChartDataResponse>
}

export interface IFPTChartDataResponse {
    x: number,
    y: number
}

export class FptDetail implements IFptDetail {
    id;
    wellId;
    surveyDate;
    enthalphy;
    wellName;
    pAtm;
    remarks;
    testWells: Array<FptTestWell>;
    connectedWells: Array<FptConnectedWellDetail>;
    vessel: VesselDetail;
    testResults: Array<FptTestWellResult>;

    constructor() {
        this.id = "";
        this.wellId = "";
        this.surveyDate = new Date();
        this.wellName = "";
        this.pAtm = 0;
        this.enthalphy = 0;
        this.remarks = "";
        this.testWells = [];
        this.connectedWells = [];
        this.vessel = new VesselDetail();
        this.testResults = [];
    }

    clone(fptDetail: FptDetail) {
        this.id = fptDetail.id;
        this.wellId = fptDetail.wellId;
        this.surveyDate = fptDetail.surveyDate;
        this.wellName = fptDetail.wellName;
        this.pAtm = fptDetail.pAtm;
        this.remarks = fptDetail.remarks;
        this.testWells = fptDetail.testWells;
        this.connectedWells = fptDetail.connectedWells;
        this.vessel = fptDetail.vessel;
        this.testResults = fptDetail.testResults;
        this.enthalphy = fptDetail.enthalphy;
    }
}

export class FptTestWellDetail implements IFptTestWellDetail {
    sequence;
    fcv;
    whp;

    constructor() {
        this.sequence = 0;
        this.fcv = 0;
        this.whp = 0;
    }

    clone(fptTestWellDetail: FptTestWellDetail) {
        this.sequence = fptTestWellDetail.sequence;
        this.fcv = fptTestWellDetail.fcv;
        this.whp = fptTestWellDetail.whp;
    }
}

export class FptTestVesselDetail implements IFptTestVesselDetail {
    sequence;
    psep;
    wkph;

    constructor() {
        this.sequence = 0;
        this.psep = 0;
        this.wkph = 0;
    }

    clone(fptTestVesselDetail: FptTestVesselDetail) {
        this.sequence = fptTestVesselDetail.sequence;
        this.psep = fptTestVesselDetail.psep;
        this.wkph = fptTestVesselDetail.wkph;
    }
}

export class FptConnectedWellDetail implements IFptConnectedWellDetail {
    wellId;
    wellName;
    enthalphy;
    dWsWhp;
    prodPercentage;
    table: Array<FptTestWellDetail>;

    constructor() {
        this.wellId = "";
        this.wellName = "";
        this.enthalphy = 0;
        this.dWsWhp = 0;
        this.prodPercentage = 0;
        this.table = [];
    }

    clone(fptConnectedWellDetail: FptConnectedWellDetail) {
        this.wellId = fptConnectedWellDetail.wellId;
        this.wellName = fptConnectedWellDetail.wellName;
        this.enthalphy = fptConnectedWellDetail.enthalphy;
        this.dWsWhp = fptConnectedWellDetail.dWsWhp;
        this.prodPercentage = fptConnectedWellDetail.prodPercentage;
        this.table = fptConnectedWellDetail.table;
    }
}

export class VesselDetail implements IVesselDetail {
    vesselGroupId;
    vesselId;
    vesselName;
    table: Array<FptTestVesselDetail>;

    constructor() {
        this.vesselGroupId = "";
        this.vesselId = "";
        this.vesselName = "";
        this.table = [];
    }

    clone(vesselDetail: VesselDetail) {
        this.vesselGroupId = vesselDetail.vesselGroupId;
        this.vesselId = vesselDetail.vesselId;
        this.vesselName = vesselDetail.vesselName;
        this.table = vesselDetail.table;
    }
}

export class FptTestWellResult implements IFptTestWellResult {
    sequence;
    fcv;
    whp;
    psep;
    wkph;
    remarks;

    constructor() {
        this.sequence = 0;
        this.fcv = 0;
        this.whp = 0;
        this.psep = 0;
        this.wkph = 0;
        this.remarks = "";
    }

    clone(fptTestWellResult: FptTestWellResult) {
        this.sequence = fptTestWellResult.sequence;
        this.fcv = fptTestWellResult.fcv;
        this.whp = fptTestWellResult.whp;
        this.psep = fptTestWellResult.psep;
        this.wkph = fptTestWellResult.wkph;
        this.remarks = fptTestWellResult.remarks;
    }
}

export class FptTestWell implements IFptTestWell {
    wellId;
    wellName;
    enthalphy;
    table: Array<FptTestWellDetail>;

    constructor() {
        this.wellId = "";
        this.wellName = "";
        this.enthalphy = 0;
        this.table = [];
    }

    clone(fptTestWell: FptTestWell) {
        this.wellId = fptTestWell.wellId;
        this.wellName = fptTestWell.wellName;
        this.enthalphy = fptTestWell.enthalphy;
        this.table = fptTestWell.table;
    }
}

export class FptTestWellForm implements IFptTestWellForm {
    wellId;
    enthalphy;
    table: Array<FptTestWellDetail>;

    constructor() {
        this.wellId = "";
        this.enthalphy = 0;
        this.table = [];
    }

    clone(fptTestWellForm: FptTestWellForm) {
        this.wellId = fptTestWellForm.wellId;
        this.enthalphy = fptTestWellForm.enthalphy;
        this.table = fptTestWellForm.table;
    }
}

export class FptVesselForm implements IFptVesselForm {
    vesselGroupId;
    table: Array<FptTestVesselDetail>;

    constructor() {
        this.vesselGroupId = "";
        this.table = [];
    }

    clone(fptVesselForm: FptVesselForm) {
        this.vesselGroupId = fptVesselForm.vesselGroupId;
        this.table = fptVesselForm.table;
    }
}

export class FptForm implements IFptForm {
    wellId;
    surveyDate;
    pAtm;
    remarks;
    testWells: Array<FptTestWellForm>;
    vessel;
    connectedWells: Array<FptConnectedWellForm>;
    testResults: Array<FptTestWellResult>;

    constructor() {
        this.wellId = "";
        this.surveyDate = new Date();
        this.pAtm = 0;
        this.remarks = "";
        this.testWells = [];
        this.vessel = new FptVesselForm();
        this.connectedWells = [];
        this.testResults = [];
    }

    clone(fptForm: FptForm) {
        this.wellId = fptForm.wellId;
        this.surveyDate = fptForm.surveyDate;
        this.pAtm = fptForm.pAtm;
        this.remarks = fptForm.remarks;
        this.testWells = fptForm.testWells;
        this.vessel = fptForm.vessel;
        this.connectedWells = fptForm.connectedWells;
        this.testResults = fptForm.testResults;
    }
}