import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Grid } from "@mui/material";
import {
  DecimationSegmentDetailsTable,
  DecimationSegmentTable,
  IDecimationChartDataResponse,
  IDecimationChartSeriesResponse,
} from "../../../../app/models/decimation";
import { useStore } from "../../../../app/stores/store";
import PTDecimationChart from "../PTDecimationChart";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

function PTDecimationViewStep3() {
  const { decimationStore } = useStore();
  const [decimationSegmentTable, setDecimationSegmentTable] = useState<Array<DecimationSegmentTable>>([]);
  const [decimationSegmentTable1, setDecimationSegmentTable1] = useState<Array<DecimationSegmentTable>>([]);
  const [series, setSeries] = useState<Array<IDecimationChartSeriesResponse>>([]);

  const fetchChart = React.useCallback(() => {
    let setSeries1: any = [];
    let setDataTemperature: any = [];
    let setDataPressure: any = [];
    decimationStore.decimation.decimationSegmentDetails
      .filter((x) => !x.isEliminated)
      .forEach((element) => {
        const initialDataTemp1: IDecimationChartDataResponse = {
          x: element.depthDecimated,
          y: element.temperatureDecimated,
        };
        setDataTemperature.push(initialDataTemp1);
        const initialDataPressure1: IDecimationChartDataResponse = {
          x: element.depthDecimated,
          y: element.pressureDecimated,
        };
        setDataPressure.push(initialDataPressure1);
      });

    const initialData: IDecimationChartSeriesResponse = {
      name: "Temperature",
      data: setDataTemperature,
    };

    setSeries1.push(initialData);

    const initialData1: IDecimationChartSeriesResponse = {
      name: "Pressure",
      data: setDataPressure,
    };

    setSeries1.push(initialData1);

    setSeries(setSeries1);
  }, [decimationStore]);

  useEffect(() => {
    let selectedSegments = decimationStore.decimation.selectedSegments;

    if (decimationStore.decimation.decimationSegmentDetails.length > 0) {
      let checkDecimates = decimationStore.decimation.decimationSegmentDetails;
      if (checkDecimates.length > 0) {
        let dataExport: any = [];
        checkDecimates.forEach((x) => {
          const newDecimationSegmentDetail = new DecimationSegmentDetailsTable();
          newDecimationSegmentDetail.sequence = x.sequence;
          newDecimationSegmentDetail.segment = x.segment ?? 0;
          newDecimationSegmentDetail.depthDecimated = x.depthDecimated;
          newDecimationSegmentDetail.speedDecimated = x.speedDecimated;
          newDecimationSegmentDetail.pressureDecimated = x.pressureDecimated;
          newDecimationSegmentDetail.temperatureDecimated = x.temperatureDecimated;
          newDecimationSegmentDetail.isEliminated = x.isEliminated;
          dataExport.push(newDecimationSegmentDetail);
        });
        decimationStore.setDecimationSegmentDetails(dataExport);
        setDecimationSegmentTable1(dataExport);
      }
    } else {
      let checkDecimates = decimationStore.decimation.table.filter((x) => selectedSegments.includes(x.segment ?? 0));
      if (checkDecimates.length > 0) {
        let dataExport: any = [];
        checkDecimates.forEach((x) => {
          const newDecimationSegmentDetail = new DecimationSegmentDetailsTable();
          newDecimationSegmentDetail.sequence = x.sequence;
          newDecimationSegmentDetail.segment = x.segment ?? 0;
          newDecimationSegmentDetail.depthDecimated = x.depth;
          newDecimationSegmentDetail.speedDecimated = x.speed;
          newDecimationSegmentDetail.pressureDecimated = x.pressure;
          newDecimationSegmentDetail.temperatureDecimated = x.temperature;
          newDecimationSegmentDetail.isEliminated = false;
          dataExport.push(newDecimationSegmentDetail);
        });
        decimationStore.setDecimationSegmentDetails(dataExport);
        setDecimationSegmentTable1(dataExport);
      }
    }
    let checkUndecimates = decimationStore.decimation.table.filter((x) => !selectedSegments.includes(x.segment ?? 0));
    if (checkUndecimates.length > 0) {
      setDecimationSegmentTable(checkUndecimates);
    }

    fetchChart();
  }, [decimationStore, fetchChart]);

  const processRowUpdate = useCallback(
    async (newRow: any) => {
      const updatedRow = { ...newRow, isNew: false };
      setDecimationSegmentTable1(decimationSegmentTable1.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
      decimationStore.setDecimationSegmentDetails(
        decimationStore.decimation.decimationSegmentDetails.map((row) => (row.sequence === newRow.sequence ? updatedRow : row))
      );
      fetchChart();
      return updatedRow;
    },
    [decimationSegmentTable1, decimationStore, fetchChart]
  );

  const gridColumns1: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 300, type: "string" },
    { align: "center", headerAlign: "center", field: "depth", headerName: "Depth", flex: 1, type: "number" },
    { align: "center", headerAlign: "center", field: "pressure", headerName: "Pressure", flex: 1, type: "number" },
    { align: "center", headerAlign: "center", field: "temperature", headerName: "Temperature", flex: 1, type: "number" },
  ];

  const gridColumns2: GridColDef[] = [
    {
      align: "center",
      headerAlign: "center",
      field: "isEliminated",
      headerName: "Eliminate",
      width: 100,
      type: "boolean",
      renderCell: (params) => {
        return params.value ? <Checkbox disabled defaultChecked /> : <Checkbox disabled />;
      },
    },
    { align: "center", headerAlign: "center", field: "depthDecimated", headerName: "Depth", flex: 1, type: "number" },
    { align: "center", headerAlign: "center", field: "pressureDecimated", headerName: "Pressure", flex: 1, type: "number" },
    { align: "center", headerAlign: "center", field: "temperatureDecimated", headerName: "Temperature", flex: 1, type: "number" },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left" }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <b>Undecimated Data Set</b>
          <StyledDataGridPremium
            pagination
            showColumnVerticalBorder
            showCellVerticalBorder
            columns={gridColumns1}
            rows={decimationSegmentTable}
            getRowId={(row) => row.sequence}
            autoHeight
          />
        </Grid>
        <Grid item xs={6}>
          <b>Decimated Data Set</b>
          <StyledDataGridPremium
            columns={gridColumns2}
            rows={decimationSegmentTable1}
            getRowId={(row) => row.sequence}
            autoHeight
            processRowUpdate={processRowUpdate}
            pagination
            showColumnVerticalBorder
            showCellVerticalBorder
          />
        </Grid>
      </Grid>
      {/* <Box sx={{ width: "100%" }}>
        <b>Grafik Temperature vs Pressure per Segment</b>
        <PTDecimationChart series={series} />
      </Box> */}
    </Box>
  );
}

export default PTDecimationViewStep3;
