import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-premium";
import {
  DecimationSegmentTable,
  defaultDecimatedData,
  IDecimationChartDataResponse,
  IDecimationChartSeriesResponse,
} from "../../../app/models/decimation";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import PTDecimationChart from "./PTDecimationChart";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { Add, Delete } from "@mui/icons-material";
import { interpolateData } from "../../../app/config/utils";

const typeData = [
  {
    name: "Manual",
    value: "manual",
  },
  {
    name: "From to Depth",
    value: "fromToDepth",
  },
  {
    name: "Gradient Pressure",
    value: "gradientPressure",
  },
  {
    name: "Gradient Temperature",
    value: "gradientTemperature",
  },
];

function PTDecimationStep3() {
  const { decimationStore } = useStore();
  const [decimationSegmentTable, setDecimationSegmentTable] = useState<Array<DecimationSegmentTable>>([]);
  const [decimationSegmentTable1, setDecimationSegmentTable1] = useState<Array<DecimationSegmentTable>>([]);
  const [series, setSeries] = useState<Array<IDecimationChartSeriesResponse>>([]);
  const [typeSelected, setTypeSelected] = useState<string>("manual");
  const [depth, setDepth] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [interval, setInterval] = useState<number>(0);
  const [gapPressure, setGapPressure] = useState<number>(0);
  const [gapTemperature, setGapTemperature] = useState<number>(0);
  const [deafultValue, setDeafultValue] = useState<number>(0);

  const fetchChart = React.useCallback(() => {
    let setSeries1: any = [];
    let setDataTemperature: any = [];
    let setDataPressure: any = [];
    decimationStore.decimation.decimationSegmentDetails
      .filter((x) => !x.isEliminated)
      .forEach((element) => {
        const initialDataTemp1: IDecimationChartDataResponse = {
          x: element.depthDecimated,
          y: element.temperatureDecimated,
        };
        setDataTemperature.push(initialDataTemp1);
        const initialDataPressure1: IDecimationChartDataResponse = {
          x: element.depthDecimated,
          y: element.pressureDecimated,
        };
        setDataPressure.push(initialDataPressure1);
      });

    const initialData: IDecimationChartSeriesResponse = {
      name: "Temperature",
      data: setDataTemperature,
    };

    setSeries1.push(initialData);

    const initialData1: IDecimationChartSeriesResponse = {
      name: "Pressure",
      data: setDataPressure,
    };

    setSeries1.push(initialData1);

    setSeries(setSeries1);
  }, [decimationStore]);

  useEffect(() => {
    let selectedSegments = decimationStore.decimation.selectedSegments;
    let checkUndecimates = decimationStore.decimation.table.filter((x) => selectedSegments.includes(x.segment ?? 0));

    if (checkUndecimates.length > 0) {
      let tempDecimationSegmentTable1 = checkUndecimates.map((item: any) => ({
        ...item,
        depthDecimated: Math.round(item.depth),
        pressureDecimated: Math.round(item.pressure),
        temperatureDecimated: Math.round(item.temperature),
      }));
      setDecimationSegmentTable([...checkUndecimates].sort((a, b) => a.depth - b.depth));
      decimationStore.setDecimationSegmentDetails(tempDecimationSegmentTable1);
      // setDecimationSegmentTable1(tempDecimationSegmentTable1);
      setDecimationSegmentTable1([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processRowUpdate = (newRow: any) => {
    const updatedRow = { ...newRow, isNew: false };
    setDecimationSegmentTable1(decimationSegmentTable1.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
    decimationStore.setDecimationSegmentDetails(
      decimationStore.decimation.decimationSegmentDetails.map((row) => (row.sequence === newRow.sequence ? updatedRow : row))
    );

    fetchChart();
    return updatedRow;
  };

  const gridColumns1: GridColDef[] = [
    { field: "depth", headerName: "Depth", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
    { field: "pressure", headerName: "Pressure", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
    { field: "temperature", headerName: "Temperature", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
  ];

  const gridColumns2: GridColDef[] = [
    { field: "depthDecimated", headerName: "Depth", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
    { field: "pressureDecimated", headerName: "Pressure", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
    { field: "temperatureDecimated", headerName: "Temperature", flex: 1, type: "number", align: "center", headerAlign: "center", sortable: false },
    {
      field: "isEliminated",
      sortable: false,
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Delete onClick={() => deleteDecimatedData(params.row.sequence)} sx={{ cursor: "pointer", color: "red" }} />,
    },
  ];

  const addDecimatedData = () => {
    let temp: any = [...decimationSegmentTable1];

    if (typeSelected === "manual") {
      let manualTemporary = [
        ...decimationSegmentTable,
        ...[{ ...defaultDecimatedData, depth: depth, sequence: decimationSegmentTable1.length + 1 }],
      ].sort((a, b) => a.depth - b.depth);
      let dataIndex = [...manualTemporary].findIndex((item) => item.depth === depth);
      let interpolatePressure = interpolateData(manualTemporary as any, depth, dataIndex, "pressure");
      let interpolateTemperature = interpolateData(manualTemporary as any, depth, dataIndex, "temperature");

      temp.push({
        ...defaultDecimatedData,
        sequence: decimationSegmentTable1.length + 1,
        pressureDecimated: interpolatePressure,
        temperatureDecimated: interpolateTemperature,
        depthDecimated: depth,
      });
    } else {
      let fromToDepthTemporary = [defaultDecimatedData];
      let count = from;
      let sequence = temp.length + 1;

      let i = from;
      while (i <= to) {
        if (count <= to) {
          let eliminateData = i === from ? from : count;

          let manualTemporary = [
            ...fromToDepthTemporary,
            ...decimationSegmentTable,
            ...[{ ...defaultDecimatedData, depth: eliminateData, sequence: decimationSegmentTable1.length + 1 }],
          ].sort((a, b) => a.depth - b.depth);

          let dataIndex = [...manualTemporary].findIndex((item) => item.depth === eliminateData);
          let interpolatePressure = interpolateData(manualTemporary as any, eliminateData, dataIndex, "pressure");
          let interpolateTemperature = interpolateData(manualTemporary as any, eliminateData, dataIndex, "temperature");

          let finalInterpolatePressure =
            typeSelected === "gradientPressure" ? (interpolatePressure > gapPressure ? deafultValue : interpolatePressure) : interpolatePressure;
          let finalInterpolateTemperature =
            typeSelected === "gradientTemperature"
              ? interpolateTemperature > gapTemperature
                ? deafultValue
                : interpolateTemperature
              : interpolateTemperature;

          temp.push({
            ...defaultDecimatedData,
            sequence: sequence,
            pressureDecimated: finalInterpolatePressure,
            temperatureDecimated: finalInterpolateTemperature,
            depthDecimated: i === from ? from : count,
          });

          fromToDepthTemporary.push({
            ...defaultDecimatedData,
            sequence: sequence,
            pressureDecimated: finalInterpolatePressure,
            temperatureDecimated: finalInterpolateTemperature,
            depthDecimated: i === from ? from : count,
          });
        }
        count += interval;
        sequence += 1;
        i += interval;
      }
    }

    setDecimationSegmentTable1(temp);
    decimationStore.setDecimationSegmentDetails(temp);
  };

  const deleteDecimatedData = (sequence: number) => {
    let temp: any = [...decimationSegmentTable1]
      .filter((item) => item.sequence !== sequence)
      .map((item, index) => ({ ...item, sequence: index + 1 }));

    setDecimationSegmentTable1(temp);
    decimationStore.setDecimationSegmentDetails(temp);
  };

  const generateDynamicField = () => {
    if (typeSelected === "manual")
      return <TextField fullWidth type="number" label="Depth" value={depth} onChange={(e) => setDepth(Number(e.target.value))} />;

    if (typeSelected === "fromToDepth")
      return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
          <TextField fullWidth type="number" label="From" value={from} onChange={(e) => setFrom(Number(e.target.value))} />
          <TextField fullWidth type="number" label="To" value={to} onChange={(e) => setTo(Number(e.target.value))} />
          <TextField fullWidth type="number" label="Interval" value={interval} onChange={(e) => setInterval(Number(e.target.value))} />
        </Box>
      );

    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
        <TextField fullWidth type="number" label="From" value={from} onChange={(e) => setFrom(Number(e.target.value))} />
        <TextField fullWidth type="number" label="To" value={to} onChange={(e) => setTo(Number(e.target.value))} />
        <TextField fullWidth type="number" label="Interval" value={interval} onChange={(e) => setInterval(Number(e.target.value))} />
        {typeSelected === "fromToDepth" ? undefined : (
          <>
            <TextField
              fullWidth
              type="number"
              label={typeSelected === "gradientTemperature" ? "Gap Temperature" : "Gap Pressure"}
              value={typeSelected === "gradientTemperature" ? gapTemperature : gapPressure}
              onChange={(e) =>
                typeSelected === "gradientTemperature" ? setGapTemperature(Number(e.target.value)) : setGapPressure(Number(e.target.value))
              }
            />
            <TextField fullWidth type="number" label="Default Value" value={deafultValue} onChange={(e) => setDeafultValue(Number(e.target.value))} />
          </>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Type</InputLabel>
            <Select label="Type" value={typeSelected} onChange={(e) => setTypeSelected(e.target.value)}>
              {typeData.map((item) => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", gap: "12px" }}>
            {generateDynamicField()}
            <Button variant="contained" onClick={addDecimatedData}>
              <Add />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <b>Undecimated Data Set</b>
          <StyledDataGridPremium
            columns={gridColumns1}
            rows={decimationSegmentTable}
            getRowId={(row) => row.sequence}
            sx={{ mt: "6px" }}
            autoHeight
            pagination
            showColumnVerticalBorder
            showCellVerticalBorder
          />
        </Grid>
        <Grid item xs={6}>
          <b>Decimated Data Set</b>
          <StyledDataGridPremium
            sx={{ mt: "6px" }}
            columns={gridColumns2}
            pagination
            showColumnVerticalBorder
            showCellVerticalBorder
            rows={decimationSegmentTable1}
            getRowId={(row) => row.sequence}
            autoHeight
            processRowUpdate={processRowUpdate}
          />
        </Grid>
      </Grid>
      {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left", mt: "24px" }}>
        <b>Grafik Temperature vs Pressure per Segment</b>
        <PTDecimationChart series={series} />
      </Box> */}
    </Box>
  );
}

export default PTDecimationStep3;
