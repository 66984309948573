import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import React, { useEffect } from "react";
import { IDailyChart } from "../../../../app/models/dailysheet";

interface IProps {
  series: Array<IDailyChart> | undefined;
}

const initCharts: IDailyChart[] = [
  {
    name: "",
    data: [
      {
        x: "",
        y: null,
      },
    ],
  },
];

function DailysheetCharts(props: IProps) {
  const [series, setSeries] = React.useState(initCharts);

  useEffect(() => {
    if (props.series) {
      setSeries(props.series);
    }
  }, [props.series]);

  const options: ApexCharts.ApexOptions = {
    xaxis: {
      type: "category",
      tickAmount: 8,
    },
    // yaxis: {
    //   title: {
    //     text: y_label.toLocaleUpperCase()
    //   },
    //   max:  Math.max.apply(Math, data.map((item) => item[y_label])),
    // },
    // title: {
    //   align: 'left',
    //   text: 'Well',
    // },
  };

  return <ReactApexChart options={options} series={series} />;
}

export default DailysheetCharts;
