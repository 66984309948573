export type GeneralObjectFile = {
  contentDisposition: string;
  contentType: string;
  fileName: string;
  headers: {
    "Content-Disposition": string[];
    "Content-Type": string[];
    length: number;
    name: string;
  };
};

export type TPTSData = {
  id: string;
  wellId: string;
  ptsDate: Date;
  wellCondition: string;
  wellName: string;
  fileName: string;
  fileUrl: string;
  file: GeneralObjectFile;
};

export interface IPtsTable {
  id: string;
  ptsDate: Date;
  objectId: string;
  objectName: string;
}

export interface IPtsSelectors {
  value: string;
  text: string;
  additionalText?: string;
}

export interface IPtsRequest {
  ptsDate: Date;
  objectId: string;
  table: Array<PtsTableRequest>;
}

export interface IPtsTableRequest {
  sequence: number;
  time: Date;
  depth: number;
  rate: number;
  press: number;
  temp: number;
  spin: number;
  ttemp: number;
}

export class PtsTableRequest implements IPtsTableRequest {
  sequence;
  time;
  depth;
  rate;
  press;
  temp;
  spin;
  ttemp;

  constructor() {
    this.sequence = 0;
    this.time = new Date();
    this.depth = 0;
    this.rate = 0;
    this.press = 0;
    this.temp = 0;
    this.spin = 0;
    this.ttemp = 0;
  }

  clone(ptsTableRequest: IPtsTableRequest) {
    this.sequence = ptsTableRequest.sequence;
    this.time = ptsTableRequest.time;
    this.depth = ptsTableRequest.depth;
    this.rate = ptsTableRequest.rate;
    this.press = ptsTableRequest.press;
    this.temp = ptsTableRequest.temp;
    this.spin = ptsTableRequest.spin;
    this.ttemp = ptsTableRequest.ttemp;
  }
}

export class Pts implements IPtsRequest {
  ptsDate;
  objectId;
  table: Array<PtsTableRequest>;

  constructor() {
    this.ptsDate = new Date();
    this.objectId = "";
    this.table = [];
  }

  clone(pts: IPtsRequest) {
    this.ptsDate = pts.ptsDate;
    this.objectId = pts.objectId;
    this.table = pts.table;
  }
}
