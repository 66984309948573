import { capitalize, Select, FormControl, MenuItem, InputLabel, SelectProps, FormHelperText  } from '@mui/material';
import { useField } from "formik";
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { RkdFormikContext } from './RkdForm';


interface Props extends SelectProps{
  label: string;
  list: Array<any>;
}

function RkdSelect(props: Props) {
  const { list, label, ...otherProps } = props;
  const [field, meta] = useField(props.name!);  
  const context = useContext(RkdFormikContext);  
  const [optionValue, setOptionValue] = useState(context?.values[props.name!] ? context?.values[props.name!] : '');
  const handleOnChange = (e: any) => {
    context!.setFieldValue(props.name!, e.target.value);
    setOptionValue(e.target.value);    
  }
  
  return (
    <FormControl fullWidth>
      <InputLabel id="selectId" sx={{ bgcolor: '#ffffff' }}>{label}</InputLabel>
      <Select
        sx={{ mb: 1 }}                
        labelId="selectId" 
        displayEmpty                                                                          
        onChange={(e) => handleOnChange(e)}
        value={optionValue}                
        {...otherProps}
      >        
        {/* <MenuItem key={0} value=""></MenuItem>           */}
        {list.map((item, index) => (
          <MenuItem key={index+1} value={item.value}>{item.text}</MenuItem>          
        ))}                
      </Select>   
      <FormHelperText sx={{ mb: 1 }}>{meta.error && capitalize(meta.error)}</FormHelperText>            
    </FormControl>
  );
}

export default observer(RkdSelect);