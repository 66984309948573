import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { TGeophysictGravityChartData, TGeophysictGravityChartResponse } from "../../../app/models/geophysics";
import { Box } from "@mui/material";

function GravitySurveyChart() {
  const { getGravitySurveyChart, selectedGeophysicGravityId } = useStore().geophysicsGravityGridStore;
  const [chartData, setChartData] = useState<TGeophysictGravityChartData[]>([]);

  useEffect(() => {
    getGravitySurveyChart(selectedGeophysicGravityId).then((res) => setChartData(res.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("chartData", chartData);

  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="index" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={Math.max(...chartData.map((item) => item.svd))} label="Max SVD" stroke="red" />
          <ReferenceLine y={Math.max(...chartData.map((item) => item.fhd))} label="Max FHD" stroke="red" />
          <Line type="monotone" dataKey="svd" stroke="#8884d8" />
          <Line type="monotone" dataKey="fhd" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default observer(GravitySurveyChart);
