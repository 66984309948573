import { observer } from "mobx-react-lite";
import { IFPTChartDataResponse, IFPTChartSeriesResponse } from "../../app/models/fpt";
import Chart from 'react-apexcharts'
import { useState } from "react";
import { Button } from "@mui/material";
import { useStore } from "../../app/stores/store";

interface IProps {
    series: Array<IFPTChartSeriesResponse>
  //   yAxis: Array<IPtsChartYAxis>
}

function FPTChart(props: IProps){
  const { ftpStore } = useStore();
  const [ series, setSeries] = useState(props.series);

  const options: ApexCharts.ApexOptions  = {
      xaxis: {
        type: 'numeric',
        title: {
          text: 'Test Well WHP (psig)'
        }
      },
      markers:{
        size: 6
      },
      yaxis: [
        {
          title: {
            text: "Ws (kph)"
          },
        }
      ]
  };

  const handleRefresh = () => {
    ftpStore.setFPTChart([]);
    let arrayChartSeries : any = [];

    let arrayChart : Array<IFPTChartDataResponse> = []
    ftpStore.fpt.vessel.table.forEach(data => {
        let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
            x : data.psep,
            y : data.wkph
        }
        arrayChart.push(initialDataFPTChartDataResponse);
    });
    let initialDataFPTChartSeriesResponse : IFPTChartSeriesResponse = {
        name : "Vessel",
        data : arrayChart
    }
    arrayChartSeries.push(initialDataFPTChartSeriesResponse);

    arrayChart = [];
    ftpStore.fpt.testResults.forEach(data => {
        let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
            x : data.psep,
            y : data.whp
        }
        arrayChart.push(initialDataFPTChartDataResponse);
    });
    initialDataFPTChartSeriesResponse = {
        name : "Test Well",
        data : arrayChart
    }
    arrayChartSeries.push(initialDataFPTChartSeriesResponse);

    arrayChart = [];
    ftpStore.fpt.connectedWells.forEach(data => {
        data.table.forEach(data1 => {
            let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
                x : data1.whp,
                y : data1.fcv
            }
            arrayChart.push(initialDataFPTChartDataResponse);
        })
    });
    initialDataFPTChartSeriesResponse = {
        name : "Other Well(s)",
        data : arrayChart
    }
    arrayChartSeries.push(initialDataFPTChartSeriesResponse);
    ftpStore.setFPTChart(arrayChartSeries);

    setSeries(ftpStore.fptChart);
  }
  
  return (
    <div>
      <Button variant='contained' color='primary' onClick={handleRefresh} sx={{ width: '10%' }}>Refresh Chart</Button>
      <Chart options={options} series={series} type="scatter" />
    </div>
  )
}

export default observer(FPTChart);