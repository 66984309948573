import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Tab, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import ReportGrid from "./components/ReportGrid";
import ReportLineChart from "./components/ReportLineChart";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { TPtReportWellDataResponse } from "../../../app/models/ptModule";

type TDisplaySelectedWell = {
  wellName: string;
  wellId: string;
  date: string;
  table: TPtReportWellDataResponse[];
};

function PTReportForm() {
  const { ptModuleStore } = useStore();
  const { getPtReportWellOptions, ptReportWellOptions, getPtReportWellData, ptReportWellData, getDecimationReportFile, loading } = ptModuleStore;
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedWell, setSelectedWell] = useState("");
  const [displaySelectedGridData, setDisplaySelectedGridData] = useState<TDisplaySelectedWell[]>([]);
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => setTabValue(newValue);

  const handleAddGridData = () => {
    let temp = [...displaySelectedGridData];

    temp.push({
      wellId: selectedWell,
      wellName: ptReportWellOptions.find((item) => item.value === selectedWell)?.text ?? "",
      date: format(new Date(selectedDate ?? ""), "dd-MM-YYY"),
      table: ptReportWellData,
    });

    setDisplaySelectedGridData(temp);
  };

  const handleDownloadExcel = () => {
    const payload = {
      data: displaySelectedGridData.map((item) => ({
        wellName: item.wellName,
        table: item.table,
      })),
    };

    getDecimationReportFile(payload);
  };

  useEffect(() => {
    getPtReportWellOptions(format(new Date(selectedDate ?? ""), "yyyy-MM-dd"));
  }, [getPtReportWellOptions, selectedDate]);

  useEffect(() => {
    if (!selectedWell) return;

    const payload = {
      date: format(new Date(selectedDate ?? ""), "yyyy-MM-dd"),
      wellIds: [selectedWell],
    };

    getPtReportWellData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPtReportWellData, selectedWell]);

  return (
    <RkdCard title="PT Report" subtitle="View & Download PT Data from This Page">
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={6} sx={{ display: "flex", gap: "12px" }}>
          <LocalizationProvider style={{ width: "100%", flex: 1 }} dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(e: any) => setSelectedDate(e)}
              value={selectedDate}
              inputFormat="dd MMM yyyy"
              label="Date"
              renderInput={(props) => <TextField style={{ width: "100%" }} label="Date" {...props} />}
            />
          </LocalizationProvider>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Well</InputLabel>
            <Select value={selectedWell} label="Well" onChange={(e) => setSelectedWell(e.target.value)}>
              {ptReportWellOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap="12px">
          <LoadingButton
            disabled={!selectedWell}
            loading={loading}
            sx={{ height: "100%" }}
            component="label"
            variant="contained"
            onClick={handleAddGridData}
          >
            Add
          </LoadingButton>
          <LoadingButton
            disabled={!displaySelectedGridData.length}
            loading={loading}
            sx={{ height: "100%" }}
            component="label"
            variant="contained"
            onClick={handleDownloadExcel}
          >
            Download
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          {!!displaySelectedGridData.length ? (
            <Box>
              <Divider sx={{ mt: "12px", mb: "24px" }} />
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Generate Grids
              </Typography>
              <Box sx={{ width: "100%", typography: "body1", mb: "24px", mt: "12px" }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      {displaySelectedGridData?.map((item: TDisplaySelectedWell, index: number) => (
                        <Tab key={item.wellName} label={`${item.wellName} (${item.date})`} value={(index + 1).toString()} />
                      ))}
                    </TabList>
                  </Box>
                  {displaySelectedGridData.map((item, index) => (
                    <TabPanel sx={{ color: "black" }} value={(index + 1).toString()}>
                      <ReportGrid data={item.table} />
                      <ReportLineChart data={item.table} />
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            </Box>
          ) : undefined}
        </Grid>
      </Grid>
    </RkdCard>
  );
}

export default observer(PTReportForm);
