import { action, makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  TPtModuleDetailDataResponse,
  TPtModuleWellReferenceOptionsResponse,
  TPtReportDownloadPayload,
  TPtReportWellDataPayload,
  TPtReportWellDataResponse,
  TPtReportWellOptions,
} from "../models/ptModule";

export default class PtModuleStore {
  loading = false;
  loadingWellReference = false;
  ptModuleDetail: TPtModuleDetailDataResponse | undefined = undefined;
  ptModuleWellReferenceOptions: TPtModuleWellReferenceOptionsResponse[] = [];
  ptReportWellOptions: TPtReportWellOptions[] = [];
  ptReportWellData: TPtReportWellDataResponse[] = [];

  constructor() {
    makeAutoObservable(this, {
      createPtModule: action,
    });
  }

  getPtModuleDetail = async (decimationId: string) => {
    this.loading = true;

    try {
      const result = await agent.PtModule.detail(decimationId);

      runInAction(() => (this.ptModuleDetail = result.data));
    } catch (error) {
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createPtModule = async (decimationPayload: TPtModuleDetailDataResponse) => {
    this.loading = true;

    try {
      await agent.PtModule.create(decimationPayload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getWellReference = async (excludeWellId: string, excluedeSurveyDate: Date) => {
    this.loadingWellReference = true;

    try {
      const result = await agent.PtModule.getWellReferenceOptions(excludeWellId, excluedeSurveyDate);

      runInAction(() => (this.ptModuleWellReferenceOptions = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loadingWellReference = false));
    }
  };

  getDecimationReference = async (decimationId: string, date: string) => {
    this.loading = true;

    try {
      return await agent.PtModule.getDecimationReference(decimationId, date);
    } catch (error) {
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getPtReportWellOptions = async (date: string) => {
    this.loading = true;

    try {
      const result = await agent.PtModule.getPtReportWellOptions(date);

      runInAction(() => (this.ptReportWellOptions = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getPtReportWellData = async (payload: TPtReportWellDataPayload) => {
    this.loading = true;

    try {
      const result = await agent.PtModule.getPtReportWellData(payload);

      runInAction(() => (this.ptReportWellData = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getDecimationReportFile = async (payload: TPtReportDownloadPayload) => {
    this.loading = true;

    try {
      return await agent.PtModule.downloadDecimationReport(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
