import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { observer } from "mobx-react-lite";
import { capitalizeFirstLetter } from "../../../../app/config/enum";

type TStep2LineChartProps = {
  values: any;
};

function Step3LineChart({ values }: TStep2LineChartProps) {
  const { step3 } = values;

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={step3?.details?.map((item: any) => ({
          name: `${capitalizeFirstLetter(step3.zAxis)} ${item.depthOrElevation}`,
          valueSelectionP: item.valueSelectionP,
          tsat: item.tsat,
          deltaTSat: item.deltaTSat,
        }))}
        margin={{ right: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12, fontStyle: "italic", offset: "-5" }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line strokeWidth={2} label={step3?.selectionP} dataKey="valueSelectionP" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line strokeWidth={2} dataKey="tsat" stroke="#82ca9d" />
        <Line strokeWidth={2} dataKey="deltaTSat" stroke="red" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default observer(Step3LineChart);
