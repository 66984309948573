import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { observer } from "mobx-react-lite";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import WellGeometryDropdownExcel from "./WellGeometryDropdownExcel";
import WellGeometryCompletionChart from "./WellGeometryCompletionChart";
import { useNavigate, useParams } from "react-router-dom";
import WellGeometryCompletionGridHole from "./WellGeometryCompletionGridHole";
import WellGeometryCompletionGridOD from "./WellGeometryCompletionGridOD";
import WellGeometryCompletionGridID from "./WellGeometryCompletionGridID";
import { IObjectOptions } from "../../../app/models/object";
import { LoadingButton } from "@mui/lab";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const columnsHole = ["fromMD", "toMD", "holeDiameter"];
const columnsOD = ["fromMD", "toMD", "cassingType", "csgOD"];
const columnsID = ["fromMD", "toMD", "cassingType", "csgDescription", "csgOD", "csgID"];

const WellGeometryCompletionForm = () => {
  const { commonStore, wellGeometryStore, objectStore } = useStore();
  const [selectedObject, setSelectedObject] = useState<TSelectedData | undefined>(undefined);
  const navigate = useNavigate();
  const params = useParams();
  const [id] = useState(params.id);

  const [objectType, setObjectType] = useState<Array<IObjectOptions>>([]);
  const [objectId, setObjectId] = useState("");
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOnChangeObjectId = (val: TSelectedData) => {
    setObjectId(val.value);
  };

  useEffect(() => {
    commonStore.setTitlePage("Well Geometry");
  });

  useEffect(() => {
    objectStore.getObjectOptions("").then((res) => {
      setObjectType(res);
    });
  }, [objectStore]);

  useEffect(() => {
    let objectTemp: IObjectOptions[] = [];

    const fetchObject = async () => {
      await objectStore.getObjectOptions("").then((res) => {
        setObjectType(res);
        objectTemp = res;
      });
    };

    fetchObject();

    if (id) {
      wellGeometryStore.getWellGeometryCompletionDetail(id).then((res) => {
        let temp = objectTemp.find((item) => item.value === res.objectId);

        setSelectedObject({ label: temp?.text ?? "", value: temp?.value ?? "" });
        wellGeometryStore.setCompletionData(res);
        setObjectId(res.objectId);
      });
    } else {
      setObjectId("");
      wellGeometryStore.setCompletionData(null);
    }
  }, [id, wellGeometryStore]);

  const handleSubmit = () => {
    setLoading(true);
    if (id) {
      wellGeometryStore
        .editCompletionData(objectId, wellGeometryStore.completionHole, wellGeometryStore.completionOD, wellGeometryStore.completionID)
        .then(() => {
          navigate("/master/geometry");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      wellGeometryStore
        .createCompletionData(objectId, wellGeometryStore.completionHole, wellGeometryStore.completionOD, wellGeometryStore.completionID)
        .then(() => {
          navigate("/master/geometry");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (wellGeometryStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title={id ? "Edit Well Geometry Completion" : `Add Well Geometry Completion`}>
      <Grid container>
        <Grid item xs={12}>
          <WellGeometryDropdownExcel
            columns={[columnsHole, columnsOD, columnsID]}
            setData={wellGeometryStore.setCompletionDataExcel}
            type="completion"
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Autocomplete
            disablePortal
            fullWidth
            sx={{ marginBotom: 0 }}
            onChange={(e: any, val: any) => {
              setSelectedObject(val);
              handleOnChangeObjectId(val);
            }}
            disableClearable
            value={selectedObject}
            getOptionLabel={(option) => option.label}
            options={objectType.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => <TextField value={selectedObject?.label} name="objectId" {...params} label="Object Name *" />}
          />
        </Grid>
        <Grid item xs={12}>
          <WellGeometryCompletionChart
            data={[wellGeometryStore.completionHole, wellGeometryStore.completionOD, wellGeometryStore.completionID]}
            lines={["holeDiameter", "csgOD", "csgID"]}
          />
        </Grid>

        <Grid item xs={5}>
          <WellGeometryCompletionGridHole data={wellGeometryStore.completionHole} />
        </Grid>
        <Grid item xs={7}>
          <WellGeometryCompletionGridOD data={wellGeometryStore.completionOD} />
        </Grid>
        <Grid item xs={12}>
          <WellGeometryCompletionGridID data={wellGeometryStore.completionID} />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="info"
              sx={{ mr: "8px" }}
              onClick={() => {
                navigate("/master/geometry");
              }}
            >
              Cancel
            </Button>
            <LoadingButton variant="contained" color="primary" onClick={handleSubmit} loading={loading} disabled={loading || objectId === ""}>
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </RkdCard>
  );
};

export default observer(WellGeometryCompletionForm);
