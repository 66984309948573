import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridValueFormatterParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { ISteamResultTables } from "../../app/models/steam";
import { useStore } from "../../app/stores/store";
import {
  calculateStandardDeviation,
  calculateSteamRsd,
  corrSteamFlowFormula,
  saturationPressure,
  steamFlowRateFormula,
  SteamFlowRateSpreadFormula,
} from "./helper/steam/formula";
import { calculateAverageSteamResult, calculatePressure } from "./helper/tftFormula";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format } from "date-fns";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";

function TFTProcessSteamStep4() {
  const { tftSteamStore } = useStore();

  const [results, setResults] = useState(tftSteamStore.tft.steam.tftSteamResult.table);

  const renderEditTimeStartInputCell: GridColDef["renderCell"] = (params) => {
    let timeDefault = params.value;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Time Start"
          value={params.row.timeStart}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
          mask="__:__"
          onChange={(newValue, date) => {
            if (!isNaN(newValue)) {
              if (newValue === null) {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: timeDefault,
                });
              } else {
                let newTime = new Date();
                let splitTime = date?.split(":");
                if (splitTime !== undefined) {
                  newTime.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
                }
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newTime,
                });
              }
            } else {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: timeDefault,
              });
            }
          }}
          renderInput={(props) => <TextField label="Date" sx={{ my: 1 }} {...props} />}
        />
      </LocalizationProvider>
    );
  };

  const renderEditTimeEndInputCell: GridColDef["renderCell"] = (params) => {
    let timeDefault = params.value;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Time End"
          value={params.row.timeEnd}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
          mask="__:__"
          onChange={(newValue, date) => {
            if (!isNaN(newValue)) {
              if (newValue === null) {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: timeDefault,
                });
              } else {
                let newTime = new Date();
                let splitTime = date?.split(":");
                if (splitTime !== undefined) {
                  newTime.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
                }
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newTime,
                });
              }
            } else {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: timeDefault,
              });
            }
          }}
          renderInput={(props) => <TextField label="Date" sx={{ my: 1 }} {...props} />}
        />
      </LocalizationProvider>
    );
  };

  const handleRowEditResult = useCallback(
    (newValue: GridValidRowModel) => {
      const data: ISteamResultTables[] = tftSteamStore.tft.steam.tftSteamResult.table.map((current) => {
        if (current.sequence !== newValue.sequence) return current;

        let ppmw = 0;

        if (tftSteamStore.tft.steam.ampleAnalysis.table[newValue.sequence - 1].ppMw) {
          ppmw = tftSteamStore.tft.steam.ampleAnalysis.table[newValue.sequence - 1].ppMw;
        }
        const newSatPress = saturationPressure(calculatePressure(newValue.separatorTemp), tftSteamStore.tft.baroPress);
        const newSteamFlowRate = steamFlowRateFormula(
          ppmw,
          tftSteamStore.tft.steam.drycallPTFCorrections[0].injectionRateAverrage,
          tftSteamStore.tft.volSF6,
          tftSteamStore.tft.steamTracerMW,
          tftSteamStore.tft.baselineSF6
        );

        return {
          sequence: newValue.sequence,
          labNumber: newValue.labNumber,
          timeStart: newValue.timeStart,
          timeEnd: newValue.timeEnd,
          separatorTemp: newValue.separatorTemp,
          pressPsig: newValue.pressPsig,
          saturationPressure: newSatPress,
          injRateSLPM: tftSteamStore.tft.steam.drycallPTFCorrections[0].injectionRateAverrage,
          ppmTracer: ppmw,
          steamFlowRate: newSteamFlowRate,
        };
      });
      setResults(data);

      return newValue;
    },
    [tftSteamStore]
  );

  useEffect(() => {
    setResults(tftSteamStore.tft.steam.tftSteamResult.table);
  }, [tftSteamStore.tft.steam.tftSteamResult.table]);

  const columnResult: GridColDef[] = [
    {
      field: "labNumber",
      headerName: "Lab Number",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "timeStart",
      headerName: "Time Start",
      width: 200,
      type: "dateTime",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      valueFormatter: (params: GridValueFormatterParams) => {
        return format(new Date(params.value), "HH:mm");
      },
      renderEditCell: renderEditTimeStartInputCell,
    },
    {
      field: "timeEnd",
      headerName: "Time End",
      width: 200,
      type: "dateTime",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      valueFormatter: (params: GridValueFormatterParams) => {
        return format(new Date(params.value), "HH:mm");
      },
      renderEditCell: renderEditTimeEndInputCell,
    },
    {
      field: "separatorTemp",
      headerName: "Separator Temp, F",
      width: 200,
      type: "number",
      editable: true,
      groupable: false,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      valueGetter: (params: any) => {
        if (params.value == null) {
          return null;
        }

        return params.value;
      },
    },
    {
      field: "pressPsig",
      headerName: "Press, psig",
      width: 200,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "saturationPressure",
      headerName: "Saturation Pressure, psig",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        if (params.row.saturationPressure == null) {
          return <Typography variant="body1">Out Of Range</Typography>;
        } else {
          return <Typography variant="body1">{params.row.saturationPressure}</Typography>;
        }
      },
    },
    {
      field: "injRateSLPM",
      headerName: "5.00% SF6 Inj Rate, SLPM",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "ppmTracer",
      headerName: "PPM, Tracer in steam",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "steamFlowRate",
      headerName: "Steam Flow Rate, KPH",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
  ];

  useEffect(() => {
    tftSteamStore.setSteamResultTable(results);
  }, [results, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setAvgSeparatorTempSteam(calculateAverageSteamResult(tftSteamStore.tft.steam.tftSteamResult.table, "separatorTemp"));
    tftSteamStore.setAvgPressPsigSteam(calculateAverageSteamResult(tftSteamStore.tft.steam.tftSteamResult.table, "pressPsig"));
    tftSteamStore.setAvgSaturationPressureSteam(calculateAverageSteamResult(tftSteamStore.tft.steam.tftSteamResult.table, "saturationPressure"));
    tftSteamStore.setAvgSteamFlowRateSteam(calculateAverageSteamResult(tftSteamStore.tft.steam.tftSteamResult.table, "steamFlowRate"));
    tftSteamStore.setSteamFlowRateSpread(SteamFlowRateSpreadFormula(tftSteamStore.tft.steam.tftSteamResult.table));
    tftSteamStore.setSteamStandardDeviation(calculateStandardDeviation(tftSteamStore.tft.steam.tftSteamResult.table));
    tftSteamStore.setSteamRsd(
      calculateSteamRsd(calculateStandardDeviation(tftSteamStore.tft.steam.tftSteamResult.table), tftSteamStore.tftAverages.steam.avgSteamFlowRate)
    );
  }, [results, tftSteamStore, tftSteamStore.tft.baroPress]);

  useEffect(() => {
    tftSteamStore.setCSteamFlow(corrSteamFlowFormula(tftSteamStore.tft.ncgContent, tftSteamStore.tftAverages.steam.avgSteamFlowRate));
  }, [tftSteamStore.tft.ncgContent, tftSteamStore.tftAverages.steam.avgSteamFlowRate, tftSteamStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <StyledDataGridPremium
        columns={columnResult}
        rows={results}
        getRowId={(row) => row.sequence}
        autoHeight
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEditResult}
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "35%" }}>
          <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none" }}>
            <Table sx={{ minWidth: 250, border: "none" }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="left">
                    Steam Port Location :
                  </TableCell>
                  <TableCell align="left">{tftSteamStore.tft.steamPortLoccationId}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} sx={{ width: "100%", boxShadow: "none", border: 0 }}>
            <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Average</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Separator Temp
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tftAverages.steam.avgSeparatorTemp}</TableCell>
                </TableRow>
                <TableRow key={2} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Separator Press, Psig
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tftAverages.steam.avgSeparatorTemp}</TableCell>
                </TableRow>
                <TableRow key={3} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Saturation Pressure Psig
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tftAverages.steam.avgSaturationPressure}</TableCell>
                </TableRow>
                <TableRow key={4} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Steam Flow Rate, KPH
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tftAverages.steam.avgSteamFlowRate}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: "65%", display: "flex", justifyContent: "end" }}>
          <TableContainer component={Paper} sx={{ width: 500, boxShadow: "none" }}>
            <Table sx={{ width: 500, border: "none" }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="right">
                    Corrected Steam Flow Rate % NCG by Wt
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.correctionSteamFlowRate}</TableCell>
                </TableRow>
                <TableRow key={2} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="right">
                    Steam Flow Rate Spread (KPH)
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tft.steam.tftSteamResult.steamFlowRateSpread}</TableCell>
                </TableRow>
                <TableRow key={3} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="right">
                    Standar Deviation
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tft.steam.tftSteamResult.standardDeviation}</TableCell>
                </TableRow>
                <TableRow key={4} sx={{ "& td, th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="right">
                    RSD %
                  </TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="center">{tftSteamStore.tft.steam.tftSteamResult.rsd}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default observer(TFTProcessSteamStep4);
