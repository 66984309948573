import { observer } from 'mobx-react-lite';
import Chart from 'react-apexcharts'
import { ITftCompletionChart } from '../../app/models/tft';

interface IProps {
  data: ITftCompletionChart,
  title: string,
}

function TFTComplesionChart(props: IProps) {
  const { data, title } = props;
  const options: ApexCharts.ApexOptions = {
    xaxis: {
      type: 'numeric',
      tickAmount: 8,
    },
    title: {
        text: title,
        align: 'center',
        style: {
          fontSize: "12px",
          color: '#666'
        }
    },
  }

  const series: ApexAxisChartSeries = [
    {
        name: data.data.name,
        data: data.data.data
    }
  ]

  return <Chart options={options} series={series} type="line" height={300} width={'100%'} />;
};

export default observer(TFTComplesionChart);