import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import PTDecimationChart from "../PTDecimationChart";
import GravitySurveyChart from "../../../geophysics/gravitySurvey/GravitySurveyChart";

type TBarDialogProps = {
  openChartDialog: boolean;
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>;
  isDecimation?: boolean;
};

function DynamicBarShowChartDialog({ setOpenChartDialog, openChartDialog, isDecimation = false }: TBarDialogProps) {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => setOpenChartDialog(false);

  return (
    <Dialog fullWidth maxWidth="md" open={openChartDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Generated Chart</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {isDecimation ? <PTDecimationChart /> : <GravitySurveyChart />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DynamicBarShowChartDialog);
