import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { ICompletionID } from '../../../app/models/wellGeometry';
import { useStore } from '../../../app/stores/store';
import { toJS } from 'mobx';
import { DataGrid, GridCellEditCommitParams, GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import dataCassing from './data/cassing_description.json';

interface IProps {
  data: Array<ICompletionID>,
}

function WellGeometryCompletionGridID(props: IProps) {
  const { data } = props;
  const dataRow = toJS(data);
  const { wellGeometryStore } = useStore();
  const { setCompletionID } = wellGeometryStore;
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const gridColumns: GridColumns<ICompletionID> = [     
    { field: 'fromMD', headerName: 'From MD (meter)', width: 150, type: "number",editable: true, sortable: false },
    { field: 'toMD', headerName: 'To MD (meter)', width: 150, type: "number", editable: true, sortable: false },
    { field: 'cassingType', headerName: 'CSG Type', width: 120, type: "number", editable: true, sortable: false },
    { field: 'csgDescription', headerName: 'CSG Description', width: 120, type: "singleSelect", valueOptions: dataCassing.map((item) => item.PipeDescr), editable: true, sortable: false },
    { field: 'csgOD', headerName: 'CSG OD', flex: 0.5, type: "number", editable: true, sortable: false },
    { field: 'csgID', headerName: 'CSG ID', flex: 0.5, type: "number", editable: true, sortable: false },
  ]

  const handleEditGrid = (params: GridCellEditCommitParams) => {
    const edited = dataRow.map((item: any) => {
      if(item.id === params.id)
      {
        item[params.field] = params.value;
      }
      return item
    })
    setCompletionID(edited)
  }

  return (
    <Box sx={{ p:1, height: 600 }}>
      <DataGrid 
        rows={dataRow} 
        columns={gridColumns}  
        onCellEditCommit={handleEditGrid} 
        getRowHeight={() => 'auto'}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[25, 100, 150]}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      />
    </Box>
  );
}

export default observer(WellGeometryCompletionGridID);