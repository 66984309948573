import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

function BiweeklyDataGrid() {
  const { biweeklyGridStore, commonStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    biweeklyGridStore.getBiweeklyGrid();
  }, [biweeklyGridStore]);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.6,
      type: "string",
      renderCell: (params) => format(new Date(params.row.date), "dd MMMM yyyy"),
    },
    {
      field: "modifiedDateUtc",
      headerName: "Modified Date",
      flex: 0.6,
      type: "string",
      renderCell: (params) => format(new Date(params.row.modifiedDateUtc), "dd MMMM yyyy, hh:mm:ss"),
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/geochemistry-biweekly/${params.row.date}`);
          }}
        />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      loading={biweeklyGridStore.loadingGrid}
      showCellVerticalBorder
      showColumnVerticalBorder
      checkboxSelection
      disableColumnMenu
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={biweeklyGridStore.dataGridResult.data}
    />
  );
}

export default observer(BiweeklyDataGrid);
