import { Box } from "@mui/system";
import { DecimationSegmentTable, DecimationSelect } from "../../../app/models/decimation";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { mergeAndInterpolateData } from "../../../app/config/utils";

interface IProps {
  decimationSegmentSelect: Array<DecimationSelect>;
  value: number;
}

function PTDecimationTablePerSegment(props: IProps) {
  const [selectedSegmen, setSelectedSegmen] = useState("");
  const [valuePrev, setValuePrev] = useState(0);
  const [decimationSegmentTable, setDecimationSegmentTable] = useState<DecimationSegmentTable[]>([]);
  const { decimationStore } = useStore();

  const gridColumns: GridColDef[] = [
    { field: "depth", headerName: "Depth", flex: 1, type: "number", align: "center", headerAlign: "center" },
    { field: "pressure", headerName: "Pressure", flex: 1, type: "number", align: "center", headerAlign: "center" },
    { field: "temperature", headerName: "Temperature", flex: 1, type: "number", align: "center", headerAlign: "center" },
  ];

  useEffect(() => {
    if (props.value > 0) {
      setSelectedSegmen(props.value + "");
      let result = mergeAndInterpolateData(decimationStore.decimation.table, props.value);

      setDecimationSegmentTable(result as DecimationSegmentTable[]);
    }
  }, [props.value, decimationStore]);

  function handleChange(event: any) {
    let checkSegment = decimationStore.decimation.selectedSegments.find((x) => x === event.target.value);
    if (checkSegment === null || checkSegment === undefined) {
      let selectedSegmens: any = [];
      if (decimationStore.decimation.selectedSegments.length > 0) {
        var index = decimationStore.decimation.selectedSegments.indexOf(valuePrev);
        if (index !== -1) {
          decimationStore.decimation.selectedSegments.splice(index, 1);
        }
        selectedSegmens = decimationStore.decimation.selectedSegments;
      }
      decimationStore.setDecimationSegmentSelected([]);
      setSelectedSegmen(event.target.value);
      selectedSegmens.push(event.target.value);
      decimationStore.setDecimationSegmentSelected(selectedSegmens);
      let result = mergeAndInterpolateData(decimationStore.decimation.table, event.target.value);

      setDecimationSegmentTable(result as DecimationSegmentTable[]);
    } else {
      alert("Segmen is choosen");
    }
  }

  function handleOpen(event: any) {
    setValuePrev(parseInt(selectedSegmen));
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left" }}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Segmen</InputLabel>
        <Select name="wesumSelectedSegmentllId" labelId="Segment" label="Segmen" onChange={handleChange} onOpen={handleOpen} value={selectedSegmen}>
          {props.decimationSegmentSelect.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <StyledDataGridPremium
        showColumnVerticalBorder
        sx={{ width: "100%" }}
        showCellVerticalBorder
        pagination
        columns={gridColumns}
        rows={decimationSegmentTable}
        getRowId={(row) => row.sequence}
        autoHeight
      />
    </Box>
  );
}

export default PTDecimationTablePerSegment;
