import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";


export default class ObjectStatusGridStore extends DataGridStore {

  constructor() {
      super();

      makeObservable(this, {
          getObjectStatusList: action,
      });
  }

  getObjectStatusList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.ObjectStatus.listGrid(urlParams);
      let id = 1;
      result.data.forEach(item => {
        item.id = id.toString()
        id++
      })
      this.setDataGridResult(result);
      
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingGrid = false);
    }
  }

  updateGridCallback = action(() => {
    this.getObjectStatusList();
  })

}