import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { TBrineORCDetailDataResponse } from "../../../app/models/geochemistry";
import { useState } from "react";

function BrineORCPageCreateGrid() {
  const { brineORCMonitoringStore } = useStore();
  const [tempData, setTempData] = useState<TBrineORCDetailDataResponse[]>([]);

  const handleRowEdit = (newData: any, oldData: any) => {
    const temp: TBrineORCDetailDataResponse[] = brineORCMonitoringStore.brineORCMonitoringDetail.map((current, currentIndex) => {
      if (oldData.tph10 !== newData.tph10) {
        let helper = [...tempData];
        let helpe1r = { ...current };

        if (current.orcGroupId === newData.orcGroupId) {
          helpe1r.tph10 = newData.tph10;
          setTempData(helper);
          return helpe1r;
        }

        return current;
      }

      if (oldData.tph !== newData.tph) {
        let helper = [...tempData];
        let helpe1r = { ...current };

        if (current.orcGroupId === newData.orcGroupId) {
          helpe1r.tph = newData.tph;
          setTempData(helper);
          return helpe1r;
        }

        return current;
      }

      if (oldData.lpSeparator !== newData.lpSeparator) {
        let helper = [...tempData];
        let helpe1r = { ...current };

        if (current.orcGroupId === newData.orcGroupId) {
          helpe1r.lpSeparator = newData.lpSeparator;
          setTempData(helper);
          return helpe1r;
        }

        return current;
      }

      if (newData.id === current.id) return newData;

      return current;
    });

    brineORCMonitoringStore.setManualBrineOrcData(temp);
    return newData;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "orcGroupName",
      headerName: "ORC Group",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "orcName",
      headerName: "ORC Name",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "lpSeparator",
      headerName: "(Bar)",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "tph",
      headerName: "TPH",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "tph10",
      headerName: "TPH/10",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "pressureIn",
      headerName: "Press In",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "pT801",
      headerName: "PT 801 (Bar)",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "tT801",
      headerName: "TT 801(c)",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "bypassFcv",
      headerName: "Bypass FCV",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "preheaterTemperature",
      headerName: "Preheater Temperature",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "grossGeneration",
      headerName: "Gross Generation",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "mw",
      headerName: "MW",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
  ];

  return (
    <StyledDataGridPremium
      experimentalFeatures={{ columnGrouping: true }}
      getRowId={(row) => row.id}
      loading={brineORCMonitoringStore.loadingGrid}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columnGroupingModel={[
        {
          groupId: "LP Sep",
          headerAlign: "center",
          children: [{ field: "lpSeparator" }],
        },
        {
          groupId: "Flow Rate",
          headerAlign: "center",
          children: [{ field: "tph" }, { field: "tph10" }],
        },
        {
          groupId: "Press In",
          headerAlign: "center",
          children: [{ field: "pT801" }],
        },
        {
          groupId: "Temp In",
          headerAlign: "center",
          children: [{ field: "tT801" }],
        },
      ]}
      columns={gridColumns}
      processRowUpdate={handleRowEdit}
      rows={brineORCMonitoringStore.brineORCMonitoringDetail}
    />
  );
}

export default observer(BrineORCPageCreateGrid);
