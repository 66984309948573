import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { DecimationSegmentSummaryTable, DecimationSegmentTable } from "../../../app/models/decimation";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useStore } from "../../../app/stores/store";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

type TPTDecimationStep1 = {
  showTableValidation: boolean;
  setShowTableValidation: Dispatch<SetStateAction<boolean>>;
};

function PTDecimationStep1({ showTableValidation, setShowTableValidation }: TPTDecimationStep1) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const hiddenFileInput = useRef<any>(null);
  const { decimationStore } = useStore();
  const [decimationTable, setDecimationTable] = useState(decimationStore.decimation.table);
  const [decimationSegmentSummaryTable, setDecimationSegmentSummaryTable] = useState<Array<DecimationSegmentSummaryTable>>([]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (decimationStore.decimation.table.length > 0) {
      updateSummarySegment(decimationTable.filter((x) => x.segment !== null));
      setDecimationTable(decimationStore.decimation.table);
    } else {
      setDecimationTable([]);
      setDecimationSegmentSummaryTable([]);
    }
  }, [decimationStore.decimation.table, decimationTable]);

  const gridColumns: GridColDef[] = [
    { flex: 1, field: "segmentStatus", headerName: "Segment Status", align: "center", headerAlign: "center", sortable: false },
    // { flex: 1, field: "sequence", headerName: "Sequence", type: "number", align: "center", headerAlign: "center", sortable: false },
    { flex: 1, field: "segment", headerName: "Segment #", type: "number", align: "center", headerAlign: "center", sortable: false, editable: true },
    {
      flex: 1,
      field: "time",
      sortable: false,
      headerName: "Time",
      type: "dateTime",
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        return format(new Date(params.value), "HH:mm:ss");
      },
    },
    { flex: 1, field: "depth", headerName: "Depth", type: "number", align: "center", sortable: false, headerAlign: "center" },
    { flex: 1, field: "speed", headerName: "Speed", type: "number", align: "center", sortable: false, headerAlign: "center" },
    { flex: 1, field: "pressure", headerName: "Pressure", type: "number", align: "center", sortable: false, headerAlign: "center" },
    { flex: 1, field: "temperature", headerName: "Temperature", type: "number", sortable: false, align: "center", headerAlign: "center" },
  ];

  const gridSummaryColumns: GridColDef[] = [
    { align: "center", headerAlign: "center", flex: 1, field: "segment", sortable: false, headerName: "Segment #", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "begin", sortable: false, headerName: "Begin", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "end", sortable: false, headerName: "End", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "type", sortable: false, headerName: "Type", type: "string" },
  ];

  const processRowUpdate = useCallback(
    async (newRow: any) => {
      const updatedRow = { ...newRow, isNew: false };
      // setDecimationTable(decimationTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
      var checkDecimation;
      if (newRow.segment !== null) {
        checkDecimation = decimationTable.find((x) => x.segment === newRow.segment);
      }
      if (checkDecimation === undefined || checkDecimation?.segmentStatus === newRow.segmentStatus) {
        for (let i = newRow.sequence - 1; i < decimationTable.length; i++) {
          if (decimationTable[i].segmentStatus === newRow.segmentStatus) {
            const updateDecimation = decimationTable[i];
            updateDecimation.sequence = decimationTable[i].sequence;
            if (updateDecimation.segment === null || updateDecimation.sequence === newRow.sequence) {
              updateDecimation.segment = newRow.segment;
            }
            updateDecimation.segmentStatus = decimationTable[i].segmentStatus;
            updateDecimation.time = decimationTable[i].time;
            updateDecimation.depth = decimationTable[i].depth;
            updateDecimation.speed = decimationTable[i].speed;
            updateDecimation.pressure = decimationTable[i].pressure;
            updateDecimation.temperature = decimationTable[i].temperature;
            setDecimationTable(decimationTable.map((row) => (row.sequence === decimationTable[i].sequence ? updateDecimation : row)));
            decimationStore.setDecimationSegmentTable(
              decimationTable.map((row) => (row.sequence === decimationTable[i].sequence ? updateDecimation : row))
            );
          } else {
            break;
          }
        }
      } else {
        updatedRow.segment = null;
        setDecimationTable(decimationTable.map((row) => (row.sequence === updatedRow.sequence ? updatedRow : row)));
        decimationStore.setDecimationSegmentTable(decimationTable.map((row) => (row.sequence === updatedRow.sequence ? updatedRow : row)));
        alert("There is a segment " + checkDecimation?.segment + " in segment status " + checkDecimation?.segmentStatus);
      }
      return updatedRow;
    },
    [decimationTable, decimationStore]
  );

  function updateSummarySegment(array: Array<DecimationSegmentTable>) {
    setDecimationSegmentSummaryTable([]);
    let dataExport: any = [];
    let segment;
    let begin;
    let end;
    let type;
    let id = 1;
    let lastSequence = 1;
    for (let i = 0; i < array.length; i++) {
      if (i === 0) {
        segment = array[i].segment;
        begin = array[i].sequence;
        type = array[i].segmentStatus;
        lastSequence += 1;
      } else if (
        array[i].segmentStatus !== array[i - 1].segmentStatus ||
        array[i].segment !== array[i - 1].segment ||
        array[i].sequence !== lastSequence
      ) {
        end = array[i - 1].sequence;
        lastSequence = array[i].sequence;
        let newSummary = new DecimationSegmentSummaryTable();
        newSummary.id = id;
        if (segment != null || segment !== undefined) {
          newSummary.segment = segment;
        }
        if (begin != null || begin !== undefined) {
          newSummary.begin = begin;
        }
        if (end != null || end !== undefined) {
          newSummary.end = end;
        }
        if (type != null || type !== undefined) {
          newSummary.type = type;
        }
        dataExport.push(newSummary);
        segment = array[i].segment;
        begin = array[i].sequence;
        type = array[i].segmentStatus;
        if (i === array.length - 1) {
          end = array[i].sequence;
          let newSummary = new DecimationSegmentSummaryTable();
          if (segment != null || segment !== undefined) {
            newSummary.segment = segment;
          }
          if (begin != null || begin !== undefined) {
            newSummary.begin = begin;
          }
          if (end != null || end !== undefined) {
            newSummary.end = end;
          }
          if (type != null || type !== undefined) {
            newSummary.type = type;
          }
          dataExport.push(newSummary);
        } else {
          end = null;
        }
        id++;
      } else if (array[i].segmentStatus === array[i - 1].segmentStatus && i === array.length - 1) {
        end = array[i].sequence;
        let newSummary = new DecimationSegmentSummaryTable();
        newSummary.id = id;
        if (segment != null || segment !== undefined) {
          newSummary.segment = segment;
        }
        if (begin != null || begin !== undefined) {
          newSummary.begin = begin;
        }
        if (end != null || end !== undefined) {
          newSummary.end = end;
        }
        if (type != null || type !== undefined) {
          newSummary.type = type;
        }
        dataExport.push(newSummary);
      } else {
        lastSequence += 1;
      }
    }
    setDecimationSegmentSummaryTable(dataExport);
  }

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("file", e.target.files[0]);

      decimationStore.uploadDecimationTemplate(formData).then((res) => {
        res.data.length > 0 ? setShowTableValidation(false) : setShowTableValidation(true);

        let countHelper = 0;
        let segmentStatusHelper = "";

        let result = res.data.map((item, index) => {
          if (item.segmentStatus !== segmentStatusHelper) countHelper++;

          segmentStatusHelper = item.segmentStatus;
          return { ...item, segment: countHelper };
        });

        setDecimationTable(result as unknown as DecimationSegmentTable[]);
        decimationStore.setDecimationSegmentTable(result as unknown as DecimationSegmentTable[]);
        // setDecimationTable(res.data);
        // decimationStore.setDecimationSegmentTable(res.data);
        decimationStore.setDecimationSegmentCount(0);
        decimationStore.setDecimationSegmentSelected([]);
      });
    }
    e.target.value = "";
  };

  useEffect(() => {
    !!decimationTable.length ? setShowTableValidation(false) : setShowTableValidation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decimationTable]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left" }}>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" sx={{ width: "20%" }} onClick={handleMenuClick}>
            Templates
          </Button>
        </Grid>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button variant="text" color="primary" href={`/templates/Template_Pressure_Temperature.xlsx`} download target="_blank" rel="noreferrer">
            Download
          </Button>
        </MenuItem>
        <MenuItem>
          <Button variant="text" color="primary" onClick={handleClick}>
            Upload
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={hiddenFileInput}
              hidden
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
            />
          </Button>
        </MenuItem>
      </Menu>
      <StyledDataGridPremium
        disableColumnMenu
        columns={gridColumns}
        pagination
        showColumnVerticalBorder
        showCellVerticalBorder
        rows={decimationTable}
        getRowId={(row) => row.sequence}
        autoHeight
        processRowUpdate={processRowUpdate}
      />
      {showTableValidation ? <label style={{ color: "red" }}>The table must be filled / Upload the xlsx file first</label> : null}
      <Box>
        <b>Summary Segment</b>

        <StyledDataGridPremium
          sx={{ mt: "6px" }}
          disableColumnMenu
          columns={gridSummaryColumns}
          rows={decimationSegmentSummaryTable}
          getRowId={(row) => row.id}
          pagination
          showColumnVerticalBorder
          showCellVerticalBorder
          autoHeight
        />
      </Box>
    </Box>
  );
}

export default PTDecimationStep1;
