import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import WellGeometryList from './WellGeometryList';
import { useStore } from '../../../app/stores/store';
import { useEffect } from 'react';

function WellGeometryPage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Well Geometry',);
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <WellGeometryList />
      </Grid>
    </Grid>
  );
}

export default observer(WellGeometryPage);