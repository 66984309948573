import { useEffect } from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { TPTSData } from "../../../app/models/pts";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PTSDataGrid() {
  const { ptsStore } = useStore();
  const { setSelectedPTS, loadingDownloadFile } = ptsStore;
  const navigate = useNavigate();

  useEffect(() => {
    ptsStore.getPtsList();
  }, [ptsStore]);

  const gridColumns: GridColumns<TPTSData> = [
    { field: "wellName", headerName: "Well Name", flex: 0.6, filterable: false, sortable: true },
    { field: "wellCondition", headerName: "Well Condition", flex: 0.6 },
    {
      field: "ptsDate",
      headerName: "Date",
      flex: 0.6,
      type: "string",
      renderCell: (params) => format(new Date(params.row.ptsDate), "dd MMMM yyyy"),
    },
    {
      field: "fileName",
      headerName: "File",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
          // onClick={() => getPTSFile(params.row.id, params.row.fileUrl)}
        >
          {loadingDownloadFile ? (
            <CircularProgress />
          ) : (
            <a rel="noreferrer" href={params.row.fileUrl}>
              {params.row.fileName.length > 25 ? `${params.row.fileName.substring(0, 25)}...` : params.row.fileName}
            </a>
          )}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/pts/edit/${params.row.id}`);
            setSelectedPTS(params.row as any);
          }}
        />,
      ],
    },
  ];

  return <RkdDataGrid columns={gridColumns} gridStore={ptsStore} />;
}

export default observer(PTSDataGrid);
