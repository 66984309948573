import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DAILY_SHEET, DAILY_SHEET_PRODUCTIONS, formatDateToMMDDYYYY } from "../config/enum";
import {
  IDailyInjections,
  IDailyInjectionTable,
  IDailyInjRequest,
  IDailyInjRequestTable,
  IDailyMonitoringTable,
  IDailyMonRequest,
  IDailyProdHpSeparator,
  IDailyProdHpSepReq,
  IDailyProdRequest,
  IDailyProductions,
  IDailyProdWells,
  IDailySheet,
  IDailySheetRequest,
  TCopyPreviousDailySheetPayload,
  TDailySheetPayload,
  TDailySheetResponse,
  TProductionsTable,
} from "../models/dailysheet";

export default class DailysheetStore {
  dailysheet: IDailySheet = {
    dailyLogId: "",
    dailyDate: new Date(),
    createdById: "",
    modifiedById: "",
    status: "",
    remarks: "",
    productions: [],
    injections: [],
    monitorings: [],
  };

  dailysheetReq: IDailySheetRequest = {
    dailyDate: new Date(),
    createdById: "",
    modifiedById: "",
    remarks: "",
    productions: [],
    injections: [],
    monitorings: [],
  };

  dailySheetData: TDailySheetResponse | null = null;

  loadingGetData = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  addDailySheet = async (isDraft: boolean, payload: TDailySheetPayload) => {
    this.loadingForm = true;

    try {
      await agent.Dailysheet.addDailySheet(isDraft, payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  copyPreviousDailySheet = async (payload: TCopyPreviousDailySheetPayload) => {
    this.loadingForm = true;

    try {
      await agent.Dailysheet.copyPreviousDailySheet(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  getDailySheet = async (date: string) => {
    this.loadingGetData = true;

    try {
      let response = await agent.Dailysheet.getDailySheet(date);

      runInAction(() => (this.dailySheetData = response));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetData = false));
    }
  };

  getDailyByDate = async (date: Date) => {
    this.loadingGetData = true;

    try {
      const params = new URLSearchParams();
      if (date) params.append("date", formatDateToMMDDYYYY(date));
      const data = await agent.Dailysheet.getByDate(params);
      this.setDailySheet(data);
      this.setDailyDate(date);
      this.initProdRequest(data.productions);
      this.initInjRequest(data.injections);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingGetData = false));
    }
  };

  initProdRequest = (data: IDailyProductions[]) => {
    let temp: IDailyProdRequest[] = [];
    data.forEach((item) => {
      let rows: IDailyProdHpSepReq[] = [];
      item.hpSeparators.forEach((row) => {
        const tabData = {
          hpSeparatorId: row.hpSeparatorId,
          hpSeparatorName: row.hpSeparatorName,
          hpInletPress: row.hpInletPress,
          hpInletTemp: row.hpInletTemp,
          hpSteamRate: row.hpSteamRate,
          hpBrineRate: row.hpBrineRate,
          brinePumpId: row.brinePumpId,
          brinePumpName: row.brinePumpName,
          bpBrineRate: row.bpBrineRate,
          wells: row.wells,
        };
        rows.push(tabData);
      });
      temp.push({
        lpSeparatorId: item.lpSeparatorId,
        lpSeparatorName: item.lpSeparatorName,
        lpInletPress: item.lpInletPress,
        lpInletTemp: item.lpInletTemp,
        lpSteamRate: item.lpSteamRate,
        lpBrineRate: item.lpBrineRate,
        hpSeparators: rows,
      });
    });
    this.dailysheetReq.productions = temp;
  };

  initInjRequest = (data: IDailyInjections[]) => {
    let temp: IDailyInjRequest[] = [];
    data.forEach((item) => {
      let rows: IDailyInjRequestTable[] = [];
      item.table.forEach((row) => {
        const tabData = {
          wellId: row.wellId,
          wellName: row.wellName,
          fcv: row.fcv,
          whp: row.whp,
          wht: row.wht,
          hourDuration: row.hourDuration,
          brineRate: row.brineRate,
          avgBrineRate: row.avgBrineRate,
          totalBrineRate: row.totalBrineRate,
        };
        rows.push(tabData);
      });
      temp.push({
        locationId: item.locationId,
        locationName: item.locationName,
        table: rows,
      });
    });
    this.dailysheetReq.injections = temp;
  };

  initMonitoringRequest = (data: IDailyMonitoringTable[]) => {
    let temp: IDailyMonRequest[] = [];
    data.forEach((item) => {
      temp.push({
        wellId: item.wellId,
        wellName: item.wellName,
        whp: item.whp,
        wht: item.wht,
      });
    });
    this.dailysheetReq.monitorings = temp;
  };

  setProductions = (wells: IDailyProdWells[], data: any, hpSep: IDailyProdHpSeparator[]) => {
    this.dailysheetReq.productions.forEach((item) => {
      if (item.lpSeparatorId === data[0].lpSeparatorId) {
        item.lpSeparatorId = data[0].lpSeparatorId;
        item.lpSeparatorName = data[0].lpSeparatorName;
        item.lpInletPress = data[0].lpInletPress;
        item.lpInletTemp = data[0].lpInletTemp;
        item.lpSteamRate = data[0].lpSteamRate;
        item.lpBrineRate = data[0].lpBrineRate;
        item.hpSeparators = hpSep;
        let tempHpSep = item.hpSeparators;
        wells.forEach((row) => {
          tempHpSep.forEach((item) => {
            item.wells.forEach((item2) => {
              if (item2.wellId === row.wellId) {
                item2.wellId = row.wellId;
                item2.wellName = row.wellName;
                item2.fcv = row.fcv;
                item2.whp = row.whp;
                item2.wht = row.wht;
              }
            });
          });
        });
      }
    });
  };

  setInjections = (locId: string, data: IDailyInjectionTable[]) => {
    this.dailysheetReq.injections.forEach((item) => {
      if (item.locationId === locId) {
        let rows: IDailyInjRequestTable[] = [];
        data.forEach((row) => {
          const tabData = {
            wellId: row.wellId,
            wellName: row.wellName,
            fcv: row.fcv,
            whp: row.whp,
            wht: row.wht,
            hourDuration: row.hourDuration,
            brineRate: row.brineRate,
            avgBrineRate: row.avgBrineRate,
            totalBrineRate: row.totalBrineRate,
          };
          rows.push(tabData);
        });
        item.table = rows;
      }
    });
  };

  setMonitorings = (data: IDailyMonitoringTable[]) => {
    let temp: IDailyMonRequest[] = [];
    data.forEach((item) => {
      temp.push({
        wellId: item.wellId,
        wellName: item.wellName,
        whp: item.whp,
        wht: item.wht,
      });
    });
    this.dailysheetReq.monitorings = temp;
  };

  setDailySheet = (data: IDailySheet) => {
    data.productions.forEach((item) => {
      let wellsRow: IDailyProdWells[] = [];
      item.hpSeparators.forEach((row) => {
        wellsRow = [...wellsRow, ...row.wells];
      });
      item.wells = wellsRow;
    });
    this.dailysheet = data;
  };

  setDailyDate = (date: Date) => {
    this.dailysheetReq.dailyDate = date;
  };

  setCreatedBy = (modifiedById: string, createdById: string) => {
    this.dailysheetReq.createdById = createdById;
    this.dailysheetReq.modifiedById = modifiedById;
  };

  setRemarks = (remarks: string) => {
    this.dailysheetReq.remarks = remarks;
  };

  setManualDailySheetData = (data: any, index: number, identifier: string) => {
    if (this.dailySheetData) {
      if (identifier === DAILY_SHEET.PRODUCTIONS) this.dailySheetData.productions[index].table = data;
      if (identifier === DAILY_SHEET.INJECTIONS) this.dailySheetData.injections[index].table = data;
      if (identifier === DAILY_SHEET.MONITORINGS) this.dailySheetData.monitoring.table = data;
    }
  };

  setManualDailySheetDataProductions = (identifier: string, index: number, value: number) => {
    if (this.dailySheetData) {
      if (identifier === DAILY_SHEET_PRODUCTIONS.BRINE_RATE) this.dailySheetData.productions[index].brineRate = value;
      if (identifier === DAILY_SHEET_PRODUCTIONS.INLET_PRESS) this.dailySheetData.productions[index].inletPress = value;
      if (identifier === DAILY_SHEET_PRODUCTIONS.INLET_TEMP) this.dailySheetData.productions[index].inletTemp = value;
      if (identifier === DAILY_SHEET_PRODUCTIONS.STEAM_RATE) this.dailySheetData.productions[index].steamRate = value;
    }
  };
}
