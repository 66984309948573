import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { TOrcGridResponse, TOrcOptions, TOrcPayload } from "../models/orc";
import { IDeleteData } from "../models/account";

export default class OrcGridStore extends DataGridStore {
  orcAadEditLoading = false;
  orcGetDetailLoading = false;
  orcDeleteLoading = false;
  orcGetOptionsLoading = false;
  orcDetail: TOrcGridResponse | undefined = undefined;
  selectedOrc: TOrcGridResponse | null = null;

  orcOptions: TOrcOptions[] = [];

  constructor() {
    super();

    makeObservable(this, {
      getOrcGrid: action,
    });
  }

  getOrcGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.ORC.getOrcGrid(urlParams);

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getOrcOptions = async () => {
    this.orcGetOptionsLoading = true;
    try {
      const res = await agent.ORC.getORCOption();

      runInAction(() => (this.orcOptions = res));
      return res;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.orcGetOptionsLoading = false));
    }
  };

  addOrc = async (payload: TOrcPayload) => {
    this.orcAadEditLoading = true;

    try {
      await agent.ORC.addOrc(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.orcAadEditLoading = false));
    }
  };

  editOrc = async (id: string, payload: TOrcPayload) => {
    this.orcAadEditLoading = true;

    try {
      await agent.ORC.editOrc(id, payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.orcAadEditLoading = false));
    }
  };

  getOrcDetail = async (id: string) => {
    this.orcGetDetailLoading = true;

    try {
      let res = await agent.ORC.getOrcDetail(id);
      runInAction(() => (this.orcDetail = res));

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.orcGetDetailLoading = false));
    }
  };

  deleteOrc = async (ids: IDeleteData) => {
    this.orcDeleteLoading = true;

    try {
      await agent.ORC.deleteOrc(ids);
    } catch (error) {
      runInAction(() => (this.orcDeleteLoading = false));
    }
  };

  setSelectedOrc = (data: TOrcGridResponse | null) => {
    this.selectedOrc = data;
  };

  setGridFromDetail = async (data: any[]) => {
    this.setDataGridResult({ rowCount: 0, data: data });
  };

  clearGridAfterUploadGeological = () => {
    this.setDataGridResult({ rowCount: 0, data: [] });
  };
}
