import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { ISteamSampleAnalysisTables } from "../../app/models/steam";
import { useStore } from "../../app/stores/store";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  bombHeadSpacePressFormula,
  grmConFormula,
  grNaohFormula,
  hsFormula,
  LvFormula,
  microGramsFormula,
  ppmwFormula,
  sampDensFormula,
  scrubFormula,
  sF6MolesInjectionFormula,
  solFracFormula,
  totalHSMolesFormula,
  totalLoopMolesFormula,
  totalMolesSF6BombFormula,
  totDensFormula,
} from "./helper/steam/formula";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";

function TFTProcessSteamStep2() {
  const { tftSteamStore, accountStore } = useStore();

  const [sampleAnalysisTables, setSampleAnalysisTables] = useState<ISteamSampleAnalysisTables[]>(tftSteamStore.tft.steam.ampleAnalysis.table);

  const handleAddRow = () => {
    setSampleAnalysisTables([
      ...sampleAnalysisTables,
      {
        sequence: sampleAnalysisTables.length + 1,
        labNumber: sampleAnalysisTables.length + 1,
        bombVolume: 0,
        bombVolumeRef: "",
        bombNumber: "",
        emptyWeight: 0,
        initialWeight: 0,
        weightRecvd: 0,
        bombInitialPressureReading: 0,
        bombInitialPressureLoopSize: 0,
        bombTemprature: 0,
        loopSizeInjected: 0,
        loopPressure: 0,
        loopTemprature: 0,
        areaCounts: 0,
        naOHDestinity: 0,
        grNaOH: 0,
        scrub: 0,
        grmCon: 0,
        totDens: 0,
        sampDens: 0,
        lv: 0,
        hs: 0,
        bombHeadspacePressure: 0,
        totalHSMoles: 0,
        totalLoopMoles: 0,
        solFrac: 0,
        sF6MolesInjection: 0,
        totalMolesSF6Bomb: 0,
        microGrams: 0,
        ppMw: 0,
      },
    ]);

    tftSteamStore.addSteamResultTable();
  };

  const deleteRow = (sequence: number) => {
    let data = sampleAnalysisTables.filter((x) => x.sequence !== sequence);
    tftSteamStore.clearSteamResultTable();
    data.forEach((x, index) => {
      x.sequence = index + 1;
      tftSteamStore.addSteamResultTable();
    });
    setSampleAnalysisTables(data);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "compoundName") {
      tftSteamStore.setCompoundName(event.target.value);
    }
    if (event.target.name === "molecularWeight") {
      tftSteamStore.setMolecularWeight(Number(event.target.value));
    }
    if (event.target.name === "solubilityFactor") {
      tftSteamStore.setSolubilityFactor(Number(event.target.value));
    }
    if (event.target.name === "inletSystemDeadVolume") {
      tftSteamStore.setInletSystemDeadVolume(Number(event.target.value));
    }
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    if (event.target.name === "milkedBombs") {
      if (event.target.value === "yes") {
        tftSteamStore.setMilkedBombs(true);
      }
      if (event.target.value === "no") {
        tftSteamStore.setMilkedBombs(false);
      }
    }
  };

  const handleRowEditSampleAnalysis = useCallback(
    (newValue: GridValidRowModel) => {
      const data: ISteamSampleAnalysisTables[] = tftSteamStore.tft.steam.ampleAnalysis.table.map((current) => {
        if (current.sequence !== newValue.sequence) return current;

        const newGrNaOH = grNaohFormula(newValue.naOHDestinity, newValue.initialWeight, newValue.emptyWeight);
        const newScrub = scrubFormula(newValue.initialWeight, newValue.emptyWeight, newGrNaOH);
        const newGrmCon = grmConFormula(newValue.weightRecvd, newValue.initialWeight);
        const newTotDens = totDensFormula(newGrNaOH, newValue.weightRecvd, newValue.emptyWeight);
        const newSampDens = sampDensFormula(newGrNaOH, newValue.weightRecvd, newValue.initialWeight);
        const newLv = LvFormula(newValue.weightRecvd, newValue.emptyWeight, newTotDens);
        const newHs = hsFormula(
          tftSteamStore.tft.steam.ampleAnalysis.milkedBombs,
          newValue.bombVolume,
          newValue.weightRecvd,
          newValue.emptyWeight,
          newSampDens
        );
        const newBombHeadspacePressure = bombHeadSpacePressFormula(
          newValue.bombInitialPressureReading,
          newHs,
          tftSteamStore.tft.steam.ampleAnalysis.inletSystemDeadVolume,
          newValue.bombInitialPressureLoopSize
        );
        const newTotalHSMoles = totalHSMolesFormula(newBombHeadspacePressure, newHs, newValue.bombTemprature);
        const newTotalLoopMoles = totalLoopMolesFormula(newValue.loopPressure, newValue.loopSizeInjected, newValue.loopTemprature);
        const newSolFrac = solFracFormula(tftSteamStore.tft.steam.ampleAnalysis.solubilityFactor, newLv, newHs);
        const newSF6MolesInjection = sF6MolesInjectionFormula(tftSteamStore.tft.steam.regressions, newValue.areaCounts);
        const newTotalMolesSF6Bomb = totalMolesSF6BombFormula(
          tftSteamStore.tft.steam.ampleAnalysis.milkedBombs,
          newSF6MolesInjection,
          newTotalLoopMoles,
          newTotalHSMoles,
          newSolFrac
        );
        const newMicroGrams = microGramsFormula(newTotalMolesSF6Bomb, tftSteamStore.tft.steam.ampleAnalysis.molecularWeight);
        const newPpmw = ppmwFormula(newValue.areaCounts, newMicroGrams, newGrmCon);

        return {
          sequence: newValue.sequence,
          labNumber: newValue.labNumber,
          bombVolume: newValue.bombVolume,
          bombVolumeRef: newValue.bombVolumeRef,
          bombNumber: newValue.bombNumber,
          emptyWeight: newValue.emptyWeight,
          initialWeight: newValue.initialWeight,
          weightRecvd: newValue.weightRecvd,
          bombInitialPressureReading: newValue.bombInitialPressureReading,
          bombInitialPressureLoopSize: newValue.bombInitialPressureLoopSize,
          bombTemprature: newValue.bombTemprature,
          loopSizeInjected: newValue.loopSizeInjected,
          loopPressure: newValue.loopPressure,
          loopTemprature: newValue.loopTemprature,
          areaCounts: newValue.areaCounts,
          naOHDestinity: newValue.naOHDestinity,
          grNaOH: newGrNaOH,
          scrub: newScrub,
          grmCon: newGrmCon,
          totDens: newTotDens,
          sampDens: newSampDens,
          lv: newLv,
          hs: newHs,
          bombHeadspacePressure: newBombHeadspacePressure,
          totalHSMoles: newTotalHSMoles,
          totalLoopMoles: newTotalLoopMoles,
          solFrac: newSolFrac,
          sF6MolesInjection: newSF6MolesInjection,
          totalMolesSF6Bomb: newTotalMolesSF6Bomb,
          microGrams: newMicroGrams,
          ppMw: newPpmw,
        };
      });
      setSampleAnalysisTables(data);

      return newValue;
    },
    [tftSteamStore]
  );

  const columnGcParameters: GridColDef[] = [
    {
      field: "operator",
      headerName: "Operator",
      width: 120,
      type: "string",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: () => {
        return <Typography variant="body1">{accountStore.account?.displayName}</Typography>;
      },
    },
    {
      field: "carrierGas",
      headerName: "Carrier Gas",
      width: 120,
      type: "string",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "carrierFlow",
      headerName: "Carrier Flow",
      width: 120,
      type: "number",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "auxGas",
      headerName: "Aux Gas",
      width: 120,
      type: "string",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "auxFlow",
      headerName: "Auz Flow",
      width: 120,
      type: "number",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    { field: "ma", headerName: "mA", width: 120, type: "number", headerClassName: "tab-header", headerAlign: "center", align: "center", flex: 1 },
    {
      field: "detTemp",
      headerName: "Det Temp",
      width: 120,
      type: "number",
      headerClassName: "tab-header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const columnAmpleAnalysis: GridColDef[] = [
    {
      field: "bombVolume",
      headerName: "Bomb Volume",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "bombVolumeRef",
      headerName: "Bombcol Ref",
      width: 120,
      type: "string",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "bombNumber",
      headerName: "Bomb Number",
      width: 120,
      type: "string",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "emptyWeight",
      headerName: "Empty Weight",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "initialWeight",
      headerName: "Initial Weight",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "weightRecvd",
      headerName: "Weight Recvd",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "bombInitialPressureReading",
      headerName: "Bomb Initial Pressure Reading",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "bombInitialPressureLoopSize",
      headerName: "Bomb Initial Pressure Loop Size",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "bombTemprature",
      headerName: "Bomb Temprature (in Degrees C)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "loopSizeInjected",
      headerName: "Loop Size Injected (in mls)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "loopPressure",
      headerName: "Loop Pressure (in psi)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "loopTemprature",
      headerName: "Loop Temprature e (in Degrees C)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "areaCounts",
      headerName: "Area Counts",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "naOHDestinity",
      headerName: "NaOH Density",
      width: 120,
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: true,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [<GridActionsCellItem color="error" icon={<DeleteIcon />} label="Delete" onClick={() => deleteRow(params.row.sequence)} />];
      },
    },
  ];

  const columnAmpleAnalysisDisabled: GridColDef[] = [
    {
      field: "grNaOH",
      headerName: "Gr NaOH",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.grNaOH}</Typography>;
      },
    },
    {
      field: "scrub",
      headerName: "Scrub",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.scrub}</Typography>;
      },
    },
    {
      field: "grmCon",
      headerName: "Grm Con",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.grmCon}</Typography>;
      },
    },
    {
      field: "totDens",
      headerName: "Tot Dens",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.totDens}</Typography>;
      },
    },
    {
      field: "sampDens",
      headerName: "Samp Dens",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.sampDens}</Typography>;
      },
    },
    {
      field: "lv",
      headerName: "LV",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.lv}</Typography>;
      },
    },
    {
      field: "hs",
      headerName: "HS",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.hs}</Typography>;
      },
    },
    {
      field: "BombHeadspacePressure",
      headerName: "Bomb Headspace Pressure",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.bombHeadspacePressure}</Typography>;
      },
    },
    {
      field: "totalHSMoles",
      headerName: "Total HS Moles",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.totalHSMoles}</Typography>;
      },
    },
    {
      field: "totalLoopMoles",
      headerName: "Total Loop Moles",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.totalLoopMoles}</Typography>;
      },
    },
    {
      field: "solFrac",
      headerName: "Sol. Frac",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.solFrac}</Typography>;
      },
    },
    {
      field: "sF6MolesInjection",
      headerName: "SF6 Moles in Injection",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.sF6MolesInjection}</Typography>;
      },
    },
    {
      field: "totalMolesSF6Bomb",
      headerName: "Total Moles SF6 Bomb",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.totalMolesSF6Bomb}</Typography>;
      },
    },
    {
      field: "microGrams",
      headerName: "Micro Grams",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.microGrams}</Typography>;
      },
    },
    {
      field: "ppMw",
      headerName: "PPMw",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.ppMw}</Typography>;
      },
    },
  ];

  useEffect(() => {
    tftSteamStore.setSampleAnalysisTable(sampleAnalysisTables);
  }, [sampleAnalysisTables, tftSteamStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="body1">GC Parameter</Typography>
      </Box>
      <StyledDataGridPremium
        columns={columnGcParameters}
        rows={[tftSteamStore.tft.steam.sF6Analysis]}
        pagination
        autoHeight
        getRowId={(row) => row.carrierGas}
        showCellVerticalBorder
        showColumnVerticalBorder
      />
      <Box sx={{ display: "flex", gap: 1, flexDirection: "row", width: "100%", mt: 2 }}>
        <TextField
          label="Compound Name"
          variant="outlined"
          name="compoundName"
          value={tftSteamStore.tft.steam.ampleAnalysis.compoundName}
          onChange={handleChange}
        />
        <FormControl sx={{ minWidth: "15%" }}>
          <InputLabel id="demo-simple-select-label">Milked Bombs</InputLabel>
          <Select
            name="milkedBombs"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tftSteamStore.tft.steam.ampleAnalysis.milkedBombs ? "yes" : "no"}
            label="Milked Bombs"
            onChange={(e) => handleChangeSelect(e)}
          >
            <MenuItem value={"yes"}>Yes</MenuItem>
            <MenuItem value={"no"}>No</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Molecul Weight"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1, step: "0.01" } }}
          name="molecularWeight"
          variant="outlined"
          value={tftSteamStore.tft.steam.ampleAnalysis.molecularWeight}
          onChange={handleChange}
          sx={{ width: "15%" }}
        />
        <TextField
          label="Solubility Factor"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1, step: "0.01" } }}
          name="solubilityFactor"
          variant="outlined"
          value={tftSteamStore.tft.steam.ampleAnalysis.solubilityFactor}
          onChange={handleChange}
          sx={{ width: "15%" }}
        />
        <TextField
          label="InletSystem Dead Volume (less loop)"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1, step: "0.01" } }}
          name="inletSystemDeadVolume"
          value={tftSteamStore.tft.steam.ampleAnalysis.inletSystemDeadVolume}
          onChange={handleChange}
          variant="outlined"
          sx={{ width: "30%" }}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Button variant="contained" color="primary" onClick={handleAddRow} sx={{ width: "10%" }}>
          Add
        </Button>
      </Box>
      <StyledDataGridPremium
        columns={columnAmpleAnalysis}
        rows={sampleAnalysisTables}
        getRowId={(row) => row.sequence}
        autoHeight
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEditSampleAnalysis}
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
      />
      <StyledDataGridPremium
        columns={columnAmpleAnalysisDisabled}
        rows={sampleAnalysisTables}
        getRowId={(row) => row.sequence}
        autoHeight
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
      />
    </Box>
  );
}

export default observer(TFTProcessSteamStep2);
