import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import ObjectForm from "./ObjectForm";
import { ObjectSchema } from "./schema/objectSchema";
import { FormikHelpers } from "formik";
import { Grid } from "@mui/material";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";
import { ILocationOptions } from "../../../app/models/location";

const initialValues: any = {
  objectTypeId: "",
  objectTypeName: "",
  objectName: "",
  wellSite: "",
  drillingSpudDate: new Date(),
  drillingCompletedDate: new Date(),
  rigName: "",
  rkbHeight: undefined,
  isActive: true,
  totalDepth: undefined,
  cellarEvalation: "",
  latitude: undefined,
  longitude: undefined,
  inclinasi: undefined,
  azimuth: undefined,
  srNo: "",
  locationId: "",
  remarks: "",
};

function ObjectFormPage() {
  const { objectStore, commonStore, locationStore } = useStore();
  const { getObjectDetail } = objectStore;
  const params = useParams();
  const [id] = useState(params.id);
  const navigate = useNavigate();
  const [data, setData] = useState(initialValues);
  const [selectedLocation, setSelectedLocation] = useState<TSelectedData | undefined>(undefined);
  const [location, setLocation] = useState<Array<ILocationOptions>>([]);

  useEffect(() => {
    let locationOptionsTemp: ILocationOptions[] = [];

    const fetchLocation = async () => {
      locationStore.getLocationOptions("").then((res) => {
        setLocation(res);
        locationOptionsTemp = res;
      });
    };

    fetchLocation();

    if (id)
      getObjectDetail(id).then((res) => {
        if (res) {
          let tempLocation = locationOptionsTemp.find((item) => item.value === res.locationId);
          setSelectedLocation({ label: tempLocation?.text ?? "", value: tempLocation?.value ?? "" });
          setData(res);
        }
      });
  }, [id, getObjectDetail]);

  useEffect(() => {
    commonStore.setTitlePage("Well Object");
  });

  const onSubmit = async (values: ObjectSchema, helpers: FormikHelpers<ObjectSchema>) => {
    if (id) {
      await objectStore.editObject(id, values).then(() => {
        navigate("/master/object");
      });
    } else {
      await objectStore.createObject(values).then(() => {
        navigate("/master/object");
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ObjectForm
          onSubmit={onSubmit}
          location={location}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          initialValues={data}
          id={id}
        />
      </Grid>
    </Grid>
  );
}

export default observer(ObjectFormPage);
