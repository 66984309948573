import { Box } from "@mui/system";
import RkdCard from "../../../app/component/card/RkdCard";
import { IObjectOptions } from "../../../app/models/object";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Divider, FormControl, Grid, TextField } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PTDecimationStepper from "./PTDecimationStepper";
import PTDecimationStep1 from "./PTDecimationStep1";
import PTDecimationStep2 from "./PTDecimationStep2";
import PTDecimationStep3 from "./PTDecimationStep3";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Decimation, DecimationSegmentCount } from "../../../app/models/decimation";

const steps = ["Step 1", "Step 2", "Step 3"];
export type TSelectedData = {
  value: string;
  label: string;
};

function PTDecimationForm() {
  const { objectStore, decimationStore } = useStore();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [objectIdsOption, setObjectIdsOption] = useState<Array<IObjectOptions>>([]);
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const [dateSurvey, setDateSurvey] = useState(new Date());
  const { wellId } = useParams<{ wellId: string }>();
  const { surveyDate } = useParams<{ surveyDate: string }>();
  const [showTableValidation, setShowTableValidation] = React.useState(false);
  const [showWellValidation, setShowWellValidation] = React.useState(false);
  const [showSurveyNrValidation, setShowSurveyNrValidation] = React.useState(false);
  const [showSurveyDateValidation, setShowSurveyDateValidation] = React.useState(false);
  const [showSumSelectedSegmentValidation, setShowSumSelectedSegmentValidation] = React.useState(false);
  const [showSelectedSegmentValidation, setShowSelectedSegmentValidation] = React.useState(false);
  const [surveyNr, setSurveyNr] = React.useState("");

  const fetchData = React.useCallback(() => {
    if (wellId && surveyDate) {
      decimationStore.getDecimation(wellId, surveyDate).then((decimation) => {
        let tempWell = objectIdsOption.find((item) => item.value === decimationStore.decimation.wellId);
        decimationStore.setDecimation(decimation);
        setSelectedWell({ label: tempWell?.text ?? "", value: tempWell?.value ?? "" });
        setDateSurvey(new Date(decimationStore.decimation.surveyDate + "Z"));
        setSurveyNr(decimationStore.decimation.surveyNo);
        if (decimationStore.decimation.selectedSegments.length > 0) {
          decimationStore.setDecimationSelectedSegment(decimationStore.decimation.selectedSegments);
        }

        if (decimationStore.decimation.currentStep === "Step1") {
          setActiveStep(0);
        } else if (decimationStore.decimation.currentStep === "Step2") {
          setActiveStep(1);
        } else if (decimationStore.decimation.currentStep === "Step3") {
          setActiveStep(2);
        }

        if (decimationStore.decimation.table.length > 0) {
          for (let i = 0; i < decimationStore.decimation.table.length; i++) {
            if (i === 0) {
              if (decimationStore.decimation.table[i].speed === 0) {
                decimationStore.decimation.table[i].segmentStatus = "Static";
              } else {
                decimationStore.decimation.table[i].segmentStatus = "Up";
              }
            } else {
              if (decimationStore.decimation.table[i].speed >= decimationStore.decimation.table[i - 1].speed) {
                decimationStore.decimation.table[i].segmentStatus = "Up";
              } else if (decimationStore.decimation.table[i].speed < decimationStore.decimation.table[i - 1].speed) {
                decimationStore.decimation.table[i].segmentStatus = "Down";
              } else {
                decimationStore.decimation.table[i].segmentStatus = "Static";
              }
            }
          }

          decimationStore.setDecimationSelectedSegment(decimationStore.decimation.selectedSegments);
          let setDecimationSegmentSelected: any = [];
          decimationStore.decimationSelectedSegment
            .filter((x) => x.selected)
            .forEach((element) => {
              setDecimationSegmentSelected.push(element.segment);
            });
          decimationStore.setDecimationSegmentSelected(setDecimationSegmentSelected);
          decimationStore.setDecimationSegmentCount(decimationStore.decimation.selectedSegments.length);
        }
      });
    }
  }, [decimationStore, surveyDate, wellId]);

  useEffect(() => {
    fetchData();
    if ((wellId === null && surveyDate === null) || (wellId === undefined && surveyDate === undefined)) {
      decimationStore.decimation = new Decimation();
      decimationStore.decimationSegmenCount = new DecimationSegmentCount();
    }
  }, [decimationStore, fetchData, surveyDate, wellId]);

  useEffect(() => {
    objectStore.getObjectOptions("Well").then((res) => {
      setObjectIdsOption(res);
    });
  }, [objectStore]);

  function handleChangeSurveyNr(event: any) {
    setSurveyNr(event.target.value);
    decimationStore.decimation.surveyNo = event.target.value;
  }

  function handleChangeSurveyDate(event: any) {
    setDateSurvey(event);
    decimationStore.decimation.surveyDate = event;
  }

  const handleNext = () => {
    let validationStep = 0;

    if (activeStep === 0) {
      if (showTableValidation) return;
    } else if (activeStep === 1) {
      if (decimationStore.decimationSegmenCount.value === 0) {
        validationStep += 1;
        setShowSumSelectedSegmentValidation(true);
      } else {
        setShowSumSelectedSegmentValidation(false);
      }

      if (decimationStore.decimationSegmenCount.value !== decimationStore.decimation.selectedSegments.length) {
        validationStep += 1;
        setShowSelectedSegmentValidation(true);
      } else {
        setShowSelectedSegmentValidation(false);
      }
    }

    if (validationStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const onSubmit = async () => {
    let validation = 0;
    if (decimationStore.decimation.wellId === null || decimationStore.decimation.wellId === "") {
      validation += 1;
      setShowWellValidation(true);
    } else {
      setShowWellValidation(false);
    }

    if (decimationStore.decimation.surveyNo === null || decimationStore.decimation.surveyNo === "") {
      validation += 1;
      setShowSurveyNrValidation(true);
    } else {
      setShowSurveyNrValidation(false);
    }

    if (decimationStore.decimation.surveyDate === null) {
      validation += 1;
      setShowSurveyDateValidation(true);
    } else {
      setShowSurveyDateValidation(false);
    }

    let decimations = decimationStore.decimation.table.filter((x) => x.segment !== null);
    if (decimations.length !== decimationStore.decimation.table.length || decimationStore.decimation.table.length === 0) {
      validation += 1;
      setShowTableValidation(true);
    } else {
      setShowTableValidation(false);
    }

    if (decimationStore.decimationSegmenCount.value === 0) {
      validation += 1;
      setShowSumSelectedSegmentValidation(true);
    } else {
      setShowSumSelectedSegmentValidation(false);
    }

    if (decimationStore.decimationSegmenCount.value !== decimationStore.decimation.selectedSegments.length) {
      validation += 1;
      setShowSelectedSegmentValidation(true);
    } else {
      setShowSelectedSegmentValidation(false);
    }

    if (validation === 0) {
      decimationStore.decimation.currentStep = "Step" + (activeStep + 1);
      await decimationStore.createDecimation(decimationStore.decimation);
      navigate("/pt");
    }
  };

  const onSubmitDraft = async () => {
    let validation = 0;
    if (decimationStore.decimation.wellId === null || decimationStore.decimation.wellId === "") {
      validation += 1;
      setShowWellValidation(true);
    } else {
      setShowWellValidation(false);
    }

    if (decimationStore.decimation.surveyNo === null || decimationStore.decimation.surveyNo === "") {
      validation += 1;
      setShowSurveyNrValidation(true);
    } else {
      setShowSurveyNrValidation(false);
    }

    if (decimationStore.decimation.surveyDate === null) {
      validation += 1;
      setShowSurveyDateValidation(true);
    } else {
      setShowSurveyDateValidation(false);
    }

    if (activeStep === 0) {
      let decimations = decimationStore.decimation.table.filter((x) => x.segment !== null);
      if (decimations.length !== decimationStore.decimation.table.length || decimationStore.decimation.table.length === 0) {
        validation += 1;
        setShowTableValidation(true);
      } else {
        setShowTableValidation(false);
      }
    } else if (activeStep === 1) {
      if (decimationStore.decimationSegmenCount.value === 0) {
        validation += 1;
        setShowSumSelectedSegmentValidation(true);
      } else {
        setShowSumSelectedSegmentValidation(false);
      }

      if (decimationStore.decimationSegmenCount.value !== decimationStore.decimation.selectedSegments.length) {
        validation += 1;
        setShowSelectedSegmentValidation(true);
      } else {
        setShowSelectedSegmentValidation(false);
      }
    }

    if (validation === 0) {
      decimationStore.decimation.currentStep = "Step" + (activeStep + 1);
      await decimationStore.draftDecimation(decimationStore.decimation);
      navigate("/pt");
    }
    // if(ptsTable.length === 0){
    //     setShowTableValidation(true);
    // }
    // else{
    //     setShowTableValidation(false);
    //     pts.table = ptsTable;
    //     await createPts(pts);
    // }
  };

  const onCancel = async () => {
    decimationStore.decimation = new Decimation();
    navigate("/pt");
  };

  if (objectStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title="Pressure & Temperature" subtitle="Form">
      <PTDecimationStepper activeStep={activeStep} steps={steps} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <FormControl>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                onChange={(e: any, val: any) => {
                  setSelectedWell(val);
                  decimationStore.decimation.wellId = val.value;
                }}
                disableClearable
                value={selectedWell}
                getOptionLabel={(option) => option.label}
                options={objectIdsOption.map((item) => ({ label: item.text, value: item.value }))}
                renderInput={(params) => <TextField value={selectedWell?.label} {...params} label="Well" />}
              />
              {showWellValidation ? <label style={{ color: "red" }}>Well must be choosen</label> : null}
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth name="surveyNo" onChange={handleChangeSurveyNr} type="text" label="Survey NR" value={surveyNr} />
              {showSurveyNrValidation ? <label style={{ color: "red" }}>Survey Nr must be filled</label> : null}
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  onChange={handleChangeSurveyDate}
                  value={dateSurvey}
                  renderInput={(props) => <TextField fullWidth label="Survey Date" {...props} />}
                />
              </LocalizationProvider>
              {showSurveyDateValidation ? <label style={{ color: "red" }}>Survey Date must be filled</label> : null}
            </Grid>
          </Grid>
        </FormControl>
        <Divider sx={{ my: "6px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          {activeStep === 0 ? (
            <PTDecimationStep1 setShowTableValidation={setShowTableValidation} showTableValidation={showTableValidation} />
          ) : activeStep === 1 ? (
            <PTDecimationStep2
              showValidationSelectedSegment={showSelectedSegmentValidation}
              showValidationSumSelectedSegment={showSumSelectedSegmentValidation}
            />
          ) : (
            <PTDecimationStep3 />
          )}
          <Grid container>
            <Grid item xs={6}>
              <Button variant="contained" color="error" onClick={onCancel} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <LoadingButton type="button" variant="contained" color="primary" onClick={onSubmitDraft} sx={{ mr: 1 }}>
                Save As Draft
              </LoadingButton>
              {activeStep === steps.length - 1 && (
                <LoadingButton type="button" variant="contained" color="primary" onClick={onSubmit}>
                  Submit
                </LoadingButton>
              )}
            </Grid>
            <Grid xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                variant="contained"
                startIcon={<KeyboardArrowLeftIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext} variant="contained" endIcon={<KeyboardArrowRightIcon />} disabled={activeStep === steps.length - 1}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default PTDecimationForm;
