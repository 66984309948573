import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import RkdCard from "../../app/component/card/RkdCard";
import { Box, Button } from "@mui/material";
import FPTGrid from "./FPTGrid";
import { Add, Delete } from "@mui/icons-material";
import { useStore } from "../../app/stores/store";

function FPTList() {
  const { ftpGridStore, ftpStore, dialogStore, snackbarStore, commonStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await ftpStore.deleteFPT(commonStore.selectedDataOnGrid).then(() => {
          ftpGridStore.getFPTList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <RkdCard title="Flow Performance Test" subtitle="Add Edit & Delete Flow Performance Test from this Page">
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
        <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
          Add
        </Button>
        <Button
          disabled={commonStore.selectedDataOnGrid.ids.length === 0}
          startIcon={<Delete />}
          variant="contained"
          color="error"
          onClick={() => onDelete()}
        >
          Bulk Delete
        </Button>
      </Box>
      <FPTGrid />
    </RkdCard>
  );
}

export default observer(FPTList);
