import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { TGeneralGeological } from "./Lithology";
import { useStore } from "../../../../app/stores/store";
import { IGeoTempTableRequest } from "../../../../app/models/geological";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function Temperature({
  selectedGeologicalType,
  deleteSequence,
  data,
  onDelete,
  setDeleteSequence,
  setDynamicConvertedData,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      depth: Number(row.depth),
      tempIn: Number(row.tempIn),
      tempOut: Number(row.tempOut),
      mwd: Number(row.mwd),
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoTempTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        depth: row.depth,
        tempIn: row.tempIn,
        tempOut: row.tempOut,
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    {
      field: "depth",
      headerName: "Depth (mMD)",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "tempIn",
      headerName: "Tempt In",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "tempOut",
      headerName: "Tempt Out",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "mwd",
      headerName: "mwd",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
      autoHeight
      getRowId={(row) => (id ? row.id : row.sequence)}
      columns={column}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
      processRowUpdate={handleRowEdit}
      showCellVerticalBorder
      showColumnVerticalBorder
    />
  );
}

export default observer(Temperature);
