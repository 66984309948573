import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import WellList from "./ObjectList";

function ObjectPage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Well Object',);
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <WellList />
      </Grid>
    </Grid>
  );
}

export default observer(ObjectPage);