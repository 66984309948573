import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { TPtReportWellDataResponse } from "../../../../app/models/ptModule";

export type TReportGridProps = {
  data: TPtReportWellDataResponse[];
};

export default function ReportGrid({ data }: TReportGridProps) {
  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "depth",
      headerName: "Depth",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "surveyNo",
      headerName: "Survey No",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "wellStatus",
      align: "center",
      headerName: "Well Status During Survey",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "previousWellCondition",
      headerName: "Previous Well Condition",
      align: "center",
      headerAlign: "center",
      editable: true,
      width: 200,
    },
    {
      field: "temperature",
      align: "center",
      headerName: "Temperature",
      editable: true,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "pressure",
      editable: true,
      headerName: "Pressure",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
  ];

  return <StyledDataGridPremium disableRowSelectionOnClick columns={gridColumns} rows={data} autoHeight />;
}
