import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import RkdCard from "../../../app/component/card/RkdCard";
import GeologicalReportBarScatterChart from "./GeologicalReportBarScatterChart";
import { useStore } from "../../../app/stores/store";
import { MenuProps } from "./GeologicalPageCreate";
import { GEOLOGICAL_TYPE_REPORT } from "../../../app/config/enum";
import LegendGeologicalReport from "./LegendGeologicalReport";
import { IObjectOptions } from "../../../app/models/object";
import GeologicalReportLineChart from "./GeologicalReportLineChart";

const ENUM_HELPER = {
  GEOLOGICAL: "GEOLOGICAL",
  WELL: "WELL",
};

function MyListSubheader(props: ListSubheaderProps & { muiSkipListHighlight: boolean }) {
  const { muiSkipListHighlight, ...other } = props;
  return <ListSubheader {...other} />;
}

function GeologicalReport() {
  const { objectStore, geologicalStore } = useStore();
  const { getGeologicalChart, loadingGetChart, geologicalReportData } = geologicalStore;
  const [selectedMultipleSelector, setSelectedMultipleSelector] = useState<string[]>([]);
  const [displaySelectedSelector, setDisplaySelectedSelector] = useState<string[]>([]);
  const [objectIdsOption, setObjectIdsOption] = useState<IObjectOptions[]>([]);
  const [selectedMultipleGeological, setSelectedMultipleGeological] = useState<string[]>([]);
  const [displaySelectedGeological, setDisplaySelectedGeological] = useState<string[]>([]);

  const isAllSelectorSelected = objectIdsOption.length > 0 && selectedMultipleSelector.length === objectIdsOption.length;

  const handleSelectSelector = async (event: SelectChangeEvent<typeof selectedMultipleSelector>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    if (identifier === ENUM_HELPER.GEOLOGICAL) {
      GEOLOGICAL_TYPE_REPORT.forEach((geological) => geological.value.forEach((val) => value.includes(val.value) && result.push(val.name)));
      setDisplaySelectedGeological(result);
      setSelectedMultipleGeological(typeof value === "string" ? value.split(",") : value);
    } else {
      if (value[value.length - 1] === "all") {
        setSelectedMultipleSelector(selectedMultipleSelector.length === objectIdsOption.length ? [] : objectIdsOption.map((item) => item.value));
        setDisplaySelectedSelector(objectIdsOption.map((item) => item.text));
        return;
      }
      objectIdsOption.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplaySelectedSelector(result);
      setSelectedMultipleSelector(typeof value === "string" ? value.split(",") : value);
    }
  };

  useEffect(() => {
    objectStore.getObjectOptions("Well").then((res) => {
      setObjectIdsOption(res);
    });
  }, [objectStore]);

  useEffect(() => {
    if (!selectedMultipleGeological.length && !selectedMultipleSelector.length) return;

    let payload = {
      types: selectedMultipleGeological,
      wellIds: selectedMultipleSelector,
    };

    getGeologicalChart(payload);
  }, [getGeologicalChart, selectedMultipleGeological, selectedMultipleSelector]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Geological Report" subtitle="Geological Report View">
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px", width: "100%" }}>
            <FormControl sx={{ width: "20%", justifyContent: "flex-end" }}>
              <InputLabel id="demo-multiple-checkbox-label">Well</InputLabel>
              <Select
                multiple
                value={selectedMultipleSelector}
                onChange={(e) => handleSelectSelector(e, ENUM_HELPER.WELL)}
                input={<OutlinedInput label="Well" />}
                renderValue={() => displaySelectedSelector.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={isAllSelectorSelected}
                    indeterminate={selectedMultipleSelector.length > 0 && selectedMultipleSelector.length < objectIdsOption.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {objectIdsOption.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedMultipleSelector.indexOf(item.value) > -1} />
                    <ListItemText primary={item.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "20%", justifyContent: "flex-end" }}>
              <InputLabel id="demo-multiple-checkbox-label">Geological Type</InputLabel>
              <Select
                multiple
                value={selectedMultipleGeological}
                label="Geological Type"
                renderValue={() => displaySelectedGeological.join(", ")}
                onChange={(e) => handleSelectSelector(e, ENUM_HELPER.GEOLOGICAL)}
              >
                {GEOLOGICAL_TYPE_REPORT.map((item, index) =>
                  item.value.map((geological, index) => (
                    <MenuItem key={geological.value} value={geological.value}>
                      {index === 0 ? (
                        <Box>
                          <MyListSubheader muiSkipListHighlight>{item.name}</MyListSubheader>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox checked={selectedMultipleGeological.indexOf(geological.value) > -1} />
                            <ListItemText primary={geological.name} />
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Checkbox checked={selectedMultipleGeological.indexOf(geological.value) > -1} />
                          <ListItemText primary={geological.name} />
                        </>
                      )}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Divider sx={{ mt: "6px" }} />
            <Typography variant="h2" sx={{ textAlign: "center", my: "24px" }}>
              Chart Reports
            </Typography>
            <LegendGeologicalReport />
            {loadingGetChart ? (
              <Box sx={{ mt: "24px", display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid sx={{ mt: "12px" }} container spacing="12px">
                {geologicalReportData.map((item) => (
                  <Grid key={item.wellId} item xs={6}>
                    <Box
                      sx={{
                        maxHeight: "360px",
                        display: "flex",
                        textAlign: "center",
                        flexDirection: "column",
                        border: "0.5px solid #edeff1",
                        borderRadius: "12px",
                        p: "12px",
                      }}
                    >
                      <Typography sx={{ my: "6px" }} variant="h2">
                        {item.wellName}
                      </Typography>
                      <Divider />
                      <Grid container>
                        <Grid item xs={8}>
                          <GeologicalReportLineChart data={item.charts} />
                        </Grid>
                        <Grid item xs={4}>
                          <GeologicalReportBarScatterChart barsData={item.bars} scattersData={item.scatters} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(GeologicalReport);
