import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  TBiweeklyAddPayload,
  TBiweeklyChartPayload,
  TBiweeklyChartResponse,
  TBiweeklyChartSelectorResponse,
  TBiweeklyEditPerRowPayload,
  TBiweeklyResponse,
  TGeneralTextValue,
} from "../models/geochemistry";
import { IDeleteData } from "../models/account";

export default class BiweeklyStore {
  loading = false;
  loadingDownloadTemplate = false;
  loadingUploadTemplate = false;
  loadingAdd = false;
  loadingDelete = false;
  loadingSelector = false;
  loadingChart = false;

  biweeklyData: TBiweeklyResponse | undefined = undefined;
  biweeklySelector: TBiweeklyChartSelectorResponse[] = [];
  biweeklyLeftYAxis: TGeneralTextValue[] = [];
  biweeklyRightYAxis: TGeneralTextValue[] = [];
  biweeklyCharts: TBiweeklyChartResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getBiweeklyChart = async (payload: TBiweeklyChartPayload) => {
    this.loadingChart = true;

    try {
      const result = await agent.Geochemistry.getBiweeklyChart(payload);

      runInAction(() => (this.biweeklyCharts = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };

  getBiweeklyLeftYAxis = async () => {
    this.loadingSelector = true;

    try {
      const result = await agent.Geochemistry.getBiweeklyLeftYAxis();

      runInAction(() => (this.biweeklyLeftYAxis = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loadingSelector = false));
    }
  };

  getBiweeklyRightYAxis = async () => {
    this.loadingSelector = true;

    try {
      const result = await agent.Geochemistry.getBiweeklyRightYAxis();

      runInAction(() => (this.biweeklyRightYAxis = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loadingSelector = false));
    }
  };

  getBiweeklyChartSelector = async () => {
    this.loadingSelector = true;

    try {
      const result = await agent.Geochemistry.getBiweeklyChartSelector();

      runInAction(() => (this.biweeklySelector = result));
    } catch (error) {
    } finally {
      runInAction(() => (this.loadingSelector = false));
    }
  };

  editBiweeklyPerRow = async (payload: TBiweeklyEditPerRowPayload) => {
    this.loadingAdd = true;

    try {
      await agent.Geochemistry.editBiweeklyPerRow(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingAdd = false));
    }
  };

  deleteBiweekly = async (ids: IDeleteData) => {
    this.loadingDelete = true;

    try {
      await agent.Geochemistry.deleteBiweekly(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  addBiweekly = async (payload: TBiweeklyAddPayload) => {
    this.loadingAdd = true;

    try {
      await agent.Geochemistry.addBiweekly(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingAdd = false));
    }
  };

  getBiweekly = async (date: string) => {
    this.loading = true;

    try {
      let result = await agent.Geochemistry.getBiweekly(date);

      runInAction(() => (this.biweeklyData = result));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getBiweeklyTemplate = async (date: string) => {
    this.loadingDownloadTemplate = true;

    try {
      return await agent.Geochemistry.getBiweeklyTemplate(date);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadTemplate = false));
    }
  };

  uploadBiweeklyTemplate = async (payload: FormData) => {
    this.loadingUploadTemplate = true;

    try {
      const result = agent.Geochemistry.uploadBiweeklyTemplate(payload);

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingUploadTemplate = false));
    }
  };

  setBiWeeklyData = (data: TBiweeklyResponse) => {
    this.biweeklyData = data;
  };
}
