import RkdCard from '../../../app/component/card/RkdCard';
import { Box } from '@mui/system';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ObjectTypeSchema, objectTypeSchema } from './schema/objectTypeSchema';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useStore } from '../../../app/stores/store';
import RkdBackdrop from '../../../app/component/helper/RkdBackdrop';

interface IProps {
  onSubmit: (
    values: ObjectTypeSchema,
  ) => Promise<unknown>;
  initialValues: ObjectTypeSchema;
  id?: string;
}

function ObjectTypeForm(props: IProps) {
  const { onSubmit, initialValues, id } = props;
  const { loadingForm } = useStore().objectTypeStore
  const navigate = useNavigate();

  const {
    handleSubmit: handleFormikSubmit,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
    touched,
    handleReset,
    isSubmitting
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: objectTypeSchema,
    enableReinitialize: true,
  });

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    handleFormikSubmit(e);
  };

  const isUpdate = useMemo(() => Boolean(id), [id]);

  if (loadingForm) return <RkdBackdrop />;
  return (
    <RkdCard title={`${isUpdate ? 'Edit' : 'New'} Object Type`}>
      <Box
        component={'form'}
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiFormControl-root': { mb: 2 },
          width: '50%',
        }}
      >
        <input type="hidden" name="id" value={id} />
        <TextField
          name="objectTypeName"
          label="Object Name"
          variant="outlined"
          fullWidth
          required
          value={values.objectTypeName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.objectTypeName && !!errors.objectTypeName}
          helperText={touched.objectTypeName && errors.objectTypeName}
        />

        <TextField
          name="remarks"
          label="Remarks"
          variant="outlined"
          fullWidth
          required
          multiline
          rows={'5'}
          value={values.remarks}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.remarks && !!errors.remarks}
          helperText={touched.remarks && errors.remarks}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ mr: '8px' }}
            onClick={(e) => {
              handleReset(e);
              navigate('/master/object-types');
            }}
            onBlur={handleBlur}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained" 
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default observer(ObjectTypeForm);
