import {
  DataGrid,
  DataGridProps,
  GridColDef,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  GridValueFormatterParams,
  gridClasses,
  GridRowParams,
} from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import DataGridStore from "../../stores/materialUI/dataGridStore";
import { format } from "date-fns";
import { Box } from "@mui/material";
import { useStore } from "../../stores/store";
interface Props {
  gridStore: DataGridStore;
  columns: GridColDef[];
  isRowSelectable?: ((params: GridRowParams<any>) => boolean) | undefined;
}

function RkdDataGrid({ gridStore, columns, isRowSelectable }: Props, props: Partial<DataGridProps>) {
  const { commonStore } = useStore();

  // supported server-side filtering
  columns.map((col) => {
    if (col.type === "number")
      col.filterOperators = getGridNumericOperators().filter((op) => !["isAnyOf", "isEmpty", "isNotEmpty"].includes(op.value));
    else if (col.type === "boolean") col.filterOperators = getGridBooleanOperators();
    else if (col.type === "date") {
      col.filterOperators = getGridDateOperators().filter((op) => !["isEmpty", "isNotEmpty"].includes(op.value));
      col.valueFormatter = (params: GridValueFormatterParams<Date>) => {
        return format(params.value, "d MMM yyyy");
      };
    } else if (col.type === "dateTime") {
      col.filterOperators = getGridDateOperators(true).filter((op) => !["isEmpty", "isNotEmpty"].includes(op.value));
      col.valueFormatter = (params: GridValueFormatterParams<Date>) => {
        return format(params.value, "d MMM yyyy, HH:mm:ss");
      };
    } else if (col.type === "singleSelect") col.filterOperators = getGridSingleSelectOperators();
    else if (col.type === "actions") {
    } else {
      // string or default
      col.filterOperators = getGridStringOperators().filter((op) => !["isAnyOf"].includes(op.value));
      if (col.type !== "string") {
        console.warn(`Column type of '${col.field}' is not defined, therefore the filter is set to string`);
      }
    }
    return col;
  });

  return (
    <Box
      sx={{
        height: 632,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <DataGrid
        columns={columns}
        rows={gridStore.dataGridResult.data}
        rowCount={gridStore.dataGridResult.rowCount}
        loading={gridStore.loadingGrid}
        paginationMode="server"
        pageSize={gridStore.pageSize}
        page={gridStore.currentPage}
        autoHeight
        onPageChange={(newPage) => gridStore.setPage(newPage)}
        onPageSizeChange={(newPageSize) => gridStore.setPageSize(newPageSize)}
        sortingMode="server"
        isRowSelectable={isRowSelectable}
        onSortModelChange={gridStore.setSortModel}
        filterMode="server"
        onFilterModelChange={gridStore.setFilterModel}
        rowsPerPageOptions={[10, 25, 50, 100]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          commonStore.setSelectionDataOnGrid(newSelectionModel);
        }}
        selectionModel={commonStore.selectedDataOnGrid.ids}
        showCellRightBorder
        showColumnRightBorder
        {...props}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 2,
            borderColor: "#DCDEE1",
          },
          borderColor: "#DCDEE1",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgb(237,239,241)",
            fontWeight: "bold",
            border: 1,
            borderColor: "#DCDEE1",
            borderRight: 0,
            borderTop: 1,
            borderBottom: 0,
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderRight: 0,
            borderColor: "#DCDEE1 !important",
            borderTop: 1,
            borderBottom: 0,
          },
        }}
      />
    </Box>
  );
}

export default observer(RkdDataGrid);
