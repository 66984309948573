import * as yup from "yup";

export type TPtReportWellDataResponse = {
  depth: number;
  id: string;
  pressure: number;
  previousWellCondition: string | null;
  statusDuringSurvey: string | null;
  surveyDate: Date;
  surveyNo: string;
  temperature: number;
  wellName: string;
};

export type TPtReportDownloadPayload = {
  data: {
    wellName: string;
    table: TPtReportWellDataResponse[];
  }[];
};

export type TPtReportWellDataPayload = {
  date: string;
  wellIds: string[];
};

export type TPtReportWellOptions = {
  text: string;
  value: string;
};

export type TPtModuleCorrectionReferences = {
  sequence: number;
  depth: number;
  elevation: number;
  pressure: number;
  temperature: number;
};

export type TPtModuleWellReferenceOptionsResponse = {
  wellId: string;
  surveyDate: Date;
  decimationId: string;
  wellName: string;
};

export type TPtModuleDetailDataResponse = {
  id: string;
  decimationId: string;
  wellId: string;
  surveyNo: string;
  surveyDate: Date;
  doneBy: string;
  checkedBy: string;
  remarks: string;
  step1: {
    whp: number;
    decimationRefWellId: string;
    decimationRefDate: Date;
    decimationRefId: string;
    correctionMeasureds: [
      {
        sequence: number;
        depth: number;
        elevation: number;
        measuredPressure: number;
        measuredTemperature: number;
        checkedPressure: number;
        checkedTemperature: number;
      }
    ];
    correctionReferences: [
      {
        sequence: number;
        depth: number;
        elevation: number;
        pressure: number;
        temperature: number;
      }
    ];
  };
  step2: {
    zAxis: string;
    compareField: string;
    selectionA: string;
    selectionB: string;
    details: [
      {
        sequence: number;
        depthOrElevation: number;
        selectionA: number;
        selectionB: number;
        delta: number;
      }
    ];
    limit: {
      limitDpDz: number;
      limitDtDz: number;
      limitP: number;
      limitT: number;
      limitWhpConsist: number;
      limitWhpIndicator: number;
      limitWhpVariable: number;
    };
    warning: {
      dataConcistency: string;
      whpVariability: string;
      dataReported: string;
      measureAtDepth0: string;
      reportConcistency: string;
      whpConcistency: string;
    };
  };
  step3: {
    zAxis: string;
    selectionT: string;
    selectionP: string;
    gradientCheck: number;
    liqGradientComp: number;
    details: [
      {
        sequence: number;
        depthOrElevation: number;
        valueSelectionP: number;
        valueSelectionT: number;
        tsat: number;
        deltaTSat: number;
        thermodynamicState: string;
      }
    ];
    phaseDistributor: {
      gasLevel: number;
      waterLevel: number;
      twoPhaseBottom: number;
      remarks: string;
    };
    limit: {
      limitDpDzLiq: number;
      limitDpDzLiqRel: number;
      limitDpDzap: number;
      limitDpDzapRel: number;
      limitTsat: number;
    };
  };
};

export type TPtModuleDetailResponse = {
  data: TPtModuleDetailDataResponse;
  messages: string;
  success: boolean;
};

export const ptModuleInitialValue = {
  id: "",
  wellId: "",
  decimationId: "",
  doneBy: "",
  surveyNo: "",
  surveyDate: new Date(),
  checkedBy: "",
  remarks: "",
  step1: {
    whp: 0,
    decimationRefWellId: "",
    decimationRefDate: new Date(),
    decimationRefId: "",
    correctionMeasureds: [],
    correctionReferences: [],
  },
  step2: {
    zAxis: "",
    compareField: "",
    selectionA: "",
    selectionB: "",
    details: [],
    limit: {
      limitDpDz: 0,
      limitDtDz: 0,
      limitP: 0,
      limitT: 0,
      limitWhpConsist: 0,
      limitWhpIndicator: 0,
      limitWhpVariable: 0,
    },
    warning: {
      dataConcistency: "",
      whpVariability: "",
      dataReported: "",
      measureAtDepth0: "",
      reportConcistency: "",
      whpConcistency: "",
    },
  },
  step3: {
    zAxis: "",
    selectionT: "",
    selectionP: "",
    gradientCheck: 0,
    liqGradientComp: 0,
    details: [],
    phaseDistributor: {
      gasLevel: 0,
      waterLevel: 0,
      twoPhaseBottom: 0,
      remarks: "",
    },
    limit: {
      limitDpDzLiq: 0,
      limitDpDzLiqRel: 0,
      limitDpDzap: 0,
      limitDpDzapRel: 0,
      limitTsat: 0,
    },
  },
};

export const ptModuleValidationSchema = yup.object({
  id: yup.string().nullable(),
  decimationId: yup.string().required(),
  wellId: yup.string().required(),
  surveyDate: yup.date().required(),
  doneBy: yup.string().required().nullable(),
  checkedBy: yup.string().required().nullable(),
  surveyNo: yup.string().required(),
  remarks: yup.string().nullable(),
  step1: yup.object({
    whp: yup.number().nullable(),
    decimationRefWellId: yup.string(),
    decimationRefDate: yup.date(),
    decimationRefId: yup.string().nullable(),
    correctionMeasureds: yup.array().of(
      yup.object({
        sequence: yup.number().nullable(),
        depth: yup.number().nullable(),
        elevation: yup.number().nullable(),
        measuredPressure: yup.number().nullable(),
        measuredTemperature: yup.number().nullable(),
        checkedPressure: yup.number().nullable(),
        checkedTemperature: yup.number().nullable(),
      })
    ),
    correctionReferences: yup.array().of(
      yup.object({
        sequence: yup.number(),
        depth: yup.number(),
        elevation: yup.number(),
        pressure: yup.number(),
        temperature: yup.number(),
      })
    ),
  }),
  step2: yup.object({
    zAxis: yup.string(),
    compareField: yup.string(),
    selectionA: yup.string(),
    selectionB: yup.string(),
    details: yup.array().of(
      yup.object({
        depthOrElevation: yup.number(),
        selectionA: yup.number(),
        selectionB: yup.number(),
        delta: yup.number(),
      })
    ),
    limit: yup.object({
      limitDpDz: yup.number(),
      limitDtDz: yup.number(),
      limitP: yup.number(),
      limitT: yup.number(),
      limitWhpConsist: yup.number(),
      limitWhpIndicator: yup.number(),
      limitWhpVariable: yup.number(),
    }),
    warning: yup.object({
      dataConcistency: yup.string(),
      whpVariability: yup.string(),
      dataReported: yup.string(),
      measureAtDepth0: yup.string(),
      reportConcistency: yup.string(),
      whpConcistency: yup.string(),
    }),
  }),
  step3: yup.object({
    zAxis: yup.string(),
    selectionT: yup.string(),
    selectionP: yup.string(),
    gradientCheck: yup.number(),
    liqGradientComp: yup.number(),
    details: yup.array().of(
      yup.object({
        sequence: yup.number(),
        depthOrElevation: yup.number(),
        valueSelectionP: yup.number(),
        valueSelectionT: yup.number(),
        tsat: yup.number(),
        deltaTSat: yup.number(),
        thermodynamicState: yup.string(),
      })
    ),
    phaseDistributor: yup.object({
      gasLevel: yup.number(),
      waterLevel: yup.number(),
      twoPhaseBottom: yup.number(),
      remarks: yup.string(),
    }),
    limit: yup.object({
      limitDpDzLiq: yup.number(),
      limitDpDzLiqRel: yup.number(),
      limitDpDzap: yup.number(),
      limitDpDzapRel: yup.number(),
      limitTsat: yup.number(),
    }),
  }),
});
