import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ObjectTypeGrid from "./ObjectTypeGrid";
import { Add, Delete } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";

function ObjectTypeList() {
  const { commonStore, objectTypeGridStore, dialogStore, objectTypeStore, snackbarStore } = useStore();
  const navigate = useNavigate();

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await objectTypeStore.deleteObjectType(commonStore.selectedDataOnGrid).then(() => {
          objectTypeGridStore.getObjectTypeList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <>
      <RkdCard title="Object Type" subtitle="Add Edit & Delete Object Type from this Page">
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
          <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
            Add
          </Button>
          <Button
            disabled={commonStore.selectedDataOnGrid.ids.length === 0}
            startIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => onDelete()}
          >
            Bulk Delete
          </Button>
        </Box>
        <ObjectTypeGrid />
      </RkdCard>
    </>
  );
}

export default observer(ObjectTypeList);
