import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { DecimationSelect } from "../../../app/models/decimation";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import PTDecimationTablePerSegment from "./PTDecimationTablePerSegment";

interface IProps {
  showValidationSumSelectedSegment: boolean;
  showValidationSelectedSegment: boolean;
}

function PTDecimationStep2(props: IProps) {
  const [numbers, setNumbers] = useState<Array<DecimationSelect>>([]);
  const [segments, setSegments] = useState<Array<DecimationSelect>>([]);
  const { decimationStore } = useStore();
  const [selected, setSelected] = useState(decimationStore.decimationSegmenCount.value);
  const [tableSummary, setTabelSummary] = useState<Array<any>>([]);

  useEffect(() => {
    let dataExport: any = [];
    let dataExportCount: any = [];
    let dataExportSegment: any = [];
    decimationStore.decimation.table.forEach((data) => {
      if (!!data.segment && dataExport.indexOf(data.segment) === -1) dataExport.push(data.segment);
    });
    for (let i = 0; i < dataExport.length; i++) {
      dataExportCount.push({ index: i + 1, value: i + 1, text: i + 1 });
      dataExportSegment.push({ index: i + 1, value: dataExport[i], text: "Segment " + dataExport[i] });
    }
    setNumbers(dataExportCount);
    setSegments(dataExportSegment);
  }, [decimationStore, selected]);

  useEffect(() => {
    let tabelSummary = [];
    let i = 0;
    let j = 0;
    let max = selected;
    if (selected % 2 !== 0) {
      max -= 1;
    }
    while (i < max) {
      tabelSummary.push(
        <>
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={decimationStore.decimation.selectedSegments[j]} />
          </Grid>
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={decimationStore.decimation.selectedSegments[j + 1]} />
          </Grid>
        </>
      );
      i += 2;
      j += 2;
    }
    if (selected % 2 !== 0) {
      if (selected === 1) {
        tabelSummary.push(
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={decimationStore.decimation.selectedSegments[0]} />
          </Grid>
        );
      } else {
        tabelSummary.push(
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={decimationStore.decimation.selectedSegments[selected - 1]} />
          </Grid>
        );
      }
    }
    setTabelSummary(tabelSummary);
  }, [selected, segments, decimationStore.decimation.selectedSegments, props.showValidationSelectedSegment]);

  function handleChange(event: any) {
    decimationStore.setDecimationSegmentSelected([]);
    setTabelSummary([]);
    setSelected(event.target.value);
    decimationStore.setDecimationSegmentCount(event.target.value);
    let tabelSummary = [];
    let i = 0;
    let max = event.target.value;
    if (event.target.value % 2 !== 0) {
      max -= 1;
    }
    while (i < max) {
      tabelSummary.push(
        <>
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={0} />
          </Grid>
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={0} />
          </Grid>
        </>
      );
      i += 2;
    }
    if (event.target.value % 2 !== 0) {
      if (event.target.value === 1) {
        tabelSummary.push(
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={0} />
          </Grid>
        );
      } else {
        tabelSummary.push(
          <Grid sx={{ mt: "14px" }} key={i} item xs={12}>
            <PTDecimationTablePerSegment decimationSegmentSelect={segments} value={0} />
          </Grid>
        );
      }
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControl>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputLabel id="demo-simple-select-label">Sum Selected segment</InputLabel>
              <Select
                fullWidth
                name="sumSelectedSegment"
                labelId="Sum_Selected_segment"
                label="Sum Selected segment"
                displayEmpty
                onChange={handleChange}
                value={selected}
              >
                {numbers.map((item) => (
                  <MenuItem key={item.index} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </FormControl>
        {props.showValidationSumSelectedSegment ? <label style={{ color: "red" }}>Sum Selected segment must be choosen</label> : null}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", alignItems: "left" }}>
        <b>Temperature & Pressure Data per Segment</b>
        {tableSummary}
        {props.showValidationSelectedSegment ? <label style={{ color: "red" }}>Please select all segment</label> : null}
      </Box>
    </Box>
  );
}

export default PTDecimationStep2;
