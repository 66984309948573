import { action, makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";

export default class GeophysicsGravityDataConversionGridStore extends DataGridStore {
  constructor() {
    super();

    makeObservable(this, {
      getGeophysicGravityDetailGrid: action,
    });
  }

  getGeophysicGravityDetailGrid = async (id: string) => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geophysic.getGeophysicGravityDataConversionGrid(urlParams, id);

      this.setDataGridResult(result);
      return result
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  setGridFromDetail = async (data: any[]) => {
    this.setDataGridResult({ rowCount: 0, data: data });
  };
}
