import { Dispatch, SetStateAction } from "react";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { TGeophysicsImportTemplateGravitiesResponse } from "../../../app/models/geophysics";

type TGeophysicGridAfterUploadProps = {
  convertionData: TGeophysicsImportTemplateGravitiesResponse[];
  setConvertionData: Dispatch<SetStateAction<TGeophysicsImportTemplateGravitiesResponse[]>>;
};

export default function GeophysicGridAfterUpload({ setConvertionData, convertionData }: TGeophysicGridAfterUploadProps) {
  const handleRowEdit = (newRow: any) => {
    const temp = convertionData.map((current) => {
      if (current.sequence !== newRow.sequence) return current;
      return newRow;
    });

    setConvertionData(temp);
    return newRow;
  };

  return (
    <StyledDataGridPremium
      pagination
      showColumnVerticalBorder
      showCellVerticalBorder
      columns={[
        {
          field: "id",
          headerName: "ID",
          editable: true,
          headerAlign: "center",
          width: 100,
          align: "center",
          headerClassName: "tab-header",
        },
        {
          field: "z",
          editable: true,
          headerName: "Z",
          headerAlign: "center",
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "lonDeg",
          headerName: "X (deg)",
          editable: true,
          headerAlign: "center",
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "latDeg",
          headerName: "Y (deg)",
          headerAlign: "center",
          editable: true,
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "gobsMgal",
          headerName: "Gobs (mGal)",
          headerAlign: "center",
          align: "center",
          editable: true,
          width: 160,
          headerClassName: "tab-header",
        },
        {
          field: "latitudeCorrection",
          headerName: "Latitude Correction",
          headerAlign: "center",
          editable: true,
          align: "center",
          width: 160,
          headerClassName: "tab-header",
        },
        {
          field: "latitudeAnomaly",
          headerName: "Latitude Anomaly",
          headerAlign: "center",
          editable: true,
          align: "center",
          width: 160,
          headerClassName: "tab-header",
        },
        {
          field: "freeAirCorrection",
          headerName: "Free Air Correction",
          headerAlign: "center",
          align: "center",
          width: 160,
          editable: true,
          headerClassName: "tab-header",
        },
        {
          field: "freeAirAnomaly",
          headerName: "Free Air Anomaly",
          headerAlign: "center",
          editable: true,
          align: "center",
          width: 160,
          headerClassName: "tab-header",
        },
        {
          field: "bouguerCorrection",
          headerName: "Bouguer Correction",
          headerAlign: "center",
          align: "center",
          width: 160,
          editable: true,
          headerClassName: "tab-header",
        },
        {
          field: "bouguerAnomaly",
          headerName: "Bouguer Anomaly",
          headerAlign: "center",
          width: 160,
          align: "center",
          editable: true,
          headerClassName: "tab-header",
        },
        {
          field: "tc",
          editable: true,
          headerName: "TC",
          headerAlign: "center",
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "cbaMgal",
          headerName: "CBA (mGal)",
          headerAlign: "center",
          align: "center",
          editable: true,
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "elevasi",
          headerName: "Elevasi",
          headerAlign: "center",
          align: "center",
          width: 100,
          editable: true,
          headerClassName: "tab-header",
        },
        {
          field: "svd",
          headerName: "SVD",
          headerAlign: "center",
          editable: true,
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "fhd",
          headerName: "FHD",
          headerAlign: "center",
          align: "center",
          editable: true,
          width: 100,
          headerClassName: "tab-header",
        },
        {
          field: "distance",
          headerName: "Distance",
          editable: true,
          headerAlign: "center",
          align: "center",
          width: 100,
          headerClassName: "tab-header",
        },
        // {
        //   field: "actions",
        //   headerName: "Action",
        //   type: "actions",
        //   width: 70,
        //   getActions: (params) => {
        //     return [
        //       <GridActionsCellItem
        //         color="error"
        //         icon={<DeleteIcon />}
        //         label="Delete"
        //         onClick={() => {
        //           modalStore.open(
        //             <GeologicalModalDelete
        //               handleDelete={() => {
        //                 if (params.row.geologicalId) {
        //                   setDeleteSequence([...deleteSequence, params.row.sequence]);
        //                   setDynamicConvertedData(dynamicConvertedData.filter((current) => current.sequence !== params.row.sequence));
        //                 } else {
        //                   setDynamicConvertedData(dynamicConvertedData.filter((current) => current.sequence !== params.row.sequence));
        //                 }
        //                 modalStore.close();
        //               }}
        //             />
        //           );
        //         }}
        //       />,
        //     ];
        //   },
        // },
      ]}
      rows={convertionData}
      processRowUpdate={handleRowEdit}
      autoHeight
    />
  );
}
