import { action, makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { IDeleteData } from "../models/account";

export default class GeophysicsMTGridStore extends DataGridStore {
  loadingDelete = false;
  loadingDetail = false;
  loadingStationOptions = false;

  constructor() {
    super();

    makeObservable(this, {
      loadingDelete: observable,
      loadingDetail: observable,
      getGeophysicsMTGrid: action,
      getGeophysicsDetailMT: action,
    });
  }

  getGeophysicsMTGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      let result = await agent.Geophysic.getGeophysicMTGrid(urlParams);

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getGeophysicsDetailMT = async (id: string) => {
    this.loadingDetail = true;

    try {
      const result = await agent.Geophysic.getGeophysicMTDetail(id);

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDetail = false));
    }
  };

  addMT = async (payload: any) => {
    try {
      await agent.Geophysic.addGeophysicMT(payload);
    } catch (error) {
      throw error;
    }
  };

  deleteGeophysicsGravity = async (ids: IDeleteData) => {
    this.loadingDelete = true;
    try {
      await agent.Geophysic.deleteGeophysicGravity(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  deleteGeophysicsMT = async (ids: IDeleteData) => {
    this.loadingDelete = true;
    try {
      await agent.Geophysic.deleteGeophysicMT(ids);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDelete = false));
    }
  };

  getStationOptions = async (search: string) => {
    this.loadingStationOptions = true;

    try {
      const params = new URLSearchParams({
        search,
      });
      return await agent.Geophysic.getStationOptions(params);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingStationOptions = false));
    }
  };

  updateGridCallback = action(() => {
    this.getGeophysicsMTGrid();
  });
}
