export interface IPtsChartRequest {
    objectIds: Array<string>,
    y1Selectors: Array<string>,
    y2Selectors: Array<string>
}

export interface IPtsChartResponse {
    y1: Array<IPtsChartSeriesResponse>,
    y2: Array<IPtsChartSeriesResponse>
}

export interface IPtsChartSeriesResponse {
    name: string,
    data: Array<IPtsChartDataResponse>
}

export interface IPtsChartYAxis {
    opposite: boolean,
    title: IPtsChartYAxisTitle
}

export interface IPtsChartYAxisTitle {
    text: string
}

export interface IPtsChartDataResponse {
    x: number,
    y: number
}

export interface IPtsObjectsRequest {
    objectId: string,
    well: string
}

export interface IPtsXTypesRequest {
    type: string
}

export interface IPtsY1TypesRequest {
    type: string
}

export interface IPtsY2TypesRequest {
    type: string
}

export interface IPtsChartData {
    objectIds: Array<string>,
    y1Selectors: Array<string>,
    y2Selectors: Array<string>
}

export class PtsChart implements IPtsChartRequest {
    objectIds: Array<string>;
    y1Selectors: Array<string>;
    y2Selectors: Array<string>;

    constructor() {
        this.objectIds = [];
        this.y1Selectors = [];
        this.y2Selectors = [];
    }

    clone(pts: IPtsChartRequest) {
        this.objectIds = pts.objectIds;
        this.y1Selectors = pts.y1Selectors;
        this.y2Selectors = pts.y2Selectors;
    }
}

export class PtsObjectsRequest implements IPtsObjectsRequest {
    objectId: string;
    well: string;

    constructor() {
        this.objectId = "";
        this.well = "";
    }

    clone(pts: IPtsObjectsRequest) {
        this.objectId = pts.objectId;
        this.well = pts.well;
    }
}

export class PtsXTypesRequest implements IPtsXTypesRequest {
    type: string;

    constructor() {
        this.type = "";
    }

    clone(pts: IPtsXTypesRequest) {
        this.type = pts.type;
    }
}

export class PtsY1TypesRequest implements IPtsY1TypesRequest {
    type: string;

    constructor() {
        this.type = "";
    }

    clone(pts: IPtsY1TypesRequest) {
        this.type = pts.type;
    }
}

export class PtsY2TypesRequest implements IPtsY2TypesRequest {
    type: string;

    constructor() {
        this.type = "";
    }

    clone(pts: IPtsY2TypesRequest) {
        this.type = pts.type;
    }
}

export class PtsChartYAxis implements IPtsChartYAxis {
    opposite;
    title;

    constructor() {
        this.opposite = false;
        this.title = new PtsChartYAxisTitle();
    }

    clone(pts: PtsChartYAxis) {
        this.opposite = pts.opposite;
        this.title = pts.title;
    }
}

export class PtsChartYAxisTitle implements IPtsChartYAxisTitle {
    text;

    constructor() {
        this.text = "";
    }

    clone(pts: PtsChartYAxisTitle) {
        this.text = pts.text;
    }
}