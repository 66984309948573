export enum FileType {
  Image = "image/*",
  Audio = "audio/*",
  Vieo = "video/*",
  Document = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint",
}

export const UNLIMITED_PAGE = 99999;

export enum sex {
  Male = "Laki - Laki",
  Female = "Perempuan",
}

export enum Roles {
  Superadmin = "Superadmin",
  Admin = "Admin",
}

export enum ObjectStatus {
  Production = "Production",
  Monitoring = "Monitoring",
  Injection = "Injection",
  Shutdown = "Shutdown",
}

export const BIWEEKLY_DATA = {
  Productions: "PRODUCTION",
  Injections: "INJECTION",
  Separators: "SEPARATOR",
};

export const differenceBehaviourGeologicalType = [
  {
    type: 5,
    name: "PCS",
  },
  {
    type: 6,
    name: "LOSS",
  },
  {
    type: 7,
    name: "FEEDZONE",
  },
];

export enum GeologicalType {
  LITHOLOGY = "LITHOLOGY",
  ALTERATION = "ALTERATION",
  DRILLPARAMETER = "DRILL PARAMETER",
  PCS = "PCS",
  LOSS = "LOSS",
  FEEDZONE = "FEEDZONE",
  TEMPERATURE = "TEMPERATURE",
  MEB = "MEB",
  TRAJECTORY = "TRAJECTORY",
}

export const PRODUCTIONS_TABLE = {
  // id: "ID",
  // vesselGroupId: "Vessel Group ID",
  wellName: "Well Name",
  fcv: "FCV",
  fcvPrev: "FCV Prev",
  whp: "WHP",
  whpPrev: "WHP Prev",
  wht: "WHT",
  whtPrev: "WHT Prev",
  vesselGroupName: "HP Separator Name",
  // vesselId: "Vessel Id",
  // vesselName: "Vessel Name",
  hpInletPress: "HP InletPress",
  hpInletPressPrev: "HP Inlet press Prev",
  hpInletTemp: "HP Inlet Temp",
  hpInletTempPrev: "HP Inlet Temp Prev",
  hpSteamRate: "HP Steam Rate",
  hpSteamRatePrev: "HP Steam Rate Prev",
  hpBrineRate: "HP Brine Rate",
  hpBrineRatePrev: "Has Brine Rate Prev",
  // hasBrinePump: "Has Brine Pump",
  // brinePumpId: "Brine Pump Id",
  brinePumpName: "Brine Pump Name",
  brinePumpRate: "Brine Pump Rate",
  brinePumpRatePrev: "Brine Pump Rate Rev",
  // wellId: "Well ID",
  // sequence: "Sequence",
};

// export const displayText = (role: Roles) => {
//   switch (role)
//   {
//     case Roles.Superadmin: return "Super Administrator";
//     case Roles.Admin: return "Administrator";
//     default: return "";
//   }
// }

//Notes: yang dikirim di db keys nya

export function enumToArray(obj: any) {
  return Object.keys(obj).map((value) => ({ value, text: obj[value] }));
}

export const enumMap = <T>(obj: {}, callbackFn: (key: string, value: string, index: number) => T): T[] => {
  // const enumValues: string[] = Object.values(obj);
  return Object.keys(obj).map<T>((k, i) => callbackFn(k, k, i));
};

export function removeZUTC(date: string) {
  if (date.endsWith("Z")) {
    return date.slice(0, -5);
  } else {
    return date;
  }
}

export function capitalizeFirstLetter(word: string) {
  let firstLetter = word.charAt(0);
  let anotherletter = word.slice(1);

  return firstLetter.toUpperCase() + anotherletter;
}
export const OBJECT_STATUS = {
  ACTIVE: "Active",
  NON_ACTIVE: "Non Active",
};
export const ncgDisplay = ["ncgFt3", "ncgMoles", "ncgPpmw", "ncgWt", "ncgWt10"];
export const percentDisplay = ["oBrine18", "hBrine2", "oSteam18", "hSteam2"];
export const cO2Display = ["cO2DryGas", "cO2Moles", "cO2Ppmw"];
export const h2SDisplay = ["h2SDryGas", "h2SMoles", "h2SPpmw"];
export const nH3Display = ["nH3DryGas", "nH3Moles", "nH3Ppmw"];
export const aRDisplay = ["arDryGas", "arMoles", "arPpmw"];
export const nDisplay = ["nDryGas", "nMoles", "nPpmw"];
export const cH4Display = ["cH4DryGas", "cH4Moles", "cH4Ppmw"];
export const hDisplay = ["hDryGas", "hMoles", "hPpmw"];
export const mglDisplay = [
  "li",
  "na",
  "k",
  "ca",
  "mg",
  "siO2",
  "b",
  "ci",
  "f",
  "sO4",
  "hcO3",
  "cO3",
  "nH4",
  "as",
  "sr",
  "ba",
  "fe",
  "mn",
  "nH3",
  "al",
  "sb",
  "pb",
  "zn",
  "cu",
  "cr",
  "h2S",
];
export const percentageDisplay = [
  "corrLI",
  "corrNa",
  "corrK",
  "corrCa",
  "corrMg",
  "corrMg1000",
  "corrSIO2",
  "corrB",
  "corrCI",
  "corrF",
  "corrSO4",
  "corrHCO3",
  "corrCO3",
  "corrNH4",
  "corrAs",
  "corrSr",
  "corrBa",
  "corrFe",
  "corrMn",
  "corrNH3",
  "corrAl",
  "corrSb",
  "corrPb",
  "corrZn",
  "corrCu",
  "corrCr",
  "corrH2S",
];
export const QuarterlyTable = {
  sampleDate: "Sample Date",
  elevation: "Elevation",
  pBarg: "Barg",
  pBara: "Bara",
  sRthr: "SR (t/hr)",
  bRthr: "BR (t/hr)",
  flash: "Flash (%)",
  htdCalc: "H TD Calc (kj/kg)",
  id: "ID",
  fcv: "FCV (%)",
  whpBarg: "Barg",
  whpBara: "Bara",
  linePBarg: "Barg",
  linePBara: "Bara",
  lineT: "Line T (C)",
  pSampMeasuredBarg: "Bara",
  pSampMeasuredBara: "Bara",
  tSamp: "T Samp from P (C)",
  tSampMeasured: "T Samp Measured (C)",
  hSteam: "H Steam (kj/kg)",
  hLiquid: "H Liquid (kj/kg)",
  latentHeatVapourisation: "Latent heat of Vapourisation of Water",
  htd: "H TD (kj/kg)",
  ySurface: "Y Surface",
  qAdia: "Q Aida (C)",
  latentHeatQuartz: "Latent heat of Quartz Adiabatic",
  yExcess: "Y Excess",
  li: "Li",
  na: "Na",
  k: "K",
  ca: "Ca",
  mg: "Mg",
  siO2: "SiO2",
  b: "B",
  ci: "CI",
  f: "F",
  sO4: "SO4",
  hcO3: "HCO3",
  cO3: "CO3",
  nH4: "NH4",
  as: "As",
  sr: "Sr",
  ba: "Ba",
  fe: "Fe",
  mn: "Mn",
  nH3: "NH3",
  al: "Al",
  sb: "Sb",
  pb: "Pb",
  zn: "Zn",
  cu: "Cu",
  cr: "Cr",
  h2S: "H2S",
  oBrine18: "oBrine (%)",
  hBrine2: "hBrine (%)",
  oSteam18: "oSteam (%)",
  hSteam2: "hSteam (%)",
  labPh: "Lab Ph (25 C)",
  fieldPh: "Field pH",
  tds: "TDS (mg/l)",
  ec: "EC (us/cm)",
  lab: "Lab",
  ncgFt3: "ft3/lb",
  ncgMoles: "moles/106 moles H2O",
  ncgPpmw: "ppmw",
  ncgWt: "wt%",
  ncgWt10: "wt%^10",
  air: "Air (%)",
  h2OPpmw: "H2O (ppmw)",
  cO2DryGas: "dry gas % by volume",
  cO2Moles: "moles/106 moles H2O",
  cO2Ppmw: "ppmw",
  h2SDryGas: "dry gas % by volume",
  h2SMoles: "moles/106 moles H2O",
  h2SPpmw: "ppmw",
  nH3DryGas: "dry gasa % by volume",
  nH3Moles: "moles/106 moles H2O",
  nH3Ppmw: "ppmw",
  arDryGas: "dry gas % by vlolume",
  arMoles: "moles/106 moles H2O",
  arPpmw: "ppmw",
  nDryGas: "dry gas % by vlolume",
  nMoles: "moles/106 moles H2O",
  nPpmw: "ppmw",
  cH4DryGas: "dry gas % by vlolume",
  cH4Moles: "moles/106 moles H2O",
  cH4Ppmw: "ppmw",
  hDryGas: "dry gas % by vlolume",
  hMoles: "moles/106 moles H2O",
  hPpmw: "ppmw",
  corrLI: "Li",
  corrNa: "Na",
  corrK: "K",
  corrCa: "Ca",
  corrMg: "Mg",
  corrMg1000: "Mg*100",
  corrSIO2: "SiO2",
  corrB: "B",
  corrCI: "CI",
  corrF: "F",
  corrSO4: "SO4",
  corrHCO3: "HCO3",
  corrCO3: "CO3",
  corrNH4: "NH4",
  corrAs: "As",
  corrSr: "Sr",
  corrBa: "Ba",
  corrFe: "Fe",
  corrMn: "Mn",
  corrNH3: "NH3",
  corrAl: "Al",
  corrSb: "Sb",
  corrPb: "Pb",
  corrZn: "Zn",
  corrCu: "Cu",
  corrCr: "Cr",
  corrH2S: "H2S",
  corrOTD18: "OTD (%)",
  corrHTD2: "HTD (%)",
  corrNcgPpmw: "NCG (ppmW)",
  corrH2OPpmw: "H2O (ppmW)",
  corrCO2Ppmw: "CO2 (ppmW)",
  corrH2SPpmw: "H2S (ppmW)",
  corrNH3Ppmw: "NH3 (ppmW)",
  corrArPpmw: "Ar (ppmW)",
  corrNPpmw: "N (ppmW)",
  corrCH4Ppmw: "CH 4 (ppmW)",
  corrHPpmw: "H (ppmW)",
  // sequence: 1,
};
export const editableRowData = [
  "pBara",
  "flash",
  "htdCalc",
  "whpBara",
  "linePBara",
  "pSampMeasuredBara",
  "tSamp",
  "hSteam",
  "hLiquid",
  "latentHeatVapourisation",
  "ySurface",
  "qAdia",
  "latentHeatQuartz",
  "yExcess",
  "ncgWt",
  "ncgWt10",
  "corrNcgPpmw",
  "corrH2OPpmw",
  "corrCO2Ppmw",
  "corrH2SPpmw",
  "corrNH3Ppmw",
  "corrArPpmw",
  "corrNPpmw",
  "corrCH4Ppmw",
  "corrHPpmw",
  ...percentageDisplay,
];

export function formatDateToMMDDYYYY(date: Date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${month}-${day}-${year}`;
}

export const INJECTIONS_TABLE = {
  // id: "ID",
  // wellId: "Well Id",
  wellName: "Well Name",
  fcv: "FCV",
  fcvPrev: "FCV Prev",
  whp: "WHP",
  whpPrev: "WHP Prev",
  wht: "WHT",
  whtPrev: "WHT Prev",
  duration: "Duration",
  durationPrev: "Duration Prev",
  brineRate: "Brine Rate",
  brineRatePrev: "Brine Rate Prev",
  avgBrineRate: "Average Brine Rate",
  avgBrineRatePrev: "Average Brine Rate Prev",
  totalBrineRate: "Total Brine Rate",
  totalBrineRatePrev: "Total Brine Rate Prev",
  // sequence: "Sequence",
};

export const MONITORING_TABLE = {
  // id: "ddf8522c-496a-4537-9ee2-0e2120e23d83",
  // locationId: "95948213-e67f-41af-8eb8-6cbf17dbfbf7",
  locationName: "Location Name",
  // wellId: "34c8400f-a13e-4c58-a944-08ed74d29972",
  wellName: "Well Name",
  fcv: "FCV",
  fcvPrev: "FCV Prev",
  whp: "WHP",
  whpPrev: "WHP Prev",
  wht: "WHT",
  whtPrev: "WHT Prev",
  // sequence: 1,
};

export const DAILY_SHEET_PRODUCTIONS = {
  BRINE_RATE: "BRINE RATE",
  INLET_PRESS: "INLET PRESS",
  INLET_TEMP: "INLET TEMP",
  STEAM_RATE: "STEAM RATE",
};

export const DAILY_SHEET = {
  PRODUCTIONS: "PRODUCTIONS",
  INJECTIONS: "INJECTIONS",
  MONITORINGS: "MONITORINGS",
};

export const editableRowDataEcolab = ["", "dp", "flowRateM3", "orcGroupName", "polymerization"];

export const GEOLOGICAL_TYPE_REPORT = [
  {
    name: "LITHOLOGY",
    value: [
      {
        name: "Lithology",
        value: "LITHOLOGY",
      },
    ],
  },
  {
    name: "ALTERATION",
    value: [
      {
        name: "Formation And Alteration",
        value: "ALTERATION_FORMATION_ALTERATION",
      },
    ],
  },
  {
    name: "Drill Parameter",
    value: [
      {
        name: "ROP",
        value: "DRILL_ROP",
      },
      {
        name: "TVD",
        value: "DRILL_TVD",
      },
    ],
  },
  {
    name: "PCS",
    value: [
      {
        name: "MVD",
        value: "PCS_MVD",
      },
      {
        name: "MMSL",
        value: "PCS_MMSL",
      },
    ],
  },
  {
    name: "LOSS",
    value: [
      {
        name: "LOSS (Bbl)",
        value: "LOSS",
      },
    ],
  },
  {
    name: "FEEDZONE",
    value: [
      {
        name: "MVD",
        value: "FEEDZONE_MVD",
      },
      {
        name: "MASL",
        value: "FEEDZONE_MASL",
      },
    ],
  },
  {
    name: "TEMPERATURE",
    value: [
      {
        name: "Temperature In",
        value: "TEMPERATURE_IN",
      },
      {
        name: "Temperature Out",
        value: "TEMPERATURE_OUT",
      },
    ],
  },
  {
    name: "MEB",
    value: [
      {
        name: "MEB",
        value: "MEB",
      },
    ],
  },
  {
    name: "TRAJECTORY",
    value: [
      {
        name: "Inc",
        value: "TRAJECTORY_INC",
      },
      {
        name: "Azi",
        value: "TRAJECTORY_AZI",
      },
      {
        name: "TVD Trajectory",
        value: "TRAJECTORY_TVD",
      },
      {
        name: "Elevation",
        value: "TRAJECTORY_ELEVATION",
      },
      {
        name: "Throw",
        value: "TRAJECTORY_THROW",
      },
    ],
  },
];

export const SCATTERS_LEGEND = [
  {
    name: "Feedzone Major",
    color: "red",
  },
  {
    name: "Feedzone Minor",
    color: "blue",
  },
];

export const BARS_LEGEND = [
  {
    name: "Lithology",
    value: "lithology",
    color: "#FFCA9D",
  },
  {
    name: "Alteration",
    value: "alteration",
    color: "#5BAA8D",
  },
];

export const LINE_CHARTS_LEGEND = [
  {
    name: "MEB",
    color: "red",
    value: "meb",
  },
  {
    name: "ROP",
    value: "drillRop",
    color: "blue",
  },
  {
    value: "drillTvd",
    name: "TVD",
    color: "orange",
  },
  {
    value: "loss",
    name: "LOSS",
    color: "yellow",
  },
  {
    name: "Temperature In",
    value: "temperatureIn",
    color: "grey",
  },
  {
    name: "Temperature Out",
    value: "temperatureOut",
    color: "green",
  },
  {
    name: "Inc",
    value: "trajectoryInc",
    color: "#637280",
  },
  {
    value: "trajectoryAzi",
    name: "Azi",
    color: "#43C55F",
  },
  {
    name: "TVD Trajectory",
    value: "trajectoryTvd",
    color: "#9B9AFD",
  },
  {
    name: "Elevation",
    value: "trajectoryElevation",
    color: "#CC74E3",
  },
  {
    value: "trajectoryThrow",
    name: "Throw",
    color: "#FF962F",
  },
];
