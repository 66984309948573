import { makeObservable, runInAction, action, observable } from "mobx";
import agent from "../api/agent";
import DataGridStore from "./materialUI/dataGridStore";
import { IVesselGroupPayload } from "../models/vesselGroup";

export default class VesselGroupStore extends DataGridStore {
  loadingForm = false;
  loadingListOptions = false;

  constructor() {
      super();

      makeObservable(this, {
        loadingForm: observable,
        loadingListOptions: observable,
        getVesselGroupList: action,
        getVesselGroupDetail: action,
        getVesselGroupOptions: action
      });
  }

  updateGridCallback = action(() => {
    this.getVesselGroupList();
  })

  getVesselGroupList = async () => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.VesselGroup.listGrid(urlParams);
      this.setDataGridResult(result);
      
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingGrid = false);
    }
  }

  getVesselGroupDetail = async (id: string) => {
    this.loadingForm = true;
    try {
      return await agent.VesselGroup.detail(id);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingForm = false);
    }
  }

  getVesselGroupOptions = async (search: string) => {
    this.loadingListOptions = true;
    try {
      const params = new URLSearchParams({
        search
      })
      return await agent.VesselGroup.listOptions(params);
    } catch (error) {
      throw error;
    } finally {
        runInAction(() => this.loadingListOptions = false);
    }
  }

  createVesselGroup = async (vesselgroup: IVesselGroupPayload) => {
    try {
      return await agent.VesselGroup.create(vesselgroup);
    } catch (error) {
      throw error;
    }
  }

  editVesselGroup = async (id: string, vesselgroup: IVesselGroupPayload) => {
    try {
      return await agent.VesselGroup.edit(id, vesselgroup);
    } catch (error) {
      throw error;
    }
  }

  deleteVesselGroup = async (id: any) => {
    try {
      return await agent.VesselGroup.delete(id);
    } catch (error) {
      throw error;
    }
  }

}