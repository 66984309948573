import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { ObjectTypeSchema } from "./schema/objectTypeSchema";
import { Grid } from "@mui/material";
import ObjectTypeForm from "./ObjectTypeForm";

const initialValues = {
  objectTypeName: "",
  remarks: "",
};

function ObjectTypeFormPage() {
  const { commonStore, objectTypeStore, objectTypeGridStore } = useStore();
  const { getObjectTypeDetail, createObjectType, editObjectType } = objectTypeStore;
  const params = useParams();
  const navigate = useNavigate();
  const [id] = useState(params.id);
  const [data, setData] = useState(initialValues);

  useEffect(() => {
    if (id)
      getObjectTypeDetail(id).then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, [id, getObjectTypeDetail]);

  useEffect(() => {
    commonStore.setTitlePage("Object Type");
  });

  const onSubmit = async (values: ObjectTypeSchema) => {
    if (id) {
      await editObjectType(id, values)
        .then(objectTypeGridStore.getObjectTypeList)
        .then(() => {
          navigate("/master/object-types");
        });
    } else {
      await createObjectType(values)
        .then(objectTypeGridStore.getObjectTypeList)
        .then(() => {
          navigate("/master/object-types");
        });
    }
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <ObjectTypeForm onSubmit={onSubmit} initialValues={data} id={id} />
      </Grid>
    </Grid>
  );
}

export default observer(ObjectTypeFormPage);
