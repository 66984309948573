import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { IPtsChartSeriesResponse, IPtsChartYAxis } from "../../app/models/ptsChart";

interface IProps {
  series: Array<IPtsChartSeriesResponse>
  yAxis: Array<IPtsChartYAxis>
}

function PtsChartReport(props: IProps){
    const options: ApexCharts.ApexOptions  = {
        xaxis: {
          type: 'numeric',
          tickAmount: 8,
        },
        yaxis: props.yAxis
    };
    return(
        <ReactApexChart options={options}  series={props.series} />
    );
}

export default observer(PtsChartReport);