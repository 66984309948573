import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, FormHelperText, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdForm from "../../../app/component/form/RkdForm";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IObjectOptions } from "../../../app/models/object";
import { useFormik } from "formik";
import * as yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AttachFile } from "@mui/icons-material";
import { TPTSData } from "../../../app/models/pts";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const validationSchema = yup.object({
  fileName: yup.string(),
  fileUrl: yup.string(),
  wellCondition: yup.string().required(),
  wellId: yup.string().required(),
  ptsDate: yup.date().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function PTSPageCreate() {
  const { objectStore, ptsStore } = useStore();
  const { selectedPTS, addPTS, editPTS, getDetailPTS, loadingForm, loadingCreateUpdate } = ptsStore;
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const { getObjectOptions } = objectStore;
  const [objectWellList, setObjectWellList] = useState<IObjectOptions[]>([]);
  const [files, setFiles] = useState<any>(undefined);
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedPTS) {
      return selectedPTS;
    } else {
      return { wellId: "", fileName: "", fileUrl: "", wellCondition: "", ptsDate: new Date() };
    }
  }, [selectedPTS]);

  const onSubmit = async (values: ValidationSchema) => {
    if (files) {
      const formData = new FormData();

      formData.append("wellId", values.wellId);
      formData.append("ptsDate", new Date(values.ptsDate).toISOString());
      files && formData.append("file", files[0] as any);
      values.wellCondition && formData.append("wellCondition", values.wellCondition);
      selectedPTS && id && formData.append("id", id);

      id ? await editPTS(formData, id).then(() => navigate("/pts")) : await addPTS(formData).then(() => navigate("/pts"));
    } else {
      setFileError("file is required");
    }
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (id) {
      getDetailPTS(id).then((res: TPTSData) => {
        setSelectedWell({ label: res.wellName, value: res.wellId });
        var file = new File([res.file as unknown as BlobPart], res.fileName, { type: res.file.contentType }); //add filename here])
        setFiles([file]);
      });
    }

    getObjectOptions("Well").then((res) => {
      setObjectWellList(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RkdCard title="PTS" subtitle={id ? "Edit" : "Create"}>
      {loadingForm ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <RkdForm initialValues={initialValues} submitAction={onSubmit} validationSchema={validationSchema}>
          <Grid container spacing="6px">
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                fullWidth
                onChange={(e: any, val: any) => {
                  setSelectedWell(val);
                  setFieldValue("wellId", val.value);
                }}
                disableClearable
                value={selectedWell}
                getOptionLabel={(option) => option.label}
                options={objectWellList.map((item) => ({ label: item.text, value: item.value }))}
                renderInput={(params) => <TextField helperText={errors.wellId} name="wellId" error={!!errors.wellId} {...params} label="Well Name" />}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Well Condition"
                fullWidth
                name="wellCondition"
                value={values.wellCondition}
                error={!!errors.wellCondition}
                helperText={errors.wellCondition}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="PTS Date"
                  value={values.ptsDate}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => setFieldValue("ptsDate", e)}
                  renderInput={(props) => (
                    <TextField
                      required
                      fullWidth
                      name="endDate"
                      error={!!errors.ptsDate}
                      helperText={errors.ptsDate && String(errors.ptsDate)}
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <Box>
                <span style={{ fontWeight: "bold", marginRight: "6px" }}>File:</span>
                <Button component="label" variant="contained">
                  Choose File
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files.length > 0) {
                        setFileError(undefined);
                        setFiles(e.target.files);
                        setFieldValue("fileUrl", undefined);
                      }
                    }}
                    name="postScreenshotFile"
                  />
                </Button>
                {fileError && <FormHelperText sx={{ color: "red" }}>{fileError}</FormHelperText>}
              </Box>
              <Box>
                {!!files && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                    <AttachFile sx={{ fontSize: "16px" }} />
                    {id && values.fileUrl ? (
                      <a rel="noreferrer" href={values.fileUrl}>
                        {files[0].name}
                      </a>
                    ) : (
                      files[0].name
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
            <Box>
              <Button disabled={loadingCreateUpdate} variant="contained" color="error" onClick={() => navigate("/pts")}>
                Cancel
              </Button>
            </Box>
            <Box>
              <LoadingButton loading={loadingCreateUpdate} onClick={() => handleSubmit()} type="submit" variant="contained" color="primary">
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </RkdForm>
      )}
    </RkdCard>
  );
}

export default observer(PTSPageCreate);
