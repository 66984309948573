import { action, makeObservable, observable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { TOrcOptions } from "../models/orc";
import { IDeleteData } from "../models/account";
import { TOrcGroupDetailResponse, TOrcGroupPayload } from "../models/orcGroup";
import {
  TBrineEcolabDetailDataResponse,
  TBrineEcolabPayload,
  TBrineORCChartLeftRightYAxis,
  TBrineORCChartPayload,
  TBrineORCChartResponse,
  TBrineORCChartRightYAxisPayload,
  TBrineORCDetailDataResponse,
  TBrineORCReportGridDataResponse,
  TBrineOrcMonitoringPayload,
} from "../models/geochemistry";

export default class BrineORCMonitoringStore extends DataGridStore {
  brineOrcAdEditLoading = false;
  brineORCMonitoringDetailLoading = false;
  brineORCMonitoringLoading = false;
  loadingORCReportGrid = false;
  orcGetOptionsLoading = false;
  loadingChart = false;
  loadingTemplate = false;
  brineORCChartLeftYAxis: TBrineORCChartLeftRightYAxis[] = [];
  brineORCChartRightYAxis: TBrineORCChartLeftRightYAxis[] = [];
  brineORCMonitoringDetail: TBrineORCDetailDataResponse[] = [];
  brineEcolabDetail: TBrineEcolabDetailDataResponse[] = [];
  selectedOrc: TOrcGroupDetailResponse | null = null;
  brineORCChart: TBrineORCChartResponse | null = null;
  brineORCReportGrid: TBrineORCReportGridDataResponse[] = [];

  orcOptions: TOrcOptions[] = [];

  constructor() {
    super();

    makeObservable(this, {
      getBrineORCMonitoringGrid: action,
      getBrineORCReportGrid: action,
      getBrineORCChart: action,
      setManualBrineOrcData: action,
      getBrineORCChartLeftYAxis: action,
      getBrineORCChartRightYAxis: action,
      brineORCChartLeftYAxis: observable,
      brineORCChartRightYAxis: observable,
      loadingORCReportGrid: observable,
      brineORCReportGrid: observable,
      brineORCMonitoringDetail: observable,
      loadingChart: observable,
      loadingTemplate: observable,
      brineORCChart: observable,
    });
  }

  getBrineOrcTemplate = async (date: string) => {
    this.loadingTemplate = true;

    try {
      return await agent.Geochemistry.getBrineOrcTemplate(date);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingTemplate = false));
    }
  };

  uploadBrineOrcTemplate = async (payload: FormData) => {
    this.loadingTemplate = true;

    try {
      const result = agent.Geochemistry.uploadBrineOrcTemplate(payload);

      return (await result).data;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingTemplate = false));
    }
  };

  getBrineORCReportGrid = async (orcGroupId: string, startDate: string, endDate: string) => {
    this.loadingORCReportGrid = true;

    try {
      const result = await agent.Geochemistry.getBrineORCReportGrid(orcGroupId, startDate, endDate);

      runInAction(() => (this.brineORCReportGrid = result.data));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingORCReportGrid = false));
    }
  };

  getBrineORCChart = async (payload: TBrineORCChartPayload) => {
    this.loadingChart = true;

    try {
      const result = await agent.Geochemistry.getBrineORCChart(payload);

      runInAction(() => (this.brineORCChart = result));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };

  getBrineORCChartRightYAxis = async (payload: TBrineORCChartRightYAxisPayload) => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getBrineORCChartRightYAxis(payload);

      runInAction(() => (this.brineORCChartRightYAxis = result));
      return result;
    } catch (error) {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getBrineORCChartLeftYAxis = async () => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getBrineORCChartLeftYAxis();

      runInAction(() => (this.brineORCChartLeftYAxis = result));
    } catch (error) {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  getBrineORCMonitoringGrid = async () => {
    this.loadingGrid = true;

    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Geochemistry.getBrineORCMonitoringGrid(urlParams);

      this.setDataGridResult(result);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  addEditBrineOrcMonitoring = async (payload: TBrineOrcMonitoringPayload) => {
    this.brineOrcAdEditLoading = true;

    try {
      await agent.Geochemistry.addEditBrineOrcMonitoring(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.brineOrcAdEditLoading = false));
    }
  };

  addEditBrineEcolab = async (payload: TBrineEcolabPayload) => {
    this.brineOrcAdEditLoading = true;

    try {
      await agent.Geochemistry.addEditBrineEcolab(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.brineOrcAdEditLoading = false));
    }
  };

  getBrineEcolab = async (date: string) => {
    this.loadingGrid = true;

    try {
      const result = await agent.Geochemistry.getBrineEcolab(date);

      runInAction(() => (this.brineEcolabDetail = result.data));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  addOrcGroup = async (payload: TOrcGroupPayload) => {
    this.brineOrcAdEditLoading = true;

    try {
      await agent.ORC.addOrcGroup(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.brineOrcAdEditLoading = false));
    }
  };

  editOrcGroup = async (id: string, payload: TOrcGroupPayload) => {
    this.brineOrcAdEditLoading = true;

    try {
      await agent.ORC.editOrcGroup(id, payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.brineOrcAdEditLoading = false));
    }
  };

  getBrineORCMonitoringDetail = async (date: string) => {
    this.loadingGrid = true;

    try {
      let res = await agent.Geochemistry.getBrineORCMonitoringDetail(date);

      runInAction(() => (this.brineORCMonitoringDetail = res.data));
      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  deleteBrineORCMonitoringGrid = async (ids: IDeleteData) => {
    this.brineORCMonitoringLoading = true;

    try {
      await agent.Geochemistry.deleteBrineORCMonitoringGrid(ids);
    } catch (error) {
      runInAction(() => (this.brineORCMonitoringLoading = false));
    }
  };

  setSelectedOrc = (data: TOrcGroupDetailResponse | null) => {
    this.selectedOrc = data;
  };

  setManualBrineEcolabData = async (data: TBrineEcolabDetailDataResponse[]) => {
    this.brineEcolabDetail = data;
  };

  setManualBrineOrcData = async (data: TBrineORCDetailDataResponse[]) => {
    this.brineORCMonitoringDetail = data;
  };

  setGridFromDetail = async (data: any[]) => {
    this.setDataGridResult({ rowCount: 0, data: data });
  };

  clearGridAfterUploadGeological = () => {
    this.setDataGridResult({ rowCount: 0, data: [] });
  };
}
