import { GridValidRowModel, GridColDef, GridColumnGroup } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../../../app/stores/store";
import { TProductionsTable } from "../../../../app/models/dailysheet";
import { DAILY_SHEET, PRODUCTIONS_TABLE } from "../../../../app/config/enum";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

type TDailyProductionsGrid = {
  index: number;
};

const editableRowData = [
  "hpInletPressPrev",
  "hpInletTempPrev",
  "hpSteamRatePrev",
  "hpBrineRatePrev",
  "brinePumpRatePrev",
  "fcvPrev",
  "whpPrev",
  "whtPrev",
  "wellName",
  "vesselGroupName",
  "brinePumpName",
];

function DailyProductionsGrid({ index }: TDailyProductionsGrid) {
  const { setManualDailySheetData, dailySheetData } = useStore().dailysheetStore;
  const [temporaryData, setTemporaryData] = useState<TProductionsTable[]>([...(dailySheetData?.productions[index].table ?? [])]);

  const generateGridColumns = () => {
    let temp: GridColDef<GridValidRowModel>[] = [];
    Object.entries(PRODUCTIONS_TABLE).map(([key, value], index) =>
      temp.push({
        field: key,
        editable: editableRowData.includes(key) ? false : true,
        headerAlign: "center",
        align: "center",
        headerName: value,
        flex: 1,
        width: 120,
      })
    );

    return temp;
  };

  const handleRowEdit = (newData: any) => {
    let temp = temporaryData.map((current) => {
      let { id, wellName, wellId, sequence, ...rest } = newData;

      if (current.vesselGroupId === newData.vesselGroupId && current.id !== newData.id) {
        return { ...rest, id: current.id, wellName: current.wellName, wellId: current.wellId, sequence: current.sequence };
      }
      if (current.sequence !== newData.sequence) return current;

      return newData;
    });

    setManualDailySheetData(temp, index, DAILY_SHEET.PRODUCTIONS);
    setTemporaryData(temp);
    return newData;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = generateGridColumns();
  const generateDynamicColumnGroupingModel = () => {
    let resultTemp: GridColumnGroup[] = [
      {
        groupId: "Wells Parameter",
        headerAlign: "center",
        children: [
          {
            groupId: "Well Name",
            headerAlign: "center",
            field: "wellName",
          },
          {
            groupId: "FCV (%)",
            headerAlign: "center",
            children: [{ field: "fcv" }, { field: "fcvPrev" }],
          },
          {
            groupId: "WHP (bar)",
            headerAlign: "center",
            children: [{ field: "whp" }, { field: "whpPrev" }],
          },
          {
            groupId: "WHT (Deg C)",
            headerAlign: "center",
            children: [{ field: "wht" }, { field: "whtPrev" }],
          },
        ],
      },
      {
        groupId: "HP Separator",
        headerAlign: "center",
        children: [
          {
            groupId: "HP Separator Name",
            headerAlign: "center",
            field: "vesselGroupName",
          },
          {
            groupId: "Inlet Press. (Bar)",
            headerAlign: "center",
            children: [{ field: "hpInletPress" }, { field: "hpInletPressPrev" }],
          },
          {
            groupId: "Inlet Temp. (Deg C)",
            headerAlign: "center",
            children: [{ field: "hpInletTemp" }, { field: "hpInletTempPrev" }],
          },
          {
            groupId: "Steam Rate (T/hr)",
            headerAlign: "center",
            children: [{ field: "hpSteamRate" }, { field: "hpSteamRatePrev" }],
          },
          {
            groupId: "Brine Rate (M3/hr)",
            headerAlign: "center",
            children: [{ field: "hpBrineRate" }, { field: "hpBrineRatePrev" }],
          },
        ],
      },
      {
        groupId: "Brine Pump",
        headerAlign: "center",
        children: [
          {
            groupId: "BP. Name",
            headerAlign: "center",
            field: "brinePumpName",
          },
          {
            groupId: "Brine Rate (M3/hr)",
            headerAlign: "center",
            children: [{ field: "brinePumpRate" }, { field: "brinePumpRatePrev" }],
          },
        ],
      },
    ];
    return resultTemp;
  };

  return (
    <StyledDataGridPremium
      getRowId={(row) => row.id}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={(params: any) => {
        if (editableRowData.includes(params.field)) return "datagrid-disable";

        return "";
      }}
      columnGroupingModel={generateDynamicColumnGroupingModel()}
      columns={gridColumns}
      processRowUpdate={handleRowEdit}
      rows={dailySheetData?.productions[index].table ?? []}
    />
  );
}

export default observer(DailyProductionsGrid);
