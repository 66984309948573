import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import RkdForm from "../../../app/component/form/RkdForm";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as yup from "yup";
import { Close, DownloadOutlined, UploadOutlined, OpenInBrowser, AttachFile, Delete } from "@mui/icons-material";
import { ILocationOptions } from "../../../app/models/location";
import { format } from "date-fns";
import { BootstrapDialog } from "../mtSurvey/GeophysicMTCreate";
import GeophysicGridAfterUpload from "./GeophysicGridAfterUpload";
import { TFiles, TGeophysicsImportTemplateGravitiesResponse } from "../../../app/models/geophysics";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const validationSchema = yup.object({
  locationId: yup.string(),
  year: yup.string(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function GeophysicGravityCreate() {
  const { geophysicsGravityGridStore, geophyisicsMTGridStore, geophysicsGravityDataConversionGridStore } = useStore();
  const [locationOptions, setLocationOptions] = useState<ILocationOptions[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<TSelectedData | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [convertionData, setConvertionData] = useState<TGeophysicsImportTemplateGravitiesResponse[] | []>([]);
  const navigate = useNavigate();
  const [files, setFiles] = useState<TFiles[]>([]);
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    return { locationId: "", year: new Date().getFullYear().toString() };
  }, []);

  const onSubmit = async (values: ValidationSchema) => {
    const formData = new FormData();

    convertionData.map((item, index) =>
      Object.keys(item).map((key) => {
        formData.append(
          `table[${index}].${key}`,
          item[key as keyof TGeophysicsImportTemplateGravitiesResponse]
            ? item[key as keyof TGeophysicsImportTemplateGravitiesResponse].toString()
            : "0"
        );
      })
    );

    values.year && formData.append("year", values.year);
    values.locationId && formData.append("locationId", values.locationId);
    files.map((item, index) => formData.append(`files[${index}]`, item.file as any));

    geophysicsGravityGridStore.addGravity(formData);
    navigate("/geophysic-gravity-survey");
  };

  const { handleSubmit, handleChange, values, errors, isValid, dirty, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    let locationOptionsTemp: ILocationOptions[] = [];

    const fetchLocation = async () => {
      await geophyisicsMTGridStore.getStationOptions("").then((res) => {
        setLocationOptions(res);
        locationOptionsTemp = res;
      });
    };

    fetchLocation();

    if (id) {
      geophysicsGravityGridStore.getGeophysicsDetailGravity(id).then((res) => {
        let tempLocation = locationOptionsTemp.find((item) => item.value === res.locationId);
        geophysicsGravityDataConversionGridStore.getGeophysicGravityDetailGrid(id).then((res) => setConvertionData(res.data));
        let spesificationFiles: TFiles[] =
          !!res.fileUrls?.length && !!res.files?.length
            ? res.files.map((item, index) => ({
                file: new File([item as unknown as BlobPart], item.fileName, { type: item.contentType }),
                fileUrl: res.fileUrls[index],
              }))
            : [];

        setSelectedLocation({ label: tempLocation?.text ?? "", value: tempLocation?.value ?? "" });
        setFieldValue("year", res.year.toString());
        setFieldValue("locationId", res.locationId);
        setFiles(spesificationFiles);
      });
    }
  }, []);

  const handleClose = () => setIsModalOpen(false);

  const handleChangeFiles = (value: File) => {
    let temp = [...files];

    temp.push({ file: value, fileUrl: undefined });
    setFiles(temp);
  };

  const deleteFiles = (index: number) => {
    const temp = [...files].filter((item, indexfiles) => index !== indexfiles);

    setFiles(temp);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();

    values.year && formData.append("year", values.year);
    values.locationId && formData.append("locationId", values.locationId);
    !!e.target.files && formData.append("file", e.target.files[0]);

    geophysicsGravityGridStore.uploadTemplateGravity(formData).then((res) => {
      setConvertionData(res.gravities);
      setIsModalOpen(false);
    });
  };

  if (geophyisicsMTGridStore.loadingStationOptions || geophysicsGravityGridStore.loadingDetail) return <RkdBackdrop />;

  console.log("files", files);

  return (
    <RkdCard title="Gravity Survey" subtitle={id ? "Edit" : "Create"}>
      <RkdForm
        initialValues={initialValues}
        actionAfterSubmit={() => navigate("/geophysic-gravity-survey")}
        submitAction={async () => onSubmit}
        validationSchema={validationSchema}
      >
        <Grid container spacing="6px">
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              fullWidth
              sx={{ marginBotom: 0 }}
              onChange={(e: any, val: any) => {
                setSelectedLocation(val);
                setFieldValue("locationId", val.value);
              }}
              disabled={!!id}
              disableClearable
              value={selectedLocation}
              getOptionLabel={(option) => option.label}
              options={locationOptions.map((item) => ({ label: item.text, value: item.value }))}
              renderInput={(params) => (
                <TextField
                  helperText={errors.locationId}
                  value={selectedLocation?.label}
                  name="locationId"
                  error={!!errors.locationId}
                  {...params}
                  label="Location"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Year"
                value={values.year}
                disabled={!!id}
                views={["year"]}
                inputFormat="yyyy"
                onChange={(e: any) => setFieldValue("year", format(new Date(e), "yyyy"))}
                renderInput={(props) => (
                  <TextField required fullWidth name="year" error={!!errors.year} helperText={errors.year && String(errors.year)} {...props} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Box sx={{ height: "100%" }}>
              <Button
                onClick={() => setIsModalOpen(true)}
                sx={{ height: "68%", whiteSpace: "nowrap" }}
                component="label"
                variant="outlined"
                disabled={!!id}
                startIcon={<OpenInBrowser />}
              >
                Template Modal
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <Box>
              <span style={{ fontWeight: "bold", marginRight: "6px" }}>Spesification Survey:</span>
              <Button component="label" variant="contained">
                Add File
                <input
                  type="file"
                  accept="application/pdf, image/*"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) handleChangeFiles(e.target.files[0]);
                  }}
                  name="postScreenshotFile"
                />
              </Button>
            </Box>
            <Grid container sx={{ mt: "12px", width: "100%" }}>
              {files.map((item, index) => (
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    border: "1px solid  primary.main",
                    borderRadius: "3px",
                    padding: "12px",
                  }}
                >
                  <AttachFile sx={{ flex: 0.1, mr: "2px", fontSize: "16px" }} />
                  <Box sx={{ flex: 1 }}>
                    {item.fileUrl ? (
                      <a rel="noreferrer" href={item.fileUrl}>
                        {item.file.name.length > 25 ? `${item.file.name.substring(0, 25)}...` : item.file.name}
                      </a>
                    ) : item.file.name.length > 25 ? (
                      `${item.file.name.substring(0, 25)}...`
                    ) : (
                      item.file.name
                    )}
                  </Box>
                  <Box
                    onClick={() => deleteFiles(index)}
                    sx={{ flex: 0.1, cursor: "pointer", justifyContent: "flex-end", display: "flex", alignItems: "center", width: "100%" }}
                  >
                    <Delete sx={{ color: "red", fontSize: "16px" }} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </RkdForm>
      <hr />
      <Box>
        <Box sx={{ textAlign: "center", fontWeight: "bold", fontSize: "18px", mb: "12px" }}>Data Conversion</Box>
        <GeophysicGridAfterUpload setConvertionData={setConvertionData} convertionData={convertionData} />
      </Box>
      <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
        <Box>
          <Button variant="contained" color="error" onClick={() => navigate("/geological")}>
            Cancel
          </Button>
        </Box>
        <Box>
          <LoadingButton onClick={() => handleSubmit()} type="submit" variant="contained" color="primary">
            Submit
          </LoadingButton>
        </Box>
      </Box>

      <BootstrapDialog fullWidth maxWidth="xs" onClose={handleClose} aria-labelledby="customized-dialog-title" open={isModalOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Template Modal
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth component="label" variant="outlined" startIcon={<UploadOutlined />}>
                Upload
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpload(e)}
                  name="postScreenshotFile"
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() =>
                  geophysicsGravityGridStore.downloadTemplateGravity(values.locationId ?? "", values.year ?? new Date().getFullYear().toString())
                }
                fullWidth
                disabled={!values.locationId}
                component="label"
                variant="outlined"
                startIcon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </RkdCard>
  );
}

export default observer(GeophysicGravityCreate);
