import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { capitalizeFirstLetter } from "../../../../app/config/enum";
import { useEffect } from "react";
import { generateStep2GridData } from "../utils/utils";
import { TStep2Grid } from "../utils/type";

export default function Step2Grid({ values, setFieldValue }: TStep2Grid) {
  const handleRowEdit = (row: any) => {
    return row;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "depthOrElevation",
      headerName: `${capitalizeFirstLetter(values.step2?.zAxis ?? "Z Axis")}`,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "selectionA",
      headerName: `${capitalizeFirstLetter(
        values.step2?.selectionA ? `${values.step2?.selectionA} (${values.step2?.compareField})` : "Selection A"
      )}`,
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "selectionB",
      align: "center",
      headerName: `${capitalizeFirstLetter(
        values.step2?.selectionB ? `${values.step2?.selectionB} (${values.step2?.compareField})` : "Selection B"
      )}`,
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "delta",
      flex: 1,
      headerName: "Delta",
      align: "center",
      headerAlign: "center",
      editable: true,
    },
  ];

  useEffect(() => {
    if (!values.step2?.zAxis || !values.step2?.compareField || !values.step2?.selectionA || !values.step2?.selectionB) return;

    const temp = generateStep2GridData(
      values.step2?.zAxis,
      values.step2?.compareField,
      values.step2?.selectionA,
      values.step2?.selectionB,
      values.step1?.correctionReferences,
      JSON.parse(JSON.stringify([...values.step1?.correctionMeasureds]))
    );

    setFieldValue("step2.details", temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.step2?.zAxis,
    values.step2?.selectionA,
    values.step2?.selectionB,
    values.step2?.compareField,
    values.step1?.correctionMeasureds,
    values.step1?.correctionReferences,
  ]);

  return (
    <StyledDataGridPremium
      disableRowSelectionOnClick
      getRowId={(row) => row.depthOrElevation + Math.random()}
      processRowUpdate={handleRowEdit}
      columns={gridColumns}
      rows={values.step2?.details ?? []}
      autoHeight
    />
  );
}
