import { observer } from "mobx-react-lite";
import RkdCard from "../../../app/component/card/RkdCard";
import WellGrid from "./ObjectGrid";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { MouseEvent, useState } from "react";

function ObjectList() {
  const { dialogStore, snackbarStore, commonStore, objectStore, objectGridStore } = useStore();
  const [alignment, setAlignment] = useState("all");
  const navigate = useNavigate();

  const handleChange = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    let temp = newAlignment ?? "all";
    setAlignment(temp);

    objectGridStore.getObjectList(temp);
  };

  const onDelete = () =>
    dialogStore.open({
      action: async () =>
        await objectStore.deleteObject(commonStore.selectedDataOnGrid).then(() => {
          objectGridStore.getObjectList();
          snackbarStore.show("success", `items deleted successfully`);
        }),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  return (
    <RkdCard title="Object" subtitle="Add Edit & Delete Object from this Page">
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
        <Box>
          <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="inActive">In Active</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: "12px" }}>
          <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => navigate("create")}>
            Add
          </Button>
          <Button
            disabled={commonStore.selectedDataOnGrid.ids.length === 0}
            startIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => onDelete()}
          >
            Bulk Delete
          </Button>
        </Box>
      </Box>
      <WellGrid />
    </RkdCard>
  );
}

export default observer(ObjectList);
