import { Box } from "@mui/system";
import { GridColDef, GridValidRowModel, GridValueFormatterParams } from "@mui/x-data-grid-premium";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useCallback, useEffect, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Button, Modal, TextField, Typography } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { ISteamDrycallPTFCorrections, ISteamMfcTable } from "../../app/models/steam";
import {
  biometricPressureInchFormula,
  devFormula,
  injectionRateSLPMAverageFormula,
  injectionRateSLPMFormula,
  stpFlowrateFormula,
} from "./helper/steam/formula";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TFTProcessSteamStep3() {
  const { tftSteamStore } = useStore();

  const [drycall, setDrycall] = useState(tftSteamStore.tft.steam.drycallPTFCorrections);

  const handleAddRow = () => {
    setDrycall([
      ...drycall,
      {
        sequence: drycall.length + 1,
        time: new Date(),
        mfcFlowRates: [],
        mfcFlowRateAverage: 0,
        temp: 0,
        biometricPressure: 0,
        biometricPressureInch: 0,
        dp: 0,
        stpFlowrate: 0,
        setpoint: 0,
        dev: 0,
        injectionRate: 0,
        injectionRateAverrage: 0,
        notes: "",
      },
    ]);
  };

  const deleteRow = (sequence: number) => {
    let data = drycall.filter((x) => x.sequence !== sequence);
    data.forEach((x, index) => (x.sequence = index + 1));
    setDrycall(data);
  };

  const handleRowEditDrycall = useCallback(
    (newValue: GridValidRowModel) => {
      let temp = { ...newValue };

      const data: ISteamDrycallPTFCorrections[] = tftSteamStore.tft.steam.drycallPTFCorrections.map((current) => {
        if (current.sequence !== newValue.sequence) return current;

        const newBiometricPressureInch = biometricPressureInchFormula(newValue.biometricPressure);
        const newStpFlowRate = stpFlowrateFormula(newValue.mfcFlowRateAverage, newValue.temp, newBiometricPressureInch, newValue.dp);
        const newDev = devFormula(newValue.mfcFlowRateAverage, newValue.setpoint, newStpFlowRate);
        const newInjectionRate = injectionRateSLPMFormula(newStpFlowRate);
        const newInjectionRateAverrage = injectionRateSLPMAverageFormula(tftSteamStore.tft.steam.drycallPTFCorrections, "injectionRate");

        temp.biometricPressureInch = newBiometricPressureInch;
        temp.stpFlowrate = newStpFlowRate;
        temp.dev = newDev;
        temp.injectionRate = newInjectionRate;
        temp.injectionRateAverrage = newInjectionRateAverrage;

        return {
          sequence: newValue.sequence,
          time: newValue.time,
          mfcFlowRates: newValue.mfcFlowRates,
          mfcFlowRateAverage: newValue.mfcFlowRateAverage,
          temp: newValue.temp,
          biometricPressure: newValue.biometricPressure,
          biometricPressureInch: newBiometricPressureInch,
          dp: newValue.dp,
          stpFlowrate: newStpFlowRate,
          setpoint: newValue.setpoint,
          dev: newDev,
          injectionRate: newInjectionRate,
          injectionRateAverrage: newInjectionRateAverrage,
          notes: newValue.notes,
        };
      });

      const tempData = data.map((data) => {
        return { ...data, injectionRateAverrage: injectionRateSLPMAverageFormula(drycall, "injectionRate") };
      });

      setDrycall(tempData);

      return temp;
    },
    [tftSteamStore, drycall]
  );

  const renderEditTimeInputCell: GridColDef["renderCell"] = (params) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label=""
          value={params.row.time}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
          onChange={(newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue,
            });
          }}
          renderInput={(props) => <TextField sx={{ my: 1, border: "none" }} {...props} />}
        />
      </LocalizationProvider>
    );
  };

  const RenderEditMfcFlowRate = (row: any) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<ISteamMfcTable[]>([]);
    const [mfcTemp, setMfc] = useState<number>(0);

    useEffect(() => {
      if (tftSteamStore.tft.steam.steamId) {
        tftSteamStore.getMfcFlowRate(tftSteamStore.tft.steam.steamId, row.sequence).then((data) => {
          setValue(data);
        });
      }
    }, [row.sequence]);

    const handleAddMfc = (val: number) => {
      setValue([...value, { id: `${value.length + 1}`, sequence: row.sequence, value: val }]);
      setMfc(0);
    };

    const handleDeleteRow = (id: string) => {
      let data = value.filter((x) => x.id !== id);
      data.forEach((x, index) => (x.id = (index + 1).toString()));
      setValue(data);
    };

    const handleSave = () => {
      let arr: number[] = [];
      value.forEach((x) => arr.push(x.value));
      row.mfcFlowRates = arr;
      row.mfcFlowRateAverage = row.mfcFlowRates.reduce((a: number, b: number) => a + b, 0) / row.mfcFlowRates.length;
      let data = drycall;
      data.map((x) => {
        if (x.sequence === row.sequence) {
          x.mfcFlowRateAverage = row.mfcFlowRateAverage;
        }
        return x;
      });
      setDrycall(data);
      tftSteamStore.setDrycall(drycall);
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const columnMfcFlowRate: GridColDef[] = [
      {
        field: "value",
        headerName: "Value",
        width: 150,
        type: "number",
        editable: true,
        align: "center",
        headerAlign: "center",
        headerClassName: "tab-header",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Action",
        type: "actions",
        getActions: (params) => {
          return [<GridActionsCellItem color="error" icon={<DeleteIcon />} label="Delete" onClick={() => handleDeleteRow(params.row.id)} />];
        },
      },
    ];

    return (
      <>
        <Typography sx={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} variant="body1" onClick={handleOpen}>
          {row.mfcFlowRateAverage}
        </Typography>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              MFC Flowrate
            </Typography>
            <Box sx={{ mt: 3, pr: 2, width: "100%", height: 300, overflow: "auto" }}>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <TextField
                  id="mfcFlowrate"
                  label="MFC Flowrate"
                  type="number"
                  sx={{ my: 1, mr: 2 }}
                  value={mfcTemp}
                  onChange={(e) => setMfc(Number(e.target.value))}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }}
                />
                <Button variant="contained" color="primary" sx={{ py: 2 }} onClick={() => handleAddMfc(mfcTemp)}>
                  Add
                </Button>
              </Box>
              <StyledDataGridPremium
                columns={columnMfcFlowRate}
                rows={value}
                getRowId={(row) => row.id}
                autoHeight
                showCellVerticalBorder
                showColumnVerticalBorder
                pagination
              />
            </Box>
            <Typography id="modal-modal-title" variant="body1" component="h2" sx={{ fontWeight: "bold" }}>
              Average : {value.length > 0 ? value.reduce((acc, val) => acc + val.value, 0) / value.length : 0}
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: 1, justifyContent: "flex-end", flexDirection: "row" }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSave();
                  handleClose();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  console.log("drycall", drycall);

  const columnDrycallPTFCorrections: GridColDef[] = [
    {
      field: "time",
      headerName: "Time",
      width: 150,
      type: "string",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      valueFormatter: (params: GridValueFormatterParams) => format(new Date(params.value), "HH:mm"),
      renderEditCell: renderEditTimeInputCell,
    },
    {
      field: "mfcFlowRateAverage",
      headerName: "MFC Flowrate (CC/Min)",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return RenderEditMfcFlowRate(params.row);
      },
    },
    {
      field: "temp",
      headerName: "Temp. (Deg C.)",
      width: 200,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "biometricPressure",
      headerName: "Biometric Pressure (Psia)",
      width: 200,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "biometricPressureInch",
      headerName: "Biometric Pressure Inch Hg",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "dp",
      headerName: "DP (in.H2O)",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: true,
      headerClassName: "tab-header",
    },
    {
      field: "stpFlowrate",
      headerName: "STP Flowrate (CC/Min)",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "setpoint",
      headerName: "Setpoint (SCCM)",
      width: 200,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dev",
      headerName: "% Dev",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "injectionRate",
      headerName: "Injection Rate SLPM",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "injectionRateAverrage",
      headerName: "Injection Rate Average",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "disabled-cell",
      headerClassName: "tab-header",
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      type: "string",
      align: "center",
      headerAlign: "center",
      editable: true,
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [<GridActionsCellItem color="error" icon={<DeleteIcon />} label="Delete" onClick={() => deleteRow(params.row.sequence)} />];
      },
    },
  ];

  useEffect(() => {
    tftSteamStore.setDrycall(drycall);
  }, [drycall, tftSteamStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <Button variant="contained" color="primary" onClick={handleAddRow} sx={{ width: "10%" }}>
        Add
      </Button>
      <StyledDataGridPremium
        columns={columnDrycallPTFCorrections}
        rows={drycall}
        getRowId={(row) => row.sequence}
        autoHeight
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEditDrycall}
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
      />
    </Box>
  );
}

export default observer(TFTProcessSteamStep3);
