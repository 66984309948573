import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { ICompletionHole } from '../../../app/models/wellGeometry';
import { useStore } from '../../../app/stores/store';
import { toJS } from 'mobx';
import { DataGrid, GridCellEditCommitParams } from '@mui/x-data-grid';
import { useState } from 'react';

interface IProps {
  data: Array<ICompletionHole>,
}

function WellGeometryCompletionGridHole(props: IProps) {
  const { data } = props;
  const dataRow = toJS(data);
  const { wellGeometryStore } = useStore();
  const { setCompletionHole } = wellGeometryStore;
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const gridColumns = [     
    { field: 'fromMD', headerName: 'From MD (meter)', width: 120, type: "number", editable: true, sortable: false },
    { field: 'toMD', headerName: 'To MD (meter)', width: 120, type: "number", editable: true, sortable: false },
    { field: 'holeDiameter', headerName: 'Hole Diameter', flex: 1, type: "number", editable: true, sortable: false },
    { field: 'objectCompletionId', hide: true },
  ]

  const handleEditGrid = (params: GridCellEditCommitParams) => {
    const edited = dataRow.map((item: any) => {
      if(item.id === params.id)
      {
        item[params.field] = params.value;
      }
      return item
    })
    setCompletionHole(edited)
  }

  return (
    <Box sx={{ p:1, height: 600 }}>
      <DataGrid 
        rows={dataRow} 
        columns={gridColumns}
        onCellEditCommit={handleEditGrid}  
        getRowHeight={() => 'auto'}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[25, 100, 150]}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      />
    </Box>
  );
}

export default observer(WellGeometryCompletionGridHole);