import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import ConnectionList from './ConnectionList';

function ConnectionPage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Connection');
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <ConnectionList />
      </Grid>
    </Grid>
  );
}

export default observer(ConnectionPage);