import { action, makeObservable, runInAction } from "mobx";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { IObject } from "../models/object";

export default class ObjectGridStore extends DataGridStore {
  constructor() {
    super();

    makeObservable(this, {
      getObjectList: action,
    });
  }

  updateGridCallback = action(() => {
    this.getObjectList();
  });

  getObjectList = async (filter?: string) => {
    this.loadingGrid = true;
    try {
      const urlParams = this.createDataGridParam();
      const result = await agent.Object.listGrid(urlParams);

      result.data.forEach((item) => {
        item.drillingCompletedDate = new Date(item.drillingCompletedDate + "Z");
        item.drillingSpudDate = new Date(item.drillingCompletedDate + "Z");
      });

      let temp: IObject[] = [];

      if (filter === "all") temp = [...result.data];
      if (filter === "active") temp = [...result.data].filter((item) => !item.isDeleted);
      if (filter === "inActive") temp = [...result.data].filter((item) => item.isDeleted);

      this.setDataGridResult({ rowCount: result.rowCount, data: filter ? temp : result.data });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGrid = false));
    }
  };

  setObjectGridData = (filter: string) => {
    let temp = { ...this.dataGridResult.data };

    if (filter === "all") this.setDataGridResult({ rowCount: 0, data: temp });
    if (filter === "active") this.setDataGridResult({ rowCount: 0, data: temp.filter((item) => !item.isDeleted) });
    if (filter === "inActive") this.setDataGridResult({ rowCount: 0, data: temp.filter((item) => item.isDeleted) });
  };
}
