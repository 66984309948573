import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { IBrineLtAnalysis } from "../../app/models/brine";
import { IRegressionRequest } from "../../app/models/steam";
import { useStore } from "../../app/stores/store";
import { diffFormula, predictedPpmFormula } from "./helper/brine/formula";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem, GridValidRowModel } from "@mui/x-data-grid";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";

function TFTProcessBrineStep1() {
  const { tftSteamStore } = useStore();
  const [ltAnalysis, setLtAnalysis] = useState(tftSteamStore.tft.brine.ltAnalyses);
  const prevRegression = useRef(tftSteamStore.tft.brine.regressions);
  const [regressionReq, setRegressionReq] = useState<IRegressionRequest>({
    xValues: [],
    yValues: [],
  });
  const [showRegression, setShowRegression] = useState(false);
  const [regressionActive, setRegressionActive] = useState("");

  const handleAddRow = () => {
    setLtAnalysis([
      ...ltAnalysis,
      {
        sequence: tftSteamStore.tft.brine.ltAnalyses.length + 1,
        areaCounts: 0,
        ppb: 0,
        predictedPpm: 0,
        diff: 0,
      },
    ]);
  };

  const deleteRow = (sequence: number) => {
    let data = ltAnalysis.filter((x) => x.sequence !== sequence);
    data.forEach((x, index) => (x.sequence = index + 1));
    setLtAnalysis(data);
  };

  const handleRowEditLtAnalysis = (newValue: GridValidRowModel) => {
    const data: IBrineLtAnalysis[] = tftSteamStore.tft.brine.ltAnalyses.map((current) => {
      if (current.sequence !== newValue.sequence) return current;

      const newPredictedPpm = predictedPpmFormula(tftSteamStore.tft.brine.regressions, newValue.areaCounts);
      const newDiff = diffFormula(newPredictedPpm, newValue.ppb);

      return {
        sequence: newValue.sequence,
        areaCounts: newValue.areaCounts,
        ppb: newValue.ppb,
        predictedPpm: newPredictedPpm,
        diff: newDiff,
      };
    });
    setLtAnalysis(data);

    return newValue;
  };

  const columnLtAnalysis: GridColDef[] = [
    {
      field: "areaCounts",
      headerName: "Area Counts",
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "ppb",
      headerName: "ppb",
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "predictedPpm",
      headerName: "Predicted Ppm",
      type: "number",
      cellClassName: "disabled-cell",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "diff",
      headerName: "% Diff",
      type: "number",
      cellClassName: "disabled-cell",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem color="error" icon={<DeleteIcon />} label="Delete" onClick={() => deleteRow(params.row.sequence)} />,
      ],
    },
  ];

  const columnRegressions: GridColDef[] = [
    {
      field: "coefficientName",
      headerName: "Name",
      width: 200,
      type: "string",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      flex: 1,
    },
    {
      field: "coefficients",
      headerName: "Coefficients",
      width: 250,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "tab-header",
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body1">{params.row.coefficients}</Typography>;
      },
    },
  ];

  useEffect(() => {
    let x: number[] = [];
    let y: number[] = [];
    let reg: IRegressionRequest = { xValues: [], yValues: [] };

    tftSteamStore.tft.brine.ltAnalyses.map((data) => {
      x.push(data.areaCounts);
      y.push(data.ppb);
      return data;
    });

    reg.xValues = x;
    reg.yValues = y;
    setRegressionReq(reg);
  }, [tftSteamStore.tft.brine.ltAnalyses, ltAnalysis]);

  useEffect(() => {
    if (tftSteamStore.tft.brine.regressions !== prevRegression.current) {
      const tempData = ltAnalysis.map((data) => {
        return {
          ...data,
          predictedPpm: predictedPpmFormula(tftSteamStore.tft.brine.regressions, data.areaCounts),
          diff: diffFormula(predictedPpmFormula(tftSteamStore.tft.brine.regressions, data.areaCounts), data.ppb),
        };
      });
      setLtAnalysis(tempData);
      prevRegression.current = tftSteamStore.tft.brine.regressions;
    }
  }, [ltAnalysis, tftSteamStore.tft.brine.regressions]);

  useEffect(() => {
    tftSteamStore.setLtAnalysis(ltAnalysis);
  }, [ltAnalysis, tftSteamStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleAddRow} sx={{ width: "10%" }}>
          Add
        </Button>
      </Box>
      <StyledDataGridPremium
        columns={columnLtAnalysis}
        rows={ltAnalysis ?? []}
        getRowId={(row) => row.sequence}
        processRowUpdate={handleRowEditLtAnalysis}
        pagination
        showColumnVerticalBorder
        showCellVerticalBorder
      />

      <Box sx={{ display: "flex", gap: 1, flexDirection: "row", width: "100%", mt: 1, justifyContent: "center" }}>
        <Button
          variant={regressionActive === "linear" ? "contained" : "outlined"}
          disabled={!ltAnalysis.length}
          color="primary"
          onClick={() => {
            tftSteamStore.getBrineRegressions("linear", regressionReq);
            setShowRegression(true);
            setRegressionActive("linear");
          }}
        >
          Linear
        </Button>
        <Button
          variant={regressionActive === "quadratic" ? "contained" : "outlined"}
          disabled={!ltAnalysis.length}
          color="primary"
          onClick={() => {
            tftSteamStore.getBrineRegressions("quadratic", regressionReq);
            setShowRegression(true);
            setRegressionActive("quadratic");
          }}
        >
          Quadratic
        </Button>
        <Button
          variant={regressionActive === "cubic" ? "contained" : "outlined"}
          disabled={!ltAnalysis.length}
          color="primary"
          onClick={() => {
            tftSteamStore.getBrineRegressions("cubic", regressionReq);
            setShowRegression(true);
            setRegressionActive("cubic");
          }}
        >
          Cubic
        </Button>
      </Box>

      {showRegression && (
        <StyledDataGridPremium
          columns={columnRegressions}
          rows={tftSteamStore.tft.brine.regressions}
          autoHeight
          getRowId={(row) => row.coefficientName}
          showCellVerticalBorder
          pagination
          showColumnVerticalBorder
        />
      )}
    </Box>
  );
}

export default observer(TFTProcessBrineStep1);
