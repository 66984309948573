import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ConnectionForm from "./ConnectionForm";
import {
  IConnection,
  IConnectionPayload,
} from "../../../app/models/connection";
import { ConnectionSchemaZod } from "./schema/connectionSchemaZod";

const initialValues = {
  connectionName: "",
  groups: [],
};

function ConnectionFormPage() {
  const { connectionStore, commonStore } = useStore();
  const { getConnectionDetail } = connectionStore;
  const navigate = useNavigate();
  const params = useParams();
  const [id] = useState(params.id);
  const [data, setData] = useState<IConnection>(initialValues);

  useEffect(() => {
    if (id)
      getConnectionDetail(id).then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, [id, getConnectionDetail]);

  const onSubmit = async (values: ConnectionSchemaZod) => {
    const data: IConnectionPayload = {
      id: id ? id : "",
      connectionName: values.connectionName,
      groupIds: values.groups.map((item) => item.id),
    };
    if (id) {
      await connectionStore.editConnection(id, data).then(() => {
        navigate("/master/connection");
      });
    } else {
      await connectionStore.createConnection(data).then(() => {
        navigate("/master/connection");
      });
    }
  };

  useEffect(() => {
    commonStore.setTitlePage("Connection");
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <ConnectionForm onSubmit={onSubmit} initialValues={data} id={id} />
      </Grid>
    </Grid>
  );
}

export default observer(ConnectionFormPage);
