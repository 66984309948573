import { observer } from 'mobx-react-lite';
import Chart from 'react-apexcharts'
import { ICompletion } from '../../../app/models/wellGeometry';

interface IProps {
  data: Array<any>;
  lines: Array<keyof ICompletion>;
}


function WellGeometryCompletionChart(props: IProps) {
  const { data, lines } = props;
  const options: ApexCharts.ApexOptions = {
    stroke: {
      curve: 'stepline',
      dashArray: [5, 0, 0],
      width: [2, 3, 4]
    },
    xaxis: {
      position: 'top',
      type: 'numeric',
      title: {
        text: 'meter'
      },
      min: 0,
    }, 
    
    yaxis: {
      title: {
        text: 'form md'
      },
    }
  }

  const series: ApexAxisChartSeries = [
    ...lines.map((line, idx) => {
      return {
        name: line,
        data: [{ x: data[idx].length > 1 ? data[idx][0][line] : 0 , y: 0 }].concat(data[idx].map((d: ICompletion) => { return { x: d[line], y: d.fromMD * -1 } })),
      }
    })
  ]

  return <Chart options={options} series={series} type="line" height={780} width={'50%'} />;
};

export default observer(WellGeometryCompletionChart);