import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment, useState } from "react";
import { Menu } from "../../config/menu";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import theme from "../../config/theme";

function MenuList({ menu }: { menu: Menu[] }) {
  const { account } = useStore().accountStore;

  const displayedMenu = menu.filter((menu) => {
    const allowedPermissions = menu.permissions?.filter((permission) => {
      if (!permission) return permission;
      else {
        return account?.roles.includes(permission.role) && permission.permission !== "hidden";
      }
    }) ?? [""];

    return allowedPermissions.length > 0;
  });

  return (
    <List sx={{}}>
      {displayedMenu.map((item, itemX) => (
        <MenuItem menuItem={item} key={itemX} />
      ))}
    </List>
  );
}

export default observer(MenuList);

function MenuItem({ menuItem }: { menuItem: Menu }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { account } = useStore().accountStore;

  const isSelected = () => {
    if (menuItem.submenu) {
      const resolver = (submenu: Menu[]) => {
        return submenu.find((i) => location.pathname.includes(i.route!));
      };

      const result = menuItem.submenu?.map((i) => {
        if (!i.submenu) return false;
        return !!resolver(i.submenu);
      });

      return result?.includes(true);
    } else {
      return location.pathname === menuItem.route!;
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItemButton
        onClick={handleClick}
        selected={isSelected()}
        sx={{
          ":hover": "primary.main",
          ".MuiTypography-root": {
            color: "#637280 !important",
          },
          ".MuiSvgIcon-root": {
            color: "#637280 !important",
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            ".MuiTypography-root": {
              color: `${theme.palette.primary.main} !important`,
              fontWeight: `${!!menuItem.submenu ? 700 : 500} !important`,
            },
            ".MuiSvgIcon-root": {
              color: ({ palette }) => `${palette.primary.main} !important`,
            },
          },
        }}
        component={menuItem.route ? Link : "div"}
        to={menuItem.route}
      >
        <ListItemIcon sx={{ minWidth: "30px !important", marginRight: "24px" }}>{menuItem.icon}</ListItemIcon>
        <ListItemText primary={menuItem.title} />
        {!!menuItem.submenu && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {menuItem.submenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuList menu={menuItem.submenu} />
        </Collapse>
      )}
    </Fragment>
  );
}
