import { Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "./app/stores/store";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./app/config/theme";

import DashboardLayout from "./app/component/layout/DashboardLayout";
import ExamplePage from "./features/example/ExamplePage";
import ExampleForm from "./features/example/ExampleForm";
import UserPage from "./features/user/UserPage";
import Tester from "./features/tester/Tester";
import ServerError from "./features/errors/ServerError";
import NotFound from "./features/errors/NotFound";
import LoginPage from "./features/account/LoginPage";
import RkdModal from "./app/component/helper/RkdModal";
import RkdSnackbar from "./app/component/helper/RkdSnackbar";
import RkdDialog from "./app/component/helper/RkdDialog";
import RkdBackdrop from "./app/component/helper/RkdBackdrop";
import RouteAuth from "./app/component/helper/RouteAuth";
import AccessDenied from "./features/errors/AccessDenied";
import { Roles } from "./app/config/enum";
import WellGeometryPage from "./features/master/geometry/WellGeometryPage";
import WellGeometryDirectionalForm from "./features/master/geometry/WellGeometryDirectionalForm";
import WellGeometryCompletionForm from "./features/master/geometry/WellGeometryCompletionForm";
import { LicenseInfo } from "@mui/x-license-pro";
import ObjectTypePage from "./features/master/objectType/ObjectTypePage";
import ObjectTypeFormPage from "./features/master/objectType/ObjectTypeFormPage";
import ObjectPage from "./features/master/object/ObjectPage";
import ObjectFormPage from "./features/master/object/ObjectFormPage";
import TFTPageIndex from "./features/TracerFlowTest/TFTPageIndex";
import TFTPageComplesionIndex from "./features/TracerFlowTest/TFTPageComplesionIndex";
import TFTPageProcess from "./features/TracerFlowTest/TFTPageProcess";
import TFTPageReport from "./features/TracerFlowTest/TFTPageReport";
import ConnectionPage from "./features/master/connection/ConnectionPage";
import ConnectionFormPage from "./features/master/connection/ConnectionFormPage";
import VesselGroupPage from "./features/master/vesselGroup/VesselGroupPage";
import Home from "./features/home/Home";
import VesselGroupFormPage from "./features/master/vesselGroup/VesselGroupFormPage";
import PtsPageReport from "./features/pts/PtsPageReport";
import ObjectStatusIndex from "./features/master/objectStatus/ObjectStatusIndex";
import ObjectStatusForm from "./features/master/objectStatus/ObjectStatusForm";
import LocationIndex from "./features/master/location/LocationIndex";
import LocationForm from "./features/master/location/LocationForm";
import BrinePumpIndex from "./features/master/brinePump/BrinePumpIndex";
import BrinePumpForm from "./features/master/brinePump/BrinePumpForm";
import PTDecimationIndex from "./features/pressureandtemp/decimation/PTDecimationIndex";
import PTDecimationForm from "./features/pressureandtemp/decimation/PTDecimationForm";
import PTModuleIndex from "./features/pressureandtemp/module/PTModuleIndex";
import PTDecimationViewForm from "./features/pressureandtemp/decimation/view/PTDecimationViewForm";
import FPTIndex from "./features/fpt/FPTIndex";
import PtsPageView from "./features/pts/PtsPageView";
import FPTForm from "./features/fpt/FPTForm";
// import GeologicalLithology from "./features/geological/GeologicalLithology";
// import GeologicalDrillParam from "./features/geological/GeologicalDrillParam";
// import GeologicalFeedzone from "./features/geological/GeologicalFeedzone";
// import GeologicalAlteration from "./features/geological/GeologicalAlteration";
// import GeologicalLoss from "./features/geological/GeologicalLoss";
// import GeologicalPcs from "./features/geological/GeologicalPcs";
// import GeologicalTemp from "./features/geological/GeologicalTemp";
// import GeologicalCompile from "./features/geological/GeologicalCompile";
import GeologicalMebIndex from "./features/geological/meb/GeologicalMebIndex";
import GeologicalMebForm from "./features/geological/meb/GeologicalMebForm";
import PTSPageIndex from "./features/pts/new/PTSPageIndex";
import PTSPageCreate from "./features/pts/new/PTSPageCreate";
import GeologicalPageIndex from "./features/geological/new/GeologicalPageIndex";
import GeologicalPageCreate from "./features/geological/new/GeologicalPageCreate";
import GeologicalReport from "./features/geological/new/GeologicalReport";
import GeophysicsMTPageIndex from "./features/geophysics/mtSurvey/GeophysicsMTPageIndex";
import GeophysicsGravityPageIndex from "./features/geophysics/gravitySurvey/GeophysicsGravityPageIndex";
import GeophysicMTCreate from "./features/geophysics/mtSurvey/GeophysicMTCreate";
import GeophysicGravityCreate from "./features/geophysics/gravitySurvey/GeophysicGravityCreate";
import QuarterlyReport from "./features/geochemistry/quarterlyReport/QuarterlyReport";
import Ssi from "./features/geochemistry/SSI/Ssi";
import BiweeklyDataPageIndex from "./features/geochemistry/biweeklyData/BiweeklyDataPageIndex";
import BiweeklyDataCreate from "./features/geochemistry/biweeklyData/BiweeklyDataPageCreate";
import CleanOutHistoryPageIndex from "./features/geochemistry/cleanOutReport/CleanOutHistoryPageIndex";
import OrcDataPageIndex from "./features/master/orc/OrcDataPageIndex";
import OrcPageCreate from "./features/master/orc/OrcPageCreate";
import CleanOutHistoryDetailDataGrid from "./features/geochemistry/cleanOutReport/CleanOutHistoryDetailDataGrid";
import CleanOutHistoryPageCreate from "./features/geochemistry/cleanOutReport/CleanOutHistoryPageCreate";
import BiweeklyDataReport from "./features/geochemistry/biweeklyData/BiweeklyDataReport";
import OrcGroupDataPageIndex from "./features/master/orc/group/OrcGroupDataPageIndex";
import OrcGroupPageCreate from "./features/master/orc/group/OrcGroupPageCreate";
import BrineORCMonitoringPageIndex from "./features/geochemistry/brinceORCMonitoring/BrineORCMonitoringPageIndex";
import BrineORCPageCreate from "./features/geochemistry/brinceORCMonitoring/BrineORCPageCreate";
import BrineEcolabPageCreate from "./features/geochemistry/brinceORCMonitoring/BrineEcolabPageCreate";
import BrineORCReport from "./features/geochemistry/brinceORCMonitoring/BrineORCReport";
import QuarterlyPageIndex from "./features/geochemistry/quarterlyReport/QuarterlyPageIndex";
import QuarterlyPageCreate from "./features/geochemistry/quarterlyReport/QuarterlyPageCreate";
import DailysheetPageIndex from "./features/dailyLog/dailySheet/DailysheetPageIndex";
import QueryPageIndex from "./features/dailyLog/query/QueryPageIndex";
import GeophysicGravityReport from "./features/geophysics/gravitySurvey/GeophysicGravityReport";
import PTModuleForm from "./features/pressureandtemp/module/PTModuleForm";
import PTReportIndex from "./features/pressureandtemp/report/PTReportIndex";
import PTReportForm from "./features/pressureandtemp/report/PTReportForm";

LicenseInfo.setLicenseKey("f623a5b79a4ee335443b31094a23f33dTz05NzM0MyxFPTE3NTY4ODEyODkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=");

const App = () => {
  const location = useLocation();
  const { commonStore, accountStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore]);

  if (!commonStore.appLoaded) return <RkdBackdrop />;

  return (
    <ThemeProvider theme={theme}>
      <RkdModal />
      <RkdDialog />
      <RkdSnackbar />
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route element={<DashboardLayout />}>
          <Route index={true} element={<Home />} />
          {/* Migrate from Daily Log */}
          {/* Daily Sheet */}
          <Route path="/daily-sheet" element={<DailysheetPageIndex />} />

          {/* Query */}
          <Route path="/query" element={<QueryPageIndex />} />
          {/* Migrate from Daily Log */}

          {/* ORC */}
          <Route path="/master/orc" element={<OrcDataPageIndex />} />
          <Route path="/master/orc/add" element={<OrcPageCreate />} />
          <Route path="/master/orc/:id" element={<OrcPageCreate />} />

          {/* ORC Group */}
          <Route path="/master/orc-group" element={<OrcGroupDataPageIndex />} />
          <Route path="/master/orc-group/add" element={<OrcGroupPageCreate />} />
          <Route path="/master/orc-group/:id" element={<OrcGroupPageCreate />} />

          {/* Connection */}
          <Route path="/master/connection" element={<ConnectionPage />} />
          <Route path="/master/connection/create" element={<ConnectionFormPage key={location.key} />} />
          <Route path="/master/connection/edit/:id" element={<ConnectionFormPage key={location.key} />} />

          {/* Vessel Group */}
          <Route path="/master/vessel-group" element={<VesselGroupPage />} />
          <Route path="/master/vessel-group/create" element={<VesselGroupFormPage key={location.key} />} />
          <Route path="/master/vessel-group/edit/:id" element={<VesselGroupFormPage key={location.key} />} />

          {/* Object Types */}
          <Route path="/master/object-types" element={<ObjectTypePage />} />
          <Route path="/master/object-types/create" element={<ObjectTypeFormPage />} />
          <Route path="/master/object-types/edit/:id" element={<ObjectTypeFormPage />} />

          {/* Object */}
          <Route path="/master/object" element={<ObjectPage />} />
          <Route path="/master/object/create" element={<ObjectFormPage />} />
          <Route path="/master/object/edit/:id" element={<ObjectFormPage />} />

          {/* Object Status */}
          <Route path="/master/object-status" element={<ObjectStatusIndex />} />
          <Route path="/master/object-status/create" element={<ObjectStatusForm />} />
          <Route path="/master/object-status/edit/:id" element={<ObjectStatusForm />} />

          {/* Brine Pump */}
          <Route path="/master/brine-pump" element={<BrinePumpIndex />} />
          <Route path="/master/brine-pump/create" element={<BrinePumpForm />} />
          <Route path="/master/brine-pump/edit/:id" element={<BrinePumpForm />} />

          {/* Location */}
          <Route path="/master/location" element={<LocationIndex />} />
          <Route path="/master/location/create" element={<LocationForm />} />
          <Route path="/master/location/edit/:id" element={<LocationForm />} />

          {/* Geochemistry */}

          {/* Biweekly */}
          <Route path="/geochemistry-biweekly" element={<BiweeklyDataPageIndex />} />
          <Route path="/geochemistry-biweekly-report" element={<BiweeklyDataReport />} />
          <Route path="/geochemistry-biweekly/add" element={<BiweeklyDataCreate />} />
          <Route path="/geochemistry-biweekly/:dateDetail" element={<BiweeklyDataCreate />} />
          <Route path="/geochemistry-ssi" element={<Ssi />} />
          {/* Clean Out History */}
          <Route path="/geochemistry-clean" element={<CleanOutHistoryPageIndex />} />
          <Route path="/geochemistry-clean/add" element={<CleanOutHistoryPageCreate />} />
          <Route path="/geochemistry-clean/edit/:id" element={<CleanOutHistoryPageCreate />} />
          <Route path="/geochemistry-clean/view-detail/:startDate/:endDate" element={<CleanOutHistoryDetailDataGrid />} />
          {/* Brine ORC Monitoring */}
          <Route path="/geochemistry-brine" element={<BrineORCMonitoringPageIndex />} />
          <Route path="/geochemistry-brine/add" element={<BrineORCPageCreate />} />
          <Route path="/geochemistry-brine/edit/:date" element={<BrineORCPageCreate />} />
          <Route path="/geochemistry-brine/ecolab/add-edit/:date" element={<BrineEcolabPageCreate />} />
          <Route path="/geochemistry-brine-report" element={<BrineORCReport />} />
          {/* Quarterly */}
          <Route path="/geochemistry-quarterly" element={<QuarterlyPageIndex />} />
          <Route path="/geochemistry-quarterly/add" element={<QuarterlyPageCreate />} />
          <Route path="/geochemistry-quarterly/edit/:wellId" element={<QuarterlyPageCreate />} />
          <Route path="/geochemistry-quarterly-report" element={<QuarterlyReport />} />

          {/* Geochemistry */}

          {/* Geometry */}
          <Route path="/master/geometry" element={<WellGeometryPage />} />
          <Route path="/master/geometry/directional" element={<WellGeometryDirectionalForm />} />
          <Route path="/master/geometry/directional/edit/:id" element={<WellGeometryDirectionalForm />} />
          <Route path="/master/geometry/completion" element={<WellGeometryCompletionForm />} />
          <Route path="/master/geometry/completion/edit/:id" element={<WellGeometryCompletionForm />} />

          {/* TFT */}
          <Route path="/tracer-flow-test" element={<TFTPageIndex />} />
          <Route path="/tracer-flow-test/process" element={<TFTPageProcess />} />
          <Route path="/tracer-flow-test/edit/:date/:id" element={<TFTPageProcess />} key={location.key} />
          <Route path="/tracer-flow-test/report/:id" element={<TFTPageReport />} key={location.key} />
          <Route path="/tracer-flow-test-complesion" element={<TFTPageComplesionIndex />} />
          <Route path="/tracer-flow-test-complesion/report" element={<TFTPageReport />} key={location.key} />

          {/* Geophysics */}
          <Route path="/geophysic-mt-survey" element={<GeophysicsMTPageIndex />} />
          <Route path="/geophysic-mt-survey/add" element={<GeophysicMTCreate />} />
          <Route path="/geophysic-mt-survey/edit/:id" element={<GeophysicMTCreate />} />
          <Route path="/geophysic-gravity-survey/add" element={<GeophysicGravityCreate />} />
          <Route path="/geophysic-gravity-survey/edit/:id" element={<GeophysicGravityCreate />} />
          <Route path="/geophysic-gravity-survey/report/:id" element={<GeophysicGravityReport />} />
          <Route path="/geophysic-gravity-survey" element={<GeophysicsGravityPageIndex />} />

          {/* Geological */}
          <Route path="/geological" element={<GeologicalPageIndex />} />
          <Route path="/geological/add" element={<GeologicalPageCreate />} />
          <Route path="/geological/edit/:id" element={<GeologicalPageCreate />} />
          <Route path="/geological-report" element={<GeologicalReport />} />

          {/* <Route path="/geological" element={<GeologicalIndex />} /> */}
          <Route path="/geological-meb" element={<GeologicalMebIndex />} />
          {/* <Route path="/geological/lithology" element={<GeologicalLithology />} />
          <Route path="/geological/lithology/edit/:id" element={<GeologicalLithology />} key={location.key} />
          <Route path="/geological/drillparameter" element={<GeologicalDrillParam />} />
          <Route path="/geological/drillparameter/edit/:id" element={<GeologicalDrillParam />} key={location.key} />
          <Route path="/geological/feedzone" element={<GeologicalFeedzone />} />
          <Route path="/geological/feedzone/edit/:id" element={<GeologicalFeedzone />} key={location.key} />
          <Route path="/geological/alteration" element={<GeologicalAlteration />} />
          <Route path="/geological/alteration/edit/:id" element={<GeologicalAlteration />} key={location.key} />
          <Route path="/geological/loss" element={<GeologicalLoss />} />
          <Route path="/geological/loss/edit/:id" element={<GeologicalLoss />} key={location.key} />
          <Route path="/geological/pcs" element={<GeologicalPcs />} />
          <Route path="/geological/pcs/edit/:id" element={<GeologicalPcs />} key={location.key} />
          <Route path="/geological/temperature" element={<GeologicalTemp />} />
          <Route path="/geological/temperature/edit/:id" element={<GeologicalTemp />} key={location.key} />
          <Route path="/geological-compile" element={<GeologicalCompile />} /> */}
          <Route path="/geological-meb" element={<GeologicalMebIndex />} />
          <Route path="/geological-meb/meb" element={<GeologicalMebForm />} />
          <Route path="/geological-meb/meb/edit/:id" element={<GeologicalMebForm />} />

          {/* PTS */}
          <Route path="/pts" element={<PTSPageIndex />} />
          <Route path="/pts/add/" element={<PTSPageCreate />} />
          <Route path="/pts/edit/:id" element={<PTSPageCreate />} />

          {/* <Route path="/pts" element={<PTsPageIndex />} /> */}
          {/* <Route path="/pts/edit/:objectId/:date" element={<PtsPageCreate key={location.key} />} />
          <Route path="/pts/create" element={<PtsPageCreate />} /> */}
          <Route path="/pts-report" element={<PtsPageReport />} />
          <Route path="/pts/report/:objectId/:date" element={<PtsPageView />} />

          {/* Pressure & Temperature */}
          <Route path="/pt" element={<PTDecimationIndex />} />
          <Route path="/pt/create" element={<PTDecimationForm />} />
          <Route path="/pt/edit/:wellId/:surveyDate" element={<PTDecimationForm key={location.key} />} />
          <Route path="/pt/report/:wellId/:surveyDate" element={<PTDecimationViewForm key={location.key} />} />
          <Route path="/pt/pt-module/:id" element={<PTModuleForm />} />
          <Route path="/pt-report" element={<PTReportIndex />} />
          <Route path="/pt-report/view/:id" element={<PTReportForm />} />

          {/* Flow Performance Test */}
          <Route path="/fpt" element={<FPTIndex />} />
          <Route path="/fpt/create" element={<FPTForm />} />
          <Route path="/fpt/edit/:wellId/:surveyDate" element={<FPTForm key={location.key} />} />
          <Route path="/fpt/report/:wellId/:surveyDate" element={<FPTForm key={location.key} />} />

          <Route path="/example" element={<ExamplePage />} />
          <Route path="/example/edit/:id" element={<ExampleForm key={location.key} />} />
          <Route path="/example/create" element={<ExampleForm key={location.key} />} />
          <Route element={<RouteAuth roles={[Roles.Superadmin]} />}>
            <Route path="/users/*" element={<UserPage />} />
            <Route path="/tester" element={<Tester />} />
          </Route>
          {/* Error */}
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/server-error" element={<ServerError />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default observer(App);
