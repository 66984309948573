import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { IGeoChart } from "../../../app/models/geological";
interface IProps {
  series: IGeoChart
//   yAxis: Array<IPtsChartYAxis>
}

function GeologicalMebChart(props: IProps){
    const { series } = props;
    const options: ApexCharts.ApexOptions  = {
        xaxis: {
          type: 'numeric',
          // tickAmount: 8,
          position: "top",
          labels: {
            show: true,
            rotateAlways: false,
          },
          title: {
            text: "MeB Index (gr/ml)"
          },
        },
        markers:{
          size: 6
        },
        yaxis: [
          {
            title: {
              text: "Elevation (m asl)"
            },
          },
        ],
        title: {
            text: series.name,
            align: 'center'
        },
        tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      
                return (
                '<div class="arrow_box" style="padding: 10px">' +
                '<p><b>Meb Index</b>: ' + data.x + '</p>'+
                '<p><b>Elevation</b>: ' + data.y + '</p>'+ 
                '</div>'
                )
            }
          }
    };

    const dataChart: ApexAxisChartSeries = [
        {
            name: series.name,
            data: series.data
        }
      ]
    return(
      <ReactApexChart options={options} series={dataChart} />
    );
}

export default observer(GeologicalMebChart);