import { Box } from "@mui/system";
import RkdCard from "../../../../app/component/card/RkdCard";
import { IObjectOptions } from "../../../../app/models/object";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";
import RkdBackdrop from "../../../../app/component/helper/RkdBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PTDecimationStepper from "../PTDecimationStepper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Decimation, DecimationSegmentCount } from "../../../../app/models/decimation";
import PTDecimationViewStep1 from "./PTDecimationViewStep1";
import PTDecimationViewStep2 from "./PTDecimationViewStep2";
import PTDecimationViewStep3 from "./PTDecimationViewStep3";

const steps = ["Step 1", "Step 2", "Step 3"];

function PTDecimationViewForm() {
  const { objectStore, decimationStore } = useStore();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [objectIdsOption, setObjectIdsOption] = useState<Array<IObjectOptions>>([]);
  const [selected, setSelected] = useState("");
  const [dateSurvey, setDateSurvey] = useState(new Date());
  const { wellId } = useParams<{ wellId: string }>();
  const { surveyDate } = useParams<{ surveyDate: string }>();
  const [surveyNr, setSurveyNr] = React.useState("");

  // let location = useLocation();

  // console.log(location.pathname);

  const fetchData = React.useCallback(() => {
    if (wellId && surveyDate) {
      decimationStore.getDecimation(wellId, surveyDate).then((decimation) => {
        decimationStore.setDecimation(decimation);
        setSelected(decimationStore.decimation.wellId);
        setDateSurvey(decimationStore.decimation.surveyDate);
        setSurveyNr(decimationStore.decimation.surveyNo);
        if (decimationStore.decimation.selectedSegments.length > 0) {
          decimationStore.setDecimationSelectedSegment(decimationStore.decimation.selectedSegments);
        }

        if (decimationStore.decimation.table.length > 0) {
          for (let i = 0; i < decimationStore.decimation.table.length; i++) {
            if (i === 0) {
              if (decimationStore.decimation.table[i].speed === 0) {
                decimationStore.decimation.table[i].segmentStatus = "Static";
              } else {
                decimationStore.decimation.table[i].segmentStatus = "Up";
              }
            } else {
              if (decimationStore.decimation.table[i].speed >= decimationStore.decimation.table[i - 1].speed) {
                decimationStore.decimation.table[i].segmentStatus = "Up";
              } else if (decimationStore.decimation.table[i].speed < decimationStore.decimation.table[i - 1].speed) {
                decimationStore.decimation.table[i].segmentStatus = "Down";
              } else {
                decimationStore.decimation.table[i].segmentStatus = "Static";
              }
            }
          }

          decimationStore.setDecimationSelectedSegment(decimationStore.decimation.selectedSegments);
          let setDecimationSegmentSelected: any = [];
          decimationStore.decimationSelectedSegment
            .filter((x) => x.selected)
            .forEach((element) => {
              setDecimationSegmentSelected.push(element.segment);
            });
          decimationStore.setDecimationSegmentSelected(setDecimationSegmentSelected);
          decimationStore.setDecimationSegmentCount(decimationStore.decimation.selectedSegments.length);
        }
      });
    }
  }, [decimationStore, surveyDate, wellId]);

  useEffect(() => {
    fetchData();
    if ((wellId === null && surveyDate === null) || (wellId === undefined && surveyDate === undefined)) {
      decimationStore.decimation = new Decimation();
      decimationStore.decimationSegmenCount = new DecimationSegmentCount();
    }
  }, [decimationStore, fetchData, surveyDate, wellId]);

  useEffect(() => {
    objectStore.getObjectOptions("Well").then((res) => {
      setObjectIdsOption(res);
    });
  }, [objectStore]);

  function handleChange(event: any) {
    setSelected(event.target.value);
    decimationStore.decimation.wellId = event.target.value;
  }

  function handleChangeSurveyNr(event: any) {
    setSurveyNr(event.target.value);
    decimationStore.decimation.surveyNo = event.target.value;
  }

  function handleChangeSurveyDate(event: any) {
    setDateSurvey(event);
    decimationStore.decimation.surveyDate = event;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCancel = async () => {
    decimationStore.decimation = new Decimation();
    navigate("/pt");
  };

  if (objectStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title="Pressure & Temperature" subtitle="Form">
      <PTDecimationStepper activeStep={activeStep} steps={steps} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <FormControl>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputLabel id="demo-simple-select-label">Well</InputLabel>
              <Select fullWidth name="wellId" labelId="Well" label="Well" displayEmpty onChange={handleChange} readOnly={true} value={selected}>
                {objectIdsOption.map((item, index) => (
                  <MenuItem key={index + 1} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="surveyNo"
                onChange={handleChangeSurveyNr}
                type="text"
                label="Survey NR"
                value={surveyNr}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  onChange={handleChangeSurveyDate}
                  value={dateSurvey}
                  readOnly={true}
                  renderInput={(props) => <TextField fullWidth label="Survey Date" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          {activeStep === 0 ? <PTDecimationViewStep1 /> : activeStep === 1 ? <PTDecimationViewStep2 /> : <PTDecimationViewStep3 />}
          <Grid container>
            <Grid item xs={6}>
              <Button variant="contained" color="error" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                variant="contained"
                startIcon={<KeyboardArrowLeftIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext} variant="contained" endIcon={<KeyboardArrowRightIcon />} disabled={activeStep === steps.length - 1}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default PTDecimationViewForm;
