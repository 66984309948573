import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { IObjectOptions } from "../../../app/models/object";
import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import { GeologicalType } from "../../../app/config/enum";
import Lithology from "./geologicalGrid/Lithology";
import { IGeologicalDetail, TDynamicGeologicalGridResponse, TUploadGeologicalTemplateResponse } from "../../../app/models/geological";
import PCS from "./geologicalGrid/PCS";
import Alteration from "./geologicalGrid/Alteration";
import DrillParameter from "./geologicalGrid/DrillParameter";
import Loss from "./geologicalGrid/Loss";
import FeedZone from "./geologicalGrid/FeedZone";
import Temperature from "./geologicalGrid/Temperature";
import { getGeologicalType } from "../../../app/config/utils";
import MEB from "./geologicalGrid/MEB";
import { NumericFormat } from "react-number-format";
import Trajectory from "./geologicalGrid/Trajectory";

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function GeologicalPageCreate() {
  const { snackbarStore, dialogStore, objectStore, geologicalStore, geologicalAddGridStore } = useStore();
  const [dynamicConvertedData, setDynamicConvertedData] = useState<any[]>([]);
  const [selectedGeologicalType, setSelectedGeologicalType] = useState<string>(GeologicalType.LITHOLOGY);
  const [objectWellList, setObjectWellList] = useState<IObjectOptions[]>([]);
  const [selectedWell, setSelectedWell] = useState<string>("");
  const [groundLevel, setGroundLevel] = useState<number | undefined>(undefined);
  const [rigFloorHeight, setRigFloorHeight] = useState<number | undefined>(undefined);
  const [deleteSequence, setDeleteSequence] = useState<number[]>([]);
  const [selectedMultipleWell, setSelectedMultipleWell] = useState<string[]>([]);
  const [dynamicAddData, setDynamicAddData] = useState<any | undefined>(undefined);
  const [dynamicEditData, setDynamicEditData] = useState<TDynamicGeologicalGridResponse[]>([]);
  const [value, setValue] = useState("1");
  const [displaySelectedWell, setDisplaySelectedWell] = useState<string[]>([]);
  const hiddenFileInput = useRef<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const dynamicXSMUI = useMemo(() => {
    if (selectedGeologicalType === GeologicalType.MEB) {
      return 2;
    }
    return 3;
  }, [selectedGeologicalType]);

  const onDelete = (id: string, sequence: number, tableIndex: number = 0) =>
    dialogStore.open({
      action: async () => {
        if (!id) {
          let helper = { ...dynamicAddData };
          let temp = { ...dynamicAddData?.data[tableIndex] };

          temp.table = temp.table?.filter((item: { sequence: number }) => item.sequence !== sequence);
          helper.data[tableIndex] = temp;

          setDynamicAddData(helper);
        } else {
          let payload = [{ sequence, id }];
          await geologicalStore.deleteGeologicalDetail(payload).then(() => {
            deleteCallBack(id);
            snackbarStore.show("success", `items deleted successfully`);
          });
        }
      },
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      description: `Are you sure want to delete this items?`,
    });

  const handleSubmit = () => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    if (!id && dynamicAddData.data) {
      dynamicAddData.data.map((item: any, index: number) => {
        geologicalStore
          .addGeological(geologicalName, item)
          .then(() => (dynamicAddData.data.length === index + 1 ? navigate("/geological") : undefined));
      });
    } else {
      navigate("/geological");
    }
  };

  const downloadTempalte = () => {
    const { type } = getGeologicalType(selectedGeologicalType);

    let payload = { type, wellIds: selectedMultipleWell };

    switch (selectedGeologicalType) {
      case GeologicalType.LITHOLOGY:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.ALTERATION:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.MEB:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.DRILLPARAMETER:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.PCS:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.LOSS:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.FEEDZONE:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.TEMPERATURE:
        geologicalStore.downloadTemplate(payload);
        break;
      case GeologicalType.TRAJECTORY:
        geologicalStore.downloadTemplate(payload);
        break;
    }
  };

  const handleCancel = () => navigate("/geological");

  const displayGrid = (data: TDynamicGeologicalGridResponse[] = [], tableIndex?: number) => {
    switch (selectedGeologicalType) {
      case GeologicalType.LITHOLOGY:
        return (
          <Lithology
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.ALTERATION:
        return (
          <Alteration
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.DRILLPARAMETER:
        return (
          <DrillParameter
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.PCS:
        return (
          <PCS
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.LOSS:
        return (
          <Loss
            selectedGeologicalType={selectedGeologicalType}
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.FEEDZONE:
        return (
          <FeedZone
            selectedGeologicalType={selectedGeologicalType}
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            setDeleteSequence={setDeleteSequence}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.TEMPERATURE:
        return (
          <Temperature
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.MEB:
        return (
          <MEB
            selectedGeologicalType={selectedGeologicalType}
            setDeleteSequence={setDeleteSequence}
            data={id ? dynamicEditData : data}
            onDelete={(id, sequence) => onDelete(id, sequence, tableIndex)}
            setDynamicConvertedData={setDynamicConvertedData}
            dynamicConvertedData={dynamicConvertedData}
            deleteSequence={deleteSequence}
          />
        );
      case GeologicalType.TRAJECTORY:
        return <Trajectory />;
    }
  };

  const deleteCallBack = (id: string) => {
    if (!id) return;

    const { geologicalName } = getGeologicalType(selectedGeologicalType);
    let table: any = [];
    geologicalStore.getGeologicalDetailTable(geologicalName, id).then((subData: any) => {
      table = subData.data;
      geologicalAddGridStore.setGridFromDetail(table.map((item: any, index: number) => ({ ...item, tempId: item.id, id: item.id + index })));
      setDynamicConvertedData(table);
    });
  };

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type } = getGeologicalType(selectedGeologicalType);

    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("type", type.toString());
      formData.append("wellId", selectedWell ?? null);
      formData.append("groundLevel", groundLevel ? groundLevel.toString() : "");
      formData.append("rigFloorHeight", rigFloorHeight ? rigFloorHeight.toString() : "");
      formData.append("file", e.target.files[0]);

      geologicalStore.uploadGeologicalTemplate(formData).then((res) => setDynamicAddData(res));
    }
    e.target.value = "";
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const generatePCSData = () => {
    let payload = {
      wellIds: selectedMultipleWell,
    };
    geologicalStore.generatePCSData(payload).then((res) => setDynamicAddData(res));
  };

  const isAllSelected = objectWellList.length > 0 && selectedMultipleWell.length === objectWellList.length;

  const handleSelectWell = async (event: SelectChangeEvent<typeof selectedMultipleWell>) => {
    const { value } = event.target;
    let result: string[] = [];

    if (value[value.length - 1] === "all") {
      setSelectedMultipleWell(selectedMultipleWell.length === objectWellList.length ? [] : objectWellList.map((item) => item.value));
      setDisplaySelectedWell(objectWellList.map((item) => item.text));
      return;
    }

    objectWellList.forEach((val) => value.includes(val.value) && result.push(val.text));

    setDisplaySelectedWell(result);
    setSelectedMultipleWell(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    setDynamicConvertedData([]);
    geologicalAddGridStore.clearGridAfterUploadGeological();
    geologicalStore.clearDynamicDataGrid();
    setSelectedWell("");
    setGroundLevel(undefined);
    setRigFloorHeight(undefined);
    setSelectedMultipleWell([]);
    setDisplaySelectedWell([]);
  }, [geologicalAddGridStore, selectedGeologicalType]);

  useEffect(() => {
    let tempObjectWellList: IObjectOptions[] = [];
    objectStore.getObjectOptions("Well").then((res) => {
      setObjectWellList(res);
      tempObjectWellList = res;
    });
    if (!id) return;
    let table: any = [];
    geologicalStore.getGeologicalDetail(id).then((data: IGeologicalDetail) => {
      const { geologicalName } = getGeologicalType(data.type);
      setDisplaySelectedWell([tempObjectWellList.find((val) => val.value === data.wellId)?.text || ""]);
      setSelectedWell(data.wellId);
      setSelectedMultipleWell([data.wellId]);
      setSelectedGeologicalType(data.type === "DRILLPARAMETER" ? GeologicalType.DRILLPARAMETER : data.type);
      geologicalStore.getGeologicalDetailTable(geologicalName, id).then((subData: any) => {
        setDynamicEditData(subData);
        table = subData.data;
        geologicalAddGridStore.setGridFromDetail(table.map((item: any, index: number) => ({ ...item, tempId: item.id, id: item.id + index })));
        setDynamicConvertedData(table);
      });
    });
  }, []);

  if (objectStore.loadingForm) return <RkdBackdrop />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Geological Module" subtitle="Create">
          <Grid container spacing="6px">
            <Grid item xs={dynamicXSMUI}>
              <TextField
                select
                disabled={!!id}
                fullWidth
                label="Geological Type"
                onChange={(e) => {
                  setSelectedGeologicalType(e.target.value);
                  geologicalStore.clearDynamicDataGrid();
                }}
                value={selectedGeologicalType}
              >
                {Object.values(GeologicalType)
                  .sort()
                  .map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={dynamicXSMUI}>
              {/* {!differenceBehaviourGeologicalType.map((item) => item.name).includes(selectedGeologicalType) ? ( */}
              <FormControl disabled={selectedGeologicalType === GeologicalType.TRAJECTORY} sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Well Name</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleWell}
                  onChange={handleSelectWell}
                  disabled={!!id}
                  input={<OutlinedInput label="Well Name" />}
                  renderValue={() => displaySelectedWell.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={selectedMultipleWell.length > 0 && selectedMultipleWell.length < objectWellList.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {objectWellList.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleWell.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {selectedGeologicalType === GeologicalType.MEB ? (
              <>
                <Grid item xs={2}>
                  <NumericFormat
                    customInput={TextField}
                    label="Ground Level (masl)"
                    fullWidth
                    value={groundLevel}
                    thousandSeparator={true}
                    onValueChange={(e) => setGroundLevel(e.floatValue)}
                    name="ground-level"
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumericFormat
                    customInput={TextField}
                    label="Rig Floor Height (m)"
                    fullWidth
                    value={rigFloorHeight}
                    thousandSeparator={true}
                    onValueChange={(e) => setRigFloorHeight(e.floatValue)}
                    name="rig-floor-height"
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {selectedGeologicalType === GeologicalType.PCS ? (
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box sx={{ height: "100%", width: "50%" }}>
                  <LoadingButton
                    loading={geologicalStore.dynamicDataGridLoading}
                    fullWidth
                    sx={{ height: "100%" }}
                    component="label"
                    variant="contained"
                    onClick={generatePCSData}
                  >
                    Generate PCS Data
                  </LoadingButton>
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item xs={dynamicXSMUI}>
                  <Box sx={{ height: "100%" }}>
                    <LoadingButton
                      loading={geologicalStore.loadingDownloadTemplate || geologicalStore.dynamicDataGridLoading}
                      fullWidth
                      sx={{ height: "100%" }}
                      component="label"
                      disabled={!!id || selectedMultipleWell.length === 0 || selectedGeologicalType === GeologicalType.TRAJECTORY}
                      variant="contained"
                      startIcon={<DownloadOutlined />}
                      onClick={() => downloadTempalte()}
                    >
                      Download Template
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={dynamicXSMUI}>
                  <Box sx={{ height: "100%" }}>
                    <LoadingButton
                      disabled={!!id || selectedGeologicalType === GeologicalType.TRAJECTORY}
                      loading={geologicalStore.loadingDownloadTemplate || geologicalStore.dynamicDataGridLoading}
                      fullWidth
                      sx={{ height: "100%" }}
                      component="label"
                      variant="contained"
                      startIcon={<UploadOutlined />}
                    >
                      Upload Template
                      <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        ref={hiddenFileInput}
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <hr />
          <Box>
            <Box sx={{ textAlign: "center", fontWeight: "bold", fontSize: "18px", mb: "12px" }}>Data Conversion</Box>
            {!id && selectedGeologicalType !== GeologicalType.TRAJECTORY ? (
              <Box sx={{ width: "100%", typography: "body1", mb: "24px" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      {dynamicAddData?.data.map((item: any, index: number) => (
                        <Tab label={item.wellName} value={(index + 1).toString()} />
                      ))}
                    </TabList>
                  </Box>
                  {dynamicAddData?.data.map((item: any, index: number) => (
                    <TabPanel sx={{ color: "black" }} value={(index + 1).toString()}>
                      {displayGrid(item.table, index)}
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            ) : (
              <>{displayGrid()}</>
            )}
          </Box>

          <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
            <Box>
              <LoadingButton
                loading={geologicalStore.loadingDownloadTemplate || geologicalStore.dynamicDataGridLoading}
                variant="contained"
                color="error"
                onClick={handleCancel}
              >
                Cancel
              </LoadingButton>
            </Box>
            <Box>
              <LoadingButton
                loading={geologicalStore.loadingDownloadTemplate || geologicalStore.dynamicDataGridLoading}
                onClick={() => handleSubmit()}
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(GeologicalPageCreate);
