import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { GridColDef, GridColumnGroup } from "@mui/x-data-grid-premium";
import { useStore } from "../../../../app/stores/store";
import { useMemo } from "react";
import { BIWEEKLY_DATA } from "../../../../app/config/enum";
import { observer } from "mobx-react-lite";

type TDynamicBiweeklyDataGrid = {
  isSeparator: boolean;
  identifier: string;
  date: Date;
};

function DynamicBiweeklyDataGrid({ date, identifier, isSeparator }: TDynamicBiweeklyDataGrid) {
  const { biweeklyStore } = useStore();

  const specificData = useMemo(() => {
    if (identifier === BIWEEKLY_DATA.Productions) return biweeklyStore.biweeklyData?.productions;
    if (identifier === BIWEEKLY_DATA.Injections) return biweeklyStore.biweeklyData?.injections;
    if (identifier === BIWEEKLY_DATA.Separators) return biweeklyStore.biweeklyData?.separators;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biweeklyStore.biweeklyData]);

  const handleRowEdit = (row: any) => {
    // const { geologicalName } = getGeologicalType(selectedGeologicalType ?? "");

    let payload = {
      id: row.id,
      type: identifier,
      sequence: row.sequence,
      ci: row.ci,
      silica: row.silica,
      ncg: row.ncg,
      phBrine: row.phBrine,
      phSteam: row.phSteam,
      ecBrine: row.ecBrine,
      ecSteam: row.ecSteam,
      temperature: row.temperature,
      pressure: row.pressure,
      samplingPressure: Number(row.samplingPressure),
      fcv: row.fcv,
    };

    biweeklyStore.editBiweeklyPerRow(payload).then(() => {
      // biweeklyStore.getBiweekly(new Date(date).toISOString());
    });

    return row;
  };

  const generateDynamicColumnGroupingModel = () => {
    let resultTemp: GridColumnGroup[] = [
      {
        groupId: "PH",
        headerAlign: "center",
        children: [{ field: "phPrevBrine" }, { field: "phBrine" }, { field: "phPrevSteam" }, { field: "phSteam" }],
      },
      {
        groupId: "EC (ms)",
        headerAlign: "center",
        children: [{ field: "ecPrevBrine" }, { field: "ecBrine" }, { field: "ecPrevSteam" }, { field: "ecSteam" }],
      },
    ];

    if (isSeparator) {
      resultTemp.push({
        groupId: "HP Separator",
        headerAlign: "center",
        children: [{ field: "prevTemperature" }, { field: "temperature" }, { field: "prevPressure" }, { field: "pressure" }],
      });
    } else {
      resultTemp.push(
        {
          groupId: "Wells",
          headerAlign: "center",
          children: [{ field: "prevPressure" }, { field: "pressure" }, { field: "prevSamplingPressure" }, { field: "samplingPressure" }],
        },
        {
          groupId: "FCV",
          headerAlign: "center",
          children: [{ field: "fcv" }],
        }
      );
    }

    return resultTemp;
  };

  const generateDynamicColumnsGrid = () => {
    let resultTemp: GridColDef[] = [
      {
        field: "objectName",
        headerName: "Well",
        sortable: false,
        disableColumnMenu: true,
        headerAlign: "center",
        align: "center",
        width: 150,
      },
      {
        field: "prevCI",
        headerName: "Prev CI (PPM)",
        sortable: false,
        disableColumnMenu: true,
        headerAlign: "center",
        align: "center",
        width: 150,
      },
      {
        field: "ci",
        headerName: "CI (PPM)",
        headerAlign: "center",
        align: "center",
        sortable: false,
        editable: true,
        disableColumnMenu: true,
        width: 150,
      },
      {
        field: "prevSilica",
        headerAlign: "center",
        align: "center",
        headerName: "Prev Silica (ppm)",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
      },
      {
        field: "silica",
        headerAlign: "center",
        editable: true,
        align: "center",
        headerName: "Silica (ppm)",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
      },
      {
        field: "prevNcg",
        headerName: "Prev NCG",
        headerAlign: "center",
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
      },
      {
        field: "ncg",
        headerName: "NCG",
        editable: true,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: "center",
        align: "center",
        width: 150,
      },
      {
        field: "phPrevBrine",
        headerName: "Prev Brine",
        sortable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: "phBrine",
        editable: true,
        headerName: "Brine",
        sortable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: "phPrevSteam",
        headerName: "Prev Steam",
        headerAlign: "center",
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        width: 120,
      },
      {
        field: "phSteam",
        headerAlign: "center",
        align: "center",
        editable: true,
        headerName: "Steam",
        sortable: false,
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: "ecPrevBrine",
        headerName: "Prev Brine",
        sortable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: "ecBrine",
        headerName: "Brine",
        sortable: false,
        editable: true,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: "ecPrevSteam",
        headerName: "Prev Steam",
        headerAlign: "center",
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        width: 120,
      },
      {
        field: "ecSteam",
        editable: true,
        headerAlign: "center",
        align: "center",
        headerName: "Steam",
        sortable: false,
        disableColumnMenu: true,
        width: 100,
      },
    ];

    if (isSeparator) {
      resultTemp.push(
        {
          field: "prevTemperature",
          headerAlign: "center",
          align: "center",
          headerName: "Prev Temp (C)",
          sortable: false,
          disableColumnMenu: true,
          width: 120,
        },
        {
          field: "temperature",
          headerAlign: "center",
          editable: true,
          align: "center",
          headerName: "Temp (C)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        },
        {
          field: "prevPressure",
          headerAlign: "center",
          align: "center",
          headerName: "Prev Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 140,
        },
        {
          field: "pressure",
          headerAlign: "center",
          editable: true,
          align: "center",
          headerName: "Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        }
      );
    } else {
      resultTemp.push(
        {
          field: "prevPressure",
          headerAlign: "center",
          align: "center",
          headerName: "Prev Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        },
        {
          field: "pressure",
          editable: true,
          headerAlign: "center",
          align: "center",
          headerName: "Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        },
        {
          field: "prevSamplingPressure",
          headerAlign: "center",
          align: "center",
          headerName: "Prev Sampling Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        },
        {
          field: "samplingPressure",
          headerAlign: "center",
          editable: true,
          align: "center",
          headerName: "Sampling Pressure (Bar)",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        },
        {
          field: "fcv",
          headerAlign: "center",
          editable: true,
          align: "center",
          headerName: "%",
          sortable: false,
          disableColumnMenu: true,
          width: 100,
        }
      );
    }

    return resultTemp;
  };

  return (
    <StyledDataGridPremium
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      getRowId={(row) => row.sequence}
      processRowUpdate={handleRowEdit}
      columns={generateDynamicColumnsGrid()}
      columnGroupingModel={generateDynamicColumnGroupingModel()}
      rows={specificData ?? []}
      autoHeight
    />
  );
}

export default observer(DynamicBiweeklyDataGrid);
