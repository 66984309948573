import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { BARS_LEGEND, LINE_CHARTS_LEGEND, SCATTERS_LEGEND } from "../../../app/config/enum";

export default function LegendGeologicalReport() {
  return (
    <Box sx={{ p: "24px", border: "0.5px solid #edeff1", borderRadius: "12px" }}>
      <Typography variant="h2" sx={{ fontWeight: "bold" }}>
        Legends
      </Typography>
      <Divider sx={{ my: "18px" }} />
      <Grid container spacing="12px">
        <Grid item xs={6}>
          <Typography variant="h3" sx={{ mb: "12px", fontWeight: "bold" }}>
            Line Charts
          </Typography>
          <Grid container>
            {LINE_CHARTS_LEGEND.map((item) => (
              <Grid item xs={4} key={item.name} sx={{ ml: "8px", display: "flex", gap: "12px", mb: "6px", alignItems: "flex-end" }}>
                <Skeleton variant="text" sx={{ rotate: "90deg", fontSize: "1rem", backgroundColor: item.color }} />
                <Box>{item.name}</Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h3" sx={{ mb: "12px", fontWeight: "bold" }}>
            Bars
          </Typography>
          {BARS_LEGEND.map((item) => (
            <Box key={item.name} sx={{ display: "flex", gap: "12px", mb: "6px" }}>
              <Skeleton variant="rectangular" width={20} height={20} sx={{ backgroundColor: item.color }} />
              <Box>{item.name}</Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h3" sx={{ mb: "12px", fontWeight: "bold" }}>
            Scatters
          </Typography>
          {SCATTERS_LEGEND.map((item) => (
            <Box key={item.name} sx={{ display: "flex", gap: "12px", mb: "6px" }}>
              <Skeleton variant="circular" width={20} height={20} sx={{ backgroundColor: item.color }} />
              <Box>{item.name}</Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
