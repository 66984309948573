import { IRegressionResponse, ISteamDrycallPTFCorrections, ISteamResultTables } from "../../../../app/models/steam";

export function molesSF6Formula(ppmv: number, loopSize: number, tAvg: number, loopPress: number) {
  const formula = (ppmv / 1000000) * loopSize * (1 / 1000) * (1 / 22.414) * (273.15 / (tAvg + 273.15)) * ((loopPress * 51.71) / 760);
  return formula;
}

export function predictedMolesFormula(loopSize: number, regression: IRegressionResponse[], areaCounts: number) {
  if (loopSize) {
    if (regression.length === 4) {
      return (
        regression[0].coefficients +
        areaCounts * regression[1].coefficients +
        areaCounts ** 2 * regression[2].coefficients +
        areaCounts ** 3 * regression[3].coefficients
      );
    } else if (regression.length === 3) {
      return regression[0].coefficients + areaCounts * regression[1].coefficients + areaCounts ** 2 * regression[2].coefficients;
    } else {
      console.log(loopSize, regression, areaCounts);
      return regression[0].coefficients + areaCounts * regression[1].coefficients;
    }
  } else {
    return 0;
  }
}

export function diffFormula(loopSize: number, predictedMoles: number, molesSF6: number) {
  console.log("loopSize", loopSize);
  console.log("predictedMoles", predictedMoles);
  console.log("molesSF6", molesSF6);
  if (loopSize && predictedMoles !== 0 && molesSF6 !== 0) {
    return Number((((predictedMoles - molesSF6) / molesSF6) * 100).toFixed(2));
  } else {
    return 0;
  }
}

//Step 2

export function grNaohFormula(naohDensity: number, initialWeight: number, emptyWeight: number) {
  const formula = (((naohDensity - 1.000389) / 0.01104783) * (initialWeight - emptyWeight)) / 100;
  return formula;
}

export function scrubFormula(initialWeight: number, emptyWeight: number, grNaoh: number) {
  const formula = (initialWeight - emptyWeight) / ((grNaoh / (initialWeight - emptyWeight)) * 1.104783 + 1.000389);
  return formula;
}

export function grmConFormula(weightRecvd: number, initialWeight: number) {
  const formula = weightRecvd - initialWeight;
  return formula;
}

export function totDensFormula(grNaoh: number, weightRecvd: number, emptyWeight: number) {
  const formula = (grNaoh / (weightRecvd - emptyWeight)) * 1.104783 + 1.000389;
  return formula;
}

export function sampDensFormula(grNaoh: number, weightRecvd: number, initialWeight: number) {
  const formula = (grNaoh / (weightRecvd - initialWeight)) * 1.104783 + 1.000389;
  return formula;
}

export function LvFormula(weightRecvd: number, emptyWeight: number, totDens: number) {
  const formula = (weightRecvd - emptyWeight) / totDens;
  return formula;
}

export function hsFormula(milkedBombs: boolean, bombVolume: number, weightRecvd: number, emptyWeight: number, sampDens: number) {
  if (milkedBombs) {
    return bombVolume;
  } else {
    return bombVolume - (weightRecvd - emptyWeight) / sampDens;
  }
}

export function bombHeadSpacePressFormula(
  bombInitialPressureReading: number,
  hs: number,
  inletSystemDeadVolume: number,
  bombInitialPressureLoopSize: number
) {
  if (bombInitialPressureReading > 0) {
    return (bombInitialPressureReading * (hs + inletSystemDeadVolume + bombInitialPressureLoopSize)) / hs;
  } else {
    return 0;
  }
}

export function totalHSMolesFormula(bombHeadspacePressure: number, hs: number, bombTemprature: number) {
  const formula = ((bombHeadspacePressure / 14.7) * (hs / 1000)) / (bombTemprature + 273.15) / 0.08205;
  return formula;
}

export function totalLoopMolesFormula(loopPressure: number, loopSizeInjected: number, loopTemperature: number) {
  const formula = ((loopPressure / 14.7) * (loopSizeInjected / 1000)) / (loopTemperature + 273.15) / 0.08205;
  return formula;
}

export function solFracFormula(solubilityFactor: number, lv: number, hs: number) {
  const formula = (solubilityFactor * lv) / (solubilityFactor * lv + hs);
  return formula;
}

export function sF6MolesInjectionFormula(regression: IRegressionResponse[], areaCounts: number) {
  if (regression.length === 4) {
    return (
      regression[0].coefficients +
      areaCounts * regression[1].coefficients +
      areaCounts ** 2 * regression[2].coefficients +
      areaCounts ** 3 * regression[3].coefficients
    );
  } else if (regression.length === 3) {
    return regression[0].coefficients + areaCounts * regression[1].coefficients + areaCounts ** 2 * regression[2].coefficients;
  } else {
    console.log(regression, areaCounts);
    return regression[0].coefficients + areaCounts * regression[1].coefficients;
  }
}

export function totalMolesSF6BombFormula(
  milkedBombs: boolean,
  sF6MolesInjection: number,
  totalLoopMoles: number,
  totalHSMoles: number,
  solFrac: number
) {
  if (milkedBombs) {
    return (sF6MolesInjection / totalLoopMoles) * totalHSMoles;
  } else {
    return ((sF6MolesInjection / totalLoopMoles) * totalHSMoles) / (1 - solFrac);
  }
}

export function microGramsFormula(totalMolesSF6Bomb: number, molecularWeight: number) {
  const formula = totalMolesSF6Bomb * molecularWeight * 1000000;
  return formula;
}

export function ppmwFormula(areaCounts: number, microGrams: number, grmCon: number) {
  if (areaCounts !== null || areaCounts > 0) {
    const formula = microGrams / grmCon;
    return formula;
  } else {
    return 0;
  }
}

// Step 3 - DrycallPTFCorrections

export function biometricPressureInchFormula(biometricPressurePsia: number) {
  const formula = biometricPressurePsia * 2.0360212;
  return formula;
}

export function stpFlowrateFormula(mfcFlowrate: number, temp: number, barometricPressureInch: number, dp: number) {
  if (mfcFlowrate !== null || mfcFlowrate > 0) {
    const formula = mfcFlowrate * (273.15 / (273.15 + temp)) * ((barometricPressureInch + dp * 0.07355) / 29.92);
    return formula;
  } else {
    return 0;
  }
}

export function devFormula(mfcFlowrate: number, setPoint: number, stpFlowrate: number) {
  if (mfcFlowrate !== 0 && mfcFlowrate !== null && setPoint !== 0 && setPoint !== null) {
    return (stpFlowrate - setPoint) / setPoint;
  } else {
    return 0;
  }

  //   if (mfcFlowrate !== null || mfcFlowrate > 0) {
  //     if (setPoint !== null || setPoint > 0) {
  //       const formula = (stpFlowrate - setPoint) / setPoint;
  //       return formula;
  //     } else {
  //       return 0;
  //     }
  //   } else {
  //     return 0;
  //   }
}

export function injectionRateSLPMFormula(stpFlowrate: number) {
  const formula = stpFlowrate / 1000;
  return formula;
}

export function injectionRateSLPMAverageFormula(arr: ISteamDrycallPTFCorrections[], key: keyof ISteamDrycallPTFCorrections): number {
  let sum = 0;
  let count = 0;

  arr.forEach((obj) => {
    if (typeof obj[key] === "number") {
      sum += obj[key] as number;
      count++;
    }
  });

  const average = sum / count;
  return average;
}

export function saturationPressure(pressure: number | null, baroPress: number) {
  if (pressure !== null) {
    const formula = pressure - baroPress;
    return formula;
  } else {
    return 0;
  }
}

export function steamFlowRateFormula(ppmTracer: number, injRate: number, pcntSteamTracer: number, steamTracerMW: number, baselineSteam: number) {
  if (ppmTracer !== null || ppmTracer > 0) {
    const value = (((injRate / 22.414) * (pcntSteamTracer / 100) * (steamTracerMW / 454) * 60) / (ppmTracer - baselineSteam)) * 1000;
    return value;
  } else {
    return 0;
  }
}

export function corrSteamFlowFormula(ncgContent: number, steamFlow: number) {
  if (ncgContent !== 0) {
    const value = (1 - ncgContent / 100) * steamFlow;
    return value;
  } else {
    return 0;
  }
}

export function findMinValue(arr: ISteamResultTables[], property: "steamFlowRate") {
  if (arr.length === 0) {
    return 0;
  }

  let min = arr[0][property];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][property] < min) {
      min = arr[i][property];
    }
  }

  return min;
}

export function findMaxValue(arr: ISteamResultTables[], property: "steamFlowRate") {
  if (arr.length === 0) {
    return 0;
  }

  let max = arr[0][property];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][property] > max) {
      max = arr[i][property];
    }
  }

  return max;
}

export function SteamFlowRateSpreadFormula(arr: ISteamResultTables[]) {
  const min = findMinValue(arr, "steamFlowRate");
  const max = findMaxValue(arr, "steamFlowRate");

  return max - min;
}

export function calculateStandardDeviation(data: ISteamResultTables[]) {
  const steamFlowRates = data.map((obj) => obj.steamFlowRate);
  const stdDev = calculateStdDev(steamFlowRates);
  return !isNaN(stdDev) ? stdDev : 0;
}

function calculateStdDev(data: number[]): number {
  if (data.length <= 1) {
    return NaN; // Standard deviation is undefined for a single data point
  }

  const mean = calculateMean(data);
  const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
  const variance = squaredDifferences.reduce((acc, val) => acc + val, 0) / (data.length - 1);
  const stdDev = Math.sqrt(variance);

  return stdDev;
}

function calculateMean(data: number[]): number {
  return data.reduce((acc, val) => acc + val, 0) / data.length;
}

export function calculateSteamRsd(stdDev: number, avgSteamFlowRate: number) {
  if (stdDev !== 0) {
    return stdDev / avgSteamFlowRate;
  } else {
    return 0;
  }
}
