import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { Edit } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { TOrcGridResponse } from "../../../app/models/orc";

function OrcDataGrid() {
  const { orcGridStore, commonStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    orcGridStore.getOrcGrid();
  }, [orcGridStore]);

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "orcName",
      headerName: "Name",
      flex: 0.6,
      type: "string",
    },
    {
      field: "orcDescription",
      headerName: "Description",
      flex: 0.6,
      type: "string",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          color="info"
          label="Edit"
          onClick={() => {
            navigate(`/master/orc/${params.row.id}`);
            orcGridStore.setSelectedOrc(params.row as TOrcGridResponse);
          }}
        />,
      ],
    },
  ];

  return (
    <StyledDataGridPremium
      autoHeight
      disableColumnMenu
      showCellVerticalBorder
      showColumnVerticalBorder
      checkboxSelection
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={orcGridStore.dataGridResult.data}
    />
  );
}

export default observer(OrcDataGrid);
