import { Box } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { observer } from "mobx-react-lite";
import { TReportGridProps } from "./ReportGrid";

function ReportLineChart({ data }: TReportGridProps) {
  return (
    <Box sx={{ height: "320px", display: "flex", justifyContent: "center", mt: "24px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data.map((item) => ({ name: `Depth ${item.depth}`, temperature: item.temperature, pressure: item.pressure }))}
          margin={{
            right: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{
              fontSize: 12,
              fontStyle: "italic",
              offset: "-5",
            }}
            dataKey="name"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line strokeWidth={2} dataKey="pressure" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line strokeWidth={2} dataKey="temperature" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default observer(ReportLineChart);
