import { GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { IGeoLossTableRequest } from "../../../../app/models/geological";
import { useStore } from "../../../../app/stores/store";
import { TGeneralGeological } from "./Lithology";
import { getGeologicalType } from "../../../../app/config/utils";
import { useParams } from "react-router-dom";

function Loss({
  onDelete,
  selectedGeologicalType,
  deleteSequence,
  setDeleteSequence,
  setDynamicConvertedData,
  data,
  dynamicConvertedData,
}: TGeneralGeological) {
  const { geologicalStore, geologicalAddGridStore } = useStore();
  const { id } = useParams();

  const handleRowEdit = (row: any) => {
    const { geologicalName } = getGeologicalType(selectedGeologicalType);

    let payload = {
      sequence: row.sequence,
      id: row.tempId,
      lossType: row.lossType,
      depth: row.depth,
      mvd: row.mvd,
      rateLoss: row.rateLoss,
    };

    geologicalStore.editGeological(payload, geologicalName);
    const temp: IGeoLossTableRequest[] = dynamicConvertedData.map((current) => {
      if (current.sequence !== row.sequence) return current;
      return {
        geologicalId: row.geologicalId,
        sequence: row.sequence,
        lossType: row.lossType,
        depth: row.depth,
        mvd: row.mvd,
        rateLoss: row.rateLoss,
        state: 3,
      };
    });
    setDynamicConvertedData(temp);
    return row;
  };

  const column: GridColDef[] = [
    { field: "lossType", headerName: "Loss Type", editable: true, headerAlign: "center", align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "depth", headerName: "Depth", headerAlign: "center", editable: true, align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "mvd", headerName: "mvd", editable: true, headerAlign: "center", align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "masl", headerName: "masl", headerAlign: "center", editable: true, align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "eastern", headerName: "Eastern", headerAlign: "center", editable: true, align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "northern", headerName: "Northern", headerAlign: "center", editable: true, align: "center", flex: 1, headerClassName: "tab-header" },
    { field: "rateLoss", headerName: "Rate Loss", editable: true, headerAlign: "center", align: "center", flex: 1, headerClassName: "tab-header" },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => onDelete(params.row.tempId, params.row.sequence)} />,
      ],
    },
  ];
  return (
    <StyledDataGridPremium
      columns={column}
      loading={id ? !geologicalAddGridStore.dataGridResult.data.length : !data.length}
      autoHeight
      processRowUpdate={handleRowEdit}
      showCellVerticalBorder
      showColumnVerticalBorder
      getRowId={(row) => (id ? row.id : row.sequence)}
      rows={id ? geologicalAddGridStore.dataGridResult.data : data}
    />
  );
}

export default observer(Loss);
