import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from '../../app/stores/store';


function Home() {
    const { accountStore: { account, getUser }, commonStore } = useStore();

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        commonStore.setTitlePage('Home Page');
    });

    return (
        <>
            <Typography variant="h1">Welcome, Sorik Merapi Geothermal {account?.displayName}</Typography>
        </>
    );
}

export default observer(Home);
