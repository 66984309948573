import { GridValidRowModel, GridColDef, GridEventListener, GridRowHeightParams } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { Box, Divider } from "@mui/material";

function BrineORCReportDataGrid() {
  const { brineORCMonitoringStore } = useStore();

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "brineOrcDate",
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => <Box>{format(new Date(params.row.brineOrcDate), "dd MMM yyyy")}</Box>,
    },
    {
      field: "orcs",
      headerName: "ORC",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {params.row.orcs.map((item: any, index: number) => (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Box>{item.orcName}</Box>
                {index + 1 === params.row.orcs.length ? "" : <Divider sx={{ my: "6px" }} />}
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      field: "",
      headerName: "VS Press In",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {params.row.orcs.map((item: any, index: number) => (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Box>{item.pressureIn}</Box>
                {index + 1 === params.row.orcs.length ? "" : <Divider sx={{ my: "6px" }} />}
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      align: "center",
      field: "pressureOut",
      headerAlign: "center",
      headerName: "Pressure Out",
      flex: 1,
    },
  ];

  return (
    <StyledDataGridPremium
      autoHeight
      loading={brineORCMonitoringStore.loadingORCReportGrid}
      getRowId={(row) => `${row.brineOrcDate}${row.pressureOut}`}
      showCellVerticalBorder
      getRowHeight={({ id, densityFactor, model }: GridRowHeightParams) => {
        if (model.orcs.length) {
          return 40 * model.orcs.length;
        }
        return null;
      }}
      disableColumnMenu
      showColumnVerticalBorder
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      columns={gridColumns}
      rows={brineORCMonitoringStore.brineORCReportGrid}
    />
  );
}

export default observer(BrineORCReportDataGrid);
