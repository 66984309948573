import RkdCard from "../../../app/component/card/RkdCard";
import { Box } from "@mui/system";
import { Autocomplete, Button, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { useStore } from "../../../app/stores/store";
import { IConnectionGroups, IConnectionOptions } from "../../../app/models/connection";
import RkdBackdrop from "../../../app/component/helper/RkdBackdrop";
import { LoadingButton } from "@mui/lab";
import { ConnectionSchemaZod, connectionSchemaFormik } from "./schema/connectionSchemaZod";
import DeleteIcon from "@mui/icons-material/Delete";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

interface IProps {
  onSubmit: (values: ConnectionSchemaZod, helpers: FormikHelpers<ConnectionSchemaZod>) => Promise<unknown>;
  initialValues: ConnectionSchemaZod;
  id?: string;
}

function ConnectionForm(props: IProps) {
  const { onSubmit, initialValues, id } = props;
  const { vesselGroupStore, connectionStore } = useStore();
  const [selectedObject, setSelectedObject] = useState<TSelectedData | undefined>(undefined);
  const navigate = useNavigate();

  const {
    handleSubmit: handleFormikSubmit,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
    touched,
    handleReset,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: connectionSchemaFormik,
    enableReinitialize: true,
  });

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    const data = groups.map((item) => {
      return { id: item.id, groupName: item.groupName };
    });
    setFieldValue("groups", data);
    handleFormikSubmit(e);
  };

  const isUpdate = useMemo(() => Boolean(id), [id]);
  const [selectedObjectId, setSelectedObjectId] = useState<string>("");
  const [objectIdsOption, setObjectIdsOption] = useState<Array<IConnectionOptions>>([]);
  const [groups, setGroups] = useState<Array<IConnectionGroups>>([]);
  const [validationObject, setValidationObject] = useState<boolean>(false);

  const gridColumns: GridColumns<IConnectionGroups> = [
    {
      field: "groupName",
      headerName: "Object",
      type: "string",
      flex: 1,
      renderCell(params) {
        if (params.row.objects.length > 0) {
          return (
            <p>
              {params.row.groupName}
              <ul>
                {params.row.objects.map((item) => (
                  <li key={item.id}>{item.groupName}</li>
                ))}
              </ul>
            </p>
          );
        } else {
          return params.row.groupName;
        }
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            color="error"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              // delete groups state by params.row.id
              const temp = groups.filter((item) => item.id !== params.row.id);
              setGroups(temp);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    setGroups(initialValues.groups);
  }, [initialValues.groups]);

  useEffect(() => {
    vesselGroupStore.getVesselGroupOptions("").then((res) => {
      setObjectIdsOption(res);
    });
  }, [vesselGroupStore]);

  const handleOnChangeObjectId = (val: TSelectedData) => {
    setSelectedObjectId(val.value);
  };

  const handleAddObjectIds = () => {
    const found = objectIdsOption.find((option) => option.value === selectedObjectId);
    const duplicate = groups.find((option) => option.id === selectedObjectId);
    if (found && !duplicate) {
      if (found?.additionalText === "OBJECT") {
        setGroups([
          ...groups,
          {
            id: found.value,
            groupName: found.text,
            objects: [],
          },
        ]);
      } else {
        vesselGroupStore.getVesselGroupDetail(found.value).then((res) => {
          if (res)
            setGroups([
              ...groups,
              {
                id: res.id ?? "",
                groupName: res.vesselGroupName,
                objects: res.groups.map((item) => {
                  return { id: item.id, groupName: item.groupName };
                }),
              },
            ]);
        });
      }
    } else {
      setValidationObject(true);
    }
  };

  if (connectionStore.loadingForm) return <RkdBackdrop />;

  return (
    <RkdCard title={`${isUpdate ? "Edit" : "New"} Connection`}>
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiFormControl-root": { mb: 2 },
          width: "50%",
        }}
      >
        <TextField
          name="connectionName"
          label="Connection Name"
          variant="outlined"
          fullWidth
          required
          value={values.connectionName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.connectionName && !!errors.connectionName}
          helperText={touched.connectionName && errors.connectionName}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            disablePortal
            fullWidth
            disabled={vesselGroupStore.loadingListOptions}
            sx={{ flex: 1 }}
            onChange={(e: any, val: any) => {
              setSelectedObject(val);
              handleOnChangeObjectId(val);
            }}
            disableClearable
            value={selectedObject}
            getOptionLabel={(option) => option.label}
            options={objectIdsOption.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => (
              <TextField
                error={validationObject}
                helperText={validationObject ? "Please select other object" : ""}
                value={selectedObject?.label}
                name="objectIds"
                {...params}
                label="Object"
              />
            )}
          />
          <Button sx={{ width: "53px", height: "53px", mb: 2, ml: 1 }} variant="contained" color="primary" onClick={handleAddObjectIds}>
            {" "}
            <AddIcon />{" "}
          </Button>
        </Box>

        <DataGrid rows={groups} columns={gridColumns} getRowHeight={() => "auto"} autoHeight sx={{ mb: 3 }} />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ mr: "8px" }}
            onClick={(e) => {
              handleReset(e);
              navigate("/master/connection");
            }}
            onBlur={handleBlur}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" type="submit" disabled={!isValid} loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </RkdCard>
  );
}

export default observer(ConnectionForm);
