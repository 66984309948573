import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';


interface IPorps {
  maxHeight?: string,
  width?: string,
  toggleModal: boolean,
  handleCloseModal: () => void,
  children?: JSX.Element | JSX.Element[],
}



function RkdModalForm(props: IPorps) {
  const { maxHeight, width, toggleModal, handleCloseModal, children } = props;
  const modalWrapper = {
    overflow:"auto",
    maxHeight: maxHeight ?? "100vh",
    display:"flex",
  };
  
  const modalBlock = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ?? "98%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  }
  
  const modalContentStyle ={
    position:"relative",
    borderRadius:"10px",
    width:"100%",
  };

  return (
    <Modal
        open={toggleModal}
        onClose={handleCloseModal}
        aria-describedby="modal-modal-description"
        disableScrollLock
        sx={modalWrapper}
      >
      <Box sx={modalBlock}>
        <Box sx={modalContentStyle}>
          {children} 
        </Box>
      </Box>
    </Modal>
  );
}

export default observer(RkdModalForm);